import styled from "styled-components";

const Wrapper = styled.div`
  .ant-segmented-item-selected {
    background: linear-gradient(45deg, #601c46, #e3775f) !important;
    color: #ffffff;
    font-weight: bold;
  }
  .ant-tag-blue-inverse {
    background: linear-gradient(
      45deg,
      rgb(96, 28, 70),
      rgb(227, 119, 95)
    ) !important;
    border-color: transparent !important;
    min-height: 36px;
    line-height: 36px;
    padding: 0 11px;
    font-size: 16px;
  }
  .ant-segmented-item,
  .ant-segmented {
    :where(.css-dev-only-do-not-override-i56vxb).ant-segmented
      .ant-segmented-item {
      transition: color 0s cubic-bezier(0, 0, 0, 0);
    }
  }

  .progress-section {
    width: 100%;
    max-width: 65vw;
    border-radius: 40px;
    background: #ffffff;
    padding: 1rem 2rem;
    margin: 0 auto;
    box-shadow: 0px 1px 10px 0px #0000001f;

    img {
      width: 300px;
      display: block;
      margin: 20px auto;
    }
    .title {
      font-weight: 700;
      font-size: 3rem;
      text-transform: uppercase;
      text-align: center;
      padding-bottom: 20px;
    }
    .task-progress {
      margin: 1.5rem 0;
    }

    .task-progress p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #525252;
      font-size: 3rem;
    }

    span {
      color: #525252;
    }
    .progress3 {
      width: 80% !important;
    }
    .progress {
      -webkit-appearance: none;
      appearance: none;
      border: none;
      border-radius: 10px;
      height: 3rem;
    }

    .progress::-webkit-progress-bar {
      background-color: #f0f0f0;
    }

    .progress::-webkit-progress-bar,
    .progress::-webkit-progress-value {
      border-radius: 10px;
    }

    .progress::-moz-progress-bar {
      border-radius: 10px;
    }

    .progress1::-webkit-progress-value {
      background: #185ee2;
    }

    .progress2::-webkit-progress-value {
      background: #ffb453;
    }

    .progress3::-webkit-progress-value {
      background: #18e23c;
    }
  }
`;

export default Wrapper;
export const CardWrapper = styled.div`
  .card_ {
    padding: 1rem;
    position: relative;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    // max-width: 320px;
    border-radius: 20px;
  }

  .title {
    display: flex;
    align-items: center;
  }
  .IconsView {
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    height: fit-content;
    margin: auto;
    width: auto;
    background: #fef3f2;
    padding: 13px;
    border-radius: 29px;
    border: 1px dashed #601947;
  }

  .title span {
    position: relative;
    padding: 0.5rem;
    background-color: #10b981;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 9999px;
  }

  .title span svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    height: 1rem;
  }

  .title-text {
    margin-left: 0.5rem;
    color: #374151;
    font-weight: 500;
    font-size: larger;
  }

  .percent {
    margin-left: 0.5rem;
    color: #02972f;
    font-weight: 600;
    display: flex;
  }

  .data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .data p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #000;
    font-weight: 600;
    font-size: x-large;
    // font-size: 2.25rem;
    line-height: 2.5rem;
    // font-weight: 700;
    text-align: left;
  }

  .data .range {
    position: relative;
    background-color: #e5e7eb;
    width: 100%;
    height: 0.5rem;
    border-radius: 0.25rem;
  }

  .data .range .fill {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #10b981;
    width: 76%;
    height: 100%;
    border-radius: 0.25rem;
  }
`;
export const DetailsViewWrapper = styled.div`
  .container-top-card {
    background: #fff;
    padding: 0px 0px 20px 10px;
    border-radius: 14px;
    margin: 0px 7px;
    box-shadow: 0px 0px 90px #efefef;
  }
  .card-top {
    border-left: 2px dotted #9c4063;
    padding: 0px 0px 0px 20px;
  }
  .card-top:first-child {
    border-left: none;
    padding: 0px 0px 0px 10px;
  }
  .icon-side {
    &::before {
      content: "";
    }
  }
  .F1::before {
    background: #f0e2e8 !important;
  }
  .F2::before {
    background: #c7c9f4 !important;
  }
  .F3::before {
    background: #ffd3b0 !important;
  }
  .F4::before {
    background: #b9e9c2 !important;
  }
  .F1_border,
  .F2_border,
  .F3_border,
  .F4_border {
    z-index: 99;
    height: 100vh;
    position: absolute;
  }
  .F1_border {
    border-left: 2px solid #9c4063;
  }
  .F2_border {
    border-left: 2px solid #373cb7;
  }
  .F3_border {
    border-left: 2px solid #df7d30;
  }
  .F4_border {
    border-left: 2px solid #1ca532;
  }

  .card-center {
    border: 1px dashed #9c4063;
    padding: 10px;
    border-radius: 13px;
    background: #f9f3f6;
  }
  .card-order {
    display: grid;
    border-radius: 14px;
    justify-items: center;
    align-content: space-between;
    padding: 20px;
    height: 220px;
    background: #f6f6f6;
    position: relative;
  }
  .card-icons-view {
    background: #fff;
    width: 90px;
    height: 90px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px dashed #9c4063;
  }

  .progress-section {
    width: 100%;
    max-width: 65vw;
    border-radius: 40px;
    background: #ffffff;
    padding: 1rem 2rem;
    margin: 0 auto;
    // box-shadow: 0px 1px 10px 0px #0000001f;

    img {
      width: 300px;
      display: block;
      margin: 20px auto;
    }
    .title {
      font-weight: 700;
      font-size: 3rem;
      text-transform: uppercase;
      text-align: center;
      padding-bottom: 20px;
    }
    .task-progress {
      margin: 1.5rem 0;
    }

    .task-progress p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #525252;
      font-size: 3rem;
    }

    span {
      color: #525252;
    }
    .progress3 {
      width: 80% !important;
    }
    .progress {
      -webkit-appearance: none;
      appearance: none;
      border: none;
      border-radius: 10px;
      height: 3rem;
    }

    .progress::-webkit-progress-bar {
      background-color: #f0f0f0;
    }

    .progress::-webkit-progress-bar,
    .progress::-webkit-progress-value {
      border-radius: 10px;
    }

    .progress::-moz-progress-bar {
      border-radius: 10px;
    }

    .progress1::-webkit-progress-value {
      background: #185ee2;
    }

    .progress2::-webkit-progress-value {
      background: #ffb453;
    }

    .progress3::-webkit-progress-value {
      background: #18e23c;
    }
  }
`;
