import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SystemLog from "../../modules/pages/application-logs/system-log";
import {
  GetUserSystemLogAction,
  fakeActionSystemLog,
} from "../../redux/module_/application-logs/system-log/index.action";

const mapStateToProps = (state) => ({
  SystemEventLogListResponse: state.System.SystemEventLogListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetUserSystemLogAction,
      fakeActionSystemLog,
    },
    dispatch
  );

const SystemLog__ = connect(mapStateToProps, mapDispatchToProps)(SystemLog);

export default SystemLog__;
