import React, { createContext, useState } from "react";

// export const GlobalSpinnerContext = createContext();
export const GlobalContext = createContext();
const GlobalCommonContextProvider = (props) => {
  const [commonGlobalVal, setCommonGlobalVal] = useState({
    window_: window,
    user_:
      localStorage.getItem("user") !== "undefined"
        ? JSON.parse(localStorage.getItem("user"))
        : {},
    role_configuration: localStorage.getItem("role_configuration")
      ? JSON.parse(localStorage.getItem("role_configuration"))
      : {},

    admin: localStorage.getItem("admin") ? true : false,
    account_type: parseInt(localStorage.getItem("account_type")) || 1,
    date_range: localStorage.getItem("date_range")
      ? JSON.parse(localStorage.getItem("date_range"))
      : {},
  });

  return (
    <GlobalContext.Provider
      value={{
        data: commonGlobalVal,
        updateCommonGlobalVal: (rest) => {
          setCommonGlobalVal({ ...commonGlobalVal, ...rest });
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalCommonContextProvider;
