import React from "react";
import { CardWrapper, DetailsViewWrapper } from "../style";
import { Segmented, Statistic, Table, Tag } from "antd";
import { Icon } from "@iconify/react";
import {
  Intellectual_property_count,
  Listring_policy_count,
  Product_authenticity_count,
  Product_safety_count,
  late_shipment_rate,
  on_time_delivery_rate,
  pre_fulfillment_cancellation_rate,
  valid_tracking_rate,
} from "./icons";
import { numberformat } from "../../../../config";
import dayjs from "dayjs";
import CountUp from "react-countup";
const formatter = (value) => <CountUp end={value} separator="," />;
const DetailsView = ({
  marketplaceData,
  filterDays,
  setFilterDays,
  defective_orders,
  faulti_invoices,
}) => {
  const topCard = [
    {
      label: "Product Authenticity Problems",
      value: "product_authenticity_data_defect_count_all",
    },
    {
      label: "Product Safety Problems",
      value: "product_safety_data_defect_count_all",
    },
    {
      label: "Ads Policy Problems",
      value: 0,
    },
    {
      label: "Intellectual Property Issues",
      value: "intellectual_property_data_defect_count_all",
    },
  ];
  const secondCard = [
    {
      label: "Late Shipment Rate",
      value: "late_shipment_rate",
      value1: "orderCount",
      color: "F1",
      icon: "#9C4063",
      iconView: late_shipment_rate,
    },
    {
      label: "Pre-fulfillment Cancel Rate",
      value: "pre_fulfillment_cancellation_rate",
      value1: "orderCount",
      color: "F2",
      icon: "#373CB7",
      iconView: pre_fulfillment_cancellation_rate,
    },
    {
      label: "Valid Tracking Rate",
      value: "valid_tracking_rate",
      value1: "shipmentCount",
      color: "F3",
      icon: "#DF7D30",
      iconView: valid_tracking_rate,
    },
    {
      label: "On-Time Delivery Rate",
      value: "on_time_delivery_rate",
      value1: "onTimeDeliveryCount",
      color: "F4",
      icon: "#1CA532",
      iconView: on_time_delivery_rate,
    },
  ];

  const PolicyComplianceList = [
    {
      label: "Suspected Intellectual Property Violations",
      bgColor: "#15c653",
      borderColor: "#afefbe",
      value: "suspected_intellectual_property_violations",
    },
    {
      label: "Received Intellectual Property Complaints",
      bgColor: "#f7c002",
      borderColor: "#fce7a9",
      value: "received_intellectual_property_complaints",
    },
    {
      label: "Product Authenticity Customer Complaints",
      bgColor: "#1a85ff",
      borderColor: "#b0d2ff",
      value: "product_authenticity_customer_complaints",
    },
    {
      label: "Product Condition Customer Complaints",
      bgColor: "#f8285b",
      borderColor: "#ffb7c6",
      value: "product_condition_customer_complaints",
    },
    {
      label: "Food and Product Safety Issues",
      bgColor: "#7239ea",
      borderColor: "#d0bff9",
      value: "food_and_product_safety_issues",
    },
    {
      label: "Listing Policy Violations",
      bgColor: "#f7c002",
      borderColor: "#fce7a9",
      value: "listing_policy_violations",
    },
    {
      label: "Restricted Product Policy Violations",
      bgColor: "#1a85ff",
      borderColor: "#b0d2ff",
      value: "restricted_product_policy_violations",
    },
    {
      label: "Customer Product Reviews Policy Violations",
      bgColor: "#f8285b",
      borderColor: "#ffb7c6",
      value: "customer_product_reviews_policy_violations",
    },
    {
      label: "Other Policy Violations",
      bgColor: "#7239ea",
      borderColor: "#d0bff9",
      value: "other_policy_violations",
    },
  ];

  const columns = [
    {
      title: "Date Range",

      render: (e) => {
        return (
          <span style={{ fontSize: "14px" }} className="  me-2 lh-1">
            {dayjs(new Date(e?.reportingDateRange?.reportingDateFrom)).format(
              "MMM DD, YYYY"
            )}
            -
            {dayjs(new Date(e?.reportingDateRange?.reportingDateTo)).format(
              "MMM DD, YYYY"
            )}
          </span>
        );
      },
    },
    {
      title: "Target Value",

      render: (e) => {
        return (
          <span style={{ fontSize: "14px" }} className="  me-2 lh-1">
            {e?.targetValue || 0}
          </span>
        );
      },
    },
    {
      title: "Target Condition",
      render: (e) => {
        return (
          <span style={{ fontSize: "14px" }} className="  me-2 lh-1">
            {e?.targetCondition || 0}
          </span>
        );
      },
    },
    {
      title: "Invoice Defect",

      render: (e) => {
        return (
          <span style={{ fontSize: "14px" }} className="  me-2 lh-1">
            <span style={{ fontWeight: 400 }}>Status:</span>{" "}
            {e?.invoiceDefect?.status || 0},{" "}
            <span style={{ fontWeight: 400 }}>Count:</span>{" "}
            {e?.invoiceDefect?.count || 0}
          </span>
        );
      },
    },
    {
      title: "Status",
      render: (e) => {
        return (
          <span
            className="  me-2 lh-1"
            style={{ fontWeight: 400, fontSize: "14px" }}
          >
            {e?.status || 0}
          </span>
        );
      },
    },
    {
      title: "Missing Invoice",
      render: (e) => {
        return (
          <span style={{ fontSize: "14px" }} className="  me-2 lh-1">
            <span style={{ fontWeight: 400 }}>Status:</span>{" "}
            {e?.missingInvoice?.status || 0},{" "}
            <span style={{ fontWeight: 400 }}>Count:</span>{" "}
            {e?.missingInvoice?.count || 0}
          </span>
        );
      },
    },
    {
      title: "Late Invoice",

      render: (e) => {
        return (
          <span
            style={{ fontSize: "14px" }}
            className=" fw-bolder text-gray-600 me-2 lh-1"
          >
            <span style={{ fontWeight: 400 }}>Status:</span>{" "}
            {e?.lateInvoice?.status || 0},{" "}
            <span style={{ fontWeight: 400 }}>Count:</span>{" "}
            {e?.lateInvoice?.count || 0}
          </span>
        );
      },
    },
    {
      title: "Order Count",
      render: (e) => {
        return (
          <span
            style={{ fontSize: "14px" }}
            className=" fw-bolder text-gray-600 me-2 lh-1"
          >
            {e?.orderCount || 0}
          </span>
        );
      },
    },
    {
      title: "Rate",
      render: (e) => {
        return (
          <span className="fs-4 fw-bolder text-gray-600 me-2 lh-1">
            {e?.rate || 0}
          </span>
        );
      },
    },
  ];

  const defectiveOrderList = [
    {
      label: "Product authenticity count",
      value: "product_authenticity_count",
      iconView: Product_authenticity_count,
    },
    {
      label: "Product safety count",
      value: "product_safety_count",
      iconView: Product_safety_count,
    },
    {
      label: "Listring policy count",
      value: "listing_policy_count",
      iconView: Listring_policy_count,
    },
    {
      label: "Intellectual property count",
      value: "intellectual_property_count",
      iconView: Intellectual_property_count,
    },
  ];

  const defectiveFirstList = [
    {
      label: "Order Defect Rate",
      value: "orderWithDefects",
      subValue: "rate",
    },
    {
      label: "Order Defect Count",
      value: "orderWithDefects",
      subValue: "count",
    },
    {
      label: "Orders",
      value: "orderCount",
      subValue: "",
    },
  ];
  function isFloat(n) {
    return !Number.isInteger(n);
  }

  return (
    <DetailsViewWrapper>
      <CardWrapper className="row g-5 g-xl-8 mt-5   justify-content-center">
        {[
          {
            title: "Order Defect Rate",
            prefix: "",
            BG: "#008cff",
            color: "#FFF",
            key: parseFloat(
              typeof defective_orders?.[0]?.orderWithDefects?.rate === "number"
                ? 0
                : parseFloat(
                    defective_orders?.[0]?.orderWithDefects?.rate?.replace(
                      "%",
                      ""
                    ) || 0
                  ).toFixed(2)
            ),
            icon: "hugeicons:ungroup-items",
            suffix: "%",
          },
          {
            title: "Order Defect Count",
            prefix: "",
            BG: "#fd3550",
            color: "#FFF",
            key: parseInt(defective_orders?.[0]?.orderWithDefects?.count || 0),
            icon: "fluent:text-word-count-24-regular",
            suffix: "",
          },
          {
            title: "Total Orders",
            prefix: "",
            BG: "#ffc107",
            color: "#000",
            key: parseInt(defective_orders?.[0]?.orderCount || 0),
            icon: "icon-park-outline:ad-product",
            suffix: "",
          },
          {
            title: "Late Shipment Rate",
            prefix: "",
            BG: "#15ca20",
            color: "#FFF",
            key: parseFloat(
              marketplaceData?.late_shipment_rate?.rate || 0
            ).toFixed(2),
            icon: "lets-icons:time",
            suffix: "%",
          },
          {
            title: "Pre-Fulfillment Cancel Rate",
            prefix: "",
            BG: "#0dcaf0",
            color: "#000",
            key: parseFloat(
              marketplaceData?.pre_fulfillment_cancellation_rate?.rate || 0
            ).toFixed(2),
            icon: "mdi:book-cancel",
            suffix: "%",
          },
          {
            title: "Valid Tracking Rate",
            prefix: "",
            BG: "#e72e7a",
            color: "#FFF",
            key: parseFloat(
              marketplaceData?.valid_tracking_rate?.rate || 0
            ).toFixed(2),
            icon: "hugeicons:shipment-tracking",
            suffix: "%",
          },
          {
            title: "On-Time Delivery Rate",
            prefix: "",
            BG: "#8932ff",
            color: "#FFF",
            key: parseFloat(
              marketplaceData?.on_time_delivery_rate?.rate || 0
            ).toFixed(2),
            icon: "ph:truck-fill",
            suffix: "%",
          },
          {
            title: "Product Authenticity",
            prefix: "",
            BG: "#ff6632",
            color: "#FFF",
            key: parseFloat(
              marketplaceData?.product_authenticity_count || 0
            ).toFixed(0),
            icon: "arcticons:bitwarden-authenticator",
            suffix: "",
          },
          {
            title: "Product Safety Problems",
            prefix: "",
            BG: "#212529",
            color: "#FFF",
            key: parseFloat(marketplaceData?.product_safety_count || 0).toFixed(
              0
            ),
            icon: "ic:sharp-safety-check",
            suffix: "",
          },
          {
            title: "Listing Policy Count",
            prefix: "",
            BG: "#FCDC94",
            color: "#000",
            key: parseFloat(marketplaceData?.listing_policy_count || 0).toFixed(
              0
            ),
            icon: "material-symbols:policy",
            suffix: "",
          },
          {
            title: "Ads Policy Problems",
            prefix: "",
            BG: "#C8CFA0",
            color: "#000",
            key: 0,
            icon: "academicons:ads",
            suffix: "",
          },
          {
            title: "Intellectual Property Issues",
            prefix: "",
            BG: "#78ABA8",
            color: "#FFF",
            key: parseFloat(
              marketplaceData?.intellectual_property_count || 0
            ).toFixed(0),
            icon: "mdi:property-tag-outline",
            suffix: "",
          },
        ]?.map((d, i) => (
          <div
            className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
            key={i}
            style={
              {
                // maxWidth: "500px",
                // minWidth: "300px",
              }
            }
          >
            <div
              className="card_"
              style={{
                backgroundColor: "#FFF",
                backgroundImage:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))",
              }}
            >
              <div className="title">
                <p className="title-text" style={{ color: "#6d3057" }}>
                  {d?.title}
                </p>
              </div>
              <div className="data">
                <p>
                  <Statistic
                    value={d?.key}
                    suffix={d?.suffix}
                    prefix={d?.prefix}
                    precision={isFloat(d?.key) ? 2 : 0}
                    formatter={formatter}
                  />
                </p>
              </div>
              <div className="IconsView">
                <Icon icon={d?.icon} width="35" color="#6d3057" height="35" />
              </div>
            </div>
          </div>
        ))}
      </CardWrapper>

      <div className="row d-none g-5 g-xl-8 mt-5 container-top-card justify-content-center">
        {topCard?.map((d, i) => (
          <div
            key={i}
            className="col card-top"
            style={{
              minWidth: "300px",
              maxWidth: "400px",
            }}
          >
            <a
              className=" text-hover-primary "
              style={{
                color: "#3d3d3d",
                fontSize: "14px",
                letterSpacing: "0.2px",
              }}
            >
              {d?.label}
            </a>
            <span
              className=""
              style={{ color: "#1c1c1c", fontSize: "22px", fontWeight: 900 }}
            >
              {marketplaceData?.problems?.[d?.value] || 0}
            </span>
          </div>
        ))}
      </div>
      <div className="row d-none g-5 g-xl-8 mt-1 px-2 wd justify-content-center">
        {secondCard?.map((d, i) => (
          <div
            className="col "
            style={{
              maxWidth: "500px",
              minWidth: "300px",
            }}
            key={i}
          >
            <div className="card  card-xl-stretch mb-4">
              <div className={`${d?.color}_border`} />
              <div className={`card-body  py-6   px-5 ${d?.color}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span className="">
                      <span className="ms-4">{d?.iconView}</span>
                    </span>
                  </div>
                  <div className=" text-end">
                    <a
                      class="card-title text-hover-primary "
                      style={{
                        color: "#818181",
                        fontSize: "18px",
                        letterSpacing: "0.2px",
                        fontWeight: 300,
                      }}
                    >
                      {d?.label}
                    </a>
                    <span className="fs-1 fw-bolder d-flex justify-content-end mb-1 align-items-center">
                      <div className="ant-statistic css-1enmpys">
                        <div className="ant-statistic-content">
                          <span className="ant-statistic-content-value">
                            <span
                              style={{
                                color: "#1c1c1c",
                                fontSize: "20px",
                                fontWeight: 900,
                              }}
                            >
                              {numberformat(
                                marketplaceData?.[d?.value]?.[d?.value1] || 0
                              )}
                              %
                            </span>
                          </span>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row d-none g-5 g-xl-8 px-2 ">
        <div className="col">
          <div className="card card-flush h-md-100">
            <div className="card-header">
              <div
                className="card-title "
                style={{ color: "#1c1c1c", fontSize: "23px", fontWeight: 500 }}
              >
                Defective Orders
              </div>
            </div>
            <div
              className="card-body pt-0"
              style={{ display: "grid", alignContent: "space-between" }}
            >
              <div className="row g-5 g-xl-8 mb-4 justify-content-center">
                {defectiveFirstList?.map((d, i) => (
                  <div
                    className="col"
                    style={{ minWidth: "200px", maxWidth: "450px" }}
                    key={i}
                  >
                    <div className="card-center">
                      <a
                        className="card-title  d-block"
                        style={{
                          color: "#a95575",
                          fontSize: "13px",
                          letterSpacing: "0.2px",
                          fontWeight: 500,
                        }}
                      >
                        {d?.label}
                      </a>
                      <span
                        className="text-gray-900 fs-1 fw-bold me-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#1c1c1c",
                          fontSize: "19px",
                          fontWeight: 900,
                        }}
                      >
                        {parseFloat(
                          defective_orders?.[0]?.[d?.value]?.[
                            d?.subValue
                          ]?.toString() ||
                            defective_orders?.[0]?.[d?.value] ||
                            0
                        )?.toFixed(0)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ borderTop: "1px dashed #989898" }}></div>
              <div
                className="row  mt-1"
                style={{
                  justifyContent: "center",

                  paddingTop: "15px",
                }}
              >
                {defectiveOrderList?.map((d, i) => (
                  <div
                    className="col mb-lg-5"
                    style={{ minWidth: "220px", maxWidth: "380px" }}
                    key={i}
                  >
                    <div className="card-order">
                      <div
                        style={{
                          width: "81%",
                          height: "2px",
                          position: "absolute",
                          background: "#9C4063",
                        }}
                      />
                      <div className="card-icons-view">{d?.iconView}</div>
                      <div
                        className="d-grid"
                        style={{
                          justifyItems: "center",
                          height: "80px",
                        }}
                      >
                        <a
                          style={{
                            color: "#525252",
                            textAlign: "center",
                            fontSize: "12px",
                          }}
                        >
                          {d?.label}
                        </a>
                        <span
                          className=" me-2"
                          style={{
                            color: "#1c1c1c",
                            fontWeight: 900,
                            fontSize: "26px",
                          }}
                        >
                          {numberformat(marketplaceData?.[d?.value] || 0)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-5 g-xl-8 px-2 mt-3 ">
        <div className="col" style={{ minWidth: "300px" }}>
          <div className="card card-flush h-xl-100">
            <div className="card-header">
              <div
                className="card-title "
                style={{ color: "#1c1c1c", fontSize: "23px", fontWeight: 500 }}
              >
                Policy Compliance
              </div>
              <div className="card-toolbar">
                <Tag color="green-inverse">
                  {marketplaceData?.policy_compliance?.account_health_rating
                    ?.ahrStatus || 0}
                </Tag>
              </div>
            </div>
            <div className="card-body pt-0">
              <div
                style={{
                  background: "#f6f6f6",
                  padding: "20px",
                  borderRadius: "11px",
                  position: "relative",
                }}
                className="d-flex justify-content-between align-items-center"
              >
                <div
                  className="F4_border"
                  style={{ height: "80%", position: "absolute", left: 0 }}
                ></div>
                <div style={{ width: "100%" }}>
                  <div>
                    <h2>Account health rating</h2>
                    <span>
                      This rating reflects your adherence to Amazon's selling
                      policies.
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className=" mt-3"
                  >
                    <div style={{ width: "100%" }}>
                      <progress
                        className="progress progress3"
                        max="100"
                        style={{ width: "80%" }}
                        value={
                          ((marketplaceData?.policy_compliance
                            ?.account_health_rating?.ahrScore || 0) *
                            100) /
                          1000
                        }
                      ></progress>
                    </div>
                    <div style={{ fontWeight: 800 }}>
                      <span style={{ color: "#1ca532" }}>
                        {marketplaceData?.policy_compliance
                          ?.account_health_rating?.ahrScore || 0}
                      </span>
                      /<span>10,000</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  overflow: "auto",
                  maxHeight: "200px",
                  paddingRight: "10px",
                }}
                id="kt_aside_menu_wrapper"
                className="mt-6"
              >
                {PolicyComplianceList?.map((d, i) => (
                  <div
                    key={i}
                    className="d-flex align-items-center justify-content-between my-6"
                  >
                    <div className="d-flex align-items-center">
                      <span
                        style={{
                          minWidth: 15,
                          minHeight: 15,
                          maxWidth: 15,
                          maxHeight: 15,
                          display: "flex",
                          background: d?.bgColor,
                          borderRadius: "100%",
                          border: `2px solid ${d?.borderColor}`,
                        }}
                      ></span>

                      <span
                        style={{
                          marginLeft: "7px",
                          fontSize: "14px",
                          color: "#000",
                        }}
                      >
                        {d?.label}
                      </span>
                    </div>
                    <div>
                      <b>
                        {numberformat(
                          marketplaceData?.policy_compliance?.[d?.value]
                            ?.targetValue || 0
                        )}
                      </b>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card card-flush h-md-100">
            <div className="card-header">
              <div
                className="card-title"
                style={{ color: "#1c1c1c", fontSize: "23px", fontWeight: 500 }}
              >
                Customer Sevice Perfomance
              </div>
              <div className="card-toolbar">
                <Segmented
                  options={marketplaceData?.defective_orders?.customer_service_performance?.map(
                    (d) => `${d?.days} Days`
                  )}
                  onChange={(e) => {
                    setFilterDays(e?.replace(" Days", ""));
                  }}
                  value={`${filterDays} Days`}
                  size="large"
                />
              </div>
            </div>
            <div className="card-body">
              <h3
                className="mt-5"
                style={{
                  color: "#686868",
                  fontSize: "16.7px",
                  fontWeight: 500,
                }}
              >
                Order Defect Rate consists of three different metrics:
              </h3>
              <Table
                className="mt-15"
                dataSource={[
                  {
                    title: "Negative feedback",
                    value: "negativeFeedbacks",
                  },
                  {
                    title: "A-to-z Guarantee claims",
                    value: "a_z_claims",
                  },
                  {
                    title: "Chargeback claims",
                    value: "chargebacks",
                  },
                ]}
                pagination={false}
                columns={[
                  {
                    title: "Title",
                    render: (e) => {
                      return <b>{e.title}</b>;
                    },
                  },
                  {
                    title: "Rate",
                    render: (e) => {
                      return (
                        <span>
                          {numberformat(
                            defective_orders?.[0]?.[e?.value]?.rate
                          )}
                          %
                        </span>
                      );
                    },
                  },
                  {
                    title: "Count",
                    render: (e) => {
                      return (
                        <span>
                          {numberformat(
                            defective_orders?.[0]?.[e?.value]?.count
                          )}
                        </span>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row g-5 g-xl-8 px-2 mt-3">
        <div className="col">
          <div className="card card-flush h-xl-100">
            <div className="card-header">
              <div
                style={{ color: "#1c1c1c", fontSize: "23px", fontWeight: 500 }}
                className="card-title"
              >
                Faulty Invoices
              </div>
              <div className="card-toolbar">
                <Tag color="blue-inverse">7 days</Tag>
              </div>
            </div>
            <div className="card-body  pt-0">
              <div className="table-responsive">
                <Table
                  dataSource={faulti_invoices?.map((d) => ({
                    reportingDateRange: d?.reportingDateRange,
                    targetValue: d?.targetValue,
                    targetCondition: d?.targetCondition,
                    invoiceDefect: d?.invoiceDefect,
                    status: d?.status,
                    missingInvoice: d?.missingInvoice,
                    lateInvoice: d?.lateInvoice,
                    orderCount: d?.orderCount,
                  }))}
                  columns={columns}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DetailsViewWrapper>
  );
};

export default DetailsView;
