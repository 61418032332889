import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TestPage from "../../modules/pages/sales-analytics/report-account";

import {
  SalesChannelListAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";
import {
  CardOverViewAction,
  MonthCompareDataAction,
  MarketplaceDataAction,
  GetGraphDataAction,
  fakeActionAnalytics,
} from "../../redux/module_/analytics/index.action";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,

  CardOverviewDataResponse: state.Analytics.CardOverviewDataResponse,
  MonthCompareDataResponse: state.Analytics.MonthCompareDataResponse,
  MarketplaceDataResponse: state.Analytics.MarketplaceDataResponse,
  GetGraphDataResponse: state.Analytics.GetGraphDataResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      fakeActionCatalog,

      CardOverViewAction,
      MonthCompareDataAction,
      MarketplaceDataAction,
      GetGraphDataAction,
      fakeActionAnalytics,
    },
    dispatch
  );

const TestPage__ = connect(mapStateToProps, mapDispatchToProps)(TestPage);

export default TestPage__;
