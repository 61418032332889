export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "SYSTEM_EVENT_PROCESS_LIST_SUCCESS":
    case "SYSTEM_EVENT_PROCESS_LIST_ERROR":
      return {
        ...state,
        SystemEventProcessListResponse: action.updatePayload,
      };
    case "FEED_SYSTEM_EVENT_PROCESS_LIST_SUCCESS":
    case "FEED_SYSTEM_EVENT_PROCESS_LIST_ERROR":
      return {
        ...state,
        FeedSystemEventProcessListResponse: action.updatePayload,
      };
    case "GET_EVENT_NAME_SUCCESS":
    case "GET_EVENT_NAME_ERROR":
      return {
        ...state,
        GetEventNameListResponse: action.updatePayload,
      };
    case "GET_EVENT_TYPE_SUCCESS":
    case "GET_EVENT_TYPE_ERROR":
      return {
        ...state,
        GetEventTypeListResponse: action.updatePayload,
      };

    case "FAKE_ACTION_CENTRAL_LOG":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
