import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";

import { useSelector } from "react-redux";

import { Modal, Button, Form, Table, Input, Select } from "antd";

const { TextArea } = Input;
const { Option } = Select;

export default function EmbedCodes({
  GetMenuListAction,
  fakeActionManageUser,
  GetUserListAction,
  AddEmbedCodeAction,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [submitForm, setSubmitForm] = useState(false);

  const [userList, setUserList] = useState([]);

  const EmbeddedListResponse = useSelector(
    (state) => state.User.EmbeddedListResponse || {}
  );
  const UserListResponse = useSelector(
    (state) => state.User.UserListResponse || {}
  );
  const AddEmbeddedResponse = useSelector(
    (state) => state.User.AddEmbeddedResponse || {}
  );

  useEffect(() => {
    if (AddEmbeddedResponse?.status === true) {
      setSubmitForm(false);
      setLoading(true);
      setIsModalVisible(false);
      setModalType("Add");
      GetMenuListAction();
      fakeActionManageUser("AddEmbeddedResponse");
    } else if (AddEmbeddedResponse?.status === false) {
      setSubmitForm(false);
      fakeActionManageUser("AddEmbeddedResponse");
    }
  }, [AddEmbeddedResponse]);
  useEffect(() => {
    if (UserListResponse?.status === true) {
      setUserList(UserListResponse?.data?.records || []);
      fakeActionManageUser("UserListResponse");
    } else if (UserListResponse?.status === false) {
      setList([]);
      fakeActionManageUser("UserListResponse");
    }
  }, [UserListResponse]);
  useEffect(() => {
    if (EmbeddedListResponse?.status === true) {
      setList(EmbeddedListResponse?.data?.records || []);
      setLoading(false);
      fakeActionManageUser("EmbeddedListResponse");
    } else if (EmbeddedListResponse?.status === false) {
      setLoading(false);
      setList([]);
      fakeActionManageUser("EmbeddedListResponse");
    }
  }, [EmbeddedListResponse]);

  useEffect(() => {
    setLoading(true);
    GetUserListAction();
    GetMenuListAction();

    return () => {};
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "User Name",
      dataIndex: "u_name",
      key: "u_name",
      sorter: (a, b) =>
        a.u_name.toLowerCase().localeCompare(b.u_name.toLowerCase()),
    },
    {
      title: "Link 1",
      dataIndex: "link_1",
      key: "link_1",
      width: 550,
    },
    {
      title: "Link 2",
      dataIndex: "link_2",
      key: "link_2",
      width: 550,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (timestamp) => new Date(timestamp * 1000).toLocaleString(), // Example date formatting
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
      render: (timestamp) => new Date(timestamp * 1000).toLocaleString(), // Example date formatting
    },
    {
      title: "Action",

      render: (a) => {
        return (
          <button
            onClick={() => {
              setIsModalVisible(true);
              setModalType("Edit");
              form.setFieldsValue({ ...a });
            }}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
          >
            <span className="svg-icon svg-icon-3">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                  fill="currentColor"
                />
                <path
                  d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </button>
        );
      }, // Example date formatting
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState("Add");

  const showModal = () => {
    setIsModalVisible(true);
    setModalType("Add");
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log(userList, "userList");

  const onFinish = (values) => {
    console.log("Form Values:", values);
    setIsModalVisible(false);
  };

  return (
    <Wrapper>
      <div className="card">
        <div className="card-header">
          <div className="card-title">Embed Codes</div>
          <div className="card-toolbar">
            <Button size="large" onClick={showModal} type="primary">
              Add +
            </Button>
          </div>
        </div>
        <div className="card-body pb-0">
          <Table
            pagination={true}
            scroll={{ x: "max-content" }}
            dataSource={list}
            loading={loading}
            columns={columns}
          />
        </div>
      </div>

      <Modal
        title={`${modalType} Embed Code`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            type="primary"
            loading={submitForm}
            onClick={() => {
              form.validateFields().then((values) => {
                setSubmitForm(true);
                AddEmbedCodeAction(values);
              });
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form} id="modalForm" layout="vertical" onFinish={onFinish}>
          <Form.Item name="link_1" label="Report Account">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="link_2" label="Advertising Account">
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="user_id"
            label="Users"
            rules={[{ required: true, message: "Please select an user!" }]}
          >
            <Select
              placeholder="Select an user"
              options={userList?.map((d) => ({
                label: d?.u_name,
                value: d?.u_id,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Wrapper>
  );
}
