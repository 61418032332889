import axiosCall from "../../configurations/network-services/axiosCall";

export const ChangePasswordAction = (id, data) => {
  const path = `change-password`;
  const responseType = "CHANGE_PASSWORD_PROFILE_";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateUserAction = (id, data) => {
  const path = `user-update/${id}`;
  const responseType = "UPDATE_USER_PROFILE_";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionProfile = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_PROFILE", state: data });
};
