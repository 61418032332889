import React, { useState } from "react";
import { Modal, Form, Input, Button, Segmented, message } from "antd";

const Create = ({ isOpen, onClose, CompanyAddAction }) => {
  const onFinish = (values) => {
    message.destroy();
    message.loading("Loading...", 0);
    CompanyAddAction(values);

    // onClose();
  };

  return (
    <Modal title="Add Company" open={isOpen} onCancel={onClose} footer={null}>
      <Form name="basic" onFinish={onFinish} layout="vertical">
        <div style={{ maxHeight: "calc(100vh - 440px)", overflow: "auto" }}>
          <Form.Item
            label="Company Name"
            name="company_name"
            rules={[{ required: true, message: "Please enter company name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Full Address"
            name="full_address"
            rules={[{ required: true, message: "Please enter full address" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="VAT"
            name="vat"
            rules={[{ required: true, message: "Please enter VAT" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Village"
            name="village"
            rules={[{ required: true, message: "Please enter village" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Telephone Number"
            name="telephone_number"
            rules={[
              { required: true, message: "Please enter telephone number" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Reference Contact Name"
            name="reference_contact_name"
            rules={[
              {
                required: true,
                message: "Please enter reference contact name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Reference Email"
            name="reference_email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Amazon Contact Name"
            name="amazon_contact_name"
            rules={[
              { required: true, message: "Please enter Amazon contact name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Amazon Contact Email"
            name="amazon_contact_email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid Amazon contact email",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{ display: "flex", justifyContent: "end" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Create;
