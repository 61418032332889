import { Tag, Table, Select, Skeleton } from "antd";
import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import { DotChartOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import { SwapCommaAndDot } from "../../../../config";

const AsinView = ({
  sellerList,
  sellerListLoading,
  selectedRow,
  marketplaceSelected,
  ItemsOfferGetASINOffers,
  fakeActionCatalog,
  ItemsOfferGetASINOffersGraph,
  ItemsOfferGetSellerOffersGraph,
  onClose,
}) => {
  const [chart1, setChart1] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const [asinWiseGraph, setAsinWiseGraph] = useState([]);
  const [asinWiseGraphLoading, setAsinWiseGraphLoading] = useState(true);
  const [sign, setSign] = useState("$");

  const [sellerGraph, setSellerGraph] = useState([]);
  const [sellerGraphLoading, setSellerGraphLoading] = useState(true);
  const [otherData, setOtherData] = useState({});
  const ItemsOfferGetASINOffersResponse = useSelector(
    (state) => state.Catalog.ItemsOfferGetASINOffersResponse || {}
  );
  const ItemsOfferGetASINOffersGraphResponse = useSelector(
    (state) => state.Catalog.ItemsOfferGetASINOffersGraphResponse || {}
  );
  const ItemsOfferGetSellerOffersGraphResponse = useSelector(
    (state) => state.Catalog.ItemsOfferGetSellerOffersGraphResponse || {}
  );

  useEffect(() => {
    if (ItemsOfferGetSellerOffersGraphResponse?.status) {
      setSign(ItemsOfferGetSellerOffersGraphResponse?.data?.currency);
      setSellerGraph(ItemsOfferGetSellerOffersGraphResponse?.data?.records);
      setSellerGraphLoading(false);
      fakeActionCatalog("ItemsOfferGetSellerOffersGraphResponse");
    } else if (ItemsOfferGetSellerOffersGraphResponse?.status === false) {
      setSellerGraph([]);
      setSellerGraphLoading(false);
      fakeActionCatalog("ItemsOfferGetSellerOffersGraphResponse");
    }
  }, [ItemsOfferGetSellerOffersGraphResponse]);
  useEffect(() => {
    if (ItemsOfferGetASINOffersGraphResponse?.status) {
      try {
        setSign(ItemsOfferGetASINOffersGraphResponse?.data?.currency);

        setChart1(
          ItemsOfferGetASINOffersGraphResponse?.data?.records?.chart_data?.[0]
            ?.name || null
        );
        setAsinWiseGraph(ItemsOfferGetASINOffersGraphResponse?.data?.records);
        setAsinWiseGraphLoading(false);
        fakeActionCatalog("ItemsOfferGetASINOffersGraphResponse");
      } catch (e) {
        console.error("Error", e);
      }
    } else if (ItemsOfferGetASINOffersGraphResponse?.status === false) {
      setAsinWiseGraph([]);
      setAsinWiseGraphLoading(false);
      fakeActionCatalog("ItemsOfferGetASINOffersGraphResponse");
    }
  }, [ItemsOfferGetASINOffersGraphResponse]);
  useEffect(() => {
    if (ItemsOfferGetASINOffersResponse?.status) {
      if (ItemsOfferGetASINOffersResponse?.data?.records?.length > 0) {
        setSellerGraphLoading(true);
        ItemsOfferGetSellerOffersGraph({
          ...selectedRow,
          ...marketplaceSelected,
          seller_id:
            ItemsOfferGetASINOffersResponse?.data?.records?.[0]?.seller_id ||
            null,
        });
      }
      setTableData(ItemsOfferGetASINOffersResponse?.data?.records);
      setOtherData(ItemsOfferGetASINOffersResponse?.data?.products);
      setTableLoading(false);
      fakeActionCatalog("ItemsOfferGetASINOffersResponse");
    } else if (ItemsOfferGetASINOffersResponse?.status === false) {
      setTableData([]);
      setTableLoading(false);
      fakeActionCatalog("ItemsOfferGetASINOffersResponse");
    }
  }, [ItemsOfferGetASINOffersResponse]);

  const columns = [
    {
      title: "Seller ID",
      dataIndex: "seller_id", // Reflects the data property name from the data source
      key: "seller_id",
      align: "center",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Seller Name",
      dataIndex: "seller_name", // Reflects the data property name from the data source
      key: "seller_name",
      align: "center",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Buy Box Won last 30 days",
      dataIndex: "seller_offer", // Reflects the data property name from the data source
      key: "seller_offer",
      align: "center",
      render: (e) => {
        return <span>{e}/days</span>;
      },
    },
    // Add more column definitions as needed...
  ];

  console.log(tableData, "tableData");
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    tooltip: {
      // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      useHTML: true,
      formatter: function () {
        // Custom formatting logic
        let point = this.point;
        let seriesName = this.series.name;
        let value = point.y.toFixed(2); // Format value to 2 decimal places
        let percentage = point.percentage.toFixed(1); // Format percentage to 1 decimal place

        return `<b>${seriesName}</b><br><br>
                    ${point.name}: <b>${SwapCommaAndDot(
          parseFloat(percentage || 0).toLocaleString(),
          "",
          "",
          marketplaceSelected?.marketplace_id?.split("_")?.[0]
        )}%</b>
                    
                    `;
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: `<b class="fs-8">{point.name}</b>: <span class="fs-8">{point.percentage:.1f} %</span>`,
        },
      },
    },
    series: [
      {
        name: "Buy Box Share",
        colorByPoint: true,
        data: tableData?.map((d) => ({
          name: d?.seller_name || d.seller_id,
          y: parseFloat(d?.seller_offer),
        })),
      },
    ],
  };

  const generateLast30Days = () => {
    const dates = [];
    const dateFormat = { day: "numeric", month: "short", year: "numeric" };

    for (let i = 29; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push(
        date.toLocaleDateString("en-GB", dateFormat).replace(/ /g, "/")
      );
    }
    return dates;
  };
  const transformString = (str) => {
    // Remove underscores and add a space
    let modifiedString = str?.replace(/_/g, " ");

    // Capitalize the first letter of each word
    modifiedString = modifiedString?.replace(/\b\w/g, (char) =>
      char?.toUpperCase()
    );

    return modifiedString;
  };
  const optionsLine1 = {
    title: {
      text: "",
    },
    xAxis: {
      title: {
        text: "Day",
      },
      categories: generateLast30Days(),
    },
    yAxis: {
      title: {
        text: `Price (${sign})`,
      },
      labels: {
        formatter: function () {
          return SwapCommaAndDot(
            parseFloat(this.value || 0).toLocaleString(),
            sign,
            "",
            marketplaceSelected?.marketplace_id?.split("_")?.[0]
          ); // Custom format: currency with 2 decimal places
        },
      },
    },
    series: [],
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            // Custom formatting logic
            return SwapCommaAndDot(
              parseFloat(this.y || 0).toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id?.split("_")?.[0]
            );
          },
        },
        enableMouseTracking: true,
      },
    },

    tooltip: {
      formatter: function () {
        console.log(this.x, this.y, this.series.name, "this.x");
        return (
          "<b>" +
          this.series.name +
          "</b><br/>" +
          "Day: " +
          this.x +
          "<br/>" +
          "Value: " +
          SwapCommaAndDot(
            parseFloat(this.y || 0).toLocaleString(),
            sign,
            "",
            marketplaceSelected?.marketplace_id?.split("_")?.[0]
          )
        );
      },
    },
  };

  useEffect(() => {
    setChart1(Object.keys(sellerList || {})?.[0] || null);

    setTableData([]);
    setTableLoading(true);
    ItemsOfferGetASINOffers({
      ...selectedRow,
      ...marketplaceSelected,
    });

    setAsinWiseGraphLoading(true);
    ItemsOfferGetASINOffersGraph({
      ...selectedRow,
      ...marketplaceSelected,
    });
    setSellerGraphLoading(true);
    ItemsOfferGetSellerOffersGraph({
      ...selectedRow,
      ...marketplaceSelected,
      seller_id: Object.keys(sellerList || {})?.[0] || null,
    });
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card p-6">
          <div className="row">
            <div className="col-xxl-9 col-lg-7 col-md-12">
              <div className="d-flex flex-column py-2">
                <div className="d-flex align-items-center">
                  <svg
                    width={30}
                    onClick={onClose}
                    height={30}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    fill="#000"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.009 5.325c.3.299.3.784 0 1.084l-4.825 4.824h13.483a.767.767 0 0 1 0 1.534H6.184l4.825 4.824a.767.767 0 0 1-1.084 1.084L3.79 12.543c-.3-.3-.3-.785 0-1.084l6.134-6.133c.299-.3.784-.3 1.084 0Z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <img
                    src={otherData?.image}
                    alt
                    className="me-4 w-150px h-150px"
                  />

                  <div className="">
                    <div className="fs-4 fw-bold mb-3">
                      {selectedRow?.item_name}
                    </div>
                    <Tag color="blue">SKU : {selectedRow?.sku}</Tag>
                    <Tag color="cyan">ASIN : {selectedRow?.asin}</Tag>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-lg-5 col-md-12">
              <div className="row" style={{ justifyItems: "end" }}>
                <div className="col-xxl-12 col-md-6 border border-gray-300 border-dashed rounded  min-w-125px py-3 px-4 mb-3">
                  <div className="d-flex align-items-center">
                    <i className="ki-duotone ki-arrow-up fs-3 text-success me-2">
                      <span className="path1" />
                      <span className="path2" />
                    </i>{" "}
                    <div className="fs-2 fw-bold counted">
                      {otherData?.total_offers}
                    </div>
                  </div>

                  <div className="fw-semibold fs-6 text-gray-500">
                    Total Sellers
                  </div>
                </div>

                <div className="col-xxl-12 col-md-6 border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                  <div className="d-flex align-items-center">
                    <i className="ki-duotone ki-arrow-down fs-3 text-danger me-2">
                      <span className="path1" />
                      <span className="path2" />
                    </i>{" "}
                    <div className="fs-2 fw-bold counted">
                      {SwapCommaAndDot(
                        parseFloat(
                          otherData?.buybox_percentage || 0
                        ).toLocaleString(),
                        "",
                        "",
                        marketplaceSelected?.marketplace_id?.split("_")?.[0]
                      )}
                      %
                    </div>
                  </div>

                  <div className="fw-semibold fs-6 text-gray-500">
                    Sellers having Buy box{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xxl-6 col-lg-12">
            <div className="card mt-3">
              <div className="card-header">
                <div className="card-title">Buy Box Percentage</div>
              </div>
              <div className="card-body">
                <HighchartsReact highcharts={Highcharts} options={options} />
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-lg-12">
            <div className="card mt-3">
              <div className="card-header">
                <div className="card-title">Sellers having Buy box</div>
              </div>
              <div
                className="card-body"
                style={{ overflow: "auto", height: "452px" }}
              >
                <Table
                  columns={columns}
                  loading={tableLoading}
                  dataSource={tableData}
                  scroll={{ x: "max-content" }}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-6 col-lg-12">
            <div className="card mt-3">
              <div className="card-header">
                <div className="card-title">
                  Sellers wise Buy box price over last 30 days
                </div>
                <div className="card-toolbar">
                  <Select
                    onChange={(e) => {
                      setChart1(e);
                    }}
                    loading={sellerListLoading}
                    className="w-200px"
                    value={chart1}
                    options={asinWiseGraph?.chart_data?.map((d) => ({
                      label: d?.name,
                      value: d?.name,
                    }))}
                    placeholder="Select Seller"
                  />
                </div>
              </div>
              <div className="card-body">
                {sellerGraphLoading ? (
                  <div className="h-300px align-items-center d-flex justify-content-center">
                    <Skeleton.Node active>
                      <DotChartOutlined
                        style={{
                          fontSize: 40,
                          color: "#bfbfbf",
                        }}
                      />
                    </Skeleton.Node>
                  </div>
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      ...optionsLine1,
                      xAxis: {
                        title: {
                          text: "Day",
                        },
                        categories: asinWiseGraph?.chartDate?.map((d) =>
                          moment(d, "YYYY-MM-DD").format("DD, MMM YYYY")
                        ),
                      },
                      series: [
                        {
                          name: transformString(
                            asinWiseGraph?.chart_data?.filter(
                              (d) => d?.name === chart1
                            )?.[0]?.name
                          ),
                          data: asinWiseGraph?.chart_data
                            ?.filter((d) => d?.name === chart1)?.[0]
                            ?.data?.map((d) => parseFloat(d?.price)),
                        },
                      ],
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-lg-12">
            <div className="card mt-3">
              <div className="card-header">
                <div className="card-title">
                  Statistics of all Sellers having Buy box winner over last 30
                  days
                </div>
                <div className="card-toolbar"></div>
              </div>

              <div className="card-body">
                {asinWiseGraphLoading ? (
                  <div className="h-300px align-items-center d-flex justify-content-center">
                    <Skeleton.Node active>
                      <DotChartOutlined
                        style={{
                          fontSize: 40,
                          color: "#bfbfbf",
                        }}
                      />
                    </Skeleton.Node>
                  </div>
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      ...optionsLine1,
                      xAxis: {
                        title: {
                          text: "Day",
                        },
                        categories: asinWiseGraph?.chartDate?.map((d) =>
                          moment(d, "YYYY-MM-DD").format("DD, MMM YYYY")
                        ),
                      },
                      series: asinWiseGraph?.chart_data?.map((d) => {
                        console.log(d, "d");
                        return {
                          name: transformString(d?.name),
                          data: d?.data?.map((r) => parseFloat(r?.price)), //parseFloat(r?.price)
                        };
                      }),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsinView;
