import { Select, Table, Input, Segmented, DatePicker, Result } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../components/loading";
import Pagination from "../../../components/pagination";
import ASINLink from "../../../components/router-link";
import {
  AllMarketPlace,
  DefaultPerPage,
  MarketplaceFlag,
  checkPermission,
  numberformat,
} from "../../../config";
import BrandRevenueListWrapper from "./style";
import ASINTable from "../../../components/table";
import NoData from "../../../components/no-data";
import { EyeTwoTone } from "@ant-design/icons";
import {
  brandRevenueListSortBy,
  brandRevenueListColor,
} from "../../../config/static-select-options";

import ReactApexChart from "react-apexcharts";
import { GlobalContext } from "../../../commonContext";

const { Option } = Select;

const options = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: ["#4318FF", "#39B8FF", "#e2e8f0", "#01b574"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: "#7551FF",
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    // theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  },
  // yaxis: {
  //   show: false,
  // },
  legend: {
    show: true,
  },
  grid: {
    show: true,
    column: {
      color: ["#4318FF", "#39B8FF", "#e2e8f0", "#01b574"],
      opacity: 0.5,
    },
  },
  color: ["#4318FF", "#39B8FF", "#e2e8f0", "#01b574"],
};

// Example series data for different metrics

const series = [
  {
    name: "Units Sold",
    data: [10, 15, 20, 25, 30, 35],
  },
  {
    name: "Orders",
    data: [5, 10, 15, 20, 25, 30],
  },
  {
    name: "Revenue",
    data: [2000, 2500, 3000, 3500, 4000, 4500],
  },
  {
    name: "Net Profit",
    data: [800, 1000, 1200, 1500, 1800, 2000],
  },
  {
    name: "Net Margin",
    data: [40, 40, 40, 42, 45, 44],
  },
];

// Example metric options
const metricOptions = [
  { value: "Units Sold", label: "Units Sold" },
  { value: "Orders", label: "Orders" },
  { value: "Revenue", label: "Revenue" },
  { value: "Net Profit", label: "Net Profit" },
  { value: "Net Margin", label: "Net Margin" },
];

const colorView = {
  1: "Gold",
  2: "Green",
  3: "Orange",
  4: "Red",
};

export default function (props) {
  const contextValue = useContext(GlobalContext);
  const { SalesChannelListAction, fakeActionCatalog } = props;
  const [selectedMetric, setSelectedMetric] = useState("Units Sold");

  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterData, setFilterData] = useState({});
  const handleMetricChange = (value) => {
    setSelectedMetric(value);
  };

  const [selectTab, setSelectTab] = useState("Analytics by ASIN");

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );
            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                  },
                ],
              });
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);
        setMarketplaceLoading(false);
        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  const dataSource = [
    {
      Brand: "Brand A",
      ASIN: "ASIN123",
      "Units Sold": 200,
      Orders: 150,
      "Net Margin": 25,
      Revenue: 5000,
      "Net Profit": 1000,
      "Refunds Units": 20,
      Velocity: 5,
      "Refunds Revenue": 400,
      "Refund Cogs": 50,
      "Refund %": "4%",
      "Total Fees": 300,
      "Total COGs": 1200,
      Taxes: 150,
      "Available Inventory": 100,
      "Net ROI": "80%",
      "Buybox Price": 25,
      Sessions: 800,
      "Conversion Rate": "18%",
      "Sales Rank": 500,
      "PPC Cost": 200,
      "PPC Cost % Of Total Sales": "4%",
      "PPC Orders": 30,
      "PPC Sales": 600,
      "PPC Sales % Of Total Sales": "12%",
      "PPC Conversion": "10%",
      "PPC Clicks": 500,
      "PPC Impressions": 10000,
      "Warehouse Inventory": 200,
      "Inbound Inventory": 50,
      "Unfulfillable Inventory": 10,
      "Researching Inventory": 5,
      "Reserved Inventory": 20,
      "Total Inventory": 285,
    },
    // Add more data as needed
  ];

  const columnsTabBrand = [
    {
      title: "Brand",
      dataIndex: "Brand",
      key: "Brand",
    },
  ];
  const columnsTabASIN = [
    {
      title: "ASIN",
      dataIndex: "ASIN",
      key: "ASIN",
    },
  ];
  const columns = [
    {
      title: "Units Sold",
      dataIndex: "Units Sold",
      key: "Units Sold",
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Orders",
      dataIndex: "Orders",
      key: "Orders",
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Net Margin",
      dataIndex: "Net Margin",
      key: "Net Margin",
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Revenue",
      dataIndex: "Revenue",
      key: "Revenue",
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Net Profit",
      dataIndex: "Net Profit",
      key: "Net Profit",
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Refunds Units",
      dataIndex: "Refunds Units",
      key: "Refunds Units",
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Velocity",
      dataIndex: "Velocity",
      key: "Velocity",
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Refunds Revenue",
      dataIndex: "Refunds Revenue",
      key: "Refunds Revenue",
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Refund COGS",
      dataIndex: "Refund Cogs",
      key: "Refund Cogs",
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Refund %",
      dataIndex: "Refund %",
      key: "Refund %",
      render: (e) => {
        return <span>{numberformat(e)}%</span>;
      },
    },
    {
      title: "Total Fees",
      dataIndex: "Total Fees",
      key: "Total Fees",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Total COGs",
      dataIndex: "Total COGs",
      key: "Total COGs",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Taxes",
      dataIndex: "Taxes",
      key: "Taxes",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Available Inventory",
      dataIndex: "Available Inventory",
      key: "Available Inventory",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Net ROI",
      dataIndex: "Net ROI",
      key: "Net ROI",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Buybox Price",
      dataIndex: "Buybox Price",
      key: "Buybox Price",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Sessions",
      dataIndex: "Sessions",
      key: "Sessions",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Conversion Rate",
      dataIndex: "Conversion Rate",
      key: "Conversion Rate",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Sales Rank",
      dataIndex: "Sales Rank",
      key: "Sales Rank",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "PPC Cost",
      dataIndex: "PPC Cost",
      key: "PPC Cost",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "PPC Cost % Of Total Sales",
      dataIndex: "PPC Cost % Of Total Sales",
      key: "PPC Cost % Of Total Sales",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "PPC Orders",
      dataIndex: "PPC Orders",
      key: "PPC Orders",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "PPC Sales",
      dataIndex: "PPC Sales",
      key: "PPC Sales",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "PPC Sales % Of Total Sales",
      dataIndex: "PPC Sales % Of Total Sales",
      key: "PPC Sales % Of Total Sales",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "PPC Conversion",
      dataIndex: "PPC Conversion",
      key: "PPC Conversion",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "PPC Clicks",
      dataIndex: "PPC Clicks",
      key: "PPC Clicks",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "PPC Impressions",
      dataIndex: "PPC Impressions",
      key: "PPC Impressions",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Warehouse Inventory",
      dataIndex: "Warehouse Inventory",
      key: "Warehouse Inventory",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Inbound Inventory",
      dataIndex: "Inbound Inventory",
      key: "Inbound Inventory",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Unfulfillable Inventory",
      dataIndex: "Unfulfillable Inventory",
      key: "Unfulfillable Inventory",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Researching Inventory",
      dataIndex: "Researching Inventory",
      key: "Researching Inventory",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Reserved Inventory",
      dataIndex: "Reserved Inventory",
      key: "Reserved Inventory",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Total Inventory",
      dataIndex: "Total Inventory",
      key: "Total Inventory",
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
  ];

  useEffect(() => {
    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();
    return () => {};
  }, []);

  if (checkPermission(contextValue, "brand_analytics", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }

  return (
    <>
      <BrandRevenueListWrapper
        className="fadeInBottom  content d-flex flex-column flex-column-fluid p-5"
        id="kt_content"
      >
        <div className="card">
          <div className="card-header">
            <div className="card-title"></div>
            <div className="card-toolbar fix-over">
              {Object.keys(filterData)?.map((d, i) => {
                return (
                  <Input
                    className="w-150px me-3"
                    placeholder={`Select ${d}`}
                    size="large"
                    value={filterData?.[d]}
                    onChange={(e) => {
                      setFilterData({ ...filterData, [d]: e.target.value });
                    }}
                  />
                );
              })}

              <Select
                className="w-200px "
                placeholder="Select filter type"
                maxTagCount={"responsive"}
                getPopupContainer={(target) => target.parentNode}
                size="large"
                onChange={(e) => {
                  setSelectedFilter(e);
                  const nullObject = {};

                  for (const key of e) {
                    nullObject[key] = filterData?.[key] || null;
                  }
                  setFilterData(nullObject);
                }}
                value={selectedFilter}
                mode="multiple"
                options={[
                  "category",
                  "sub_category",
                  "colors",
                  "asin",
                  "brand",
                ]?.map((d) => {
                  return { label: d, value: d };
                })}
              />
              <DatePicker.RangePicker
                size="large"
                getPopupContainer={(target) => target.parentNode}
                className="w-250px me-3 ms-3"
              />
              <Select
                className="w-225px me-3"
                size="large"
                allowClear
                getPopupContainer={(target) => target.parentNode}
                options={marketplaceList}
                onChange={(_, e) => {
                  setMarketplaceSelected({
                    marketplace: e?.marketplace,
                    marketplace_id: e?.marketplace_id,
                    region: e?.region,
                    sales_channel: e?.sales_channel,
                  });
                }}
                value={marketplaceSelected?.marketplace_id}
                loading={marketplaceLoading}
                // value={selectedMarketplace}
                placeholder="Select Marketplace"
              />
            </div>
          </div>
          <div className="card-body">
            {" "}
            <ReactApexChart
              options={options}
              series={series.filter((item) => item.name === selectedMetric)}
              type="line"
              height={350}
            />
          </div>
        </div>
        <div className="card mt-5">
          <div className="card-body">
            <Table
              scroll={{ x: "max-content" }}
              dataSource={dataSource}
              columns={
                selectTab === "Analytics by ASIN"
                  ? [...columnsTabASIN, ...columns]
                  : [...columnsTabBrand, ...columns]
              }
            />
          </div>
        </div>
      </BrandRevenueListWrapper>
    </>
  );
}
