import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProductResearch from "../../modules/pages/product-research";
import {
  GetProductsListAction,
  GetCategoriesListAction,
  GetBrandsListAction,
  GetBrandsListFilterAction,
  GetConfigurationAction,
  UpdateConfigurationAction,
  GetDomainListAction,
  PutStoreMyListAction,
  AllMyListAction,
  DeleteMyListSingleAction,
  fakeActionProducts,
} from "../../redux/modules/product-research/product-research.action";

const mapStateToProps = (state) => ({
  GetProductsListResponse: state.ProductsResearch.GetProductsListResponse,
  DeleteMyListSingleResponse: state.ProductsResearch.DeleteMyListSingleResponse,
  GetAllMyListInProductsResponse:
    state.ProductsResearch.GetAllMyListInProductsResponse,
  PutStoreMyListProductResponse:
    state.ProductsResearch.PutStoreMyListProductResponse,
  GetCategoriesListResponse: state.ProductsResearch.GetCategoriesListResponse,
  GetBrandsListResponse: state.ProductsResearch.GetBrandsListResponse,
  GetConfigurationResponse: state.ProductsResearch.GetConfigurationResponse,
  GetDomainListResponse: state.ProductsResearch.GetDomainListResponse,
  UpdateConfigurationResponse:
    state.ProductsResearch.UpdateConfigurationResponse,
  GetBrandsListFilterResponse:
    state.ProductsResearch.GetBrandsListFilterResponse,

  // GetActiveCategoryResponse: state.AppLogs.GetActiveCategoryResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetProductsListAction,
      GetCategoriesListAction,
      GetBrandsListAction,
      GetBrandsListFilterAction,
      GetConfigurationAction,
      UpdateConfigurationAction,
      GetDomainListAction,
      PutStoreMyListAction,
      AllMyListAction,
      DeleteMyListSingleAction,
      fakeActionProducts,
    },
    dispatch
  );

const ProductResearchASINWiser = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductResearch);

export default ProductResearchASINWiser;
