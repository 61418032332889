export const LoginResponse = {
  code: "200",
  status: true,
  message: "User data found.",
  data: {
    auth_token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImp0aSI6ImZoXldpcCFhRzZETHNtaFMifQ.eyJpc3MiOiJhc2lud2lzZXIiLCJhdWQiOiJhc2lud2lzZXIiLCJqdGkiOiJmaF5XaXAhYUc2RExzbWhTIiwiaWF0IjoxNjkzNDcwMzM4LCJleHAiOjE2OTM1NTY3MzgsInVpZCI6Mn0.9Tj0o_VDOSv83Yw9BI3S8U6NP-g53YX0dBL7FpIxbtY",
    user_data: {
      id: 2,
      rds_credential_id: 1,
      server_credential_id: 1,
      parent: null,
      name: "Anis",
      seller_name: "Anish",
      user_type: 2,
      email: "anish@dulaboratories.com",
      photo: null,
      db_name: "aw_db_20a4axaud8_anish",
      contact_no: "5645656564",
      address: null,
      company_registration_number: "",
      company_name: "",
      vat: "",
      status: 1,
      pending_reports: 64,
      vat_type: "Standard_Rate",
      vat_on_cost: 1,
      vat_on_sale: 1,
      vat_on_rate: 20,
      is_big_data: 0,
      user_status: 0,
      amazon_advertising_client_id: null,
      amazon_advertising_client_secret: null,
      amazon_advertising_return_url: null,
      amazon_advertising_response: null,
      amazon_advertising_access_token: null,
      amazon_advertising_refresh_token: null,
      amazon_advertising_access_token_expiry: null,
      amazon_advertising_token_type: null,
      created_at: 1663838578,
      updated_at: 1693469703,
      deleted_at: null,
      deleted_by: null,
      stripe_id: null,
      card_brand: null,
      card_last_four: null,
      trial_ends_at: null,
      is_active_subscription: 1,
      subscription_status: null,
      is_user_credential: 1,
    },
    serverCredential: {
      id: 1,
      name: "ec2_server",
      ip_address: "18.130.209.14",
      public_ip_address: "18.130.209.14",
      instance_type: "t2.medium",
      platform: "ubuntu",
      is_default: 1,
      created_at: 1663584410,
      updated_at: 1663584410,
      deleted_at: null,
      deleted_by: null,
    },
    rdsCredential: {
      id: 1,
      name: "asinwiser-user",
      size: "db.t3.medium",
      hostname: "asinwiser-user.cceolp2dbxeh.eu-west-2.rds.amazonaws.com",
      username: "root",
      password: "FjymUVzSKNgft5Yt",
      is_default: 1,
      created_at: 1663584410,
      updated_at: 1663584410,
      deleted_at: null,
      deleted_by: null,
    },
    currencyCode: null,
    services: [
      {
        title: "Sales Analytics",
        slug: "sales-analytics",
      },
      {
        title: "Product Research",
        slug: "product-research",
      },
      {
        title: "Competitor Research",
        slug: "competitor-research",
      },
      {
        title: "Inventory",
        slug: "inventory",
      },
      {
        title: "Account Status",
        slug: "account-status",
      },
      {
        title: "Reports",
        slug: "reports",
      },
      {
        title: "Brand Analytics",
        slug: "brand-analytics",
      },
      {
        title: "Amazon Catalog Health",
        slug: "amazon-catalog-health",
      },
      {
        title: "Finance",
        slug: "finance",
      },
      {
        title: "Customer sentiment",
        slug: "customer-sentiment",
      },
      {
        title: "Vendor",
        slug: "vendor",
      },
      {
        title: "Advertising Analytics",
        slug: "advertising-analytics",
      },
      {
        title: "Agency",
        slug: "agency",
      },
      {
        title: "Client Data",
        slug: "client-data",
      },
      {
        title: "Catalog",
        slug: "catalog",
      },
    ],
    plan: null,
  },
  error: [],
};
export const CheckSubscriptionResponse = {
  code: "200",
  status: true,
  message: "Subscription details.",
  data: {
    is_active_subscription: 1,
    subscription_status: null,
    services: [
      {
        title: "Sales Analytics",
        slug: "sales-analytics",
      },
      {
        title: "Product Research",
        slug: "product-research",
      },
      {
        title: "Competitor Research",
        slug: "competitor-research",
      },
      {
        title: "Inventory",
        slug: "inventory",
      },
      {
        title: "Account Status",
        slug: "account-status",
      },
      {
        title: "Reports",
        slug: "reports",
      },
      {
        title: "Brand Analytics",
        slug: "brand-analytics",
      },
      {
        title: "Amazon Catalog Health",
        slug: "amazon-catalog-health",
      },
      {
        title: "Finance",
        slug: "finance",
      },
      {
        title: "Customer sentiment",
        slug: "customer-sentiment",
      },
      {
        title: "Vendor",
        slug: "vendor",
      },
      {
        title: "Advertising Analytics",
        slug: "advertising-analytics",
      },
      {
        title: "Agency",
        slug: "agency",
      },
      {
        title: "Client Data",
        slug: "client-data",
      },
      {
        title: "Catalog",
        slug: "catalog",
      },
    ],
    plan: null,
  },
  error: [],
};
export const GetSalesChannelMarketplaceResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    sales_channel: [
      {
        id: 0,
        sales_channel: "ALL",
        marketplace: null,
        region: null,
        marketplace_id: null,
        default_marketplace: 0,
        status: 1,
        created_at: 1693472236,
        updated_at: 1693472236,
      },
      {
        id: 1,
        sales_channel: "Amazon.co.uk",
        marketplace: "UK",
        region: null,
        marketplace_id: "A1F83G8C2ARO7P",
        default_marketplace: 1,
        status: 1,
        created_at: 1688019115,
        updated_at: 1693464006,
      },
      {
        id: 2,
        sales_channel: "Amazon.es",
        marketplace: "Spain",
        region: null,
        marketplace_id: "A1RKKUPIHCS9HS",
        default_marketplace: 0,
        status: 1,
        created_at: 1688019116,
        updated_at: 1693464006,
      },
      {
        id: 3,
        sales_channel: "Amazon.it",
        marketplace: "Italy",
        region: null,
        marketplace_id: "APJ6JRA9NG5V4",
        default_marketplace: 0,
        status: 1,
        created_at: 1688019116,
        updated_at: 1693464006,
      },
      {
        id: 4,
        sales_channel: "Non-Amazon",
        marketplace: null,
        region: null,
        marketplace_id: null,
        default_marketplace: 0,
        status: 1,
        created_at: 1688019116,
        updated_at: 1693464006,
      },
      {
        id: 5,
        sales_channel: "Amazon.fr",
        marketplace: "France",
        region: null,
        marketplace_id: "A13V1IB3VIYZZH",
        default_marketplace: 0,
        status: 1,
        created_at: 1688019117,
        updated_at: 1693464006,
      },
      {
        id: 6,
        sales_channel: "Amazon.de",
        marketplace: "Germany",
        region: null,
        marketplace_id: "A1PA6795UKMFR9",
        default_marketplace: 0,
        status: 1,
        created_at: 1688019117,
        updated_at: 1693464006,
      },
      {
        id: 7,
        sales_channel: "Amazon.nl",
        marketplace: "Netherlands",
        region: null,
        marketplace_id: "A1805IZSGTT6HS",
        default_marketplace: 0,
        status: 1,
        created_at: 1688019118,
        updated_at: 1693464006,
      },
      {
        id: 8,
        sales_channel: "SI UK Prod Marketplace",
        marketplace: null,
        region: null,
        marketplace_id: null,
        default_marketplace: 0,
        status: 1,
        created_at: 1688019118,
        updated_at: 1693464006,
      },
      {
        id: 9,
        sales_channel: "Amazon.pl",
        marketplace: "Poland",
        region: null,
        marketplace_id: "A1C3SOZRARQ6R3",
        default_marketplace: 0,
        status: 1,
        created_at: 1688019118,
        updated_at: 1693464006,
      },
    ],
    currency_sign_list: {
      A2Q3Y263D00KWC: "R$",
      A2EUQ1WTGCTBG2: "$",
      A1AM78C64UM0Y8: "$",
      ATVPDKIKX0DER: "$",
      A2VIGQ35RCS4UG: "AED",
      A1PA6795UKMFR9: "€",
      ARBP9OOSHTCHU: "EGP",
      A1RKKUPIHCS9HS: "€",
      A13V1IB3VIYZZH: "€",
      AMEN7PMS3EDWL: "€",
      A1F83G8C2ARO7P: "£",
      A21TJRUUN4KGV: "₹",
      APJ6JRA9NG5V4: "€",
      A1805IZSGTT6HS: "€",
      A1C3SOZRARQ6R3: "zł",
      A17E79C6D8DWNP: "SAR",
      A2NODRKZP88ZB9: "kr",
      A33AVAJ2PDY3EV: "₺",
      A19VAU5U5O7RUS: "S$",
      A39IBJ37TRP1C6: "$",
      A1VC38T7YXB528: "¥",
    },
  },
  error: [],
};
export const GetColumnConfigurationResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: [],
  error: [],
};
export const GetAnalyticsBrandChartResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    chartData: [],
    chartDate: [],
  },
  error: [],
};
export const GetAnalyticsBrandListResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [],
    pagination: {
      totalCount: 8,
      page: 1,
      page_size: 10,
      page_count: 1,
    },
  },
  error: [],
};
export const GetAnalyticsSKUListResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [],
    pagination: {
      totalCount: 18,
      page: 1,
      page_size: 10,
      page_count: 2,
    },
  },
  error: [],
};
export const GetAnalyticsSKUChartResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    chartData: [],
    chartDate: [],
  },
  error: [],
};
export const GetAnalyticsASINListResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [],
    pagination: {
      totalCount: 18,
      page: 1,
      page_size: 10,
      page_count: 2,
    },
  },
  error: [],
};
export const GetAnalyticsCategoryChartResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    chartData: [],
    chartDate: [],
  },
  error: [],
};
export const GetAnalyticsCategoryListResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [],
    pagination: {
      totalCount: 3,
      page: 1,
      page_size: 10,
      page_count: 1,
    },
  },
  error: [],
};
export const GetDomainListResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    domain_list: {
      1: "US(Amazon.com)",
      2: "UK(Amazon.co.uk)",
      3: "Germany(Amazon.de)",
      4: "France(Amazon.fr)",
      6: "Canada(Amazon.ca)",
      8: "Italy(Amazon.it)",
      9: "Spain(Amazon.es)",
      10: "India(Amazon.in)",
      11: "Mexico(Amazon.com.mx)",
    },
    currency_sign_list: {
      1: "$",
      2: "£",
      3: "€",
      4: "F",
      6: "$",
      7: "€",
      9: "€",
      10: "₹",
      11: "$",
    },
  },
  error: [],
};
export const GetConfigurationResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: [
    {
      _id: {
        $oid: "633ec68e304d00003600115b",
      },
      fieldName: "title",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec68e304d00003600115c",
      },
      fieldName: "parentAsin",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec68f304d00003600115e",
      },
      fieldName: "buyBoxSellerIdHistory",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec68f304d00003600115f",
      },
      fieldName: "salesRankReference",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec68f304d000036001161",
      },
      fieldName: "reviewsCount",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec68f304d000036001162",
      },
      fieldName: "productPartNumber",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec690304d000036001163",
      },
      fieldName: "manufacturer",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec690304d000036001165",
      },
      fieldName: "productGroup",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec690304d000036001166",
      },
      fieldName: "model",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec690304d000036001167",
      },
      fieldName: "color",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec690304d000036001168",
      },
      fieldName: "size",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec690304d000036001169",
      },
      fieldName: "binding",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec691304d00003600116a",
      },
      fieldName: "lastPriceChange",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec691304d00003600116b",
      },
      fieldName: "trackingSince",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec691304d00003600116c",
      },
      fieldName: "rootCategory",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec691304d00003600116d",
      },
      fieldName: "categoryName",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec691304d00003600116e",
      },
      fieldName: "categoryId",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec691304d00003600116f",
      },
      fieldName: "reviewCount90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec691304d000036001170",
      },
      fieldName: "reviewCount180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec692304d000036001171",
      },
      fieldName: "packageLength",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec692304d000036001172",
      },
      fieldName: "packageHeight",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec692304d000036001173",
      },
      fieldName: "packageWidth",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec692304d000036001174",
      },
      fieldName: "packageWeight",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec692304d000036001175",
      },
      fieldName: "packageQuantity",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec692304d000036001176",
      },
      fieldName: "itemWeight",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec693304d000036001177",
      },
      fieldName: "releaseDate",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec693304d000036001178",
      },
      fieldName: "publicationDate",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec693304d000036001179",
      },
      fieldName: "numberOfPages",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec693304d00003600117a",
      },
      fieldName: "numberOfItems",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec693304d00003600117b",
      },
      fieldName: "author",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec693304d00003600117c",
      },
      fieldName: "format",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec693304d00003600117d",
      },
      fieldName: "edition",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec694304d00003600117e",
      },
      fieldName: "isAdultProduct",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec694304d00003600117f",
      },
      fieldName: "isEligibleForTradeIn",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec694304d000036001180",
      },
      fieldName: "type",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec694304d000036001181",
      },
      fieldName: "amazonCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec694304d000036001182",
      },
      fieldName: "amazon30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec694304d000036001183",
      },
      fieldName: "amazon90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec694304d000036001184",
      },
      fieldName: "amazon180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec695304d000036001185",
      },
      fieldName: "usedCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec695304d000036001186",
      },
      fieldName: "used30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec695304d000036001187",
      },
      fieldName: "used90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec695304d000036001188",
      },
      fieldName: "used180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec695304d000036001189",
      },
      fieldName: "usedOOS90Days",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec695304d00003600118a",
      },
      fieldName: "lightningDealsCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec695304d00003600118b",
      },
      fieldName: "warehouseDealsCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec696304d00003600118c",
      },
      fieldName: "warehouseDeals30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec696304d00003600118d",
      },
      fieldName: "warehouseDeals90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec696304d00003600118e",
      },
      fieldName: "warehouseDeals180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec696304d00003600118f",
      },
      fieldName: "currentCollectible",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec696304d000036001190",
      },
      fieldName: "collectible30daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec696304d000036001191",
      },
      fieldName: "collectible90daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec697304d000036001192",
      },
      fieldName: "collectible180daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec697304d000036001193",
      },
      fieldName: "new3rdPartyFBMcurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec697304d000036001194",
      },
      fieldName: "new3rdPartyFBM30Days",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec697304d000036001195",
      },
      fieldName: "new3rdPartyFBM90Days",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec697304d000036001196",
      },
      fieldName: "new3rdPartyFBM180Days",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec697304d000036001197",
      },
      fieldName: "new90DaysOOS",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec697304d000036001198",
      },
      fieldName: "buyBoxCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec698304d000036001199",
      },
      fieldName: "buyBox90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec698304d00003600119a",
      },
      fieldName: "buyBoxOOS90Days",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec698304d00003600119b",
      },
      fieldName: "buyBox180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec698304d00003600119c",
      },
      fieldName: "newOfferCount",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec698304d00003600119d",
      },
      fieldName: "newOfferCount30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec698304d00003600119e",
      },
      fieldName: "newOfferCount90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec698304d00003600119f",
      },
      fieldName: "newOfferCount180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec699304d0000360011a0",
      },
      fieldName: "usedOfferCountCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec699304d0000360011a1",
      },
      fieldName: "usedOfferCount30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec699304d0000360011a2",
      },
      fieldName: "usedOfferCount90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec699304d0000360011a3",
      },
      fieldName: "usedOfferCount180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69a304d0000360011a6",
      },
      fieldName: "buyBoxStock",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69a304d0000360011a7",
      },
      fieldName: "tradeInCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69a304d0000360011a8",
      },
      fieldName: "tradeIn30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69a304d0000360011a9",
      },
      fieldName: "tradeIn90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69a304d0000360011aa",
      },
      fieldName: "newEbayCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69a304d0000360011ab",
      },
      fieldName: "newEbay30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69a304d0000360011ac",
      },
      fieldName: "newEbay90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69b304d0000360011ad",
      },
      fieldName: "newEbay180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69b304d0000360011ae",
      },
      fieldName: "usedEbayCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69b304d0000360011af",
      },
      fieldName: "usedEbay30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69b304d0000360011b0",
      },
      fieldName: "usedEbay90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69b304d0000360011b1",
      },
      fieldName: "usedEbay180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69b304d0000360011b2",
      },
      fieldName: "refurbishedCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69b304d0000360011b3",
      },
      fieldName: "refurbished30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69c304d0000360011b4",
      },
      fieldName: "refurbished90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69c304d0000360011b5",
      },
      fieldName: "refurbished180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69c304d0000360011b6",
      },
      fieldName: "likeNewCollectibleCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69c304d0000360011b7",
      },
      fieldName: "likeNewCollectible30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69c304d0000360011b8",
      },
      fieldName: "likeNewCollectible90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69c304d0000360011b9",
      },
      fieldName: "likeNewCollectible180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69d304d0000360011ba",
      },
      fieldName: "veryGoodCollectibleCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69d304d0000360011bb",
      },
      fieldName: "veryGoodCollectible30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69d304d0000360011bc",
      },
      fieldName: "veryGoodCollectible90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69d304d0000360011bd",
      },
      fieldName: "veryGoodCollectible180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69d304d0000360011be",
      },
      fieldName: "goodCollectibleCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69d304d0000360011bf",
      },
      fieldName: "goodCollectible90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69d304d0000360011c0",
      },
      fieldName: "acceptableCollectibleCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69e304d0000360011c1",
      },
      fieldName: "acceptableCollectible90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69e304d0000360011c2",
      },
      fieldName: "newCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69e304d0000360011c3",
      },
      fieldName: "new30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69e304d0000360011c4",
      },
      fieldName: "new90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69e304d0000360011c5",
      },
      fieldName: "new180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69e304d0000360011c7",
      },
      fieldName: "salesRank30daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69f304d0000360011c8",
      },
      fieldName: "salesRank90daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69f304d0000360011c9",
      },
      fieldName: "salesRank180daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69f304d0000360011ca",
      },
      fieldName: "salesRankDrop30days",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69f304d0000360011cb",
      },
      fieldName: "salesRankDrop90days",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69f304d0000360011cc",
      },
      fieldName: "salesRankDrop180days",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69f304d0000360011cd",
      },
      fieldName: "lowestSellerFBM",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a0304d0000360011cf",
      },
      fieldName: "buyBoxSellerId",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a0304d0000360011d0",
      },
      fieldName: "usedLikeNewCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a0304d0000360011d1",
      },
      fieldName: "usedLikeNew30daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a0304d0000360011d2",
      },
      fieldName: "usedLikeNew90daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a0304d0000360011d3",
      },
      fieldName: "usedLikeNew180daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a0304d0000360011d4",
      },
      fieldName: "usedVeryGoodCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a1304d0000360011d5",
      },
      fieldName: "usedVeryGood90daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a1304d0000360011d6",
      },
      fieldName: "usedGoodCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a1304d0000360011d7",
      },
      fieldName: "usedGood30daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a1304d0000360011d8",
      },
      fieldName: "usedGood90daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a1304d0000360011d9",
      },
      fieldName: "usedGood180daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a1304d0000360011da",
      },
      fieldName: "refurbishedOfferCountCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a1304d0000360011db",
      },
      fieldName: "refurbishedOfferCount30daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a2304d0000360011dc",
      },
      fieldName: "refurbishedOfferCount90daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a2304d0000360011dd",
      },
      fieldName: "refurbishedOfferCount180daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a2304d0000360011de",
      },
      fieldName: "collectibleOfferCountCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a2304d0000360011df",
      },
      fieldName: "collectibleOfferCount30daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a2304d0000360011e0",
      },
      fieldName: "collectibleOfferCount90daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a2304d0000360011e1",
      },
      fieldName: "collectibleOfferCount180daysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a2304d0000360011e2",
      },
      fieldName: "listPriceCurrent",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a3304d0000360011e3",
      },
      fieldName: "listPrice30DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a3304d0000360011e4",
      },
      fieldName: "listPrice90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec6a3304d0000360011e5",
      },
      fieldName: "listPrice180DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "63b6a9f71786c09412a93da1",
      },
      fieldName: "avgNewOfferCount",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "63b6aa2c1786c09412a93da2",
      },
      fieldName: "reviewsCount90DaysAvg",
      status: 0,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec68e304d00003600115a",
      },
      fieldName: "asin",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec68f304d00003600115d",
      },
      fieldName: "imageCSV",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec68f304d000036001160",
      },
      fieldName: "ratingReviews",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec690304d000036001164",
      },
      fieldName: "brand",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec699304d0000360011a4",
      },
      fieldName: "offerCountFBA",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec699304d0000360011a5",
      },
      fieldName: "offerCountFBM",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69e304d0000360011c6",
      },
      fieldName: "currentSalesRank",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "633ec69f304d0000360011ce",
      },
      fieldName: "amazon90DaysOOS",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "63723d3863f163f8700eeb52",
      },
      fieldName: "productCodeEAN",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "63723d3863f163f8700eeb53",
      },
      fieldName: "productCodeUPC",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "63a5316b6a200cea450d45c1",
      },
      fieldName: "estimatedRevenue",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "63b6a9d31786c09412a93da0",
      },
      fieldName: "variationASINs",
      status: 1,
      sticky: 0,
    },
    {
      _id: {
        $oid: "63c7ebae92c7f05d8ec53f2f",
      },
      fieldName: "estimatedSales",
      status: 1,
      sticky: 0,
    },
  ],
  error: [],
};
export const GetAllMyListInProductsResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: [],
  error: [],
};
export const GetActiveCategoryResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: [],
  error: [],
};
export const GetBrandsListResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [],
    pagination: [],
  },
  error: [],
};
export const GetBrandsListFilterResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [
      {
        BrandId: 7853171,
        Name: "TheftBlock",
      },
      {
        BrandId: 1027475,
        Name: "Yorkim",
      },
    ],
    pagination: [],
  },
  error: [],
};
export const GetProductsListResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [],
    pagination: {
      totalCount: 134856,
      page: 1,
      pageSize: "10",
      pageCount: "10",
      offset: 0,
    },
    amazonImagePath: "https://images-na.ssl-images-amazon.com/images/I/",
  },
  error: [],
};
export const GetCompetitorListResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [],
    pagination: {
      totalCount: 54459,
      page: 1,
      pageSize: "10",
      pageCount: "10",
      offset: 0,
    },
    amazonImagePath: "https://images-na.ssl-images-amazon.com/images/I/",
  },
  error: [],
};
export const GetMarketplaceListResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    marketplace_list: [
      {
        marketplace_id: "A1F83G8C2ARO7P",
        marketplace: "United Kingdom",
        sales_channel: "Amazon.co.uk",
        marketplace_name: "UK",
        region: "Europe",
        region_name: "eu-west-1",
        sellercentral_url: "https://sellercentral.amazon.co.uk",
        vat_setting: {
          id: "2",
          marketplace_id: "A1F83G8C2ARO7P",
          vat_type: "standard_rate",
          vat_on_cost: "1",
          vat_on_sale: "1",
          vat_on_rate: "20",
          vat_apply_from: null,
          created_at: "1685969697",
          updated_at: "1685969697",
        },
      },
    ],
    currency_sign_list: {
      A2Q3Y263D00KWC: "R$",
      A2EUQ1WTGCTBG2: "$",
      A1AM78C64UM0Y8: "$",
      ATVPDKIKX0DER: "$",
      A2VIGQ35RCS4UG: "AED",
      A1PA6795UKMFR9: "€",
      ARBP9OOSHTCHU: "EGP",
      A1RKKUPIHCS9HS: "€",
      A13V1IB3VIYZZH: "€",
      AMEN7PMS3EDWL: "€",
      A1F83G8C2ARO7P: "£",
      A21TJRUUN4KGV: "₹",
      APJ6JRA9NG5V4: "€",
      A1805IZSGTT6HS: "€",
      A1C3SOZRARQ6R3: "zł",
      A17E79C6D8DWNP: "SAR",
      A2NODRKZP88ZB9: "kr",
      A33AVAJ2PDY3EV: "₺",
      A19VAU5U5O7RUS: "S$",
      A39IBJ37TRP1C6: "$",
      A1VC38T7YXB528: "¥",
    },
  },
  error: [],
};
export const GetInventoryDetailsDataResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    type_data: {
      in_stock: 14,
      out_of_stock: 730,
      inbound: 0,
      inbound_working: 0,
      inbound_shipped: 0,
      inbound_receiving: 0,
      unsellable: 3,
      researching: 0,
      reserved: 1,
      fc_transfer: 0,
      fc_processing: 1,
      customer_orders: 0,
      all: 744,
      ageing_91_180: 0,
      stranded: 2,
    },
    records: [],
    pagination: {
      totalCount: 744,
      page: 1,
      page_size: 10,
      page_count: 75,
    },
  },
  error: [],
};
export const GetBrandWiseAnalyticsListResponse = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [],
    pagination: {
      totalCount: 16098,
      page: 1,
      pageSize: "10",
      pageCount: "10",
      offset: 0,
    },
  },
  error: [],
};
