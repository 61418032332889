import { CaretRightOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Collapse,
  Input,
  Result,
  Segmented,
  Select,
  Tabs,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../commonContext";
import { AllMarketPlace, checkPermission } from "../../../config";
import Wrapper from "./style";
import Icons from "../../../components/icons";
import NoData from "../../../components/no-data";
import Chart from "react-apexcharts";

export default function (props) {
  const { SalesChannelListAction, fakeActionCatalog } = props;

  const contextValue = useContext(GlobalContext);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    account_type: null,
  });
  const [isSubmit, setIsSubmit] = useState(true);
  const [selectedTab, setSelectedTab] = useState("1");
  const [sign, setSign] = useState("$");

  const [filters, setFilters] = useState({
    search_text: "",
  });

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );

            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                        {/* {`Amazon.${e?.account_type || ''}.${e?.short_name || ''}`} */}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                    account_type: e?.account_type,
                  },
                ],
              });
              if (e?.default_marketplace === 1) {
                setMarketplaceSelected({
                  marketplace: e?.marketplace,
                  marketplace_id: e.marketplace_id,
                  region: e.region,
                  sales_channel: e.sales_channel,
                  account_type: e?.account_type,
                });
              }
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);
        setMarketplaceLoading(false);
        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);

      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  useEffect(() => {
    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();
    return () => {};
  }, []);

  const title = {
    1: "Amazon Fulfillment",
    2: "Your Fulfillment",
  };

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Revenue", "Cost", "Net Profit"],
    },
    yaxis: {
      title: {
        text: "$ (USD)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val;
        },
      },
    },
    colors: ["#546E7A", "#FFCA28", "#ff8300"],
    // legend: {
    //   position: "top",
    //   horizontalAlign: "right",
    //   offsetX: 40,
    // },
  };

  const series = [
    {
      name: "Amazon Fulfillment",
      data: [1, 16.26, -15.26],
    },
    {
      name: "Merchant Fulfillment",
      data: [1, 1.29, -0.29],
    },
    {
      name: "Catalog Fulfillment",
      data: [1, 1.29, -0.29],
    },
  ];

  if (checkPermission(contextValue, "finance", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }

  return (
    <>
      <Wrapper className="fadeInBottom content d-flex flex-column flex-column-fluid">
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-5 fix-over">
          <div></div>
          <div className=" fix-over">
            <Button
              onClick={() => setIsSubmit(true)}
              size="large"
              className="me-3"
              type="primary"
            >
              Search
            </Button>
            <Input
              placeholder="Search by ASIN"
              onChange={(e) =>
                setFilters({ ...filters, search_text: e.target.value })
              }
              value={filters?.search_text}
              size="large"
              onPressEnter={() => {
                setIsSubmit(true);
              }}
              className="w-200px me-3"
            />
            <Select
              className="w-225px "
              size="large"
              allowClear
              getPopupContainer={(target) => target.parentNode}
              options={marketplaceList}
              onChange={(_, e) => {
                setMarketplaceSelected({
                  marketplace: e?.marketplace,
                  marketplace_id: e?.marketplace_id,
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                  account_type: e?.account_type,
                });
              }}
              value={marketplaceSelected?.marketplace_id}
              loading={marketplaceLoading}
              placeholder="Select Marketplace"
            />
          </div>
        </div>
        {isSubmit ? (
          <>
            <div
              className="card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-7"
              style={{
                backgroundSize: "auto calc(100% + 10rem)",
                backgroundPositionX: "100%",
                backgroundImage:
                  'url("https://preview.keenthemes.com/metronic8/demo23/assets/media/illustrations/sketchy-1/4.png")',
              }}
            >
              {/*begin::Card header*/}
              <div className="card-header pt-10">
                <div className="d-flex align-items-center">
                  {/*begin::Icon*/}
                  <div className="me-3">
                    <div className="">
                      <img
                        className="w-175px"
                        src="https://m.media-amazon.com/images/I/41vGflQZxAS._SL120_.jpg"
                        alt="image"
                      />
                    </div>
                  </div>
                  {/*end::Icon*/}
                  {/*begin::Title*/}
                  <div className="d-flex flex-column">
                    <h2 className="fs-1hx lh-4 fw-bold text-gray-900 me-2  ls-n2 w-75 mb-1">
                      Rebecca Mobili Orologio Da Appendere, Orologi Design, Mdf
                      Metallo, Bianco Grigio, Stile Moderno, Per Camera Ufficio
                      - Misure: 50 x 50 x 4,5 cm (HxLxP) - Art. RE6589
                    </h2>
                    <div
                      className="text-muted fw-bold"
                      style={{ fontSize: "20px" }}
                    >
                      <a href="#">B0957ZMRMH</a>
                    </div>
                  </div>
                  {/*end::Title*/}
                </div>
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pb-0">
                {/*begin::Navs*/}
                <Tabs
                  activeKey={selectedTab}
                  onChange={(e) => setSelectedTab(e)}
                  items={[
                    {
                      key: "1",
                      label: "Amazon Fulfillment",
                      children: null,
                    },
                    {
                      key: "2",
                      label: "Merchant Fulfillment",
                      children: null,
                    },
                    {
                      key: "3",
                      label: "Catalog Fulfillment",
                      children: null,
                    },
                  ]}
                />

                {/*begin::Navs*/}
              </div>
              {/*end::Card body*/}
            </div>
            <div className="row">
              {selectedTab == "1" && (
                <div className="col-xxl-4 col-xl-6 col-lg-12 col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Amazon Fulfillment</div>
                    </div>

                    <div
                      style={{ maxHeight: "300px", overflow: "auto" }}
                      className="card-body"
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <Tooltip
                          title="Price charged to the customer. Amazon adds the item price and shipping when assessing competitiveness across sellers."
                          placement="bottom"
                        >
                          <label className="fw-bold">Item price</label>
                        </Tooltip>
                        <Input className="mw-200px" prefix={sign} />
                      </div>
                      <div className="separator my-5" />
                      <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        // defaultActiveKey={["1"]}
                      >
                        <Collapse.Panel
                          header={
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Amazon fees</span>
                              <b>{sign}7.48</b>
                            </div>
                          }
                          key="1"
                        >
                          <div className="separator mb-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Referral Fee</label>
                            <b>{sign}6.49</b>
                          </div>
                          <div className="separator my-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Fixed Closing Fee</label>
                            <b>{sign}0.99</b>
                          </div>
                          <div className="separator my-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Variable Closing Fee</label>
                            <b>{sign}0.00</b>
                          </div>
                        </Collapse.Panel>
                        <Collapse.Panel
                          header={
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Fulfillment cost</span>
                              <b>{sign}9.91</b>
                            </div>
                          }
                          key="2"
                        >
                          <div className="separator mb-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>FBA fulfillment fees</label>
                            <b>{sign}9.91</b>
                          </div>

                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <Tooltip
                              title="Cost of shipping the item to an Amazon fulfillment center. You may use an average to account for zone differences."
                              placement="bottom"
                            >
                              <label>Shipping to Amazon</label>
                            </Tooltip>
                            <Input className="mw-200px" prefix={sign} />
                          </div>
                        </Collapse.Panel>
                        <Collapse.Panel
                          header={
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Storage cost</span>
                              <b>{sign}0.45</b>
                            </div>
                          }
                          key="3"
                        >
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <Tooltip
                              placement="bottom"
                              title="The estimated amount you'll pay in storage for the product per unit sold. This amount is calculated by dividing the total estimated storage cost (monthly storage cost per unit multiplied by average inventory units stored) by the estimated monthly units sold for each fulfillment channel."
                            >
                              <label>Storage cost per unit sold</label>
                            </Tooltip>
                            <b>{sign}0.45</b>
                          </div>
                        </Collapse.Panel>
                        <Collapse.Panel
                          header={
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Other costs</span>
                              <b>{sign}7.57</b>
                            </div>
                          }
                          key="4"
                        >
                          <div className="separator mb-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <Tooltip
                              title="The VAT value provided is an estimate based on the standard rate. You can edit the percentage if you know the reduced or super-reduced rate for this product."
                              placement="bottom"
                            >
                              <label>Estimated VAT</label>
                            </Tooltip>
                            <div className="d-flex align-items-center">
                              <Input
                                size="large"
                                suffix="%"
                                className="mw-150px me-2"
                              />
                              <b>{sign}7.57</b>
                            </div>
                          </div>
                          <div className="separator my-3" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Miscellaneous cost</label>
                            <Input
                              size="large"
                              prefix={sign}
                              className="mw-200px"
                            />
                          </div>
                          <div className="separator my-3" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Cost of goods sold</label>
                            <Input
                              size="large"
                              prefix={sign}
                              className="mw-200px"
                            />
                          </div>
                          <div className="separator my-3" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Fee discounts</label>
                            <b>{sign}0.00</b>
                          </div>
                        </Collapse.Panel>
                      </Collapse>
                      <div className="separator my-5" />
                      <div className="d-flex flex-wrap gap-5 justify-content-between px-4">
                        <div className="d-grid">
                          <label>
                            Cost per unit{" "}
                            <Tooltip title="Cost per unit is defined as the total of Amazon fees, fulfillment cost, cost of goods sold, and other costs.">
                              <Icons type="info" />
                            </Tooltip>
                          </label>
                          <b>{sign}25.59</b>
                        </div>
                        <div className="d-grid">
                          <label>Estimated sales</label>
                          <Input size="small" className="mw-100px" />
                        </div>
                        <div className="d-grid">
                          <b>Net profit</b>
                          <b className="fs-5 text-success fw-bold">
                            {sign}16.40
                          </b>
                        </div>
                        <div className="d-grid">
                          <b>Net margin</b>
                          <b className="fs-5 text-success fw-bold">39.05%</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab == "2" && (
                <div className="col-xxl-4 col-xl-6 col-lg-12 col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Merchant Fulfillment</div>
                    </div>

                    <div
                      style={{ maxHeight: "300px", overflow: "auto" }}
                      className="card-body pt-5"
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <Tooltip
                          title="Item price is the price that the product is listed for before any applicable shipping charges are added"
                          placement="bottom"
                        >
                          <label className="fw-bold">Item price</label>
                        </Tooltip>
                        <Input
                          defaultValue={41.99}
                          className="mw-200px"
                          prefix={sign}
                        />
                      </div>
                      <div className="separator my-5" />
                      <div className="d-flex align-items-center justify-content-between">
                        <Tooltip
                          title="Price charged to the customer for shipping. For seller-fulfilled orders, the seller collects this amount as revenue. For FBA orders, since shipping is already included, this amount is zero and doesn't affect FBA fulfillment fees."
                          placement="bottom"
                        >
                          <label className="fw-bold">Shipping charge</label>
                        </Tooltip>
                        <Input
                          defaultValue={0}
                          className="mw-200px"
                          prefix={sign}
                        />
                      </div>
                      <div className="separator my-5" />
                      <div className="d-flex align-items-center justify-content-between">
                        <Tooltip
                          title="Price charged to the customer. Amazon adds the item price and shipping when assessing competitiveness across sellers."
                          placement="bottom"
                        >
                          <label className="fw-bold">Sales price</label>
                        </Tooltip>
                        <b>{sign}41.99</b>
                      </div>
                      <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        // defaultActiveKey={["1"]}
                      >
                        <Collapse.Panel
                          header={
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Amazon fees</span>
                              <b>{sign}7.48</b>
                            </div>
                          }
                          key="1"
                        >
                          <div className="separator mb-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Referral Fee</label>
                            <b>{sign}6.49</b>
                          </div>
                          <div className="separator my-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Fixed Closing Fee</label>
                            <b>{sign}0.99</b>
                          </div>
                          <div className="separator my-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Variable Closing Fee</label>
                            <b>{sign}0.00</b>
                          </div>
                        </Collapse.Panel>
                        <div className=" my-5" />
                        <div className="d-flex align-items-center justify-content-between px-5">
                          <label className="fw-bold">Fulfillment cost</label>
                          <Input className="mw-200px" prefix={sign} />
                        </div>
                        <Collapse.Panel
                          header={
                            <div className="d-grid align-items-center justify-content-between">
                              <Tooltip title="To enter individual fulfillment-related costs, Enter the total fulfillment cost in the field next to “Fulfillment cost” or click “View and edit your fulfillment cost breakdown“. If you enter those details, the “Fulfillment cost” field updates with the total of the individual costs.">
                                <span style={{ color: "#008296" }}>
                                  View and edit your fulfillment cost breakdown
                                </span>
                              </Tooltip>
                              <small style={{ color: "red" }}>
                                Enter your fulfillment costs for a more accurate
                                comparison
                              </small>
                            </div>
                          }
                          key="2"
                        >
                          <div className="separator mb-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Labor</label>
                            <Input
                              size="large"
                              className="mw-200px"
                              prefix={sign}
                            />
                          </div>
                          <div className="separator my-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Packing material</label>
                            <Input
                              size="large"
                              className="mw-200px"
                              prefix={sign}
                            />
                          </div>
                          <div className="separator my-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Shipping to customer</label>
                            <Input
                              size="large"
                              className="mw-200px"
                              prefix={sign}
                            />
                          </div>
                          <div className="separator my-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Customer service</label>
                            <Input
                              size="large"
                              className="mw-200px"
                              prefix={sign}
                            />
                          </div>
                        </Collapse.Panel>
                        <Collapse.Panel
                          header={
                            <div className="d-grid align-items-center justify-content-between">
                              <Tooltip title="To enter individual fulfillment-related costs, Enter the total fulfillment cost in the field next to “Fulfillment cost” or click “View and edit your fulfillment cost breakdown“. If you enter those details, the “Fulfillment cost” field updates with the total of the individual costs.">
                                <b>Storage cost</b>
                              </Tooltip>
                              <b style={{ color: "red" }}>{sign}0.00</b>
                              <small style={{ color: "red" }}>
                                Enter your storage costs for a more accurate
                                comparison
                              </small>
                            </div>
                          }
                          key="3"
                        >
                          <div className="separator mb-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <Tooltip title="The amount it costs to store a single unit of this product per month. At Amazon, this is reflected in the monthly storage fee, which is calculated based on the volume of the product.">
                              <label>Monthly storage cost per unit</label>
                            </Tooltip>
                            <Input
                              size="large"
                              className="mw-200px"
                              prefix={sign}
                            />
                          </div>
                          <div className="separator my-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <Tooltip title="The average number of units you hold in inventory for this product">
                              <label>Average inventory units stored</label>
                            </Tooltip>
                            <Input
                              prefix=""
                              size="large"
                              className="mw-200px"
                            />
                          </div>
                          <div className="separator my-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <Tooltip title="We estimate that you will sell this number of units based on demand forecasts and your current price. This is only an estimate and can be edited.">
                              <label>Estimated monthly units sold</label>
                            </Tooltip>
                            <Input
                              prefix=""
                              size="large"
                              className="mw-200px"
                            />
                          </div>
                          <div className="separator my-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <Tooltip title="The estimated amount you'll pay in storage for the product per unit sold. This amount is calculated by dividing the total estimated storage cost (monthly storage cost per unit multiplied by average inventory units stored) by the estimated monthly units sold for each fulfillment channel.">
                              <label>Storage cost per unit sold</label>
                            </Tooltip>
                            <b>{sign}0.00</b>
                          </div>
                        </Collapse.Panel>
                        <Collapse.Panel
                          header={
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Other costs</span>
                              <b>{sign}7.57</b>
                            </div>
                          }
                          key="4"
                        >
                          <div className="separator mb-5" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <Tooltip
                              title="The VAT value provided is an estimate based on the standard rate. You can edit the percentage if you know the reduced or super-reduced rate for this product."
                              placement="bottom"
                            >
                              <label>Estimated VAT</label>
                            </Tooltip>
                            <div className="d-flex align-items-center">
                              <Input
                                size="large"
                                suffix="%"
                                className="mw-150px me-2"
                              />
                              <b>{sign}7.57</b>
                            </div>
                          </div>
                          <div className="separator my-3" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Miscellaneous cost</label>
                            <Input
                              size="large"
                              prefix={sign}
                              className="mw-200px"
                            />
                          </div>
                          <div className="separator my-3" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Cost of goods sold</label>
                            <Input
                              size="large"
                              prefix={sign}
                              className="mw-200px"
                            />
                          </div>
                          <div className="separator my-3" />
                          <div className="d-flex align-items-center justify-content-between ps-7">
                            <label>Fee discounts</label>
                            <b>{sign}0.00</b>
                          </div>
                        </Collapse.Panel>
                      </Collapse>
                      <div className="separator my-5" />
                      <div className="d-flex flex-wrap gap-5 justify-content-between px-4">
                        <div className="d-grid">
                          <label>
                            Cost per unit{" "}
                            <Tooltip title="Cost per unit is defined as the total of Amazon fees, fulfillment cost, cost of goods sold, and other costs.">
                              <Icons type="info" />
                            </Tooltip>
                          </label>
                          <b>{sign}25.59</b>
                        </div>
                        <div className="d-grid">
                          <label>Estimated sales</label>
                          <Input size="small" className="mw-100px" />
                        </div>
                        <div className="d-grid">
                          <b>
                            Net proceeds{" "}
                            <Tooltip title="Provide inputs for off-Amazon costs to view accurate net profit and net margin estimates">
                              <Icons type="info" />
                            </Tooltip>
                          </b>
                          <b className="fs-5 text-success fw-bold">
                            {sign}16.40
                          </b>
                        </div>
                        <div className="d-grid">
                          <b>
                            Net margin{" "}
                            <Tooltip title="Provide inputs for off-Amazon costs to view accurate net profit and net margin estimates">
                              <Icons type="info" />
                            </Tooltip>
                          </b>
                          <b className="fs-5 text-success fw-bold">39.05%</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab == "3" && (
                <div className="col-xxl-4 col-xl-6 col-lg-12 col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Catalog Fulfillment</div>
                    </div>

                    <div
                      style={{ maxHeight: "300px", overflow: "auto" }}
                      className="card-body pt-5"
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <Tooltip
                          title="Item price is the price that the product is listed for before any applicable shipping charges are added"
                          placement="bottom"
                        >
                          <label className="fw-bold">Item Price</label>
                        </Tooltip>
                        <Input
                          defaultValue={41.99}
                          className="mw-200px"
                          prefix={sign}
                        />
                      </div>
                      <div className="separator my-5" />
                      <div className="d-flex align-items-center justify-content-between">
                        <Tooltip
                          title="Item price is the price that the product is listed for before any applicable shipping charges are added"
                          placement="bottom"
                        >
                          <label className="fw-bold">Storage Fees</label>
                        </Tooltip>
                        <Input
                          defaultValue={41.99}
                          className="mw-200px"
                          prefix={sign}
                        />
                      </div>
                      <div className="separator my-5" />
                      <div className="d-flex align-items-center justify-content-between">
                        <Tooltip
                          title="Price charged to the customer for shipping. For seller-fulfilled orders, the seller collects this amount as revenue. For FBA orders, since shipping is already included, this amount is zero and doesn't affect FBA fulfillment fees."
                          placement="bottom"
                        >
                          <label className="fw-bold">Shipping charge</label>
                        </Tooltip>
                        <Input
                          defaultValue={0}
                          className="mw-200px"
                          prefix={sign}
                        />
                      </div>
                      <div className="separator my-5" />
                      <div className="d-flex align-items-center justify-content-between">
                        <Tooltip
                          title="Price charged to the customer for shipping. For seller-fulfilled orders, the seller collects this amount as revenue. For FBA orders, since shipping is already included, this amount is zero and doesn't affect FBA fulfillment fees."
                          placement="bottom"
                        >
                          <label className="fw-bold">Other Cost</label>
                        </Tooltip>
                        <Input
                          defaultValue={0}
                          className="mw-200px"
                          prefix={sign}
                        />
                      </div>
                      <div className="separator my-5" />
                      <div className="d-flex flex-wrap gap-5 justify-content-between  ">
                        <div className="d-grid">
                          <label>
                            Cost per unit{" "}
                            <Tooltip title="Cost per unit is defined as the total of Amazon fees, fulfillment cost, cost of goods sold, and other costs.">
                              <Icons type="info" />
                            </Tooltip>
                          </label>
                          <b>{sign}25.59</b>
                        </div>
                        <div className="d-grid">
                          <label>Estimated sales</label>
                          <Input size="small" className="mw-100px" />
                        </div>
                        <div className="d-grid">
                          <b>Net profit</b>
                          <b className="fs-5 text-success fw-bold">
                            {sign}16.40
                          </b>
                        </div>
                        <div className="d-grid">
                          <b>Net margin</b>
                          <b className="fs-5 text-success fw-bold">39.05%</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-xxl-8 col-xl-6 col-lg-12 col-12">
                <div className="card">
                  <div className="card-body p-0">
                    <Chart
                      options={options}
                      series={series}
                      type="bar"
                      height={355}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <NoData />
        )}
      </Wrapper>
    </>
  );
}
