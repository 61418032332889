import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  SalesChannelListAction,
  ItemsOfferGetResellerList,
  fakeActionCatalog,
  ItemsOfferGetResellerProductList,
} from "../../redux/module_/catalog/index.action";
import ResellerMonitor from "../../modules/pages/brand-protection/reseller-monitor";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
  ItemsOfferGetResellerProductListResponse:
    state.Catalog.ItemsOfferGetResellerProductListResponse,
  ItemsOfferGetResellerListResponse:
    state.Catalog.ItemsOfferGetResellerListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      ItemsOfferGetResellerList,
      ItemsOfferGetResellerProductList,
      fakeActionCatalog,
    },
    dispatch
  );

const ResellerMonitor_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResellerMonitor);

export default ResellerMonitor_;
