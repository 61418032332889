import React, { useEffect, useState } from "react";
import { DefaultPerPage } from "../../../../config";
import { useSelector } from "react-redux";
import { Table } from "antd";
import Pagination from "../../../../components/pagination";

export default function AmazonFeedListing({
  FeedSystemEventProcessListAction,
  fakeActionCLS,
  columns,
}) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const FeedSystemEventProcessListResponse = useSelector(
    (state) => state?.CLS?.FeedSystemEventProcessListResponse || {}
  );

  useEffect(() => {
    if (FeedSystemEventProcessListResponse?.status === true) {
      setTotalPage(
        FeedSystemEventProcessListResponse?.data?.pagination?.totalCount || 0
      );
      setList(FeedSystemEventProcessListResponse?.data?.records || []);
      setLoading(false);
      fakeActionCLS("FeedSystemEventProcessListResponse");
    } else if (FeedSystemEventProcessListResponse?.status === false) {
      setLoading(false);
      setList([]);
      fakeActionCLS("FeedSystemEventProcessListResponse");
    }
  }, [FeedSystemEventProcessListResponse]);

  useEffect(() => {
    setLoading(true);
    setList([]);
    FeedSystemEventProcessListAction({
      page: page,
      perPage: pageSize,
    });

    return () => {};
  }, []);
  const onPageNo = (e) => {
    setPage(e);
    setLoading(true);
    FeedSystemEventProcessListAction({
      page: e,
      perPage: pageSize,
    });
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    FeedSystemEventProcessListAction({
      page: page,
      perPage: e,
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <Table
          columns={columns}
          dataSource={list}
          scroll={{ x: "max-content" }}
          loading={loading}
          pagination={false}
        />
        <Pagination
          loading={loading || list?.length === 0}
          pageSize={pageSize}
          pageSizeStatus={false}
          page={page}
          totalPage={totalPage}
          onPerPage={onPerPage}
          onPageNo={onPageNo}
        />
      </div>
    </div>
  );
}
