export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "USER_LIST_SUCCESS":
    case "USER_LIST_ERROR":
      return {
        ...state,
        UserListResponse: action.updatePayload,
      };
    case "EMBEDDED_DATA_SUCCESS":
    case "EMBEDDED_DATA_ERROR":
      return {
        ...state,
        EmbeddedListResponse: action.updatePayload,
      };
    case "ADD_EMBEDDED_DATA_SUCCESS":
    case "ADD_EMBEDDED_DATA_ERROR":
      return {
        ...state,
        AddEmbeddedResponse: action.updatePayload,
      };
    case "SWITCH_USER_SUCCESS":
    case "SWITCH_USER_ERROR":
      return {
        ...state,
        SwitchUserResponse: action.updatePayload,
      };
    case "SWITCH_USER_BACK_SUCCESS":
    case "SWITCH_USER_BACK_ERROR":
      return {
        ...state,
        SwitchUserBackResponse: action.updatePayload,
      };
    case "GET_COMPANY_LIST_SUCCESS":
    case "GET_COMPANY_LIST_ERROR":
      return {
        ...state,
        GetCompanyListResponse: action.updatePayload,
      };
    case "GET_ADMIN_LIST_SUCCESS":
    case "GET_ADMIN_LIST_ERROR":
      return {
        ...state,
        GetAdminListResponse: action.updatePayload,
      };
    case "ADD_COMPANY_SUCCESS":
    case "ADD_COMPANY_ERROR":
      return {
        ...state,
        AddCompanyDataResponse: action.updatePayload,
      };
    case "ADD_ADMIN_SUCCESS":
    case "ADD_ADMIN_ERROR":
      return {
        ...state,
        AddAdminResponse: action.updatePayload,
      };
    case "ALL_ADMIN_A_SUCCESS":
    case "ALL_ADMIN_A_ERROR":
      return {
        ...state,
        AllAdminAResponse: action.updatePayload,
      };
    case "ADD_USER_L_SUCCESS":
    case "ADD_USER_L_ERROR":
      return {
        ...state,
        AddUserLResponse: action.updatePayload,
      };
    case "ALL_COMPANY_LIST_SUCCESS":
    case "ALL_COMPANY_LIST_ERROR":
      return {
        ...state,
        AllCompanyListResponse: action.updatePayload,
      };
    case "USER_UPDATE_SUCCESS":
    case "USER_UPDATE_ERROR":
      return {
        ...state,
        UserUpdateResponse: action.updatePayload,
      };
    case "USER_DELETE_SUCCESS":
    case "USER_DELETE_ERROR":
      return {
        ...state,
        UserDeleteResponse: action.updatePayload,
      };
    case "GET_ADMIN_CONFIG_DATA_SUCCESS":
    case "GET_ADMIN_CONFIG_DATA_ERROR":
      return {
        ...state,
        GetAdminConfigDataResponse: action.updatePayload,
      };
    case "ADD_ADMIN_CONFIGURATION_SUCCESS":
    case "ADD_ADMIN_CONFIGURATION_ERROR":
      return {
        ...state,
        AddAdminConfigDataResponse: action.updatePayload,
      };

    case "FAKE_ACTION_MANAGE_USER":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
