import { DatePicker, Result, Select, Table, Tag, Tooltip, message } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Wrapper } from "../new-style";

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import {
  AllMarketPlace,
  SwapCommaAndDot,
  checkPermission,
  updateDate,
} from "../../../../config";
import moment from "moment";
import ASINTooltip from "../../../../components/tooltip";
import { TableLoading } from "../../../../components/table-animation";
import { GlobalContext } from "../../../../commonContext";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

const ProductDetails = (props) => {
  const {
    SalesChannelListAction,
    fakeActionCatalog,
    ParentDataAction,
    TopProductAction,
    fakeActionAnalytics,
  } = props;

  const contextValue = useContext(GlobalContext);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const colorUpdate = (value) => {
    if (parseFloat(value) > 0) {
      return <span style={{ color: "green" }}>{value}%</span>;
    }
    return <span style={{ color: "red" }}>{value}%</span>;
  };
  const [sign, setSign] = useState("$");

  const columns = [
    {
      title: "Parent ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (e) => {
        return <Tag color="cyan">{e}</Tag>;
      },
    },
    {
      title: "Product Name",
      dataIndex: "title",
      key: "title",
      width: 300,
      render: (e) => {
        return (
          <Tooltip title={e} placement="topLeft">
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "300px",
              }}
            >
              {e}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Size Name",
      dataIndex: "size_name",
      key: "size_name",
    },

    {
      title: "Sales",
      dataIndex: "order_sales",
      key: "order_sales",
      width: 170,
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              parseFloat(e?.value || 0)?.toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "% Variation Previous Period",
      dataIndex: "order_sales",
      key: "order_sales",
      render: (e) => {
        return (
          <span>
            {colorUpdate(
              SwapCommaAndDot(
                parseFloat(e?.previous_percentage || 0)?.toLocaleString(),
                "",
                "",
                marketplaceSelected?.marketplace_id
              )
            )}
          </span>
        );
      },
    },
    {
      title: "% Variation Same Period Last Year",
      dataIndex: "order_sales",
      key: "order_sales",
      render: (e) => {
        return (
          <span>
            {colorUpdate(
              SwapCommaAndDot(
                parseFloat(e?.period_percentage || 0)?.toLocaleString(),
                "",
                "",
                marketplaceSelected?.marketplace_id
              )
            )}
          </span>
        );
      },
    },
  ];
  const [dateFilter, setDateFilter] = useState({
    start: dayjs().subtract(30, "days")?.format("YYYY-MM-DD"),
    end: dayjs()?.format("YYYY-MM-DD"),
  });

  const [topProductData, setTopProductData] = useState({});
  const [topProductLoading, setTopProductLoading] = useState(true);

  const [parentData, setParentData] = useState([]);
  const [parentLoading, setParentLoading] = useState(true);

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const TopProductsResponse = useSelector(
    (state) => state.Analytics.TopProductsResponse || {}
  );
  const ParentDataResponse = useSelector(
    (state) => state.Analytics.ParentDataResponse || {}
  );

  useEffect(() => {
    if (ParentDataResponse?.status === true) {
      setSign(ParentDataResponse?.data?.currency);
      setParentData(
        ParentDataResponse?.data?.data?.map((d, i) => ({ ...d, key: i })) || []
      );
      setTimeout(() => {
        setParentLoading(false);
      }, 500);
      fakeActionAnalytics("ParentDataResponse");
    } else if (ParentDataResponse?.status === false) {
      setParentData([]);
      setParentLoading(false);
      fakeActionAnalytics("ParentDataResponse");
    }
  }, [ParentDataResponse]);
  useEffect(() => {
    if (TopProductsResponse?.status === true) {
      setSign(TopProductsResponse?.data?.currency);
      setTopProductData(TopProductsResponse?.data || {});
      setTimeout(() => {
        setTopProductLoading(false);
      }, 500);
      fakeActionAnalytics("TopProductsResponse");
    } else if (TopProductsResponse?.status === false) {
      setTopProductData({});
      setTopProductLoading(false);
      fakeActionAnalytics("TopProductsResponse");
    }
  }, [TopProductsResponse]);

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );
            if (e?.default_marketplace === 1) {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
              });

              setParentLoading(true);

              ParentDataAction({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                daterange: dateFilter?.start + "+-+" + dateFilter?.end,
              });
              setTopProductData({});
              setTopProductLoading(true);
              TopProductAction({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                daterange: dateFilter?.start + "+-+" + dateFilter?.end,
              });
            } else {
              // setParentLoading(false);
              // setTopProductLoading(false);
            }
            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                  },
                ],
              });
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);

        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);

        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);

      setMarketplaceList([]);
      message.destroy();
      message.warning(SalesChannelListResponse?.message);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  const currentMonth = moment().month(); // Get the current month (0-indexed)
  const quarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3), "months");
  const quarterEnd = quarterStart.clone().add(3, "months").subtract(1, "day");

  const lastQuarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3) - 3, "months");
  const lastQuarterEnd = lastQuarterStart
    .clone()
    .add(3, "months")
    .subtract(1, "day");

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
      type: "last_30_days_by_day",
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
      type: "last_30_days_by_day",
    },
    {
      label: "This Quarter",
      value: [dayjs(quarterStart), dayjs(quarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Quarter",
      value: [dayjs(lastQuarterStart), dayjs(lastQuarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
      type: "last_12_months_by_month",
    },
    {
      label: "Custom Range",
      value: [0, 0],
      type: "last_12_months_by_month",
    },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const expandedRowRender = (record) => {
    const expandedColumns = [
      {
        title: "Variation ASIN",
        dataIndex: "asin",
        key: "asin",
      },
      // Add other columns as needed for the expanded row
      {
        title: "Product Name",
        dataIndex: "title",
        key: "title",
        render: (e) => {
          return (
            <Tooltip title={e} placement="topLeft">
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "300px",
                }}
              >
                {e}
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: "Size Name",
        dataIndex: "size_name",
        key: "size_name",
      },

      {
        title: "Sales",
        dataIndex: "order_sales",
        key: "order_sales",
        render: (e) => {
          return (
            <span>
              {SwapCommaAndDot(
                parseFloat(e?.value || 0)?.toLocaleString(),
                sign,
                "",
                marketplaceSelected?.marketplace_id
              )}
            </span>
          );
        },
      },
      {
        title: "% Variation Previous Period",
        dataIndex: "order_sales",
        key: "order_sales",
        render: (e) => {
          return <span>{colorUpdate(e?.previous_percentage || 0)}</span>;
        },
      },
      {
        title: "% Variation Same Period Last Year",
        dataIndex: "order_sales",
        key: "order_sales",
        render: (e) => {
          return <span>{colorUpdate(e?.period_percentage || 0)}</span>;
        },
      },
    ];

    const expandedData = record.child_data.map((variation, index) => ({
      ...variation,
      key: index,
    }));

    return (
      <Table
        columns={expandedColumns}
        dataSource={expandedData}
        pagination={true}
      />
    );
  };
  const columns__ = [
    {
      title: "Product Title",
      dataIndex: "title",
      key: "title",
      width: 500,
    },
    {
      title: "Sales",
      dataIndex: "order_sales",
      key: "order_sales",
      width: 200,
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              parseFloat(e?.value || 0)?.toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "% Variation (Previous Period)",
      dataIndex: "order_sales",
      key: "order_sales",
      width: 250,
      render: (e) => {
        return (
          <span>
            {colorUpdate(
              SwapCommaAndDot(
                parseFloat(e?.previous_percentage || 0)?.toLocaleString(),
                "",
                "",
                marketplaceSelected?.marketplace_id
              )
            )}
          </span>
        );
      },
    },
    {
      title: "% Variation (Same Period Last Year)",
      dataIndex: "order_sales",
      key: "order_sales",
      width: 320,
      render: (e) => {
        return (
          <span>
            {colorUpdate(
              SwapCommaAndDot(
                parseFloat(e?.period_percentage || 0)?.toLocaleString(),
                "",
                "",
                marketplaceSelected?.marketplace_id
              )
            )}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    setParentLoading(true);
    setMarketplaceLoading(true);
    setTopProductLoading(true);
    SalesChannelListAction();

    setDateFilter({
      start: contextValue?.data?.date_range?.start_date,
      end: contextValue?.data?.date_range?.end_date,
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (topProductData?.graph_data?.length === 0) return;
    // Create chart instance
    let chart = am4core.create("chartdiv", am4charts.PieChart);

    // Add data
    chart.data = topProductData?.graph_data?.map((d) => ({
      category: d?.asin,
      value: parseFloat(d?.value),
    }));

    // Add pie series
    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "category";

    // Configure series
    series.slices.template.propertyFields.fill = "color"; // You can set the color property for each slice

    // Set the inner radius for the ring effect
    series.innerRadius = am4core.percent(50);

    // Enable labels and ticks
    series.labels.template.disabled = true; // You can customize labels as needed
    series.ticks.template.disabled = true;

    // Add a legend
    chart.legend = new am4charts.Legend();

    // Enable responsive features
    chart.responsive.enabled = true;
    chart.logo.disabled = true;
    return () => {
      // Cleanup code when the component unmounts
      chart.dispose();
    };
  }, [topProductData?.graph_data]);

  if (checkPermission(contextValue, "analytics", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }

  return (
    <Wrapper className="p-4 fadeInBottom">
      <div className="d-flex justify-content-end flex-wrap  mb-5">
        <div className="fix-over">
          <DatePicker.RangePicker
            presets={rangePresets}
            className=" w-275px"
            size="large"
            getPopupContainer={(target) => target.parentNode}
            value={
              dateFilter?.start && dateFilter?.end
                ? [
                    dayjs(dateFilter?.start, "YYYY-MM-DD"),
                    dayjs(dateFilter?.end, "YYYY-MM-DD"),
                  ]
                : null
            }
            onChange={(e) => {
              setDateFilter({
                start: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
              });

              const date_range = {
                start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
              };
              contextValue?.updateCommonGlobalVal({
                date_range,
              });
              updateDate(date_range);

              setParentLoading(true);
              setTopProductData({});
              setTopProductLoading(true);
              ParentDataAction({
                marketplace: marketplaceSelected?.marketplace,
                marketplace_id: marketplaceSelected?.marketplace_id,
                region: marketplaceSelected?.region,
                sales_channel: marketplaceSelected?.sales_channel,
                daterange:
                  (e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null) +
                  "+-+" +
                  (e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null),
              });
              TopProductAction({
                marketplace: marketplaceSelected?.marketplace,
                marketplace_id: marketplaceSelected?.marketplace_id,
                region: marketplaceSelected?.region,
                sales_channel: marketplaceSelected?.sales_channel,
                daterange:
                  (e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null) +
                  "+-+" +
                  (e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null),
              });
            }}
          />
        </div>
        <div className="fix-over">
          <Select
            className="w-225px  ms-3"
            size="large"
            getPopupContainer={(target) => target.parentNode}
            options={marketplaceList}
            onChange={(_, e) => {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
              });
              setParentLoading(true);
              setTopProductData({});
              setTopProductLoading(true);
              ParentDataAction({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                daterange: dateFilter?.start + "+-+" + dateFilter?.end,
              });
              TopProductAction({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                daterange: dateFilter?.start + "+-+" + dateFilter?.end,
              });
            }}
            value={marketplaceSelected?.marketplace_id}
            loading={marketplaceLoading}
            placeholder="Select Marketplace"
          />
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-7">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Top 10 Parent Product</div>
            </div>
            <div
              className="card-body"
              style={{
                minHeight: "380px",
                maxHeight: "380px",
                overflow: "auto",
              }}
            >
              {topProductLoading ? (
                <TableLoading
                  id="test-table"
                  columns={[1, 2, 3, 4, 5]}
                  checkBoxVal={true}
                  actions={[1, 2]}
                />
              ) : (
                <Table
                  columns={columns__}
                  dataSource={topProductData?.data || []}
                  scroll={{ x: "max-content" }}
                  pagination={false}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-5">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Unit sold by Parent ASIN</div>
            </div>
            <div
              className="card-body"
              style={{ filter: `blur(${topProductLoading ? "5px" : "0px"})` }}
            >
              <div id="chartdiv" style={{ width: "100%", height: "330px" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-10">
        <div className="card-header">
          <div className="card-title">Product Details</div>
        </div>
        <div className="card-body">
          {parentLoading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <Table
              columns={columns}
              dataSource={parentData}
              rowKey={"key"}
              expandable={{
                expandedRowKeys,
                expandedRowRender,
                onExpand: handleExpand,
              }}
              scroll={{ x: "max-content" }}
              pagination={{
                total: parentData?.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductDetails;
