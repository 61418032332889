import { DatePicker, Select, Space, Table, Tag, Tooltip, message } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { GlobalContext } from "../../../commonContext";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  AllMarketPlace,
  DefaultPerPage,
  SwapCommaAndDot,
  createOption,
  updateDate,
} from "../../../config";
import Pagination from "../../../components/pagination";

const { RangePicker } = DatePicker;

const VendorOrder = ({
  SalesChannelListAction,
  fakeActionCatalog,
  GetVendorOrderStatusAction,
  GetVendorOrderListAction,
  fakeActionAnalytics,
}) => {
  const contextValue = useContext(GlobalContext);
  const [sign, setSign] = useState("$");
  const [selectedRow, setSelectedRow] = useState({});
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [dateFilter, setDateFilter] = useState({
    start: dayjs().subtract(30, "days")?.format("YYYY-MM-DD"),
    end: dayjs()?.format("YYYY-MM-DD"),
  });
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
  const [orderFilterList, setOrderFilterList] = useState([]);
  const [orderFilterLoading, setOrderFilterLoading] = useState(true);
  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const GetVendorOrderStatusResponse = useSelector(
    (state) => state.Analytics.GetVendorOrderStatusResponse || {}
  );
  const GetVendorOrderListResponse = useSelector(
    (state) => state.Analytics.GetVendorOrderListResponse || {}
  );

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      const filteredValue = SalesChannelListResponse?.data?.records?.filter(
        (d) => d?.account_type === "Vendor"
      );
      if (filteredValue?.length > 0) {
        const salesList = filteredValue?.reduce((acc, e, i) => {
          const regionIndex = acc.findIndex((r) => r.label === e.region_label);
          if (i === 0) {
            setMarketplaceSelected({
              marketplace: e?.marketplace,
              marketplace_id: e?.marketplace_id,
              region: e?.region,
              sales_channel: e?.sales_channel,
            });
            setTableLoading(true);

            GetVendorOrderListAction({
              page: page,
              order_status: selectedOrderStatus,
              pageSize: pageSize,
              start_date: dateFilter?.start,
              end_date: dateFilter?.end,

              ...{
                region: e?.region,
                sales_channel: e?.sales_channel,
              },
            });
          }
          if (regionIndex === -1) {
            acc.push({
              label: e.region_label,
              options: [
                {
                  label: (
                    <>
                      <img
                        src={`/domainImage/${e?.marketplace_flag}.png`}
                        style={{ height: "20px" }}
                        loading="lazy"
                        className="me-3"
                        onError={(e) => {
                          e.target.src = "/media/domainImage/red-flag.png";
                        }}
                      />
                      {e?.region_label === "Ecommerce"
                        ? "Ecommerce"
                        : `Amazon.${e?.account_type || ""}.${
                            e?.short_name || ""
                          }`}
                    </>
                  ),
                  value: e.marketplace_id,
                  region: e.region,
                  marketplace_id: e?.marketplace_id,
                  marketplace: e?.marketplace,
                  sales_channel: e?.sales_channel,
                },
              ],
            });
          } else {
            acc[regionIndex].options.push({
              label: (
                <>
                  <img
                    src={`/domainImage/${e?.marketplace_flag}.png`}
                    style={{ height: "20px" }}
                    loading="lazy"
                    className="me-3"
                    onError={(e) => {
                      e.target.src = "/media/domainImage/red-flag.png";
                    }}
                  />
                  {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                </>
              ),
              value: e.marketplace_id,
              marketplace_id: e?.marketplace_id,
              region: e.region,
              marketplace: e?.marketplace,
              sales_channel: e?.sales_channel,
            });
          }
          return acc;
        }, []);

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);
        setMarketplaceLoading(false);
        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);

        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);

      setMarketplaceList([]);
      message.destroy();
      message.warning(SalesChannelListResponse?.message);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  useEffect(() => {
    if (GetVendorOrderStatusResponse?.status === true) {
      setOrderFilterList(
        Object?.keys(GetVendorOrderStatusResponse?.data?.status)?.map((d) => ({
          label: d,
          value: d,
        }))
      );
      setOrderFilterLoading(false);
      fakeActionAnalytics("GetVendorOrderStatusResponse");
    } else if (GetVendorOrderStatusResponse?.status === false) {
      setOrderFilterList([]);
      setOrderFilterLoading(false);
      fakeActionAnalytics("GetVendorOrderStatusResponse");
    }
  }, [GetVendorOrderStatusResponse]);
  useEffect(() => {
    if (GetVendorOrderListResponse?.status === true) {
      setSign(GetVendorOrderListResponse?.data?.currency || []);
      setTableData(GetVendorOrderListResponse?.data?.data || []);
      setTotalPage(
        GetVendorOrderListResponse?.data?.pagination?.totalCount || 0
      );
      setTableLoading(false);
      fakeActionAnalytics("GetVendorOrderListResponse");
    } else if (GetVendorOrderListResponse?.status === false) {
      setTableData([]);
      setTotalPage(0);
      setTableLoading(false);
      fakeActionAnalytics("GetVendorOrderListResponse");
    }
  }, [GetVendorOrderListResponse]);

  const columns = [
    {
      title: "PO Number",
      dataIndex: "purchase_order_number",
      key: "purchase_order_number",
      render: (status, row) => (
        <Tag
          onClick={() => setSelectedRow(row)}
          color="blue-inverse"
          style={{ cursor: "pointer" }}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "PO Status",
      dataIndex: "purchase_order_status",
      key: "purchase_order_status",
      render: (status) => <Tag color="blue">{status}</Tag>,
    },
    {
      title: "Order Date",
      dataIndex: "purchase_order_date_n",
      key: "purchase_order_date_n",
    },
    // { title: "ID", dataIndex: "id", key: "id" },

    {
      title: "Total Accepted Cost",
      dataIndex: "net_cost_amount",
      key: "net_cost_amount",
      align: "right",
      render: (text) => {
        // if (!text || !Array.isArray(text)) {
        //   return "$0";
        // }
        // const amount = text
        //   ?.map((d) => (d?.net_cost ? JSON.parse(d?.net_cost)?.amount : 0))
        //   ?.reduce(
        //     (accumulator, currentValue) =>
        //       accumulator + parseFloat(currentValue),
        //     0
        //   );
        return (
          <Tag color="green">
            {SwapCommaAndDot(
              parseFloat(text || 0)?.toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </Tag>
        );
      },
    },
    {
      title: "Total Accepted Quantity",
      dataIndex: "accepted_quantity",
      key: "accepted_quantity",
      align: "right",
      render: (text) => parseInt(text || 0).toLocaleString(),
    },
    { title: "Marketplace", dataIndex: "marketplace", key: "marketplace" },
  ];

  const currentMonth = moment().month(); // Get the current month (0-indexed)
  const quarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3), "months");
  const quarterEnd = quarterStart.clone().add(3, "months").subtract(1, "day");

  const lastQuarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3) - 3, "months");
  const lastQuarterEnd = lastQuarterStart
    .clone()
    .add(3, "months")
    .subtract(1, "day");

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
      type: "last_30_days_by_day",
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
      type: "last_30_days_by_day",
    },
    {
      label: "This Quarter",
      value: [dayjs(quarterStart), dayjs(quarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Quarter",
      value: [dayjs(lastQuarterStart), dayjs(lastQuarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
      type: "last_12_months_by_month",
    },
    {
      label: "Custom Range",
      value: [0, 0],
      type: "last_12_months_by_month",
    },
  ];

  // const expandable = {
  //   expandedRowRender: (record) => expandedRowRender(record),
  //   rowExpandable: (record) => record.children && record.children.length,
  // };

  const dataSource = [
    {
      key: "1",
      date: "2024-01-01",
      vendorProductIdentifier: "SKU123",
      buyerProductIdentifier: "ASIN123",
      productTitle: "Product Name 1",
      totalItemsOrdered: "10",
      totalValueOrdered: "1000.00",
    },
    // ... more data
  ];
  const [filteredData, setFilteredData] = useState(dataSource);

  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      const [start, end] = dates;
      const filtered = dataSource.filter((data) => {
        const date = moment(data.date);
        return date.isBetween(start, end, null, "[]"); // inclusive of start and end dates
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(dataSource); // Reset if no date range is selected
    }
  };

  useEffect(() => {
    setMarketplaceLoading(true);
    SalesChannelListAction();

    setDateFilter({
      start: contextValue?.data?.date_range?.start_date,
      end: contextValue?.data?.date_range?.end_date,
    });
    setOrderFilterLoading(true);
    GetVendorOrderStatusAction();
    return () => {};
  }, []);

  const columns__ = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (status) => <Tag color="cyan">{status}</Tag>,
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      render: (status) => <Tag color="geekblue">{status}</Tag>,
    },
    {
      title: "Purchase Order",
      dataIndex: "purchaseOrder",
      key: "purchaseOrder",
      render: (status) => <Tag color="green">{status}</Tag>,
    },
    {
      title: "State",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Product ASIN",
      dataIndex: "productAsin",
      key: "productAsin",
      width: 400,
      render: (e) => {
        return (
          <Tooltip title={e}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 400,
              }}
            >
              {e}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "EAN UPC",
      dataIndex: "eanUpc",
      key: "eanUpc",
    },
    {
      title: "Transaction type",
      dataIndex: "transactionType",
      key: "transactionType",
    },
    {
      title: "Financial burden",
      dataIndex: "financialCharge",
      key: "financialCharge",
      render: (text) => `€${parseFloat(text).toFixed(2)}`,
    },
  ];

  const data__ = [
    {
      key: "1",
      transactionId: "2656587190VUCSBE",
      marketplace: "IT",
      purchaseOrder: "6CYF2FDW",
      status: "Addebitato",
      date: "12/1/2024",
      productAsin:
        "Biancante Trio - Kit Anti Tartaro con Gommina Spazzolino per Denti, Specchio Levatartaro e Scovolino Interdentale",
      eanUpc: "8028518000574",
      transactionType: "ASN non valido",
      financialCharge: "1,26",
    },
    {
      key: "1",
      transactionId: "2656587190VUCSBE",
      marketplace: "IT",
      purchaseOrder: "6CYF2FDW",
      status: "Addebitato",
      date: "12/1/2024",
      productAsin:
        "Biancante Trio - Kit Anti Tartaro con Gommina Spazzolino per Denti, Specchio Levatartaro e Scovolino Interdentale",
      eanUpc: "8028518000574",
      transactionType: "ASN non valido",
      financialCharge: "1,26",
    },
    {
      key: "1",
      transactionId: "2656587190VUCSBE",
      marketplace: "IT",
      purchaseOrder: "6CYF2FDW",
      status: "Addebitato",
      date: "12/1/2024",
      productAsin:
        "Biancante Trio - Kit Anti Tartaro con Gommina Spazzolino per Denti, Specchio Levatartaro e Scovolino Interdentale",
      eanUpc: "8028518000574",
      transactionType: "ASN non valido",
      financialCharge: "1,26",
    },
    {
      key: "1",
      transactionId: "2656587190VUCSBE",
      marketplace: "IT",
      purchaseOrder: "6CYF2FDW",
      status: "Addebitato",
      date: "12/1/2024",
      productAsin:
        "Biancante Trio - Kit Anti Tartaro con Gommina Spazzolino per Denti, Specchio Levatartaro e Scovolino Interdentale",
      eanUpc: "8028518000574",
      transactionType: "ASN non valido",
      financialCharge: "1,26",
    },
    {
      key: "1",
      transactionId: "2656587190VUCSBE",
      marketplace: "IT",
      purchaseOrder: "6CYF2FDW",
      status: "Addebitato",
      date: "12/1/2024",
      productAsin:
        "Biancante Trio - Kit Anti Tartaro con Gommina Spazzolino per Denti, Specchio Levatartaro e Scovolino Interdentale",
      eanUpc: "8028518000574",
      transactionType: "ASN non valido",
      financialCharge: "1,26",
    },
    {
      key: "1",
      transactionId: "2656587190VUCSBE",
      marketplace: "IT",
      purchaseOrder: "6CYF2FDW",
      status: "Addebitato",
      date: "12/1/2024",
      productAsin:
        "Biancante Trio - Kit Anti Tartaro con Gommina Spazzolino per Denti, Specchio Levatartaro e Scovolino Interdentale",
      eanUpc: "8028518000574",
      transactionType: "ASN non valido",
      financialCharge: "1,26",
    },
    {
      key: "1",
      transactionId: "2656587190VUCSBE",
      marketplace: "IT",
      purchaseOrder: "6CYF2FDW",
      status: "Addebitato",
      date: "12/1/2024",
      productAsin:
        "Biancante Trio - Kit Anti Tartaro con Gommina Spazzolino per Denti, Specchio Levatartaro e Scovolino Interdentale",
      eanUpc: "8028518000574",
      transactionType: "ASN non valido",
      financialCharge: "1,26",
    },

    // ... more items
  ];
  const state = {
    series: [70, 20, 10],
    options: {
      chart: {
        // width: 380,
        type: "pie",
      },
      labels: ["Wrong packaging", "Wrong asin", "Expiration date missing"],
    },
  };

  const onPageNo = (e) => {
    setTableLoading(true);
    setTableData([]);

    setPage(1);
    GetVendorOrderListAction({
      start_date: dateFilter?.start,
      end_date: dateFilter?.end,
      region: marketplaceSelected?.region,
      sales_channel: marketplaceSelected?.sales_channel,
      page: e,
      pageSize: pageSize,
      order_status: selectedOrderStatus,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setTableLoading(true);
    setTableData([]);

    GetVendorOrderListAction({
      start_date: dateFilter?.start,
      end_date: dateFilter?.end,
      region: marketplaceSelected?.region,
      sales_channel: marketplaceSelected?.sales_channel,
      page: 1,
      pageSize: e,
      order_status: selectedOrderStatus,
    });
  };
  if (Object.keys(selectedRow || {})?.length > 0) {
    const columns3 = [
      {
        title: "Vendor ASIN",
        dataIndex: "vendor_product_identifier",
        key: "vendor_product_identifier",
      },
      {
        title: "Product Name",
        dataIndex: "product_name",
        key: "product_name",
        width: 450,
        render: (e) => {
          return e || "-";
        },
      },
      {
        title: "ASIN",
        dataIndex: "amazon_product_identifier",
        key: "amazon_product_identifier",
        render: (e) => {
          return e || "-";
        },
      },
      {
        title: "Cost Price",
        dataIndex: "cost_price",
        key: "cost_price",
        render: (e) => {
          return SwapCommaAndDot(
            parseFloat(e || 0).toFixed(2),
            sign,
            "",
            marketplaceSelected?.marketplace_id
          );
        },
      },
      {
        title: "Accepted Quanity",
        dataIndex: "accepted_quantity",
        key: "accepted_quantity",
        render: (text) => {
          return text?.toLocaleString() || "0";
          // return SwapCommaAndDot(
          //   parseFloat(
          //     parseFloat(
          //       JSON.parse(text?.ordered_quantity)?.amount *
          //         JSON.parse(text?.net_cost)?.amount
          //     ).toFixed(2)
          //   ),
          //   sign,
          //   "",
          //   marketplaceSelected?.marketplace_id
          // );
        },
      },
      {
        title: "Total Accepted Cost",
        dataIndex: "net_cost_amount",
        key: "net_cost_amount",
        render: (e) => {
          return SwapCommaAndDot(
            parseFloat(e || 0).toFixed(2),
            sign,
            "",
            marketplaceSelected?.marketplace_id
          );
        },
      },

      // {
      //   title: "External ID",
      //   dataIndex: "external_id",
      //   key: "external_id",
      //   render: (e) => {
      //     return e || "-";
      //   },
      // },
      // {
      //   title: "SKU",
      //   dataIndex: "sku",
      //   key: "sku",
      //   render: (e) => {
      //     return e || "-";
      //   },
      // },

      // {
      //   title: "Unit Of Measure",
      //   dataIndex: "ordered_quantity",
      //   key: "ordered_quantity",
      //   render: (e) => {
      //     return (
      //       <div>{(e ? JSON.parse(e)?.unitOfMeasure : 0).toLocaleString()}</div>
      //     );
      //   },
      // },
      // {
      //   title: "Unit Size",
      //   dataIndex: "ordered_quantity",
      //   key: "ordered_quantity",
      //   render: (e) => {
      //     return (
      //       <div>{(e ? JSON.parse(e)?.unitSize : 0).toLocaleString()}</div>
      //     );
      //   },
      // },
      // {
      //   title: "List Price",
      //   dataIndex: "list_price",
      //   key: "list_price",
      //   render: (e) => {
      //     return <div>{(e ? JSON.parse(e)?.amount : 0).toLocaleString()}</div>;
      //   },
      // },

      // Add more columns as needed
    ];

    return (
      <div className="p-5 fadeInBottom">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <span
                onClick={() => setSelectedRow({})}
                className="svg-icon svg-icon-muted svg-icon-2hx me-4"
                style={{ cursor: "pointer" }}
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y={11}
                    width={13}
                    height={2}
                    rx={1}
                    fill="currentColor"
                  />
                  <path
                    d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            <div className="card-toolbar">
              <Tag style={{ fontSize: "16px" }} bordered={false} color="blue">
                Total Accepted Cost:{" "}
                {SwapCommaAndDot(
                  parseFloat(
                    selectedRow?.net_cost_amount || 0
                  )?.toLocaleString(),
                  sign,
                  "",
                  marketplaceSelected?.marketplace_id
                )}
              </Tag>
              <Tag
                style={{ fontSize: "16px" }}
                bordered={false}
                color="blue-inverse"
              >
                Total Accepted Quantity:{" "}
                {parseInt(selectedRow?.accepted_quantity || 0).toLocaleString()}
              </Tag>
              <Tag style={{ fontSize: "16px" }} color="lime">
                PO: {selectedRow?.purchase_order_number}
              </Tag>
              <Tag style={{ fontSize: "16px" }} color="cyan">
                Status: {selectedRow?.purchase_order_status}
              </Tag>

              <Tag style={{ fontSize: "16px" }} color="magenta">
                Marketplace: {selectedRow?.marketplace}
              </Tag>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <Table
                columns={columns3}
                dataSource={selectedRow?.items_data || []}
                expandable={false}
                pagination={true}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="p-5 fadeInBottom">
      <div className="d-flex justify-content-end mb-5 fix-over">
        <DatePicker.RangePicker
          presets={rangePresets}
          className=" w-275px"
          size="large"
          getPopupContainer={(trigger) => trigger.parentNode}
          value={
            dateFilter?.start && dateFilter?.end
              ? [
                  dayjs(dateFilter?.start, "YYYY-MM-DD"),
                  dayjs(dateFilter?.end, "YYYY-MM-DD"),
                ]
              : null
          }
          onChange={(e) => {
            setDateFilter({
              start: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
              end: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
            });

            const date_range = {
              start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
              end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
            };
            contextValue?.updateCommonGlobalVal({
              date_range,
            });
            updateDate(date_range);

            setPage(1);
            setTableLoading(true);
            GetVendorOrderListAction({
              start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
              end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
              region: marketplaceSelected?.region,
              sales_channel: marketplaceSelected?.sales_channel,
              page: 1,
              pageSize: pageSize,
              order_status: selectedOrderStatus,
            });
          }}
        />
        <Select
          allowClear
          options={orderFilterList}
          size="large"
          loading={orderFilterLoading}
          placeholder="Select Order Status"
          className="w-200px ms-3"
          onChange={(e) => {
            setSelectedOrderStatus(e);
            setPage(1);
            setTableLoading(true);
            GetVendorOrderListAction({
              start_date: dateFilter?.start,
              end_date: dateFilter?.end,
              region: marketplaceSelected?.region,
              sales_channel: marketplaceSelected?.sales_channel,
              page: 1,
              pageSize: pageSize,
              order_status: e,
            });
          }}
          value={selectedOrderStatus}
          getPopupContainer={(trigger) => trigger.parentNode}
        />

        <Select
          className="w-225px  ms-3"
          size="large"
          getPopupContainer={(trigger) => trigger.parentNode}
          options={marketplaceList}
          onChange={(_, e) => {
            setMarketplaceSelected({
              marketplace: e?.marketplace,
              marketplace_id: e?.marketplace_id,
              region: e?.region,
              sales_channel: e?.sales_channel,
            });
            setTableLoading(true);
            setPage(1);
            GetVendorOrderListAction({
              start_date: dateFilter?.start,
              end_date: dateFilter?.end,
              region: e?.region,
              sales_channel: e?.sales_channel,
              page: 1,
              pageSize: pageSize,
              order_status: selectedOrderStatus,
            });
          }}
          value={marketplaceSelected?.marketplace_id}
          loading={marketplaceLoading}
          placeholder="Select Marketplace"
        />
      </div>

      <div className="card mb-5">
        <div className="card-header">
          <div className="card-title">Orders</div>
        </div>
        <div className="card-body mt-0 pt-3">
          <div className="table-responsive">
            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
              loading={tableLoading}
              scroll={{ x: "max-content" }}
            />
          </div>
          <Pagination
            loading={tableLoading || tableData?.length === 0}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={onPerPage}
            onPageNo={onPageNo}
          />
        </div>
      </div>

      <div className="row d-none mt-5 mb-5">
        <div className="col-6">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Chargeback</div>
            </div>
            <div className="card-body pb-0 pt-0 h-400px hover-scroll">
              <div className="table-responsive mb-5">
                <Table
                  columns={columns__}
                  dataSource={data__}
                  scroll={{ x: "max-content" }}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 ">
          <div className="card  ">
            <div className="card-header">
              <div className="card-title">Motivations of Chargeback</div>
            </div>
            <div className="card-body h-400px">
              <ReactApexChart
                options={{
                  ...state.options,
                  colors: [
                    "#7acec2",
                    "#e6dfcc",
                    "#412517",
                    "#FFFF99",
                    "#00B3E6",
                    "#E6B333",
                  ],
                }}
                series={state.series}
                type="pie"
                height={350}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorOrder;
