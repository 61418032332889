export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_KEEPA_TOKEN_COUNT_SUCCESS":
    case "GET_KEEPA_TOKEN_COUNT_ERROR":
      return {
        ...state,
        GetKeepaTokenCountResponse: action.updatePayload,
      };
    case "GET_SELLING_REGION_SUCCESS":
    case "GET_SELLING_REGION_ERROR":
      return {
        ...state,
        GetSellingRegionResponse: action.updatePayload,
      };
    case "GET_REGION_BY_MARKETPLACE_SUCCESS":
    case "GET_REGION_BY_MARKETPLACE_ERROR":
      return {
        ...state,
        GetRegionByMarkeptlaceResponse: action.updatePayload,
      };
    case "USER_CREDENTIAL_UPDATE_SP_CREDENTIAL_SUCCESS":
    case "USER_CREDENTIAL_UPDATE_SP_CREDENTIAL_ERROR":
      return {
        ...state,
        UserCredentialUpdateSpCredentialResponse: action.updatePayload,
      };
    case "USER_CREDENTIAL_GENERATE_REFRESH_SUCCESS":
    case "USER_CREDENTIAL_GENERATE_REFRESH_ERROR":
      return {
        ...state,
        UserCredentialGenerateRefreshResponse: action.updatePayload,
      };
    case "FAKE_ACTION_COMMON":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
