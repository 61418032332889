import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  ADVERTISING_CLIENT_ID,
  APPID,
  MarketplaceFlag,
  ReturnURL,
} from "../../../../config";

export default function (props) {
  const { show, close, GetMarketplaceListAction, fakeActionCatalog } = props;

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    seller_name: JSON.parse(localStorage.getItem("user"))?.seller_name,
  });

  const GetMarketplaceListResponse = useSelector(
    (state) => state.Catalog.GetMarketplaceListResponse || {}
  );

  useEffect(() => {
    if (GetMarketplaceListResponse?.status === true) {
      let make_ = {};
      GetMarketplaceListResponse?.data?.forEach((e) => {
        if (!make_[e.region]) {
          make_ = { ...make_, [e.region]: [] };
        }
        make_[e.region]?.push(e);
      });
      setMarketplaceList(make_);

      if (GetMarketplaceListResponse?.data?.length > 0) {
        const { marketplace, marketplace_id, region, sales_channel } =
          GetMarketplaceListResponse?.data?.[0];

        setMarketplaceSelected({
          marketplace: marketplace,
          marketplace_id: marketplace_id,
          region: region,
          sales_channel: sales_channel,
        });
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("GetMarketplaceListResponse");
    } else if (GetMarketplaceListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      fakeActionCatalog("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListResponse]);

  useEffect(() => {
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(ADVERTISING_CLIENT_ID);
    };
    let a = document.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";

    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    const getRoot = document.getElementById("amazon-root");
    getRoot.appendChild(a);

    GetMarketplaceListAction();
    return () => {};
  }, []);

  return (
    <Modal
      centered
      show={show}
      onHide={close}
      size="lg"
      style={{ zIndex: 9999 }}
    >
      <Modal.Header className="px-5" closeButton>
        <Modal.Title>Add New Advertising Credentials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pe-7" id="kt_modal_add_customer_scroll" style={{}}>
          <div className="row mb-7 align-items-center">
            <div className="col-md-4 fv-row">
              <label className=" fs-6 fw-bold mb-2">Seller Account Name</label>
            </div>
            <div className="col-md-8 fv-row">
              <Input
                placeholder="Enter Seller Account Name"
                style={{ height: "38px" }}
                className="ant_common_input"
                size="large"
                id="seller_account_name"
                value={marketplaceSelected?.seller_name || ""}
                onChange={(e) => {
                  setMarketplaceSelected({
                    ...marketplaceSelected,
                    seller_name: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row mb-7 align-items-center">
            <div className="col-md-4 fv-row">
              <label className=" fs-6 fw-bold mb-2">Marketplace</label>
            </div>
            <div className="col-md-8 fv-row">
              <Select
                className="me-3"
                style={{ width: "100%" }}
                size="large"
                getPopupContainer={(target) => target.parentNode}
                options={
                  marketplaceList &&
                  Object.keys(marketplaceList)?.length !== 0 &&
                  Object.keys(marketplaceList)?.map((d, i) => {
                    return {
                      label: d,
                      options: marketplaceList[d]?.map((r) => {
                        return { label: r?.name, value: r?.id, ...r };
                      }),
                    };
                  })
                }
                onChange={(_, e) => {
                  setMarketplaceSelected({
                    ...marketplaceSelected,
                    marketplace: e?.label,
                    marketplace_id: e?.id,
                    region: e?.region,
                    sales_channel: e?.sales_channel,
                  });
                }}
                value={marketplaceSelected?.marketplace_id}
                loading={marketplaceLoading}
                placeholder="Select Marketplace"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="reset"
          id="kt_modal_add_customer_cancel"
          className="btn btn-light me-3"
          onClick={() => {
            close();
          }}
        >
          Discard
        </button>

        <a
          id="LoginWithAmazon"
          style={{
            marginLeft: "10px",
            opacity:
              Object.values(marketplaceSelected)?.filter((d) => !d)?.length ===
              0
                ? 1
                : 0.5,
            pointerEvents:
              Object.values(marketplaceSelected)?.filter((d) => !d)?.length ===
              0
                ? "all"
                : "none",
          }}
          onClick={() => {
            if (
              Object.values(marketplaceSelected)?.filter((d) => !d)?.length !==
              0
            ) {
              return;
            }
            let options = {};
            options.scope = "advertising::campaign_management";
            options.response_type = "code";

            window.amazon.Login.authorize(
              options,
              `${ReturnURL}?marketplace_id=${
                marketplaceSelected?.marketplace_id || ""
              }&seller_name=${marketplaceSelected?.seller_name || ""}`
            );
            close();
          }}
        >
          <img
            border="0"
            alt="Login with Amazon"
            src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
            width="156"
            height="32"
          />
        </a>
      </Modal.Footer>
    </Modal>
  );
}
