import styled from "styled-components";

const Wrapper = styled.div`
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: #ca695a !important;
  }
  a {
    color: #ca695a !important;
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #ca695a !important;
    }
  }
  .ant-input-affix-wrapper {
    background-color: #fef3f2 !important;
    border-color: #601947 !important;
  }
`;

export default Wrapper;
