export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "ANALYTIC_BY_CARD_SUCCESS":
    case "ANALYTIC_BY_CARD_ERROR":
      return {
        ...state,
        AnalyticByCardResponse: action.updatePayload,
      };
    case "ANALYTIC_BY_TABLE_SUCCESS":
    case "ANALYTIC_BY_TABLE_ERROR":
      return {
        ...state,
        AnalyticByTableResponse: action.updatePayload,
      };
    case "ANALYTIC_BY_LINE_GRAPH_SUCCESS":
    case "ANALYTIC_BY_LINE_GRAPH_ERROR":
      return {
        ...state,
        AnalyticByLineGraphResponse: action.updatePayload,
      };
    case "ANALYTIC_BY_PIE_GRAPH_SUCCESS":
    case "ANALYTIC_BY_PIE_GRAPH_ERROR":
      return {
        ...state,
        AnalyticByPieGraphResponse: action.updatePayload,
      };
    case "CARD_OVERVIEW_DATA_SUCCESS":
    case "CARD_OVERVIEW_DATA_ERROR":
      return {
        ...state,
        CardOverviewDataResponse: action.updatePayload,
      };
    case "MONTH_COMPARE_DATA_SUCCESS":
    case "MONTH_COMPARE_DATA_ERROR":
      return {
        ...state,
        MonthCompareDataResponse: action.updatePayload,
      };
    case "MARKETPLACE_DATA_SUCCESS":
    case "MARKETPLACE_DATA_ERROR":
      return {
        ...state,
        MarketplaceDataResponse: action.updatePayload,
      };
    case "PARENT_DATA_SUCCESS":
    case "PARENT_DATA_ERROR":
      return {
        ...state,
        ParentDataResponse: action.updatePayload,
      };
    case "TOP_PRODUCTS_SUCCESS":
    case "TOP_PRODUCTS_ERROR":
      return {
        ...state,
        TopProductsResponse: action.updatePayload,
      };
    case "GET_CATEGORY_LIST_SUCCESS":
    case "GET_CATEGORY_LIST_ERROR":
      return {
        ...state,
        GetCategoryListResponse: action.updatePayload,
      };
    case "GET_SUB_CATEGORY_LIST_SUCCESS":
    case "GET_SUB_CATEGORY_LIST_ERROR":
      return {
        ...state,
        GetSubCategoryListResponse: action.updatePayload,
      };
    case "GET_GRAPH_DATA_SUCCESS":
    case "GET_GRAPH_DATA_ERROR":
      return {
        ...state,
        GetGraphDataResponse: action.updatePayload,
      };
    case "GET_VENDOR_ANALYTICS_BY_CARD_SUCCESS":
    case "GET_VENDOR_ANALYTICS_BY_CARD_ERROR":
      return {
        ...state,
        GetVendorAnalyticsByCardResponse: action.updatePayload,
      };
    case "GET_VENDOR_ANALYTICS_BY_CHART_SUCCESS":
    case "GET_VENDOR_ANALYTICS_BY_CHART_ERROR":
      return {
        ...state,
        GetVendorAnalyticsByChartResponse: action.updatePayload,
      };
    case "GET_VENDOR_ORDER_STATUS_SUCCESS":
    case "GET_VENDOR_ORDER_STATUS_ERROR":
      return {
        ...state,
        GetVendorOrderStatusResponse: action.updatePayload,
      };
    case "GET_VENDOR_ORDER_LIST_SUCCESS":
    case "GET_VENDOR_ORDER_LIST_ERROR":
      return {
        ...state,
        GetVendorOrderListResponse: action.updatePayload,
      };

    case "FAKE_ACTION_ANALYTICS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
