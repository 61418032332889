import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ChangePasswordAction,
  UpdateUserAction,
  fakeActionProfile,
} from "../../redux/module_/profile/profile.action";
import Profile from "../../modules/pages/user-setting/lib/profile";

const mapStateToProps = (state) => ({
  ChangePasswordResponse: state.profileReducer.ChangePasswordResponse,
  UpdateUserProfileResponse: state.profileReducer.UpdateUserProfileResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ChangePasswordAction,
      UpdateUserAction,
      fakeActionProfile,
    },
    dispatch
  );

const Profile_ = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default Profile_;
