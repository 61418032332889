import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ManageCompany from "../../modules/pages/admin/manage-company";
import {
  CompanyListAction,
  CompanyAddAction,
  fakeActionManageUser,
} from "../../redux/module_/manage-user/index.action";

const mapStateToProps = (state) => ({
  GetCompanyListResponse: state.User.GetCompanyListResponse,
  AddCompanyDataResponse: state.User.AddCompanyDataResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      CompanyListAction,
      CompanyAddAction,
      fakeActionManageUser,
    },
    dispatch
  );

const ManageCompany_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCompany);

export default ManageCompany_;
