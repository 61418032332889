import React, { useContext, useEffect } from "react";
import { Wrapper } from "./style";
import { Result, Select, Table, Tabs, Tag } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { DefaultPerPage, checkPermission, timeSince } from "../../../../config";
import moment from "moment";
import Pagination from "../../../../components/pagination";

import {
  ClockCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../commonContext";
import AmazonFeedListing from "./amazon_feed_listing";

const CentralLog = (props) => {
  const {
    GetCatalogListAmazonAction,
    GetEventNameAction,
    GetEventTypeAction,
    fakeActionCLS,
  } = props;
  const contextValue = useContext(GlobalContext);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [eventNameLoading, setEventNameLoading] = useState(true);
  const [eventTypeLoading, setEventTypeLoading] = useState(true);

  const [eventNameList, setEventNameList] = useState([]);
  const [eventTypeList, setEventTypeList] = useState([]);

  const [filters, setFilters] = useState({
    event_type: null,
    event_name: null,
  });

  const [selectedTab, setSelectedTab] = useState("1");

  const SystemEventProcessListResponse = useSelector(
    (state) => state?.CLS?.SystemEventProcessListResponse || {}
  );

  const GetEventNameListResponse = useSelector(
    (state) => state?.CLS?.GetEventNameListResponse || {}
  );

  const GetEventTypeListResponse = useSelector(
    (state) => state?.CLS?.GetEventTypeListResponse || {}
  );

  const columns = [
    {
      title: "#",
      align: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Event Date",
      align: "left",
      render: (text) => {
        return (
          <span>
            {moment(new Date(text?.event_date)).isValid()
              ? moment(new Date(text?.event_date)).format("MMM DD, YYYY")
              : ""}
          </span>
        );
      },
    },
    {
      title: "Event Name",
      align: "left",
      render: (text) => {
        return <span>{text?.event_name || "-"}</span>;
      },
    },
    {
      title: "Event Type",
      align: "left",
      render: (text) => {
        return <span>{text?.event_type || "-"}</span>;
      },
    },
    {
      title: "Event Status",
      align: "left",
      render: (item) => {
        return (
          <Tag
            color={
              item.event_status === "PENDING"
                ? "warning"
                : item.event_status === "IN_PROGRESS"
                ? "processing"
                : item.event_status === "DONE"
                ? "success"
                : item.event_status === "FAILED"
                ? "error"
                : item.event_status === "FATAL"
                ? "error"
                : item.event_status === "CANCELLED"
                ? "error"
                : "error"
            }
            icon={
              item.event_status === "PENDING" ? (
                <ClockCircleOutlined />
              ) : item.event_status === "IN_PROGRESS" ? (
                <SyncOutlined spin />
              ) : item.event_status === "DONE" ? (
                <CheckCircleOutlined />
              ) : item.event_status === "FAILED" ? (
                <CloseCircleOutlined />
              ) : item.event_status === "FATAL" ? (
                <CloseCircleOutlined />
              ) : item.event_status === "CANCELLED" ? (
                <CloseCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )
            }
          >
            {item.event_status || "N/A"}
          </Tag>
        );
      },
    },
    {
      title: "Priority",
      align: "left",
      render: (text) => {
        return <span>{text?.priority || 0}</span>;
      },
    },
    {
      title: "Retry Count",
      align: "left",
      render: (text) => {
        return <span>{text?.retry_count || 0}</span>;
      },
    },
    {
      title: "Execution Time",
      align: "left",
      render: (text) => {
        return <span>{text?.execution_time || "-"}</span>;
      },
    },
    {
      title: "Marketplace",
      align: "left",
      render: (text) => {
        return <span>{text?.marketplace || "-"}</span>;
      },
    },
    {
      title: "Marketplace ID",
      align: "left",
      render: (text) => {
        return <span>{text?.marketplace_id || "-"}</span>;
      },
    },
    {
      title: "Status",
      align: "left",
      render: (text) => {
        return <span>{text?.status || "0"}</span>;
      },
    },
    {
      title: "Created At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text?.created_at * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text?.created_at)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Updated At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text?.updated_at * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text?.updated_at)})
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (SystemEventProcessListResponse?.status === true) {
      setTotalPage(
        SystemEventProcessListResponse?.data?.pagination?.totalCount || 0
      );
      setList(SystemEventProcessListResponse?.data?.records || []);
      setLoading(false);
      fakeActionCLS("SystemEventProcessListResponse");
    } else if (SystemEventProcessListResponse?.status === false) {
      setLoading(false);
      setList([]);
      fakeActionCLS("SystemEventProcessListResponse");
    }
  }, [SystemEventProcessListResponse]);

  useEffect(() => {
    if (GetEventTypeListResponse?.status === true) {
      setEventTypeList(GetEventTypeListResponse?.data);
      setEventTypeLoading(false);
      fakeActionCLS("GetEventTypeListResponse");
    } else if (GetEventTypeListResponse?.status === false) {
      setEventTypeList([]);
      setEventTypeLoading(false);
      fakeActionCLS("GetEventTypeListResponse");
    }
  }, [GetEventTypeListResponse]);

  useEffect(() => {
    if (GetEventNameListResponse?.status === true) {
      setEventNameList(GetEventNameListResponse?.data);
      setEventNameLoading(false);
      fakeActionCLS("GetEventNameListResponse");
    } else if (GetEventNameListResponse?.status === false) {
      setEventNameList([]);
      setEventNameLoading(false);
      fakeActionCLS("GetEventNameListResponse");
    }
  }, [GetEventNameListResponse]);

  useEffect(() => {
    setLoading(true);
    setList([]);
    GetCatalogListAmazonAction({
      page: page,
      perPage: pageSize,
      ...filters,
    });

    setEventNameLoading(true);
    setEventNameList([]);
    GetEventNameAction();

    setEventTypeLoading(true);
    setEventTypeList([]);
    GetEventTypeAction();

    return () => {};
  }, []);

  const onPageNo = (e) => {
    setPage(e);
    setLoading(true);
    GetCatalogListAmazonAction({
      page: e,
      perPage: pageSize,
      ...filters,
    });
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    GetCatalogListAmazonAction({
      page: page,
      perPage: e,
      ...filters,
    });
  };

  if (checkPermission(contextValue, "application_logs", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }

  return (
    <Wrapper className="p-5 fadeInBottom">
      <div
        className={`${
          selectedTab === "1" ? "d-flex" : "d-none"
        } justify-content-end flex-wrap mb-5 fix-over fix-over`}
      >
        <Select
          placeholder="Select Event Name"
          size="large"
          className="w-200px ms-3"
          loading={eventNameLoading}
          options={Object?.entries(eventNameList)?.map((d) => {
            return {
              label: d?.[0],
              value: d?.[0],
            };
          })}
          allowClear
          onChange={(e) => {
            setLoading(true);
            setList([]);
            GetCatalogListAmazonAction({
              page: page,
              perPage: pageSize,
              ...filters,
              event_name: e,
            });
            setFilters({ ...filters, event_name: e });
          }}
          value={filters.event_name}
          getPopupContainer={(target) => target.parentNode}
        />
        <Select
          placeholder="Select Event Type"
          size="large"
          className="w-200px ms-3"
          allowClear
          onChange={(e) => {
            setLoading(true);
            setList([]);
            GetCatalogListAmazonAction({
              page: page,
              perPage: pageSize,
              ...filters,
              event_type: e,
            });
            setFilters({ ...filters, event_type: e });
          }}
          value={filters.event_type}
          loading={eventTypeLoading}
          options={Object?.entries(eventTypeList)?.map((d) => {
            return {
              label: d?.[1],
              value: d?.[1],
            };
          })}
          getPopupContainer={(target) => target.parentNode}
        />
        <Select
          placeholder="Select Event Status"
          className="w-200px ms-3"
          size="large"
          onChange={(e) => {
            setLoading(true);
            setList([]);
            GetCatalogListAmazonAction({
              page: page,
              perPage: pageSize,
              ...filters,
              event_status: e,
            });
            setFilters({ ...filters, event_status: e });
          }}
          value={filters?.event_status}
          allowClear
          options={[
            {
              label: "Done",
              value: "DONE",
            },
            {
              label: "Pending",
              value: "PENDING",
            },
            {
              label: "Failed",
              value: "FAILED",
            },
            {
              label: "In Progress",
              value: "IN_PROGRESS",
            },
            {
              label: "Cancelled",
              value: "CANCELLED",
            },
            {
              label: "Not Found",
              value: "NOT_FOUND",
            },
            {
              label: "Fatal",
              value: "FATAL",
            },
          ]}
          getPopupContainer={(target) => target.parentNode}
        />
      </div>
      <Tabs
        tabBarStyle={{ marginBottom: 0 }}
        onChange={(e) => {
          setSelectedTab(e);
        }}
        activeKey={selectedTab}
      >
        <Tabs.TabPane tab="Reports" key="1">
          {TableView(
            columns,
            list,
            loading,
            pageSize,
            page,
            totalPage,
            onPerPage,
            onPageNo
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Amazon Feed Listing Update" key="2">
          <AmazonFeedListing columns={columns} {...props} />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
};

export default CentralLog;
function TableView(
  columns,
  list,
  loading,
  pageSize,
  page,
  totalPage,
  onPerPage,
  onPageNo
) {
  return (
    <div className="card">
      <div className="card-body">
        <Table
          columns={columns}
          dataSource={list}
          scroll={{ x: "max-content" }}
          loading={loading}
          pagination={false}
        />
        <Pagination
          loading={loading || list?.length === 0}
          pageSize={pageSize}
          pageSizeStatus={false}
          page={page}
          totalPage={totalPage}
          onPerPage={onPerPage}
          onPageNo={onPageNo}
        />
      </div>
    </div>
  );
}
