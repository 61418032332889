import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CompetitorResearch from "../../modules/pages/competitor-research";
import {
  GetProductsListAction,
  GetBrandsListAction,
  GetBrandsListFilterAction,
  GetDomainListAction,
  PutStoreMyListAction,
  AllMyListAction,
  DeleteMyListSingleAction,
  fakeActionProducts,
} from "../../redux/modules/product-research/product-research.action";
import {
  GetCompetitorListAction,
  GetSellerListAction,
  fakeActionCompetitor,
} from "../../redux/modules/competitor-research/competitor-research.action";

const mapStateToProps = (state) => ({
  GetCompetitorListResponse: state.CompetitorResearch.GetCompetitorListResponse,
  GetSellerListResponse: state.CompetitorResearch.GetSellerListResponse,
  GetCategoriesListResponse: state.ProductsResearch.GetCategoriesListResponse,
  GetBrandsListResponse: state.ProductsResearch.GetBrandsListResponse,
  GetBrandsListFilterResponse:
    state.ProductsResearch.GetBrandsListFilterResponse,
  PutStoreMyListProductResponse:
    state.ProductsResearch.PutStoreMyListProductResponse,
  DeleteMyListSingleResponse: state.ProductsResearch.DeleteMyListSingleResponse,
  GetAllMyListInProductsResponse:
    state.ProductsResearch.GetAllMyListInProductsResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetProductsListAction,
      GetBrandsListAction,
      GetBrandsListFilterAction,
      GetDomainListAction,
      GetCompetitorListAction,
      GetSellerListAction,
      fakeActionCompetitor,

      fakeActionProducts,
      AllMyListAction,
      PutStoreMyListAction,
      DeleteMyListSingleAction,
    },
    dispatch
  );

const CompetitorResearchASINWiser = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetitorResearch);

export default CompetitorResearchASINWiser;
