import { Tag } from "antd";
import React from "react";

const View1 = () => {
  return (
    <div className="View1 p-8">
      <label className="fs-1">Late Shipment Rate</label>
      <div className="mt-5 fs-5">
        Target&nbsp;:&nbsp;
        <Tag className="border-remove" color="purple">
          under 4%
        </Tag>
      </div>
      <div className="row border-top-dotted mt-5 pt-5">
        <div className="col-xl-6 col-md-12">
          <div className="mb-5">
            Time Window&nbsp;:&nbsp;
            <Tag className="border-remove" color="blue">
              10 days
            </Tag>
          </div>
          <div className="mb-5">
            Order Date&nbsp;:&nbsp;
            <Tag className="border-remove" color="blue">
              Aug 28, 2023 - Sep 6, 2023
            </Tag>
          </div>
          <div className="fs-1 mb-5">N/A</div>
          <div>
            Orders shipped late&nbsp;:&nbsp;<b>0</b>
          </div>
          <div className="mb-5">
            Total orders&nbsp;:&nbsp;<b>0</b>
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="mb-5">
            Time Window&nbsp;:&nbsp;
            <Tag className="border-remove" color="blue">
              30 days
            </Tag>
          </div>
          <div className="mb-5">
            Order Date&nbsp;:&nbsp;
            <Tag className="border-remove" color="blue">
              Aug 8, 2023 - Sep 6, 2023
            </Tag>
          </div>
          <div className="fs-1 mb-5">N/A</div>
          <div>
            Orders shipped late&nbsp;:&nbsp;<b>0</b>
          </div>
          <div className="mb-5">
            Total orders&nbsp;:&nbsp;<b>0</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View1;
