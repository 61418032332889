import axiosCall from "../../configurations/network-services/axiosCall";

export const GetUserListAction = (value) => {
  const path = `users?filter_value=${value || ""}&sort=-u_id`;
  const responseType = "USER_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetMenuListAction = () => {
  const path = `embedded-data`;
  const responseType = "EMBEDDED_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const AddEmbedCodeAction = (data) => {
  const path = `embedded-data`;
  const responseType = "ADD_EMBEDDED_DATA";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const SwitchUserAction = (id) => {
  const path = `switch-user/${id}`;
  const responseType = "SWITCH_USER";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SwitchUserBackAction = () => {
  const path = `switch-back`;
  const responseType = "SWITCH_USER_BACK";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CompanyListAction = () => {
  const path = `company`;
  const responseType = "GET_COMPANY_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AdminListAction = () => {
  const path = `admin`;
  const responseType = "GET_ADMIN_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AdminAddAction = (data) => {
  const path = `admin`;
  const responseType = "ADD_ADMIN";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CompanyAddAction = (data) => {
  const path = `company`;
  const responseType = "ADD_COMPANY";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AllAdminAAction = () => {
  const path = `all-admin`;
  const responseType = "ALL_ADMIN_A";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddUserAction = (data) => {
  const path = `signup`;
  const responseType = "ADD_USER_L";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AllCompanyListAction = () => {
  const path = `all-company`;
  const responseType = "ALL_COMPANY_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UserUpdateAction = (id, data) => {
  const path = `user-update/${id}`;
  const responseType = "USER_UPDATE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UserDeleteAction = (id) => {
  const path = `user-delete/${id}`;
  const responseType = "USER_DELETE";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetAdminConfigAction = (id) => {
  const path = `get-admin-configuration/${id}`;
  const responseType = "GET_ADMIN_CONFIG_DATA";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddAdminConfigAction = (data) => {
  const path = `add-admin-configuration`;
  const responseType = "ADD_ADMIN_CONFIGURATION";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionManageUser = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_MANAGE_USER", state: data });
};
