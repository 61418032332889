import React, { useContext, useEffect } from "react";
import { AdvertisingWrapper } from "../style";
import { Button, Popconfirm, Table, message } from "antd";
import { ADVERTISING_CLIENT_ID, urlDecode } from "../../../../config";
import { useState } from "react";
import { useSelector } from "react-redux";
import AddNewAddModalView from "./ad-amazon";
import AdList from "./adList";
import moment from "moment";
import { TableLoading } from "../../../../components/table-animation";
import Icons from "../../../../components/icons";
import { GlobalContext } from "../../../../commonContext";

const Advertising = (props) => {
  const {
    AdvertisingCredentialsAction,
    fakeActionMarketPlace,
    CredentialDeleteAction,
  } = props;
  const contextValue = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [addNewAddModal, setAddNewAddModal] = useState(false);

  const [adList, setAdList] = useState([]);
  const [adListModal, setAdListModal] = useState(false);

  const AdvertisingCredentialsResponse = useSelector(
    (state) => state.UserSetting.AdvertisingCredentialsResponse
  );
  const GenerateRefreshAdTokenResponse = useSelector(
    (state) => state.UserSetting.GenerateRefreshAdTokenResponse
  );

  const CredentialDelete = useSelector(
    (state) => state.UserSetting.CredentialDelete
  );

  useEffect(() => {
    if (CredentialDelete?.status === true) {
      setLoading(true);
      setList([]);
      AdvertisingCredentialsAction();
      message.destroy();
      message.success(CredentialDelete?.message);
      fakeActionMarketPlace("CredentialDelete");
    } else if (CredentialDelete?.status === false) {
      message.destroy();
      message.error(CredentialDelete?.message);
      fakeActionMarketPlace("CredentialDelete");
    }
  }, [CredentialDelete]);

  useEffect(() => {
    if (
      GenerateRefreshAdTokenResponse?.status === true ||
      GenerateRefreshAdTokenResponse?.status === false
    ) {
      message.destroy();
      localStorage.removeItem("adCode");
      if (GenerateRefreshAdTokenResponse?.status) {
        setAdList(JSON.parse(GenerateRefreshAdTokenResponse?.data));

        setAdListModal(true);
        message.success(GenerateRefreshAdTokenResponse?.message);
      } else {
        message.error(GenerateRefreshAdTokenResponse?.message);
      }
      fakeActionMarketPlace("GenerateRefreshAdTokenResponse");
    }
  }, [GenerateRefreshAdTokenResponse]);

  useEffect(() => {
    if (AdvertisingCredentialsResponse?.status === true) {
      contextValue?.updateCommonGlobalVal({
        account_type: AdvertisingCredentialsResponse?.data?.account_type || 1,
      });

      setLoading(false);
      setList(AdvertisingCredentialsResponse?.data?.records);
      if (!adListModal) {
        fakeActionMarketPlace("AdvertisingCredentialsResponse");
      }
    } else if (AdvertisingCredentialsResponse?.status === false) {
      setLoading(false);
      fakeActionMarketPlace("AdvertisingCredentialsResponse");
    }
  }, [AdvertisingCredentialsResponse]);

  const columns = [
    {
      title: "#",
      dataIndex: "key", // You'll need to provide a dataIndex for each column
      key: "key",
    },
    {
      title: "Seller Account Name",

      render: (text) => {
        if (!text?.credential_details) return <div>-</div>;
        return <span>{JSON.parse(text?.credential_details)?.seller_name}</span>;
      },
    },

    // {
    //   title: "Type",

    //   render: (text) => {
    //     if (!text?.type) return <div>-</div>;
    //     return <span>{text?.type}</span>;
    //   },
    // },
    // {
    //   title: "Name",

    //   render: (text) => {
    //     if (!text?.name) return <div>-</div>;
    //     return <span>{text?.name}</span>;
    //   },
    // },
    {
      title: "Profile ID",
      render: (text) => {
        if (!text?.credential_details) return <div>-</div>;
        return <span>{JSON.parse(text?.credential_details)?.profile_id}</span>;
      },
    },
    {
      title: "Credential Type",
      render: (text) => {
        if (!text?.credential_type) return <div>-</div>;
        return <span>{text?.credential_type}</span>;
      },
    },
    {
      title: "Account Type",
      render: (text) => {
        if (!text?.account_type) return <div>-</div>;
        return <b>{text?.account_type}</b>;
      },
    },
    {
      title: "Client ID",
      render: (text) => {
        if (!text?.credential_details) return <div>-</div>;
        return <span>{JSON.parse(text?.credential_details)?.client_id}</span>;
      },
    },
    {
      title: "Country Code",
      render: (text) => {
        if (!text?.credential_details) return <div>-</div>;
        return (
          <span>{JSON.parse(text?.credential_details)?.country_code}</span>
        );
      },
    },
    {
      title: "Currency Code",

      render: (text) => {
        if (!text?.credential_details) return <div>-</div>;
        return (
          <span>{JSON.parse(text?.credential_details)?.currency_code}</span>
        );
      },
    },
    {
      title: "Time Zone",
      render: (text) => {
        if (!text?.credential_details) return <div>-</div>;
        return <span>{JSON.parse(text?.credential_details)?.time_zone}</span>;
      },
    },
    {
      title: "Created At",

      render: (text) => {
        if (!text?.updated_at) return <div>-</div>;
        return (
          <span>
            {moment(new Date(parseInt(text?.created_at) * 1000)).format(
              "YYYY/MM/DD"
            )}
          </span>
        );
      },
    },
    {
      title: "Updated At",
      render: (text) => {
        if (!text?.updated_at) return <div>-</div>;
        return (
          <span>
            {moment(new Date(parseInt(text?.updated_at) * 1000)).format(
              "YYYY/MM/DD"
            )}
          </span>
        );
      },
    },
    {
      title: "Action",
      render: (d) => {
        return (
          <>
            <Icons
              type="Edit"
              style={{ cursor: "pointer" }}
              className="ms-2"
              onClick={() => {}}
            />
            <Popconfirm
              title="Delete the row"
              placement="left"
              description="If Yes, All data will be erased and credentials will be removed"
              onConfirm={() => {
                message.destroy();
                message.loading("Loading...", 0);
                CredentialDeleteAction(d?.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Icons
                type="Delete"
                style={{ cursor: "pointer" }}
                className="ms-2"
                onClick={() => {}}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  // Sample data for demonstration purposes
  const data = [
    // Add your data objects here
  ];

  useEffect(() => {
    setLoading(true);
    setList([]);
    AdvertisingCredentialsAction();
    return () => {};
  }, []);

  return (
    <>
      <AdvertisingWrapper>
        <div className="row gy-5 g-xl-5">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header border-bottom-dashed">
                <h3 className="card-title fw-bold text-dark">
                  Amazon SP API Credentials List
                </h3>
                <div className="card-toolbar">
                  <div className="card-toolbar">
                    <Button
                      type="primary"
                      onClick={() => {
                        setAddNewAddModal(true);
                      }}
                    >
                      Add
                    </Button>
                    {/* <div id="amazon-root">
                    <a id="LoginWithAmazon"  style={{ marginLeft: 10 }}>
                      <img
                        border={0}
                        alt="Login with Amazon"
                        src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                        width={156}
                        height={32}
                      />
                    </a>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="card-body py-2">
                {loading ? (
                  <TableLoading
                    id="test-table"
                    columns={[1, 2, 3, 4, 5]}
                    checkBoxVal={true}
                    actions={[1, 2]}
                  />
                ) : (
                  <div className="table-responsive">
                    <Table
                      pagination={false}
                      scroll={{ x: "max-content" }}
                      columns={columns}
                      dataSource={list?.map((d, i) => ({ ...d, key: i + 1 }))}
                      laoding={loading}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AdvertisingWrapper>
      {addNewAddModal && (
        <AddNewAddModalView
          show={addNewAddModal}
          close={() => setAddNewAddModal(false)}
          {...props}
        />
      )}
      {adListModal && (
        <AdList
          show={adListModal}
          data={adList?.map((d, i) => {
            return { ...d, key: i };
          })}
          getList={() => {
            setLoading(true);
            setList([]);
            AdvertisingCredentialsAction();
          }}
          onHide={() => {
            setAdList([]);
            setAdListModal(false);
          }}
          {...props}
        />
      )}
    </>
  );
};

export default Advertising;
