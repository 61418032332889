import React, { Suspense, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Error from "../components/Error";
import PageLoader from "../components/page-loader";
import { MyNewSidebarFunc } from "../config";
import asyncComponent from "../core/helper/asyncComponent";
import { adminRoutersList, userRoutersList } from "./lib";

function PageRouter(props) {
  const { authStatus } = props;
  let menus = authStatus ? adminRoutersList : userRoutersList;
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {menus
          ?.filter(
            (person, index) =>
              index === menus.findIndex((other) => person.path === other.path)
          )
          ?.map((d, i) => {
            return (
              d && <Route key={i} path={d?.path} component={d?.name} exact />
            );
          })}
        {/* <Route
          path={"/wholesale-supplier-directory/wholesale-supplier-list"}
          component={WholesaleListSuppliersASINWiser}
          exact
        /> */}
        <Route path="*" render={() => <Error status={1} />} />
      </Switch>
    </Suspense>
  );
}

export default PageRouter;
