import { Checkbox, Modal, Switch, message } from "antd";
import React from "react";

const Config = ({ isOpen, onClose, list, setList, onSubmit }) => {
  const updateList = (updatedList) => {
    setList(updatedList);
  };
  return (
    <Modal
      title="Config"
      maskClosable={false}
      open={isOpen}
      onOk={onSubmit}
      onCancel={onClose}
    >
      <div className="d-flex align-items-cneter justify-content-between mt-10">
        <h3>Title</h3>

        <div className="d-flex align-items-cneter">
          <h5 className="ms-3"> Sticky</h5>
        </div>
      </div>
      <div className="configGrid mt-3">
        {list?.map((d, i) => {
          return (
            <>
              <div
                key={i}
                className="d-flex align-items-cneter justify-content-between mb-3"
              >
                <div>
                  <Checkbox
                    checked={d?.status}
                    onChange={() => {
                      const updatedList = [...list];
                      updatedList[i].status = !d.status;
                      updateList(updatedList);
                    }}
                  >
                    {d?.title}
                  </Checkbox>
                </div>

                <div className="d-flex align-items-cneter">
                  <span className="ms-3">
                    <Switch
                      onChange={() => {
                        // Clone the existing list to update
                        const updatedList = [...list];

                        // Toggle the sticky state of the current item
                        const isStickyToggled = !d.sticky;

                        // Count how many items are currently sticky
                        const stickyCount = updatedList.filter(
                          (item) => item.sticky
                        ).length;

                        // Check if toggling this item will exceed the maximum allowed sticky items
                        if (isStickyToggled && stickyCount >= 5) {
                          // Display a message if trying to exceed the maximum number of sticky items
                          message.destroy();
                          message.warning(
                            "You can only have a maximum of 5 sticky columns."
                          );
                        } else {
                          // Otherwise, proceed to toggle the sticky state
                          console.log(isStickyToggled, "!d.sticky");
                          updatedList[i].sticky = isStickyToggled;

                          // Update the main list with the modified version
                          updateList(updatedList);
                        }
                        // const updatedList = [...list];
                        // console.log(!d.sticky, "!d.sticky");
                        // updatedList[i].sticky = !d.sticky;
                        // updateList(updatedList);
                      }}
                      checked={d?.sticky}
                    />
                  </span>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </Modal>
  );
};

export default Config;
