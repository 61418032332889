import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { DatePicker, Input, Select, Table } from "antd";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { MarketplaceFlag } from "../../../config";

const Agency = (props) => {
  const { SalesChannelListAction, fakeActionCatalog } = props;
  const columns = [
    {
      title: "Seller Name",
      dataIndex: "sellerName",
      key: "sellerName",
    },
    {
      title: "Agency Name",
      dataIndex: "agencyName",
      key: "agencyName",
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
    },
    {
      title: "Conversion",
      dataIndex: "conversion",
      key: "conversion",
    },
    {
      title: "Account Status",
      dataIndex: "accountStatus",
      key: "accountStatus",
    },
    {
      title: "Catalog Status",
      dataIndex: "catalogueStatus",
      key: "catalogueStatus",
    },
    {
      title: "Total Year Spend",
      dataIndex: "totalYearSpend",
      key: "totalYearSpend",
    },
    {
      title: "Adv Spent",
      dataIndex: "advSpent",
      key: "advSpent",
    },
    {
      title: "Annual TACOS",
      dataIndex: "annualTACOS",
      key: "annualTACOS",
    },
  ];

  const data = [
    // Replace this with your actual data
    {
      key: "1",
      sellerName: "Seller 1",
      agencyName: "Agency A",
      revenue: 10000,
      conversion: 0.1,
      accountStatus: "Active",
      catalogueStatus: "Published",
      totalYearSpend: 5000,
      advSpent: 3000,
      annualTACOS: 0.15,
    },
    {
      key: "2",
      sellerName: "Seller 2",
      agencyName: "Agency B",
      revenue: 8000,
      conversion: 0.08,
      accountStatus: "Inactive",
      catalogueStatus: "Draft",
      totalYearSpend: 6000,
      advSpent: 2500,
      annualTACOS: 0.12,
    },
    // Add more data rows as needed
  ];
  // Sample data for the chart
  const salesData = [30, 40, 45, 50, 55, 60, 70];
  const advSpentData = [10, 15, 20, 25, 30, 35, 40];

  // Define the chart options
  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true, // Enable animations
        easing: "easeinout", // Animation easing style
        speed: 800, // Animation duration in milliseconds
        animateGradually: {
          enabled: true,
          delay: 150,
        },
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
    yaxis: [
      {
        seriesName: "Sales",
        title: {
          text: "Sales",
        },
      },
      {
        seriesName: "Adv Spent",
        opposite: true,
        title: {
          text: "Adv Spent",
        },
      },
    ],
    colors: ["#008FFB", "#FF4560"],
  };

  const chartSeries = [
    {
      name: "Sales",
      data: salesData,
    },
    {
      name: "Adv Spent",
      data: advSpentData,
    },
  ];

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      setMarketplaceList(SalesChannelListResponse?.data?.records || []);
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  useEffect(() => {
    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();
    return () => {};
  }, []);
  return (
    <Wrapper className="fadeInBottom p-5">
      <div className="card">
        <div className="card-header">
          <div className="card-title"></div>
          <div className="card-toolbar">
            <DatePicker.RangePicker size="large" className="w-300px me-3" />
            <Select
              className="w-225px me-3"
              size="large"
              allowClear
              getPopupContainer={(target) => target.parentNode}
              options={MarketplaceFlag(
                marketplaceList,
                "marketplace_id",
                "sales_channel"
              )}
              onChange={(_, e) => {
                setMarketplaceSelected({
                  marketplace: e?.marketplace,
                  marketplace_id: e?.marketplace_id,
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                });
              }}
              value={marketplaceSelected?.marketplace_id}
              loading={marketplaceLoading}
              // value={selectedMarketplace}
              placeholder="Select Marketplace"
            />
            <Input
              size="large"
              className="w-200px"
              placeholder="Enter Agency Name"
            />
          </div>
        </div>
        <div className="card-body">
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={350}
          />
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Agency;
