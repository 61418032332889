import styled from "styled-components";

export const Wrapper = styled.div`
  .card_wrapper {
    margin: 0 auto;

    display: grid;

    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-gap: 10px;
    align-items: stretch;
  }
  .card-view_ {
    display: grid;
    align-content: space-between;
    transition: transform 0.3s cubic-bezier(0.374, 0.019, 0.035, 1.861);

    border-radius: 4px;

    height: 180px;
    &:hover {
      transform: scale(1.03);
    }
    .card-details {
      justify-items: center;
      align-content: space-between;
      padding-top: 15px;
      background: #fff;
      height: 130px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .badge {
      width: 100%;
    }
    .graph-view {
      background: #fff;
      height: 40px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      overflow: hidden;
      div {
        min-height: 50px;
        position: relative;
        top: -4px;
      }
    }
  }
  .card-view {
    display: grid;
    align-content: space-between;
    transition: transform 0.3s cubic-bezier(0.374, 0.019, 0.035, 1.861);

    border-radius: 4px;

    width: 100%;
    height: 180px;
    &:hover {
      transform: scale(1.03);
    }
    .card-details {
      justify-items: center;
      align-content: space-between;
      padding-top: 15px;
      background: #fff;
      height: 130px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .badge {
      width: 100%;
    }
    .graph-view {
      background: #fff;
      height: 40px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      overflow: hidden;
      div {
        min-height: 50px;
        position: relative;
        top: -4px;
      }
    }
  }

  .campaign-table-container {
    font-family: Arial, sans-serif;
    max-width: 600px; /* Adjust the width as needed */
    margin: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Simple shadow effect */
  }

  .campaign-table-header {
    background-color: #4caf50; /* Dark green background */
    color: white;
    text-align: center;
    font-size: 1.2em;
    padding: 10px 0;
  }

  .campaign-table {
    background-color: #f6f6f6; /* Light grey background */
  }

  .campaign-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
  }

  .campaign-row:last-child {
    border-bottom: none;
  }

  .campaign-type {
    flex-basis: 50%;
    font-weight: bold;
  }

  .campaign-spend,
  .campaign-acos {
    flex-basis: 25%;
    text-align: right;
    padding-left: 10px; /* To ensure some spacing */
  }

  .grand-total {
    background-color: #e7e7e7; /* Slightly darker grey for grand total */
    font-weight: bold;
  }

  .data-card {
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #f0f0f0;
    font-weight: bold;
  }

  .row_ {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
  }

  .row_.even {
    background: #e7f4e4;
  }

  .row_:last-child {
    border-bottom: none;
  }
`;
