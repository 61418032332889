import axiosCall from "../../configurations/network-services/axiosCall";

export const GetInventoryDataAction = (data) => {
  const path = `inventory-data?region=${data?.region || ""}&sales_channel=${
    data?.sales_channel || ""
  }&asin=${data?.asin || ""}&pageSize=${data?.pageSize || ""}&page=${
    data?.page || ""
  }`;
  const responseType = "INVENTORY_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetAllInventoryAction = (data) => {
  const path = `inventory-replenishment?region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}&search=${
    data?.asin || ""
  }&pageSize=${data?.pageSize || ""}&page=${
    data?.page || ""
  }&days_for_the_forecast=${data?.days_for_the_forecast || ""}&sort=${
    data?.sort || ""
  }&product_listing_type=${data?.product_listing_type || ""}`;
  const responseType = "GET_ALL_INVENTORY_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const InventoryEmailFlagAction = (inventory_email_flag) => {
  let FormData_ = new FormData();
  FormData_.append("inventory_email_flag", inventory_email_flag ? 1 : 0);
  const path = `update-inventory-mail`;
  const responseType = "INVENTORY_EMAIL_FLAG";
  return axiosCall("POST", path, responseType, FormData_, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const ReplenishmentBulkUpdateAction = (data) => {
  const path = `replenishment-bulk-update`;
  const responseType = "REPLENISHMENT_BULK_UPDATE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionInventory = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_INVENTORY", state: data });
};
