import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VendorOrder from "../../modules/pages/sales-analytics/vendor-overview";

import {
  SalesChannelListAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";
import {
  AnalyticsByCardAction,
  AnalyticsByTableAction,
  AnalyticsByLineAction,
  AnalyticsByPieAction,
  fakeActionAnalytics,
  GetCategoryAction,
  GetSubCategoryAction,
  GetVendorAnalyticByCardAction,
  GetVendorAnalyticByChartAction,
} from "../../redux/module_/analytics/index.action";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,

  AnalyticByCardResponse: state.Analytics.AnalyticByCardResponse,
  AnalyticByTableResponse: state.Analytics.AnalyticByTableResponse,
  AnalyticByLineGraphResponse: state.Analytics.AnalyticByLineGraphResponse,
  AnalyticByPieGraphResponse: state.Analytics.AnalyticByPieGraphResponse,
  GetCategoryListResponse: state.Analytics.GetCategoryListResponse,
  GetSubCategoryListResponse: state.Analytics.GetSubCategoryListResponse,

  GetVendorAnalyticsByCardResponse:
    state.Analytics.GetVendorAnalyticsByCardResponse,
  GetVendorAnalyticsByChartResponse:
    state.Analytics.GetVendorAnalyticsByChartResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      AnalyticsByCardAction,
      AnalyticsByTableAction,
      AnalyticsByLineAction,
      AnalyticsByPieAction,
      GetCategoryAction,
      GetSubCategoryAction,
      fakeActionAnalytics,
      GetVendorAnalyticByCardAction,
      GetVendorAnalyticByChartAction,

      // marketplace api
      SalesChannelListAction,
      fakeActionCatalog,
    },
    dispatch
  );

const VendorOrder__ = connect(mapStateToProps, mapDispatchToProps)(VendorOrder);

export default VendorOrder__;
