import React from "react";
import { Wrapper } from "./style";

import GeneralSettings from "./lib/general-settings";
import RuleConfig from "./lib/rule-config";

const AdminSetting = (props) => {
  return (
    <Wrapper className="p-5">
      {/* <GeneralSettings /> */}
      <RuleConfig {...props} />
    </Wrapper>
  );
};

export default AdminSetting;
