import axiosCall from "../../configurations/network-services/axiosCall";

export const GetCompetitorListAction = (data) => {
  const search = data?.searchVal?.split("&").join("%26");
  const path = `products/seller-product?CategoryId=${
    data?.CategoryId || ""
  }&Brand=${data?.Brand || ""}&SellerName=${search || ""}&page=${
    data?.page
  }&SearchAsin=${data?.Asin || ""}&DomainId=${data?.DomainId || ""}&perPage=${
    data?.perPage
  }${Object.entries(data?.list)
    ?.map((d) => {
      return `&${d[0]}=${d[1] || ""}`;
    })
    ?.join("")}`;
  const responseType = "GET_COMPETITOR_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetSellerListAction = (data) => {
  const search = data?.SearchSeller?.split("&").join("%26");
  const path = `sellers?page=${data?.page || 1}&perPage=${data?.perPage || 10}&Name=${
    data.name || ''
  }&SearchSeller=${search || ""}&DomainId=${data?.DomainId || ""}`;
  const responseType = "GET_COMPETITOR_SELLER_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCompetitor = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_COMPETITOR", state: data });
};
