import styled from "styled-components";

const Wrapper = styled.div`
  .ant-segmented-item-selected {
    background: linear-gradient(45deg, #601c46, #e3775f) !important;
    color: #ffffff;
    font-weight: bold;
  }
  .ant-segmented-item,
  .ant-segmented {
    :where(.css-dev-only-do-not-override-i56vxb).ant-segmented
      .ant-segmented-item {
      transition: color 0s cubic-bezier(0, 0, 0, 0);
    }
  }
  .tabs {
    position: sticky;
    top: 60px;
    z-index: 999;

    padding: 15px 0px;

    backdrop-filter: blur(8px);
    background-color: #ffffff00;
  }
`;

export default Wrapper;
export const ProfileWrapper = styled.div``;
export const SPAPIWrapper = styled.div``;
export const AdvertisingWrapper = styled.div``;
