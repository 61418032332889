import { Tag } from "antd";
import React from "react";

const View3 = () => {
  return (
    <div className="View3  p-8">
      <label className="fs-1">Valid Tracking Rate</label>
      <div className="row  mt-2 pt-5">
        <small>Non-exempted shipments</small>
        <div className="fs-1 mb-1">N/A</div>
        <div className="mt-1 fs-5">
          Target&nbsp;:&nbsp;
          <Tag className="border-remove" color="purple">
            over 95%
          </Tag>
        </div>
        <div className="col-xl-6 col-md-12">
          <div>
            Number of non-exempted shipments&nbsp;:&nbsp;<b>0</b>
          </div>
          <div className="mb-5">
            Shipments with valid tracking&nbsp;:&nbsp;<b>0</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View3;
