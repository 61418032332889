import React from "react";
import Wrapper from "./style";
import { Progress, Table, Tag, Button, Segmented } from "antd";

const columns = [
  {
    title: "Reason",
    dataIndex: "reason",
    key: "reason",
  },

  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (text) =>
      // You can format the date here using libraries like moment.js
      // For example:
      // moment(text).format('MMM DD, YYYY')
      text,
  },
  {
    title: "What Was Impacted?",
    dataIndex: "impacted",
    key: "impacted",
  },
  {
    title: "Action Taken",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Account Health Rating Impact",
    dataIndex: "ratingImpact",
    key: "ratingImpact",
  },
  {
    title: "Next Step",
    dataIndex: "nextStep",
    key: "nextStep",
    render: (text, record) => (
      // Render a button for the "Next Step" column
      <Button type="primary">Submit Appeal</Button>
    ),
  },
];

const data = [
  {
    key: "1",
    reason: "Sample Reason 1",
    link: "Sample Link 1",
    date: "Sep 08, 2023",
    impacted: "Sample Title and ASIN 1",
    status: (
      <span className="badge badge-light-warning fs-8 fw-bold">Pending</span>
    ),
    ratingImpact: "Sample Rating Impact 1",
    nextStep: "1",
  },
  {
    key: "2",
    reason: "Sample Reason 2",
    link: "Sample Link 2",
    date: "Sep 09, 2023",
    impacted: "Sample Title and ASIN 2",
    status: (
      <span className="badge badge-light-success fs-8 fw-bold">Done</span>
    ),
    ratingImpact: "Sample Rating Impact 2",
    nextStep: "2",
  },
  {
    key: "3",
    reason: "Sample Reason 3",
    link: "Sample Link 3",
    date: "Sep 10, 2023",
    impacted: "Sample Title and ASIN 3",
    status: (
      <span className="badge badge-light-info fs-8 fw-bold">In Progress</span>
    ),
    ratingImpact: "Sample Rating Impact 3",
    nextStep: "3",
  },
  {
    key: "4",
    reason: "Sample Reason 4",
    link: "Sample Link 4",
    date: "Sep 11, 2023",
    impacted: "Sample Title and ASIN 4",
    status: (
      <span className="badge badge-light-warning fs-8 fw-bold">Pending</span>
    ),
    ratingImpact: "Sample Rating Impact 4",
    nextStep: "4",
  },
  {
    key: "5",
    reason: "Sample Reason 5",
    link: "Sample Link 5",
    date: "Sep 12, 2023",
    impacted: "Sample Title and ASIN 5",
    status: (
      <span className="badge badge-light-success fs-8 fw-bold">Done</span>
    ),
    ratingImpact: "Sample Rating Impact 5",
    nextStep: "5",
  },
  {
    key: "6",
    reason: "Sample Reason 6",
    link: "Sample Link 6",
    date: "Sep 13, 2023",
    impacted: "Sample Title and ASIN 6",
    status: (
      <span className="badge badge-light-info fs-8 fw-bold">In Progress</span>
    ),
    ratingImpact: "Sample Rating Impact 6",
    nextStep: "6",
  },
  {
    key: "7",
    reason: "Sample Reason 7",
    link: "Sample Link 7",
    date: "Sep 14, 2023",
    impacted: "Sample Title and ASIN 7",
    status: (
      <span className="badge badge-light-warning fs-8 fw-bold">Pending</span>
    ),
    ratingImpact: "Sample Rating Impact 7",
    nextStep: "7",
  },
  {
    key: "8",
    reason: "Sample Reason 8",
    link: "Sample Link 8",
    date: "Sep 15, 2023",
    impacted: "Sample Title and ASIN 8",
    status: (
      <span className="badge badge-light-success fs-8 fw-bold">Done</span>
    ),
    ratingImpact: "Sample Rating Impact 8",
    nextStep: "8",
  },
  {
    key: "9",
    reason: "Sample Reason 9",
    link: "Sample Link 9",
    date: "Sep 16, 2023",
    impacted: "Sample Title and ASIN 9",
    status: (
      <span className="badge badge-light-info fs-8 fw-bold">In Progress</span>
    ),
    ratingImpact: "Sample Rating Impact 9",
    nextStep: "9",
  },
  {
    key: "10",
    reason: "Sample Reason 10",
    link: "Sample Link 10",
    date: "Sep 17, 2023",
    impacted: "Sample Title and ASIN 10",
    status: (
      <span className="badge badge-light-warning fs-8 fw-bold">Pending</span>
    ),
    ratingImpact: "Sample Rating Impact 10",
    nextStep: "10",
  },
  {
    key: "11",
    reason: "Sample Reason 11",
    link: "Sample Link 11",
    date: "Sep 18, 2023",
    impacted: "Sample Title and ASIN 11",
    status: (
      <span className="badge badge-light-success fs-8 fw-bold">Done</span>
    ),
    ratingImpact: "Sample Rating Impact 11",
    nextStep: "11",
  },
  {
    key: "12",
    reason: "Sample Reason 12",
    link: "Sample Link 12",
    date: "Sep 19, 2023",
    impacted: "Sample Title and ASIN 12",
    status: (
      <span className="badge badge-light-info fs-8 fw-bold">In Progress</span>
    ),
    ratingImpact: "Sample Rating Impact 12",
    nextStep: "12",
  },
  {
    key: "13",
    reason: "Sample Reason 13",
    link: "Sample Link 13",
    date: "Sep 20, 2023",
    impacted: "Sample Title and ASIN 13",
    status: (
      <span className="badge badge-light-warning fs-8 fw-bold">Pending</span>
    ),
    ratingImpact: "Sample Rating Impact 13",
    nextStep: "13",
  },
  {
    key: "14",
    reason: "Sample Reason 14",
    link: "Sample Link 14",
    date: "Sep 21, 2023",
    impacted: "Sample Title and ASIN 14",
    status: (
      <span className="badge badge-light-success fs-8 fw-bold">Done</span>
    ),
    ratingImpact: "Sample Rating Impact 14",
    nextStep: "14",
  },
  {
    key: "15",
    reason: "Sample Reason 15",
    link: "Sample Link 15",
    date: "Sep 22, 2023",
    impacted: "Sample Title and ASIN 15",
    status: (
      <span className="badge badge-light-info fs-8 fw-bold">In Progress</span>
    ),
    ratingImpact: "Sample Rating Impact 15",
    nextStep: "15",
  },
  {
    key: "16",
    reason: "Sample Reason 16",
    link: "Sample Link 16",
    date: "Sep 23, 2023",
    impacted: "Sample Title and ASIN 16",
    status: (
      <span className="badge badge-light-warning fs-8 fw-bold">Pending</span>
    ),
    ratingImpact: "Sample Rating Impact 16",
    nextStep: "16",
  },
  {
    key: "17",
    reason: "Sample Reason 17",
    link: "Sample Link 17",
    date: "Sep 24, 2023",
    impacted: "Sample Title and ASIN 17",
    status: (
      <span className="badge badge-light-success fs-8 fw-bold">Done</span>
    ),
    ratingImpact: "Sample Rating Impact 17",
    nextStep: "17",
  },
  {
    key: "18",
    reason: "Sample Reason 18",
    link: "Sample Link 18",
    date: "Sep 25, 2023",
    impacted: "Sample Title and ASIN 18",
    status: (
      <span className="badge badge-light-info fs-8 fw-bold">In Progress</span>
    ),
    ratingImpact: "Sample Rating Impact 18",
    nextStep: "18",
  },
  {
    key: "19",
    reason: "Sample Reason 19",
    link: "Sample Link 19",
    date: "Sep 26, 2023",
    impacted: "Sample Title and ASIN 19",
    status: (
      <span className="badge badge-light-warning fs-8 fw-bold">Pending</span>
    ),
    ratingImpact: "Sample Rating Impact 19",
    nextStep: "19",
  },
  {
    key: "20",
    reason: "Sample Reason 20",
    link: "Sample Link 20",
    date: "Sep 27, 2023",
    impacted: "Sample Title and ASIN 20",
    status: (
      <span className="badge badge-light-success fs-8 fw-bold">Done</span>
    ),
    ratingImpact: "Sample Rating Impact 20",
    nextStep: "20",
  },
  {
    key: "21",
    reason: "Sample Reason 21",
    link: "Sample Link 21",
    date: "Sep 28, 2023",
    impacted: "Sample Title and ASIN 21",
    status: (
      <span className="badge badge-light-info fs-8 fw-bold">In Progress</span>
    ),
    ratingImpact: "Sample Rating Impact 21",
    nextStep: "21",
  },
  {
    key: "22",
    reason: "Sample Reason 22",
    link: "Sample Link 22",
    date: "Sep 29, 2023",
    impacted: "Sample Title and ASIN 22",
    status: (
      <span className="badge badge-light-warning fs-8 fw-bold">Pending</span>
    ),
    ratingImpact: "Sample Rating Impact 22",
    nextStep: "22",
  },
  {
    key: "23",
    reason: "Sample Reason 23",
    link: "Sample Link 23",
    date: "Sep 30, 2023",
    impacted: "Sample Title and ASIN 23",
    status: (
      <span className="badge badge-light-success fs-8 fw-bold">Done</span>
    ),
    ratingImpact: "Sample Rating Impact 23",
    nextStep: "23",
  },
  {
    key: "24",
    reason: "Sample Reason 24",
    link: "Sample Link 24",
    date: "Oct 01, 2023",
    impacted: "Sample Title and ASIN 24",
    status: (
      <span className="badge badge-light-info fs-8 fw-bold">In Progress</span>
    ),
    ratingImpact: "Sample Rating Impact 24",
    nextStep: "24",
  },
  {
    key: "25",
    reason: "Sample Reason 25",
    link: "Sample Link 25",
    date: "Oct 02, 2023",
    impacted: "Sample Title and ASIN 25",
    status: (
      <span className="badge badge-light-warning fs-8 fw-bold">Pending</span>
    ),
    ratingImpact: "Sample Rating Impact 25",
    nextStep: "25",
  },
  {
    key: "26",
    reason: "Sample Reason 26",
    link: "Sample Link 26",
    date: "Oct 03, 2023",
    impacted: "Sample Title and ASIN 26",
    status: (
      <span className="badge badge-light-success fs-8 fw-bold">Done</span>
    ),
    ratingImpact: "Sample Rating Impact 26",
    nextStep: "26",
  },
  {
    key: "27",
    reason: "Sample Reason 27",
    link: "Sample Link 27",
    date: "Oct 04, 2023",
    impacted: "Sample Title and ASIN 27",
    status: (
      <span className="badge badge-light-info fs-8 fw-bold">In Progress</span>
    ),
    ratingImpact: "Sample Rating Impact 27",
    nextStep: "27",
  },
  {
    key: "28",
    reason: "Sample Reason 28",
    link: "Sample Link 28",
    date: "Oct 05, 2023",
    impacted: "Sample Title and ASIN 28",
    status: (
      <span className="badge badge-light-warning fs-8 fw-bold">Pending</span>
    ),
    ratingImpact: "Sample Rating Impact 28",
    nextStep: "28",
  },
  {
    key: "29",
    reason: "Sample Reason 29",
    link: "Sample Link 29",
    date: "Oct 06, 2023",
    impacted: "Sample Title and ASIN 29",
    status: (
      <span className="badge badge-light-success fs-8 fw-bold">Done</span>
    ),
    ratingImpact: "Sample Rating Impact 29",
    nextStep: "29",
  },
  {
    key: "30",
    reason: "Sample Reason 30",
    link: "Sample Link 30",
    date: "Oct 07, 2023",
    impacted: "Sample Title and ASIN 30",
    status: (
      <span className="badge badge-light-info fs-8 fw-bold">In Progress</span>
    ),
    ratingImpact: "Sample Rating Impact 30",
    nextStep: "30",
  },
  // Add more data rows as needed
];

const ComplianceWithPolicies = () => {
  return (
    <Wrapper className="p-5 ">
      <div className="row">
        <div className="col-xl-4 col-lg-5 col-md-12">
          <div className="card" style={{ position: "sticky", top: "174px" }}>
            <div className="card-header">
              <div className="card-title">Policy Compliance</div>
              <div className="card-toolbar">
                <Tag color="green-inverse">Healthy</Tag>
                <Segmented options={["7", "30", "60"]} />
              </div>
            </div>

            <div className="card-body">
              <div className="row d-flex align-items-center bg-light-warning rounded border-warning border border-dashed p-6">
                <div className="col-8">
                  <div className="d-flex flex-stack flex-grow-1 ">
                    {/*begin::Content*/}
                    <div className=" fw-semibold">
                      <h4 className="text-gray-900 fw-bold">
                        Account health rating
                      </h4>
                      <div className="fs-6 text-gray-700 ">
                        This rating reflects your adherence to{" "}
                        <a
                          href="#"
                          className="fw-bold"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_new_card"
                        >
                          Amazon's selling policies
                        </a>
                        .
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                </div>
                <div className="col-4 justify-content-end d-flex">
                  <Progress type="dashboard" percent={50} size={20} />
                </div>
              </div>
              <div className=" mt-10">
                {/*begin::Item*/}
                <div className="d-flex align-items-center mb-8">
                  {/*begin::Bullet*/}
                  <span className="bullet bullet-vertical h-40px bg-success" />

                  <div className="flex-grow-1 mx-4">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                    >
                      Suspected Intellectual Property Violations
                    </a>
                  </div>
                  {/*end::Description*/}
                  <span className="badge badge-light-success fs-8 fw-bold">
                    0
                  </span>
                </div>
                {/*end:Item*/}
                {/*begin::Item*/}
                <div className="d-flex align-items-center mb-8">
                  {/*begin::Bullet*/}
                  <span className="bullet bullet-vertical h-40px bg-primary" />
                  {/*end::Bullet*/}
                  {/*begin::Checkbox*/}

                  {/*end::Checkbox*/}
                  {/*begin::Description*/}
                  <div className="flex-grow-1 mx-4">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                    >
                      Received Intellectual Property Complaints
                    </a>
                  </div>
                  {/*end::Description*/}
                  <span className="badge badge-light-primary fs-8 fw-bold">
                    0
                  </span>
                </div>
                {/*end:Item*/}
                {/*begin::Item*/}
                <div className="d-flex align-items-center mb-8">
                  {/*begin::Bullet*/}
                  <span className="bullet bullet-vertical h-40px bg-warning" />
                  {/*end::Bullet*/}
                  {/*begin::Checkbox*/}

                  {/*end::Checkbox*/}
                  {/*begin::Description*/}
                  <div className="flex-grow-1 mx-4">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                    >
                      Product Authenticity Customer Complaints
                    </a>
                  </div>
                  {/*end::Description*/}
                  <span className="badge badge-light-warning fs-8 fw-bold">
                    0
                  </span>
                </div>
                {/*end:Item*/}
                {/*begin::Item*/}
                <div className="d-flex align-items-center mb-8">
                  {/*begin::Bullet*/}
                  <span className="bullet bullet-vertical h-40px bg-primary" />
                  {/*end::Bullet*/}
                  {/*begin::Checkbox*/}

                  {/*end::Checkbox*/}
                  {/*begin::Description*/}
                  <div className="flex-grow-1 mx-4">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                    >
                      Product Condition Customer Complaints
                    </a>
                  </div>
                  {/*end::Description*/}
                  <span className="badge badge-light-primary fs-8 fw-bold">
                    0
                  </span>
                </div>
                {/*end:Item*/}
                {/*begin::Item*/}
                <div className="d-flex align-items-center mb-8">
                  {/*begin::Bullet*/}
                  <span className="bullet bullet-vertical h-40px bg-danger" />
                  {/*end::Bullet*/}
                  {/*begin::Checkbox*/}

                  {/*end::Checkbox*/}
                  {/*begin::Description*/}
                  <div className="flex-grow-1 mx-4">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                    >
                      Food and Product Safety Issues
                    </a>
                  </div>
                  {/*end::Description*/}
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-danger mx-3 svg-icon-2hx">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M21 11H18.9C18.5 7.9 16 5.49998 13 5.09998V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V5.09998C7.9 5.49998 5.50001 8 5.10001 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H5.10001C5.50001 16.1 8 18.4999 11 18.8999V21C11 21.6 11.4 22 12 22C12.6 22 13 21.6 13 21V18.8999C16.1 18.4999 18.5 16 18.9 13H21C21.6 13 22 12.6 22 12C22 11.4 21.6 11 21 11ZM12 17C9.2 17 7 14.8 7 12C7 9.2 9.2 7 12 7C14.8 7 17 9.2 17 12C17 14.8 14.8 17 12 17Z"
                          fill="currentColor"
                        />
                        <path
                          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="badge badge-light-danger fs-8 fw-bold">
                      0
                    </span>
                  </div>
                </div>
                {/*end:Item*/}
                {/*begin::Item*/}
                <div className="d-flex align-items-center mb-8">
                  {/*begin::Bullet*/}
                  <span className="bullet bullet-vertical h-40px bg-success" />
                  {/*end::Bullet*/}
                  {/*begin::Checkbox*/}

                  {/*end::Checkbox*/}
                  {/*begin::Description*/}
                  <div className="flex-grow-1 mx-4">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                    >
                      Listing Policy Violations
                    </a>
                  </div>
                  {/*end::Description*/}
                  <span className="badge badge-light-success fs-8 fw-bold">
                    0
                  </span>
                </div>
                {/*end:Item*/}

                {/*begin::Item*/}
                <div className="d-flex align-items-center mb-8">
                  {/*begin::Bullet*/}
                  <span className="bullet bullet-vertical h-40px bg-success" />

                  <div className="flex-grow-1 mx-4">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                    >
                      Restricted Product Policy Violations
                    </a>
                  </div>
                  {/*end::Description*/}
                  <span className="badge badge-light-success fs-8 fw-bold">
                    0
                  </span>
                </div>
                {/*end:Item*/}
                {/*begin::Item*/}
                <div className="d-flex align-items-center mb-8">
                  {/*begin::Bullet*/}
                  <span className="bullet bullet-vertical h-40px bg-primary" />
                  {/*end::Bullet*/}
                  {/*begin::Checkbox*/}

                  {/*end::Checkbox*/}
                  {/*begin::Description*/}
                  <div className="flex-grow-1 mx-4">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                    >
                      Customer Product Reviews Policy Violations
                    </a>
                  </div>
                  {/*end::Description*/}
                  <span className="badge badge-light-primary fs-8 fw-bold">
                    0
                  </span>
                </div>
                {/*end:Item*/}
                {/*begin::Item*/}
                <div className="d-flex align-items-center mb-8">
                  {/*begin::Bullet*/}
                  <span className="bullet bullet-vertical h-40px bg-warning" />
                  {/*end::Bullet*/}
                  {/*begin::Checkbox*/}

                  {/*end::Checkbox*/}
                  {/*begin::Description*/}
                  <div className="flex-grow-1 mx-4">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                    >
                      Product Authenticity Customer Complaints
                    </a>
                  </div>
                  {/*end::Description*/}
                  <span className="badge badge-light-warning fs-8 fw-bold">
                    0
                  </span>
                </div>
                {/*end:Item*/}
                {/*begin::Item*/}
                <div className="d-flex align-items-center">
                  {/*begin::Bullet*/}
                  <span className="bullet bullet-vertical h-40px bg-primary" />
                  {/*end::Bullet*/}
                  {/*begin::Checkbox*/}

                  {/*end::Checkbox*/}
                  {/*begin::Description*/}
                  <div className="flex-grow-1 mx-4">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                    >
                      Other Policy Violations
                    </a>
                  </div>
                  {/*end::Description*/}
                  <span className="badge badge-light-primary fs-8 fw-bold">
                    0
                  </span>
                </div>
                {/*end:Item*/}
                {/*begin::Item*/}

                {/*end:Item*/}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-7 col-md-12">
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: "max-content" }}
            pagination={false}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default ComplianceWithPolicies;
