import React from "react";
import Wrapper from "./style";
import { Segmented, Table, Tag } from "antd";

const dataSource = [
  {
    key: "1",
    dateRange: "2023-01-01 to 2023-01-07",
    totalOrders: 100,
    preFulfillmentCancellations: 5,
    lateShipments: 3,
    totalOrdersWithDefect: 10,
    negativeFeedback: 2,
    aToZGuaranteeClaims: 3,
    chargebackClaims: 1,
  },
  {
    key: "2",
    dateRange: "2023-01-08 to 2023-01-14",
    totalOrders: 120,
    preFulfillmentCancellations: 8,
    lateShipments: 4,
    totalOrdersWithDefect: 15,
    negativeFeedback: 3,
    aToZGuaranteeClaims: 2,
    chargebackClaims: 2,
  },
  {
    key: "3",
    dateRange: "2023-01-15 to 2023-01-21",
    totalOrders: 95,
    preFulfillmentCancellations: 7,
    lateShipments: 2,
    totalOrdersWithDefect: 8,
    negativeFeedback: 1,
    aToZGuaranteeClaims: 1,
    chargebackClaims: 0,
  },
];

const columns = [
  {
    title: "Date Range",
    dataIndex: "dateRange",
    key: "dateRange",
  },
  {
    title: "Total Orders",
    dataIndex: "totalOrders",
    key: "totalOrders",
    render: (text) => {
      return <Tag color="success">{text}</Tag>;
    },
  },
  {
    title: "Pre-fulfillment Cancellations",
    dataIndex: "preFulfillmentCancellations",
    key: "preFulfillmentCancellations",
  },
  {
    title: "Late Shipments",
    dataIndex: "lateShipments",
    key: "lateShipments",
  },
  {
    title: "Order Defect Rate",
    children: [
      {
        title: "Total Orders With Defect",
        dataIndex: "totalOrdersWithDefect",
        key: "totalOrdersWithDefect",
      },
      {
        title: "Negative Feedback",
        dataIndex: "negativeFeedback",
        key: "negativeFeedback",
      },
      {
        title: "A-to-z Guarantee Claims",
        dataIndex: "aToZGuaranteeClaims",
        key: "aToZGuaranteeClaims",
      },
      {
        title: "Chargeback Claims",
        dataIndex: "chargebackClaims",
        key: "chargebackClaims",
      },
    ],
  },
];

const PerformaneDashboard = () => {
  return (
    <Wrapper className="p-5">
      <div className="row">
        <div className="col-xl-6 col-md-12">
          <div
            className="card bgi-no-repeat"
            style={{
              backgroundImage:
                "url(https://preview.keenthemes.com/metronic8/react/demo8/media/svg/shapes/abstract-4.svg)",
              backgroundPosition: "right top",
              backgroundSize: "30%",
            }}
          >
            <div className="card-header">
              <div className="card-title">Customer Service Perfomance</div>
              <div className="card-toolbar">
                <Segmented options={["7", "30", "60"]} />
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-9"></div>
                <div className="col-3">
                  <label className="form-check form-check-custom form-check-solid form-switch mb-3">
                    <span className="form-check-label text-muted fs-4">
                      FBM
                    </span>
                    <input
                      className="form-check-input mx-5"
                      type="checkbox"
                      defaultChecked
                    />
                    <span className="form-check-label text-dark fs-4">FBA</span>
                  </label>
                </div>
                {/* <div className="col-3  fw-bold text-muted text-hover-primary fs-4">
                  Seller Fulfilled
                </div>
                <div className="col-3  fw-bold text-muted text-hover-primary fs-4">
                  Fulfilled by Amazon
                </div> */}
              </div>
              <div
                className="row mt-5 d-flex align-items-center"
                style={{
                  background: "#e9805f1f",
                  padding: "10px",
                  borderRadius: "8px",
                  border: "1px dashed #6a2348",
                }}
              >
                <div className="col-6">
                  <div className="text-dark text-hover-primary fw-bold fs-2 my-3">
                    Order Defect Rate
                  </div>
                  <div className="fs-5">
                    Target :{" "}
                    <Tag color="magenta" bordered={false}>
                      under 1%
                    </Tag>
                  </div>
                </div>
                <div className="col-3 fs-4 text-black">N/A</div>
                <div className="col-3">
                  <Tag className="fs-2 px-3 py-2" color="gold">
                    {" "}
                    0.02%
                  </Tag>
                  <div className="fs-3 mt-2 ">3 of 16,857 orders</div>
                  <div className="fw-bold text-primary">60 days</div>
                </div>
              </div>
              <div className="row fw-bold fs-4 my-5">
                Order Defect Rate consists of three different metrics
              </div>
              <div
                className="row mb-7 "
                style={{ borderBottom: "1px dashed #6f27494a" }}
              >
                <div className="col-6 fs-5 mb-3">Negative feedback</div>
                <div className="col-3  fw-bold text-muted text-hover-primary fs-4 mb-3">
                  N/A
                </div>
                <div className="col-3  fw-bold text-black text-hover-primary fs-4 mb-3">
                  0.02%
                </div>
              </div>
              <div
                className="row mb-6"
                style={{ borderBottom: "1px dashed #6f27494a" }}
              >
                <div className="col-6 fs-5 mb-3">A-to-z Guarantee claims</div>
                <div className="col-3  fw-bold text-muted text-hover-primary fs-4 mb-3">
                  N/A
                </div>
                <div className="col-3  fw-bold text-black text-hover-primary fs-4 mb-3">
                  0
                </div>
              </div>
              <div className="row">
                <div className="col-6 fs-5 mb-3">Chargeback claims</div>
                <div className="col-3  fw-bold text-muted text-hover-primary fs-4 mb-3">
                  N/A
                </div>
                <div className="col-3  fw-bold text-black text-hover-primary fs-4 mb-3">
                  0
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div
            className="card bgi-no-repeat"
            style={{
              backgroundImage:
                "url(https://preview.keenthemes.com/metronic8/react/demo8/media/svg/shapes/abstract-4.svg)",
              backgroundPosition: "right top",
              backgroundSize: "30%",
            }}
          >
            <div className="card-header">
              <div className="card-title">Shipping Performance</div>
              <div className="card-toolbar">
                <Segmented options={["7", "30", "60"]} />
              </div>
            </div>
            <div className="card-body ">
              <div className="fs-5 px-3 text-grey text-align-end mb-8">
                Seller Fulfilled
              </div>
              <div
                className="row px-3 mt-4 py-3 ms-1 d-flex align-items-center"
                style={{
                  background: "#e9805f10",
                  borderRadius: "8px",
                }}
              >
                <div className="col-6">
                  <div className="text-dark text-hover-primary fw-bold fs-2 ">
                    Late Shipment Rate
                  </div>
                  <div className="fs-5">
                    Target :{" "}
                    <Tag color="magenta" bordered={false}>
                      under 4%
                    </Tag>
                  </div>
                </div>

                <div className="col-6 text-align-end">
                  <div className="fs-3 ">N/A</div>
                </div>
              </div>
              <div
                className="row px-3 mt-4 py-3 ms-1 d-flex align-items-center"
                style={{
                  background: "#e9805f10",
                  borderRadius: "8px",
                }}
              >
                <div className="col-6">
                  <div className="text-dark text-hover-primary fw-bold fs-2 ">
                    Pre-fulfillment Cancel Rate
                  </div>
                  <div className="fs-5">
                    Target :{" "}
                    <Tag color="magenta" bordered={false}>
                      under 2.5%
                    </Tag>
                  </div>
                </div>

                <div className="col-6 text-align-end">
                  <div className="fs-3 ">N/A</div>
                </div>
              </div>
              <div
                className="row px-3 mt-4 py-3 ms-1 d-flex align-items-center"
                style={{
                  background: "#e9805f10",
                  borderRadius: "8px",
                }}
              >
                <div className="col-6">
                  <div className="text-dark text-hover-primary fw-bold fs-2 ">
                    Valid Tracking Rate
                  </div>
                  <div className="fs-5">
                    Target :{" "}
                    <Tag color="magenta" bordered={false}>
                      over 95%
                    </Tag>
                  </div>
                </div>

                <div className="col-6 text-align-end">
                  <div className="fs-3 ">N/A</div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-18 fs-7 text-primary">
                View details
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-5">
        <div className="card-body">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
      </div>
    </Wrapper>
  );
};

export default PerformaneDashboard;
