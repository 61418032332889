import styled from "styled-components";

const AuthWrapper = styled.div`
  background-color: #f9fafb;
  .ant-input-password {
    height: 40.91px !important;
    input {
      ${"" /* height: 40.9px !important; */}
    }
  }
  .hideScroll::-webkit-scrollbar-track {
    ${
      "" /* transition: all 0.8s ease 0s;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset !important; */
    }
  }
  .ant-input-affix-wrapper {
    background-color: #fff !important;
    border-color: #d9d9d9 !important;
  }
  // @media (max-width: 768) {
  //   .h-100vh {
  //     height: none !important;
  //   }
  // }
  .row {
    flex-direction: row-reverse;
  }
`;
export default AuthWrapper;
