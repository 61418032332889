import axiosCall from "../../configurations/network-services/axiosCall";

export const GetCriteriaListAction = (data) => {
  const path = `items-criteria?sales_channel=${
    data?.sales_channel || ""
  }&region=${data?.region || ""}&pageSize=${data?.perPage || ""}&page=${
    data?.page || ""
  }`;
  const responseType = "ITEMS_CRITERIA_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCriteriaViewAction = (id) => {
  const path = `items-criteria/${id}`;
  const responseType = "ITEMS_CRITERIA_VIEW";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const AddCriteriaViewAction = (data) => {
  const path = `items-criteria`;
  const responseType = "ADD_NEW_ITEMS_CRITERIA";
  return axiosCall("POST", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateCriteriaAction = (id, data) => {
  const path = `items-criteria/${id}`;
  const responseType = "UPDATE_ITEMS_CRITERIA";
  return axiosCall("PATCH", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteCriteriaAction = (id) => {
  const path = `items-criteria/${id}`;
  const responseType = "DELETE_ITEMS_CRITERIA";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetMarketplaceListAction = () => {
  const path = `get-marketplace-list`;
  const responseType = "GET_MARKETPLACE_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const CloneAmazonLQSAction = (data) => {
  const path = `clone-amazon-lqs`;
  const responseType = "CLONE_AMAZON_LQS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCriteria = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CRITERIA", state: data });
};
