import styled from "styled-components";

const Wrapper = styled.div`
  .View1,
  .View2,
  .View3,
  .View4 {
    background: #f5f8fa;
    padding: 15px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }
  .View2 {
    border: 1px solid #000;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }
  .View3 {
    border: 1px solid #f4be00;
    box-shadow: rgba(242, 188, 0, 0.1) 0px 20px 25px -5px,
      rgba(242, 188, 0, 0.04) 0px 10px 10px -5px;
  }

  .View4 {
    border: 1px solid #7239ea;
    box-shadow: rgba(81, 20, 208, 0.1) 0px 20px 25px -5px,
      rgba(81, 20, 208, 0.04) 0px 10px 10px -5px;
  }
  .border-remove {
    border: none !important;
  }
`;
export default Wrapper;
