import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProfitabilityCalculator from "../../modules/pages/profitability-calculator-new";
import {
  SalesChannelListAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      fakeActionCatalog,
    },
    dispatch
  );

const ProfitabilityCalculator_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitabilityCalculator);

export default ProfitabilityCalculator_;
