import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tools from "../../modules/pages/catalogue/tools";
import {
  GetListCatalogAction,
  GetListCatalogConfigAction,
  UpdateCatalogConfigAction,
  CatalogManualMappingAction,
  SalesChannelListAction,
  ItemsOfferTableDataAction,
  ChartAPIDataAction,
  fakeActionCatalog,
  ItemsOfferGetASINOffers,
  ItemsOfferGetSellerOffersGraph,
  ItemsOfferGetSellerList,
  ItemsOfferGetASINOffersGraph,
  ItemsOfferTableDataExportAction,
  CreateAmazonLQSAction
} from "../../redux/module_/catalog/index.action";

const mapStateToProps = (state) => ({
  CatalogResponse: state.Catalog.CatalogResponse,
  GetCatalogConfigResponse: state.Catalog.GetCatalogConfigResponse,
  UpdateCatalogConfigResponse: state.Catalog.UpdateCatalogConfigResponse,
  CatalogManualMappingResponse: state.Catalog.CatalogManualMappingResponse,
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
  ItemsOfferTableDataResponse: state.Catalog.ItemsOfferTableDataResponse,
  GetChartDataResponse: state.Catalog.GetChartDataResponse,
  ItemsOfferGetSellerOffersGraphResponse:
    state.Catalog.ItemsOfferGetSellerOffersGraphResponse,
  ItemsOfferGetASINOffersGraphResponse:
    state.Catalog.ItemsOfferGetASINOffersGraphResponse,
  ItemsOfferGetASINOffersResponse:
    state.Catalog.ItemsOfferGetASINOffersResponse,
  ItemsOfferGetSellerListResponse:
    state.Catalog.ItemsOfferGetSellerListResponse,
  ItemsOfferTableDataExportResponse:
    state.Catalog.ItemsOfferTableDataExportResponse,

});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetListCatalogAction,
      GetListCatalogConfigAction,
      UpdateCatalogConfigAction,
      CatalogManualMappingAction,
      SalesChannelListAction,
      fakeActionCatalog,
      ChartAPIDataAction,
      ItemsOfferGetASINOffersGraph,
      ItemsOfferTableDataAction,
      ItemsOfferGetSellerOffersGraph,
      ItemsOfferGetASINOffers,
      ItemsOfferGetSellerList,
      ItemsOfferTableDataExportAction,
      CreateAmazonLQSAction,
    },
    dispatch
  );

const Tools_ = connect(mapStateToProps, mapDispatchToProps)(Tools);

export default Tools_;
