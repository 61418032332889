import {
  Popover,
  Result,
  Select,
  Table,
  Tabs,
  Tag,
  DatePicker,
  Tooltip,
} from "antd";
import React, { useContext, useEffect } from "react";
import { Wrapper } from "./style";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  AllMarketPlace,
  DefaultPerPage,
  MarketplaceFlag,
  SwapCommaAndDot,
  checkPermission,
  numberformat,
  updateDate,
} from "../../../config";
import { TableLoading } from "../../../components/table-animation";
import moment from "moment";
import { GlobalContext } from "../../../commonContext";
import dayjs from "dayjs";

const Reports = ({
  fakeActionCatalog,
  SalesChannelListAction,
  GetReportsListAction,
  fakeActionReports,
}) => {
  const contextValue = useContext(GlobalContext);
  const [selected, setSelected] = useState("orders_report");

  const [dateFilter, setDateFilter] = useState({
    start: dayjs().subtract(30, "days")?.format("YYYY-MM-DD"),
    end: dayjs()?.format("YYYY-MM-DD"),
  });

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [sign, setSign] = useState("$");

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const GetAllReportsponse = useSelector(
    (state) => state.Reports.GetAllReportsponse || {}
  );

  useEffect(() => {
    if (GetAllReportsponse?.status === true) {
      setLoading(false);
      setList(GetAllReportsponse?.data?.records || []);
      setSign(GetAllReportsponse?.data?.currency || "");
      setTotalPage(GetAllReportsponse?.data?.pagination?.totalCount);
      fakeActionReports("GetAllReportsponse");
    } else if (GetAllReportsponse?.status === false) {
      setLoading(false);
      fakeActionReports("GetAllReportsponse");
    }
  }, [GetAllReportsponse]);
  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );

            if (e?.default_marketplace === 1) {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
              });
            }
            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                  },
                ],
              });
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);

        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setMarketplaceList([]);
        setLoading(false);
        setList([]);
      }

      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      setLoading(false);
      setList([]);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);
  const findURL = {
    orders_report: "fba-order-report",
    return_orders: "fba-return-report",
    archived_inventory: "fba-manage-inventory",
    finance_data: "financial-data",
    reserved_inventory: "fba-reserved-inventory",
    planning_inventory: "fba-inventory-planning",
    stranded_inventory: "fba-stranded-inventory",
    product_data: "fba-listing-report",
    reimbursements_data: "fba-reimbursements",
  };

  useEffect(() => {
    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();
    setDateFilter({
      start: contextValue?.data?.date_range?.start_date,
      end: contextValue?.data?.date_range?.end_date,
    });
    return () => {};
  }, []);

  const currentMonth = moment().month(); // Get the current month (0-indexed)
  const quarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3), "months");
  const quarterEnd = quarterStart.clone().add(3, "months").subtract(1, "day");

  const lastQuarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3) - 3, "months");
  const lastQuarterEnd = lastQuarterStart
    .clone()
    .add(3, "months")
    .subtract(1, "day");
  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
      type: "last_30_days_by_day",
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
      type: "last_30_days_by_day",
    },
    {
      label: "This Quarter",
      value: [dayjs(quarterStart), dayjs(quarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Quarter",
      value: [dayjs(lastQuarterStart), dayjs(lastQuarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
      type: "last_12_months_by_month",
    },
    {
      label: "Custom Range",
      value: [0, 0],
      type: "last_12_months_by_month",
    },
  ];

  useEffect(() => {
    if (Object.values(marketplaceSelected)?.filter((d) => d)?.length !== 0) {
      setLoading(true);
      setList([]);
      GetReportsListAction(findURL?.[selected], {
        sales_channel: marketplaceSelected?.sales_channel,
        pageSize: pageSize,
        page: page,
        start_date: dateFilter?.start,
        end_date: dateFilter?.end,
      });
    }
  }, [marketplaceSelected, pageSize, page, selected]);

  const items = [
    { label: "Orders Report", key: "orders_report" },
    // { label: "Return Orders", key: "return_orders" },
    { label: "Archived Inventory", key: "archived_inventory" },
    { label: "Finance Data", key: "finance_data" },
    { label: "Reserved Inventory", key: "reserved_inventory" },
    { label: "Planning Inventory", key: "planning_inventory" },
    { label: "Stranded Inventory", key: "stranded_inventory" },
    { label: "Product Data", key: "product_data" },
    { label: "Reimbursements Data", key: "reimbursements_data" },
  ];

  const orders_report = [
    {
      title: "Amazon Order Id",
      dataIndex: "amazon_order_id",
      key: "amazon_order_id",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="gold">{text}</Tag>;
      },
    },
    {
      title: "Merchant Order Id",
      dataIndex: "merchant_order_id",
      key: "merchant_order_id",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="purple">{text}</Tag>;
      },
    },
    {
      title: "Purchase Date",
      dataIndex: "purchase_date",
      key: "purchase_date",
      render: (text) => {
        return (
          <span>
            {!text ? "-" : moment(new Date(text)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Purchase Date UTC",
      key: "purchase_date_utc",
      dataIndex: "purchase_date_utc",
      render: (text) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      title: "Last Updated Date",
      dataIndex: "last_updated_date",
      key: "last_updated_date",
      render: (text) => {
        return (
          <span>
            {!text ? "-" : moment(new Date(text)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Order Status",
      dataIndex: "ord_status",
      key: "ord_status",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="blue">{text}</Tag>;
      },
    },
    {
      title: "Item Status",
      dataIndex: "item_status",
      key: "item_status",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="cyan">{text}</Tag>;
      },
    },
    {
      title: "Fulfillment Channel",
      dataIndex: "fulfillment_channel",
      key: "fulfillment_channel",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="geekblue">{text}</Tag>;
      },
    },
    {
      title: "Sales Channel",
      dataIndex: "sales_channel",
      key: "sales_channel",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Order Channel",
      dataIndex: "order_channel",
      key: "order_channel",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Ship Service Level",
      dataIndex: "ship_service_level",
      key: "ship_service_level",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
      render: (text) => {
        if (!text) return "-";
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {text}
              </div>
            }
          >
            <div className="product_description">{text || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="magenta-inverse">{text}</Tag>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="blue-inverse">{text}</Tag>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Item Price",
      dataIndex: "item_price",
      key: "item_price",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(text),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Item Tax",
      dataIndex: "item_tax",
      key: "item_tax",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(text),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Shipping Price",
      dataIndex: "shipping_price",
      key: "shipping_price",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(text),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Shipping Tax",
      dataIndex: "shipping_tax",
      key: "shipping_tax",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(text),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Gift Wrap Price",
      dataIndex: "gift_wrap_price",
      key: "gift_wrap_price",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(text),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Gift Wrap Tax",
      dataIndex: "gift_wrap_tax",
      key: "gift_wrap_tax",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(text),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Item Promotion Discount",
      dataIndex: "item_promotion_discount",
      key: "item_promotion_discount",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(text),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Ship Promotion Discount",
      dataIndex: "ship_promotion_discount",
      key: "ship_promotion_discount",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(text),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Ship City",
      dataIndex: "ship_city",
      key: "ship_city",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Ship State",
      dataIndex: "ship_state",
      key: "ship_state",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Ship Postal Code",
      dataIndex: "ship_postal_code",
      key: "ship_postal_code",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Ship Country",
      dataIndex: "ship_country",
      key: "ship_country",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Promotion Ids",
      dataIndex: "promotion_ids",
      key: "promotion_ids",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Is Business Order",
      dataIndex: "is_business_order",
      key: "is_business_order",
      render: (text) => {
        if (!text) return "-";
        return <Tag color={text ? "green" : "red"}>{text}</Tag>;
      },
    },
    {
      title: "Purchase Order Number",
      dataIndex: "purchase_order_number",
      key: "purchase_order_number",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Price Designation",
      dataIndex: "price_designation",
      key: "price_designation",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      render: (text, row) => {
        if (!text) return "-";

        return (
          <div>
            <img
              src={`/media/domainImage/${row?.marketplace_id}.png`}
              style={{ height: "20px" }}
              loading="lazy"
              className="me-3"
              onError={(e) => {
                e.target.src = "/media/domainImage/red-flag.png";
              }}
            />

            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
  ];
  const return_orders = [
    {
      title: "Log date",
      dataIndex: "log_date",
      key: "log_date",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {!text ? "-" : moment(new Date(text)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Return formated date",
      dataIndex: "return_formated_date",
      key: "return_formated_date",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {!text ? "-" : moment(new Date(text)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Return date",
      dataIndex: "return_date",
      key: "return_date",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {!text ? "-" : moment(new Date(text)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="gold">{text}</Tag>;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="purple">{text}</Tag>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="green">{text}</Tag>;
      },
    },
    {
      title: "Parent ASIN",
      dataIndex: "parent_asin",
      key: "parent_asin",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="cyan">{text}</Tag>;
      },
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="geekblue">{text}</Tag>;
      },
    },
    {
      title: "Product name",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
      render: (text) => {
        if (!text) return "-";
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {text}
              </div>
            }
          >
            <div className="product_description">{text || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Fulfillment center ID",
      dataIndex: "fulfillment_center_id",
      key: "fulfillment_center_id",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Detailed disposition",
      dataIndex: "detailed_disposition",
      key: "detailed_disposition",
      width: 200,
      render: (text) => {
        if (!text) return "-";
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {text}
              </div>
            }
          >
            <div className="product_description">{text || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="blue-inverse">{text}</Tag>;
      },
    },
    {
      title: "License plate number",
      dataIndex: "license_plate_number",
      key: "license_plate_number",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Customer comments",
      dataIndex: "customer_comments",
      key: "customer_comments",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Fcrd date",
      dataIndex: "fcrd_date",
      key: "fcrd_date",
      render: (text) => {
        return (
          <span>
            {!text ? "-" : moment(new Date(text)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      key: "updated_by",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },

    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        return (
          <span>
            {!text ? "-" : moment(new Date(text)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => {
        return (
          <span>
            {!text ? "-" : moment(new Date(text)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
  ];
  const archived_inventory = [
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="cyan">{text}</Tag>;
      },
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="default">{text}</Tag>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="blue">{text}</Tag>;
      },
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
      render: (text) => {
        if (!text) return "-";
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {text}
              </div>
            }
          >
            <div className="product_description">{text || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Condition Type",
      dataIndex: "condition_type",
      key: "condition_type",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="green-inverse">{text}</Tag>;
      },
    },
    {
      title: "Your Price",
      dataIndex: "your_price",
      key: "your_price",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(text),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "MFN Listing Exists",
      dataIndex: "mfn_listing_exists",
      key: "mfn_listing_exists",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "AFN Listing Exists",
      dataIndex: "afn_listing_exists",
      key: "afn_listing_exists",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "MFN Fulfillable Quantity",
      dataIndex: "mfn_fulfillable_quantity",
      key: "mfn_fulfillable_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Warehouse Quantity",
      dataIndex: "afn_warehouse_quantity",
      key: "afn_warehouse_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Fulfillable Quantity",
      dataIndex: "afn_fulfillable_quantity",
      key: "afn_fulfillable_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Unsellable Quantity",
      dataIndex: "afn_unsellable_quantity",
      key: "afn_unsellable_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Reserved Quantity",
      dataIndex: "afn_researching_quantity",
      key: "afn_researching_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Total Quantity",
      dataIndex: "afn_total_quantity",
      key: "afn_total_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Per Unit Volume",
      dataIndex: "per_unit_volume",
      key: "per_unit_volume",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Inbound Working Quantity",
      dataIndex: "afn_inbound_working_quantity",
      key: "afn_inbound_working_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Inbound Shipped Quantity",
      dataIndex: "afn_inbound_shipped_quantity",
      key: "afn_inbound_shipped_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Inbound Receiving Quantity",
      dataIndex: "afn_inbound_receiving_quantity",
      key: "afn_inbound_receiving_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Researching Quantity",
      dataIndex: "afn_researching_quantity",
      key: "afn_researching_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Reserved Future Supply",
      dataIndex: "afn_reserved_future_supply",
      key: "afn_reserved_future_supply",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "AFN Future Supply Buyable",
      dataIndex: "afn_future_supply_buyable",
      key: "afn_future_supply_buyable",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      render: (text, row) => {
        if (!text) return "-";

        return (
          <div>
            <img
              src={`/media/domainImage/${row?.marketplace_id}.png`}
              style={{ height: "20px" }}
              loading="lazy"
              className="me-3"
              onError={(e) => {
                e.target.src = "/media/domainImage/red-flag.png";
              }}
            />

            <span>{text}</span>
          </div>
        );
      },
    },
  ];
  const finance_data = [
    {
      title: "Brand ID",
      dataIndex: "brand_id",
      key: "brand_id",
      render: (e) => {
        return <Tag color="blue">{e}</Tag>;
      },
    },
    {
      title: "Category ID",
      dataIndex: "category_id",
      key: "category_id",
      render: (e) => {
        return <Tag color="cyan">{e}</Tag>;
      },
    },
    {
      title: "Vendor ID",
      dataIndex: "vendor_id",
      key: "vendor_id",
      render: (e) => {
        return <Tag color="geekblue">{e}</Tag>;
      },
    },
    {
      title: "Marketplace ID",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      render: (e) => {
        return <Tag color="gold">{e}</Tag>;
      },
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
    {
      title: "Sales Channel",
      dataIndex: "sales_channel",
      key: "sales_channel",
    },
    {
      title: "Purchase Date",
      dataIndex: "purchasedate_n",
      key: "purchasedate_n",
      render: (e) => {
        if (!e) {
          return <span>-</span>;
        }
        return <span>{e}</span>;
      },
    },
    {
      title: "Posted Date UTC",
      dataIndex: "utc_posted_date",
      key: "utc_posted_date",
    },
    {
      title: "Posted Date",
      dataIndex: "datetime_n",
      key: "datetime_n",
    },
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render: (date) => date?.split(" ")?.[0] || "-", // Extracting only the date part
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amazon Order ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Seller SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "Parent ASIN",
      dataIndex: "parent_asin",
      key: "parent_asin",
      render: (e) => {
        if (!e) {
          return <span>-</span>;
        }
        return <span>{e}</span>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (e) => {
        if (!e) {
          return <span>-</span>;
        }
        return <span>{e}</span>;
      },
    },
    {
      title: "Currency Code",
      dataIndex: "currency_code",
      key: "currency_code",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      key: "account_type",
      render: (e) => {
        if (!e) {
          return <span>-</span>;
        }
        return <span>{e}</span>;
      },
    },
    {
      title: "Fulfillment",
      dataIndex: "fulfillment",
      key: "fulfillment",
      render: (e) => {
        if (!e) {
          return <span>-</span>;
        }
        return <span>{e}</span>;
      },
    },
    {
      title: "Order City",
      dataIndex: "order_city",
      key: "order_city",
      render: (e) => {
        if (!e) {
          return <span>-</span>;
        }
        return <span>{e}</span>;
      },
    },
    {
      title: "Order State",
      dataIndex: "order_state",
      key: "order_state",
      render: (e) => {
        if (!e) {
          return <span>-</span>;
        }
        return <span>{e}</span>;
      },
    },
    {
      title: "Order Postal",
      dataIndex: "order_postal",
      key: "order_postal",
      render: (e) => {
        if (!e) {
          return <span>-</span>;
        }
        return <span>{e}</span>;
      },
    },
    {
      title: "Tax Collection Model",
      dataIndex: "tax_collection_model",
      key: "tax_collection_model",
      render: (e) => {
        if (!e) {
          return <span>-</span>;
        }
        return <span>{e}</span>;
      },
    },
    {
      title: "Product Sales",
      dataIndex: "product_sales",
      key: "product_sales",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },

    {
      title: "Product Sales Tax",
      dataIndex: "product_sales_tax",
      key: "product_sales_tax",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Shipping Credits",
      dataIndex: "shipping_credits",
      key: "shipping_credits",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Shipping Credits Tax",
      dataIndex: "shipping_credits_tax",
      key: "shipping_credits_tax",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Shipping Chargeback",
      dataIndex: "shipping_chargeback",
      key: "shipping_chargeback",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Gift wrap Credits",
      dataIndex: "gift_wrap_credits",
      key: "gift_wrap_credits",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Gift wrap Credit Tax",
      dataIndex: "giftwrap_credits_tax",
      key: "giftwrap_credits_tax",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Gift wrap Chargeback",
      dataIndex: "gift_wrap_chargeback",
      key: "gift_wrap_chargeback",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Promotional Rebates",
      dataIndex: "promotional_rebates",
      key: "promotional_rebates",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Promotional Rebates Tax",
      dataIndex: "promotional_rebates_tax",
      key: "promotional_rebates_tax",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "FBA Fees per Order",
      dataIndex: "fba_per_order_fulfillment_fee",
      key: "fba_per_order_fulfillment_fee",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "FBA Fees per Unit",
      dataIndex: "fba_per_unit_fulfillment_fee",
      key: "fba_per_unit_fulfillment_fee",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "FBA Fees Weight Based",
      dataIndex: "fba_weight_based_fee",
      key: "fba_weight_based_fee",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Fixed Closing Fees",
      dataIndex: "fixed_closing_fee",
      key: "fixed_closing_fee",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Variable Closing Fees",
      dataIndex: "variable_closing_fee",
      key: "variable_closing_fee",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Marketplace Withheld Tax",
      dataIndex: "marketplace_withheld_tax",
      key: "marketplace_withheld_tax",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Selling Fees",
      dataIndex: "selling_fees",
      key: "selling_fees",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "FBA Fees",
      dataIndex: "fba_fees",
      key: "fba_fees",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Other Transaction Fees",
      dataIndex: "other_transaction_fees",
      key: "other_transaction_fees",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Other",
      dataIndex: "other",
      key: "other",
    },
    {
      title: "Total Fees",
      dataIndex: "total",
      key: "total",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Total Sales Tax Liable",
      dataIndex: "total_sales_tax_liable",
      key: "total_sales_tax_liable",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "CGST",
      dataIndex: "tcs_cgst",
      key: "tcs_cgst",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "SGST",
      dataIndex: "tcs_sgst",
      key: "tcs_sgst",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "IGST",
      dataIndex: "tcs_igst",
      key: "tcs_igst",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (timestamp) => new Date(timestamp).toLocaleString(),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (timestamp) => new Date(timestamp).toLocaleString(),
    },
  ];
  const reserved_inventory = [
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (e) => {
        return <Tag color="blue">{e}</Tag>;
      },
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
      render: (e) => {
        return <Tag color="cyan">{e}</Tag>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (e) => {
        return <Tag color="geekblue">{e}</Tag>;
      },
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
      render: (e) => {
        return (
          <Tooltip title={e} className="two-line">
            {e || "-"}
          </Tooltip>
        );
      },
    },
    {
      title: "Reserved Quantity",
      dataIndex: "reserved_qty",
      key: "reserved_qty",
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              numberformat(e),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title: "Reserved Customer Orders",
      dataIndex: "reserved_customerorders",
      key: "reserved_customerorders",
    },
    {
      title: "Reserved FC Transfers",
      dataIndex: "reserved_fc_transfers",
      key: "reserved_fc_transfers",
    },
    {
      title: "Reserved FC Processing",
      dataIndex: "reserved_fc_processing",
      key: "reserved_fc_processing",
    },
    {
      title: "Marketplace ID",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
    },
    {
      title: "Updated Date",
      dataIndex: "updated_date",
      key: "updated_date",
    },
  ];
  const planning_inventory = [
    {
      title: "Snapshot Date",
      dataIndex: "snapshot_date",
      key: "snapshot_date",
      render: (text) => {
        return (
          <span>
            {!text ? "-" : moment(new Date(text)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="cyan">{text}</Tag>;
      },
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="blue">{text}</Tag>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="default">{text}</Tag>;
      },
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      width: 250,
      render: (text) => {
        if (!text) return "-";
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {text}
              </div>
            }
          >
            <div className="product_description">{text || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Condition Type",
      dataIndex: "conditions",
      key: "conditions",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="success">{text}</Tag>;
      },
    },

    {
      title: "Available",
      dataIndex: "available",
      key: "available",
      render: (text) => {
        if (!text) return "-";
        return <span>{Number(text).toLocaleString()}</span>;
      },
    },
    {
      title: "Pending Removal Quantity",
      dataIndex: "pending_removal_quantity",
      key: "pending_removal_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inv Age 0 To 90 Days",
      dataIndex: "inv_age_0_to_90_days",
      key: "inv_age_0_to_90_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inv Age 91 To 180 Days",
      dataIndex: "inv_age_91_to_180_days",
      key: "inv_age_91_to_180_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inv Age 181 To 270 Days",
      dataIndex: "inv_age_181_to_270_days",
      key: "inv_age_181_to_270_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inv Age 271 To 365 Days",
      dataIndex: "inv_age_271_to_365_days",
      key: "inv_age_271_to_365_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inv Age 365 Plus Days",
      dataIndex: "inv_age_365_plus_days",
      key: "inv_age_365_plus_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Qty To Be Charged Ltsf 9 Mo",
      dataIndex: "qty_to_be_charged_ltsf_9_mo",
      key: "qty_to_be_charged_ltsf_9_mo",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Projected Ltsf 11 Mo",
      dataIndex: "projected_ltsf_11_mo", /////////////
      key: "projectedLtsf11Mo",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Qty To Be Charged Ltsf 12 Mo",
      dataIndex: "qty_to_be_charged_ltsf_12_mo",
      key: "qty_to_be_charged_ltsf_12_mo",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Estimated Ltsf Next Charge",
      dataIndex: "estimated_ltsf_next_charge",
      key: "estimated_ltsf_next_charge",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Units Shipped T7",
      dataIndex: "units_shipped_t7",
      key: "units_shipped_t7",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Units Shipped T30",
      dataIndex: "units_shipped_t30",
      key: "units_shipped_t30",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Units Shipped T60",
      dataIndex: "units_shipped_t60",
      key: "units_shipped_t60",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Units Shipped T90",
      dataIndex: "units_shipped_t90",
      key: "units_shipped_t90",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Alert",
      dataIndex: "alert",
      key: "alert",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="red-inverse">{text}</Tag>;
      },
    },
    {
      title: "Your Price",
      dataIndex: "your_price",
      key: "your_price",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Sales Price",
      dataIndex: "sales_price",
      key: "sales_price",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Lowest Price New Plus Shipping",
      dataIndex: "lowest_price_new_plus_shipping",
      key: "lowest_price_new_plus_shipping",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Lowest Price Used",
      dataIndex: "lowest_price_used",
      key: "lowest_price_used",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Recommended Action",
      dataIndex: "recommended_action",
      key: "recommended_action",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Healthy Inventory Level",
      dataIndex: "healthy_inventory_level",
      key: "healthy_inventory_level",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Recommended Sales Price",
      dataIndex: "recommended_sales_price",
      key: "recommended_sales_price",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Recommended Sale Duration Days",
      dataIndex: "recommended_sale_duration_days",
      key: "recommended_sale_duration_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Recommended Removal Quantity",
      dataIndex: "recommended_removal_quantity",
      key: "recommended_removal_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Estimated Cost Savings Of Recommended Actions",
      dataIndex: "estimated_cost_savings_of_recommended_actions",
      key: "estimated_cost_savings_of_recommended_actions",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Sell Through",
      dataIndex: "sell_through",
      key: "sell_through",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Item Volume",
      dataIndex: "item_volume",
      key: "item_volume",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Volume Unit Measurement",
      dataIndex: "volume_unit_measurement",
      key: "volume_unit_measurement",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Storage Type",
      dataIndex: "storage_type",
      key: "storage_type",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Storage Volume",
      dataIndex: "storage_volume",
      key: "storage_volume",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Inv Marketplace",
      dataIndex: "invMarketplace",
      key: "inv_marketplace", //-----------------------------------------------------------------
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Product Group",
      dataIndex: "product_group",
      key: "product_group",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Sales Rank",
      dataIndex: "sales_rank",
      key: "sales_rank",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Days Of Supply",
      dataIndex: "days_of_supply",
      key: "days_of_supply",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Estimated Excess Quantity",
      dataIndex: "estimated_excess_quantity",
      key: "estimated_excess_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Weeks Of Cover T30",
      dataIndex: "weeks_of_cover_t30",
      key: "weeks_of_cover_t30",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Weeks Of Cover T90",
      dataIndex: "weeks_of_cover_t90",
      key: "weeks_of_cover_t90",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Featured Offer Price",
      dataIndex: "featuredoffer_price",
      key: "featuredoffer_price",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Sales Shipped Last 7 Days",
      dataIndex: "sales_shipped_last_7_days",
      key: "sales_shipped_last_7_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Sales Shipped Last 30 Days",
      dataIndex: "sales_shipped_last_30_days",
      key: "sales_shipped_last_30_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Sales Shipped Last 60 Days",
      dataIndex: "sales_shipped_last_60_days",
      key: "sales_shipped_last_60_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Sales Shipped Last 90 Days",
      dataIndex: "sales_shipped_last_90_days",
      key: "sales_shipped_last_90_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inv Age 0 To 30 Days",
      dataIndex: "inv_age_0_to_30_days",
      key: "inv_age_0_to_30_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inv Age 31 To 60 Days",
      dataIndex: "inv_age_31_to_60_days",
      key: "inv_age_31_to_60_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inv Age 61 To 90 Days",
      dataIndex: "inv_age_61_to_90_days",
      key: "inv_age_61_to_90_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inv Age 181 To 330 Days",
      dataIndex: "inv_age_181_to_330_days",
      key: "inv_age_181_to_330_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inv Age 331 To 365 Days",
      dataIndex: "inv_age_331_to_365_days",
      key: "inv_age_331_to_365_days",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Estimated Storage Cost Next Month",
      dataIndex: "estimated_storage_cost_next_month",
      key: "estimated_storage_cost_next_month",
      render: (text) => {
        return <span>{numberformat}</span>;
      },
    },
    {
      title: "Inbound Quantity",
      dataIndex: "inbound_quantity",
      key: "inbound_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inbound Working",
      dataIndex: "inbound_working",
      key: "inbound_working",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inbound Shipped",
      dataIndex: "inbound_shipped",
      key: "inbound_shipped",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inbound Received",
      dataIndex: "inbound_received",
      key: "inbound_received",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "No Sale Last 6 Months",
      dataIndex: "no_sale_last_6_months",
      key: "no_sale_last_6_months",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Reserved Quantity",
      dataIndex: "reserved_quantity",
      key: "reserved_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Unfulfillable Quantity",
      dataIndex: "unfulfillable_quantity",
      key: "unfulfillable_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
  ];
  const stranded_inventory = [
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Primary Action",
      dataIndex: "primaryAction",
      key: "primaryAction",
    },
    {
      title: "Date Stranded",
      dataIndex: "dateStranded",
      key: "dateStranded",
    },
    {
      title: "Date To Take Auto Removal",
      dataIndex: "dateToTakeAutoRemoval",
      key: "dateToTakeAutoRemoval",
    },
    {
      title: "Status Primary",
      dataIndex: "statusPrimary",
      key: "statusPrimary",
    },
    {
      title: "Status Secondary",
      dataIndex: "statusSecondary",
      key: "statusSecondary",
    },
    {
      title: "Error Message",
      dataIndex: "errorMessage",
      key: "errorMessage",
    },
    {
      title: "Stranded Reason",
      dataIndex: "strandedReason",
      key: "strandedReason",
    },
    {
      title: "Condition Type",
      dataIndex: "conditionType",
      key: "conditionType",
    },
    {
      title: "Fulfilled By",
      dataIndex: "fulfilledBy",
      key: "fulfilledBy",
    },
    {
      title: "Fulfillable Qty",
      dataIndex: "fulfillableQty",
      key: "fulfillableQty",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Your Price",
      dataIndex: "yourPrice",
      key: "yourPrice",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Unfulfillable Qty",
      dataIndex: "unfulfillableQty",
      key: "unfulfillableQty",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Reserved Quantity",
      dataIndex: "reservedQuantity",
      key: "reservedQuantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Inbound Shipped Qty",
      dataIndex: "inboundShippedQty",
      key: "inboundShippedQty",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplaceId",
      key: "marketplaceId",
    },
  ];
  const product_data = [
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      width: 250,
      render: (text) => {
        if (!text) return "-";
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {text}
              </div>
            }
          >
            <div className="product_description">{text || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",
      width: 250,
      render: (text) => {
        if (!text) return "-";
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {text}
              </div>
            }
          >
            <div className="product_description">{text || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="blue">{text}</Tag>;
      },
    },
    {
      title: "Listing Id",
      dataIndex: "listing_id",
      key: "listing_id",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="cyan">{text}</Tag>;
      },
    },
    {
      title: "Seller Sku",
      dataIndex: "seller_sku",
      key: "seller_sku",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="default">{text}</Tag>;
      },
    },
    {
      title: "Universal Sku",
      dataIndex: "universalSku",
      key: "universalSku",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="error">{text}</Tag>;
      },
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Gst Slab",
      dataIndex: "gstSlab",
      key: "gstSlab",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Cogs",
      dataIndex: "cogs",
      key: "cogs",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="geekblue">{text}</Tag>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Sales Rank",
      dataIndex: "salesRank",
      key: "salesRank",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Open Date",
      dataIndex: "open_date",
      key: "open_date",
      render: (text) => {
        return <span>{!text ? "-" : text}</span>;
      },
    },
    {
      title: "Image Url",
      dataIndex: "image_url",
      key: "image_url",
      render: (text) => {
        if (!text) return "-";
        return (
          <span>
            <img src={{ text }} width={100} height={100} />
          </span>
        );
      },
    },
    {
      title: "Item Is Marketplace",
      dataIndex: "item_is_marketplace",
      key: "item_is_marketplace",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Product Id Type",
      dataIndex: "product_id_type",
      key: "product_id_type",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Zshop Shipping Fee",
      dataIndex: "zshop_shipping_fee",
      key: "zshop_shipping_fee",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Item Note",
      dataIndex: "item_note",
      key: "item_note",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Item Condition",
      dataIndex: "item_condition",
      key: "item_condition",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Zshop Category1",
      dataIndex: "zshopCategory1",
      key: "zshopCategory1",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Zshop Browse Path",
      dataIndex: "zshopBrowsePath",
      key: "zshopBrowsePath",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Zshop Storefront Feature",
      dataIndex: "zshopStorefrontFeature",
      key: "zshopStorefrontFeature",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Asin1",
      dataIndex: "asin1",
      key: "asin1",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="gold">{text}</Tag>;
      },
    },
    {
      title: "Asin2",
      dataIndex: "asin2",
      key: "asin2",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="gold">{text}</Tag>;
      },
    },
    {
      title: "Asin3",
      dataIndex: "asin3",
      key: "asin3",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="gold">{text}</Tag>;
      },
    },
    {
      title: "Will Ship Internationally",
      dataIndex: "will_ship_internationally",
      key: "will_ship_internationally",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Expedited Shipping",
      dataIndex: "expedited_shipping",
      key: "expedited_shipping",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Zshop Boldface",
      dataIndex: "zshop_boldface",
      key: "zshop_boldface",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Product Id",
      dataIndex: "product_id",
      key: "product_id",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="volcano">{text}</Tag>;
      },
    },
    {
      title: "Bid For Featured Placement",
      dataIndex: "bid_for_featured_placement",
      key: "bid_for_featured_placement",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Add Delete",
      dataIndex: "add_delete",
      key: "add_delete",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Pending Quantity",
      dataIndex: "pending_quantity",
      key: "pending_quantity",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Fulfillment Channel",
      dataIndex: "fulfillment_channel",
      key: "fulfillment_channel",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Merchant Shipping Group",
      dataIndex: "merchant_shipping_group",
      key: "merchant_shipping_group",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Product Width",
      dataIndex: "package_width",
      key: "package_width",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Product Length",
      dataIndex: "package_length",
      key: "package_length",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Product Height",
      dataIndex: "package_height",
      key: "package_height",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Product Unit Weight",
      dataIndex: "productUnitWeight",
      key: "productUnitWeight",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Package Weight",
      dataIndex: "packageWeight",
      key: "packageWeight",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Storage Fee",
      dataIndex: "storageFee",
      key: "storageFee",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "FBA Fee",
      dataIndex: "fbaFee",
      key: "fbaFee",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Commission Fee",
      dataIndex: "commissionFee",
      key: "commissionFee",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplaceId",
      key: "marketplaceId",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
  ];
  const reimbursements_data = [
    {
      title: "Approval Date",
      dataIndex: "approval_date",
      key: "approval_date",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Reimbursement Id",
      dataIndex: "reimbursement_id",
      key: "reimbursement_id",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="blue">{text}</Tag>;
      },
    },
    {
      title: "Case Id",
      dataIndex: "case_id",
      key: "case_id",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="cyan">{text}</Tag>;
      },
    },
    {
      title: "Amazon Order Id",
      dataIndex: "amazon_order_id",
      key: "amazon_order_id",
      render: (text) => {
        if (!text) return "-";
        return <Tag color="default">{text}</Tag>;
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      width: 250,
      render: (text) => {
        if (!text) return "-";
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {text}
              </div>
            }
          >
            <div className="product_description">{text || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Condition Type",
      dataIndex: "conditionType",
      key: "conditionType",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Currency Unit",
      dataIndex: "currencyUnit",
      key: "currencyUnit",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Amount Per Unit",
      dataIndex: "amountPerUnit",
      key: "amountPerUnit",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Amount Total",
      dataIndex: "amountTotal",
      key: "amountTotal",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Quantity Reimbursed Cash",
      dataIndex: "quantityReimbursedCash",
      key: "quantityReimbursedCash",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Quantity Reimbursed Inventory",
      dataIndex: "quantityReimbursedInventory",
      key: "quantityReimbursedInventory",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Quantity Reimbursed Total",
      dataIndex: "quantityReimbursedTotal",
      key: "quantityReimbursedTotal",
      render: (text) => {
        return <span>{numberformat(text)}</span>;
      },
    },
    {
      title: "Original Reimbursement Id",
      dataIndex: "originalReimbursementId",
      key: "originalReimbursementId",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Original Reimbursement Type",
      dataIndex: "originalReimbursementType",
      key: "originalReimbursementType",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Is Charge Done",
      dataIndex: "isChargeDone",
      key: "isChargeDone",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplaceId",
      key: "marketplaceId",
      render: (text) => {
        if (!text) return "-";
        return <span>{text}</span>;
      },
    },
  ];

  const findColumns = {
    orders_report: orders_report,
    return_orders: return_orders,
    archived_inventory: archived_inventory,
    finance_data: finance_data,
    reserved_inventory: reserved_inventory,
    planning_inventory: planning_inventory,
    stranded_inventory: stranded_inventory,
    product_data: product_data,
    reimbursements_data: reimbursements_data,
  };

  if (checkPermission(contextValue, "reports", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }

  return (
    <Wrapper className=" fadeInBottom p-4">
      <div className="d-flex justify-content-end flex-wrap mb-5 fix-over">
        <div className="fix-over">
          <DatePicker.RangePicker
            presets={rangePresets}
            getPopupContainer={(trigger) => trigger.parentNode}
            className="me-3"
            size="large"
            value={
              dateFilter?.start && dateFilter?.end
                ? [
                    dayjs(dateFilter?.start, "YYYY-MM-DD"),
                    dayjs(dateFilter?.end, "YYYY-MM-DD"),
                  ]
                : null
            }
            onChange={(e) => {
              setDateFilter({
                start: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
              });
              const date_range = {
                start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
              };
              contextValue?.updateCommonGlobalVal({
                date_range,
              });
              updateDate(date_range);
              setLoading(true);
              setList([]);
              GetReportsListAction(findURL?.[selected], {
                sales_channel: marketplaceSelected?.sales_channel,
                pageSize: pageSize,
                page: page,
                start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
              });
            }}
          />
        </div>
        <Select
          className="w-225px me-3"
          size="large"
          getPopupContainer={(target) => target.parentNode}
          options={marketplaceList}
          onChange={(_, e) => {
            setMarketplaceSelected({
              marketplace: e?.marketplace,
              marketplace_id: e?.marketplace_id,
              region: e?.region,
              sales_channel: e?.sales_channel,
            });
          }}
          value={marketplaceSelected?.marketplace_id}
          loading={marketplaceLoading}
          placeholder="Select Marketplace"
        />
      </div>

      <div className="card mt-5">
        <div className="px-4 mt-4">
          <Tabs
            type="card"
            tabPosition={"top"}
            onChange={(e) => {
              setSelected(e);
            }}
            activeKey={selected}
            items={items}
          />
        </div>
        <div className="card-body pt-4">
          {loading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <Table
              scroll={{ x: "max-content" }}
              columns={findColumns?.[selected]}
              dataSource={list}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Reports;
