import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CentralLog from "../../modules/pages/application-logs/central-log";
import {
  GetCatalogListAmazonAction,
  GetEventNameAction,
  GetEventTypeAction,
  FeedSystemEventProcessListAction,
  fakeActionCLS,
} from "../../redux/module_/application-logs/central-log/index.action";

const mapStateToProps = (state) => ({
  SystemEventProcessListResponse: state.CLS.SystemEventProcessListResponse,
  GetEventNameListResponse: state.CLS.GetEventNameListResponse,
  GetEventTypeListResponse: state.CLS.GetEventTypeListResponse,
  FeedSystemEventProcessListResponse:
    state.CLS.FeedSystemEventProcessListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetCatalogListAmazonAction,
      GetEventNameAction,
      GetEventTypeAction,
      FeedSystemEventProcessListAction,
      fakeActionCLS,
    },
    dispatch
  );

const CentralLog_ = connect(mapStateToProps, mapDispatchToProps)(CentralLog);

export default CentralLog_;
