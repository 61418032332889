import React, { useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Carousel,
  Button,
  Empty,
  message,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddItemModal = ({
  visible,
  onCancel,
  onAdd,
  CatalogManualMappingAction,
  fakeActionCatalog,
  getList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleAdd = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      onAdd(values);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const [imageURLS, setImageURLS] = useState(null);
  const contentStyle = {
    height: "100px",
    color: "#fff",
    lineHeight: "100px",
    textAlign: "center",
    background: "#364d79",
    marginBottom: 0,
  };

  const filedsName = [
    {
      name: "asin",
      label: "ASIN",
      required: true,
    },
    {
      name: "child_asin",
      label: "Child ASIN",
      required: true,
    },
    {
      name: "parent_sku",
      label: "SKU",
      required: true,
    },
    {
      name: "category",
      label: "Category",
      required: false,
    },
    {
      name: "sub_category",
      label: "Sub Category",
      required: false,
    },
    {
      name: "product_name",
      label: "Product name",
      required: false,
    },
    {
      name: "product_title",
      label: "Product title",
      required: false,
    },
    {
      name: "product_description",
      label: "Product description",
      required: false,
    },

    {
      name: "status",
      label: "Status",
      required: false,
    },

    {
      name: "ean",
      label: "EAN",
      required: true,
    },

    {
      name: "brand",
      label: "Brand",
      required: false,
    },

    {
      name: "price",
      label: "Price",
      required: false,
    },
    {
      name: "product_cost",
      label: "Product cost",
      required: false,
    },

    {
      name: "variation_theme",
      label: "Variation theme",
      required: false,
    },
    {
      name: "style_name",
      label: "Style name",
      required: false,
    },
    {
      name: "color_name",
      label: "Color name",
      required: false,
    },
    {
      name: "size_name",
      label: "Size name",
      required: false,
    },
    {
      name: "material_name",
      label: "Material name",
      required: false,
    },
    {
      name: "flavor_name",
      label: "Flavor name",
      required: false,
    },
    {
      name: "website_shipping_weight",
      label: "Website shipping weight",
      required: false,
    },
    {
      name: "item_length",
      label: "Item length",
      required: false,
    },
    {
      name: "item_width",
      label: "Item width",
      required: false,
    },
    {
      name: "item_height",
      label: "Item height",
      required: false,
    },
    {
      name: "volume_capacity_name",
      label: "Volume capacity name",
      required: false,
    },
    {
      name: "ingredients",
      label: "Ingredients",
      required: false,
    },
    {
      name: "manufacturer",
      label: "Manufacturer",
      required: false,
    },

    {
      name: "relationship_type",
      label: "Relationship type",
      required: false,
    },

    {
      name: "marketplace_id",
      label: "Marketplace id",
      required: false,
    },
  ];

  const CatalogManualMappingResponse = useSelector(
    (state) => state.Catalog.CatalogManualMappingResponse || {}
  );

  useEffect(() => {
    if (CatalogManualMappingResponse?.status === true) {
      message.destroy();
      message.success(CatalogManualMappingResponse?.message || "-");
      getList();
      fakeActionCatalog("CatalogManualMappingResponse");
      onCancel();
    } else if (CatalogManualMappingResponse?.status === false) {
      message.destroy();
      message.error(CatalogManualMappingResponse?.message || "-");
      fakeActionCatalog("CatalogManualMappingResponse");
    }
  }, [CatalogManualMappingResponse]);

  return (
    <Modal
      title="Add Item"
      open={visible}
      onCancel={onCancel}
      confirmLoading={loading}
      footer={[]}
      width={800}
    >
      <div
        style={{
          height: "calc(100vh - 490px)",
          minHeight: "400px",
          overflow: "auto",
          padding: "0px 10px",
        }}
      >
        <Form form={form} layout="vertical">
          <div className="row d-flex align-items-center">
            <div className="col-8">
              <Form.Item name="imageUrl" label="Image URLs">
                <Input.TextArea
                  onChange={(e) => {
                    if (e?.target.value?.split(",").length > 9) {
                      message.destroy();

                      return message.warning("Max 8 image upload");
                    }
                    setImageURLS(e?.target.value);
                  }}
                  placeholder="Enter image URLs"
                  rows={2}
                />
              </Form.Item>
            </div>
            <div className="col-4">
              <div style={{ position: "relative" }}>
                <Carousel autoplay>
                  {(imageURLS?.split(",") || [])?.length === 0 ? (
                    <div>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    (imageURLS?.split(",") || [])?.map((d, i) => (
                      <div key={i}>
                        <img style={contentStyle} src={d} />
                      </div>
                    ))
                  )}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="row">
            {filedsName?.map((d, i) => {
              return (
                <div className="col-6" key={i}>
                  <Form.Item
                    name={d?.name}
                    label={d?.label}
                    rules={[
                      {
                        required: d?.required,
                        message: `Please input ${d?.label}!`,
                      },
                    ]}
                  >
                    <Input placeholder={`Enter ${d?.label}`} />
                  </Form.Item>
                </div>
              );
            })}
          </div>

          <Form.List
            name="bullet_point"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 5) {
                    return Promise.reject(
                      new Error("At least 5 Bullet points")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? "Bullet point" : ""}
                    required={true}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                    >
                      <Input
                        placeholder="Bullet point"
                        style={{
                          width: "60%",
                        }}
                      />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button ms-5"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}

                {fields?.length < 5 && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{
                        width: "60%",
                      }}
                      icon={<PlusOutlined />}
                    >
                      New Bullet
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
          {/* <Form.Item
            name="bullets"
            label="Bullet Points"
            rules={[
              {
                required: true,
                message: "Please input bullet points!",
              },
            ]}
          >
            <Input.TextArea placeholder="Enter bullet points" rows={4} />
          </Form.Item> */}
        </Form>
      </div>
      <div className="d-flex justify-content-end mt-8">
        <Button type="ghost" onClick={() => onCancel()}>
          Close
        </Button>
        <Button
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                // form.resetFields();
                const { bullet_point, imageUrl } = values;

                delete values?.imageUrl;
                delete values?.bullet_point;

                const dataAdd = {};
                // Split the imageUrl string by commas and assign to imageUrls array
                const imageUrls = imageUrl.split(",").map((url) => url.trim());

                // Populate main_image_url and other_image_url_X fields
                if (imageUrls.length > 0) {
                  dataAdd.main_image_url = imageUrls[0];
                  for (let i = 1; i < Math.min(imageUrls.length, 8); i++) {
                    dataAdd[`other_image_url_${i}`] = imageUrls[i];
                  }
                }
                // Copy bullet points from input to dataAdd
                bullet_point.forEach((point, index) => {
                  dataAdd[`bullet_point_${index + 1}`] = point;
                });
                message.destroy();
                message.loading("Loading...", 0);
                CatalogManualMappingAction({ ...values, ...dataAdd });
              })
              .catch((info) => {});
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default AddItemModal;
