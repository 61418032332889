import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Checkbox,
  Drawer,
  Dropdown,
  Input,
  List,
  Modal,
  Result,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Pagination from "../../../../components/pagination";
import {
  AllMarketPlace,
  DefaultPerPage,
  NumberWithCommas,
  checkPermission,
} from "../../../../config";

import { Link } from "react-router-dom/cjs/react-router-dom";
import { GlobalContext } from "../../../../commonContext";
import { TableLoading } from "../../../../components/table-animation";
import Wrapper from "../style";

export default function (props) {
  const {
    SalesChannelListAction,
    fakeActionCatalog,
    GetInventoryDataAction,
    ReplenishmentBulkUpdateAction,
    GetAllInventoryAction,
    fakeActionInventory,
    InventoryEmailFlagAction,
  } = props;
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [selectedRows, setSelectedRows] = useState([]);

  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [tabSelected, setTabSelected] = useState("Details");

  const [bulkAction, setBulkAction] = useState({
    show: false,
    type: "",
  });

  const [filters, setFilters] = useState({
    start_date: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
    asin: null,
    days_for_the_forecast: 7,
    product_listing_type: "FBA",
  });

  const [selectedValues, setSelectedValues] = useState({
    target_stock_days: null,
    vendor_lead_time: null,
  });

  const [typeDrawer, setTypeDrawer] = useState("");

  const [emailFlag, setEmailFlag] = useState(false);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    region_name: null,
  });

  const [sign, setSign] = useState("");
  const [sortTable, setSortTable] = useState("");

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const GetInventoryListResponse = useSelector(
    (state) => state.Inventory_.GetInventoryListResponse || {}
  );
  const GetAllInventoryListResponse = useSelector(
    (state) => state.Inventory_.GetAllInventoryListResponse || {}
  );
  const ReplenishmentBulkUpdateResponse = useSelector(
    (state) => state.Inventory_.ReplenishmentBulkUpdateResponse || {}
  );
  const InventoryEmailFlagResponse = useSelector(
    (state) => state.Inventory_.InventoryEmailFlagResponse || {}
  );

  const [inventoryData, setInventoryData] = useState([]);

  const hitGetInventoryData = (obj) => {
    GetAllInventoryAction(obj);
  };

  useEffect(() => {
    if (InventoryEmailFlagResponse?.status === true) {
      message.destroy();
      message.success(InventoryEmailFlagResponse?.message || "");
      setEmailFlag(
        InventoryEmailFlagResponse?.data?.inventory_email_flag === "0"
          ? false
          : true
      );
      fakeActionInventory("InventoryEmailFlagResponse");
    } else if (InventoryEmailFlagResponse?.status === false) {
      setLoading(false);

      setInventoryData([]);
      setTotalPage(0);
      fakeActionInventory("InventoryEmailFlagResponse");
    }
  }, [InventoryEmailFlagResponse]);
  useEffect(() => {
    if (GetAllInventoryListResponse?.status === true) {
      setEmailFlag(
        GetAllInventoryListResponse?.data?.inventory_email_flag === 0
          ? false
          : true
      );
      setInventoryData(GetAllInventoryListResponse?.data?.records || []);
      setTotalPage(
        GetAllInventoryListResponse?.data?.pagination?.totalCount || 0
      );
      setSign(GetAllInventoryListResponse?.data?.currency);
      setLoading(false);
      fakeActionInventory("GetAllInventoryListResponse");
    } else if (GetAllInventoryListResponse?.status === false) {
      setLoading(false);

      setInventoryData([]);
      setTotalPage(0);
      fakeActionInventory("GetAllInventoryListResponse");
    }
  }, [GetAllInventoryListResponse]);
  useEffect(() => {
    if (ReplenishmentBulkUpdateResponse?.status === true) {
      message.destroy();
      message.success(ReplenishmentBulkUpdateResponse?.message);
      const obj = {
        region: marketplaceSelected?.region,
        sales_channel: marketplaceSelected?.sales_channel,
        days_for_the_forecast: filters?.days_for_the_forecast,
        product_listing_type: filters?.product_listing_type,
        asin: filters?.asin,
        pageSize: pageSize,
        page: page,
        sort: sortTable,
      };
      setSelectedValues({
        target_stock_days: null,
        vendor_lead_time: null,
      });
      setSelectedRows([]);
      setBulkAction({
        show: false,
        type: "",
      });
      hitGetInventoryData(obj);
      setLoading(true);
      fakeActionInventory("ReplenishmentBulkUpdateResponse");
    } else if (ReplenishmentBulkUpdateResponse?.status === false) {
      setLoading(false);
      message.destroy();
      message.warning(ReplenishmentBulkUpdateResponse?.message);

      fakeActionInventory("ReplenishmentBulkUpdateResponse");
    }
  }, [ReplenishmentBulkUpdateResponse]);

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );

            if (e?.default_marketplace === 1) {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
              });

              const obj = {
                region: e?.region,
                sales_channel: e?.sales_channel,
                days_for_the_forecast: filters?.days_for_the_forecast,
                asin: filters?.asin,
                product_listing_type: filters?.product_listing_type,
                pageSize: pageSize,
                page: page,
                sort: sortTable,
              };
              hitGetInventoryData(obj);
            }
            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                      </>
                    ),
                    region: e.region,
                    value: e.marketplace_id,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                  },
                ],
              });
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                marketplace_id: e?.marketplace_id,
                value: e.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);
        setMarketplaceLoading(false);
        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setMarketplaceList([]);
        setLoading(false);

        setList([]);
      }

      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      setLoading(false);

      setList([]);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  useEffect(() => {
    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();

    setFilters({
      ...filters,
      start_date: contextValue?.data?.date_range?.start_date,
      end_date: contextValue?.data?.date_range?.end_date,
    });

    return () => {};
  }, []);

  const onPageNo = (e) => {
    setPage(e);
    setLoading(true);

    const apiObj = {
      region: marketplaceSelected?.region,
      sales_channel: marketplaceSelected?.sales_channel,
      days_for_the_forecast: filters?.days_for_the_forecast,
      product_listing_type: filters?.product_listing_type,
      asin: filters?.asin,
      pageSize: pageSize,
      page: e,
      sort: sortTable,
    };

    hitGetInventoryData(apiObj);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      region: marketplaceSelected?.region,
      sales_channel: marketplaceSelected?.sales_channel,
      days_for_the_forecast: filters?.days_for_the_forecast,
      product_listing_type: filters?.product_listing_type,
      asin: filters?.asin,
      pageSize: e,
      page: 1,
      sort: sortTable,
    };

    hitGetInventoryData(apiObj);
  };

  const handleMenuClick = (e) => {
    if (selectedRows?.length === 1) {
      setSelectedValues({
        target_stock_days: selectedRows?.[0]?.target_days_of_stock,
        vendor_lead_time: selectedRows?.[0]?.vendor_lead_time,
      });
    }
    setBulkAction({
      show: true,
      type: e?.key == 1 ? "Add Days of Stock" : "Add Lead Time",
    });
  };
  const items = [
    {
      label: "Add Days of stock",
      key: "1",
    },
    {
      label: "Add Lead Time",
      key: "2",
    },
  ];

  const addOrUpdateProduct = (productToAddOrUpdate) => {
    setSelectedRows((currentProducts) => {
      const index = currentProducts.findIndex(
        (p) => p.sku === productToAddOrUpdate.sku
      );

      if (index > -1) {
        // Product exists, update it
        return currentProducts.map((p) =>
          p.sku === productToAddOrUpdate.sku ? productToAddOrUpdate : p
        );
      } else {
        // Product does not exist, add it
        return [...currentProducts, productToAddOrUpdate];
      }
    });
  };

  // Remove product by SKU
  const removeProduct = (skuToRemove) => {
    setSelectedRows((currentProducts) =>
      currentProducts.filter((p) => p.sku !== skuToRemove)
    );
  };

  const DrawerView = {
    details: [
      {
        status: "Available Inventory",
        key: "afn_fulfillable_quantity",
      },
      {
        status: "Reserved Inventory",
        key: "reserved_qty",
      },
      {
        status: "Total Inventory",
        key: "total_inventory",
      },
      {
        status: "AFN Fulfillable Quantity",
        key: "afn_fulfillable_quantity",
      },
      {
        status: "AFN Reserved Quantity",
        key: "afn_reserved_quantity",
      },
      {
        status: "AFN Total Quantity",
        key: "afn_total_quantity",
      },
      {
        status: "AFN Inbound Working Quantity",
        key: "afn_inbound_working_quantity",
      },
      {
        status: "AFN Inbound Shipped Quantity",
        key: "afn_inbound_shipped_quantity",
      },
      {
        status: "AFN Inbound Receiving Quantity",
        key: "afn_inbound_receiving_quantity",
      },
      {
        status: "AFN Unsellable Quantity",
        key: "afn_unsellable_quantity",
      },
      {
        status: "AFN Researching Quantity",
        key: "afn_researching_quantity",
      },
      {
        status: "AFN Warehouse Quantity",
        key: "afn_fulfillable_quantity",
      },
      {
        status: "MFN Fulfillable Quantity",
        key: "mfn_fulfillable_quantity",
      },
    ],
    forcast: [
      {
        status: "Available inventory",
        key: "available_inventory",
      },
      {
        status: "Target days of stock",
        key: "target_stock_days",
      },
      {
        status: "Days of stock available in last 7 days",
        key: "days_of_stock_available_in_last_days",
      },
      {
        status: "Units sold in last 7 days",
        key: "units_sold_in_last_days",
      },
      {
        status: "Sales velocity",
        key: "sales_velocity",
      },
      {
        status: "Vendor Lead time",
        key: "vendor_lead_time",
      },
      {
        status: "Vendor Lead units",
        key: "vendor_lead_units",
      },
      {
        status: "Forecast days (Target days of stock)",
        key: "forecast_days",
      },
      {
        status: "Forecast Units (Target units)",
        key: "forecast_units",
      },
      {
        status: "Forecasted Suggested",
        key: "forecasted_suggested",
      },
      {
        status: "Required Order",
        key: "required_order",
      },
    ],
  };

  if (checkPermission(contextValue, "inventory", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }
  return (
    <Wrapper
      className="fadeInBottom content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <>
        {/*begin::Container*/}
        <div className="container-fluid" id="kt_content_container">
          <h2 className="mb-6 smallScreenPageHeader">Inventory</h2>
          <div className="d-flex justify-content-end flex-wrap mb-5 fix-over fix-over">
            <Dropdown
              menu={{ items, onClick: handleMenuClick }}
              className="me-3"
              trigger={["click"]}
              disabled={!selectedRows?.length}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Button
                style={{
                  opacity: selectedRows?.length === 0 ? 0.5 : 1,
                }}
                type="primary"
                size="large"
                className="ms-2"
              >
                <Space>
                  Bulk Action
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Tooltip
              getPopupContainer={(target) => target.parentNode}
              title="Forecast Period"
            >
              <Input
                size="large"
                placeholder="Forecast Period"
                className="w-150px me-3"
                suffix={
                  <Tooltip
                    getPopupContainer={(target) => target.parentNode}
                    title="Forecast Period"
                  >
                    <InfoCircleOutlined
                      style={{
                        color: "rgba(0,0,0,.45)",
                      }}
                    />
                  </Tooltip>
                }
                onPressEnter={() => {
                  setPage(1);
                  setPageSize(DefaultPerPage);
                  setLoading(true);
                  const apiObj = {
                    region: marketplaceSelected?.region,
                    sales_channel: marketplaceSelected?.sales_channel,
                    days_for_the_forecast: filters?.days_for_the_forecast,
                    asin: filters?.asin,
                    pageSize: DefaultPerPage,
                    product_listing_type: filters?.product_listing_type,
                    page: 1,
                    sort: sortTable,
                  };

                  hitGetInventoryData(apiObj);
                }}
                value={filters?.days_for_the_forecast}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    days_for_the_forecast: e.target.value,
                  })
                }
              />
            </Tooltip>
            <Input
              size="large"
              placeholder="Search by ASIN/SKU"
              className="w-200px me-3"
              onPressEnter={() => {
                setPage(1);
                setPageSize(DefaultPerPage);
                setLoading(true);
                const apiObj = {
                  region: marketplaceSelected?.region,
                  sales_channel: marketplaceSelected?.sales_channel,
                  days_for_the_forecast: filters?.days_for_the_forecast,
                  product_listing_type: filters?.product_listing_type,
                  asin: filters?.asin,
                  pageSize: DefaultPerPage,
                  page: 1,
                  sort: sortTable,
                };

                hitGetInventoryData(apiObj);
              }}
              value={filters?.asin}
              onChange={(e) => setFilters({ ...filters, asin: e.target.value })}
            />

            <Select
              size="large"
              options={marketplaceList}
              getPopupContainer={(target) => target.parentNode}
              placeholder="Select Marketplace"
              className="w-200px "
              onChange={(_, e) => {
                setMarketplaceSelected({
                  marketplace: e?.marketplace,
                  marketplace_id: e?.marketplace_id,
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                });
                setPage(1);
                setPageSize(DefaultPerPage);
                setLoading(true);
                const apiObj = {
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                  product_listing_type: filters?.product_listing_type,
                  days_for_the_forecast: filters?.days_for_the_forecast,
                  asin: filters?.asin,
                  pageSize: DefaultPerPage,
                  page: 1,
                  sort: sortTable,
                };

                hitGetInventoryData(apiObj);
              }}
              value={marketplaceSelected?.marketplace_id}
              loading={marketplaceLoading}
            />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body px-2">
                  <>
                    <div className="mx-8 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="me-3 ms-3">
                          <Checkbox
                            disabled={loading}
                            onChange={(e) => {
                              setSelectedRows(
                                e.target.checked ? inventoryData : []
                              );
                            }}
                            checked={
                              selectedRows?.length === inventoryData?.length
                            }
                          />
                          &nbsp;
                          <span
                            style={{
                              fontSize: "1.15rem",
                            }}
                            className="ms-2"
                          >
                            Select All
                          </span>
                        </span>
                        <div
                          className="sorter-full d-flex"
                          style={{
                            fontSize: "1.15rem",
                            height: "23px",
                            borderLeft: "1px solid #b9b9b9",
                            paddingLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const sort =
                              sortTable === "target_days_of_stock" ||
                              sortTable === "-target_days_of_stock" ||
                              sortTable === ""
                                ? "available_inventory"
                                : sortTable === "available_inventory"
                                ? "-available_inventory"
                                : "";
                            setSortTable(sort);
                            const obj = {
                              region: marketplaceSelected?.region,
                              sales_channel: marketplaceSelected?.sales_channel,
                              product_listing_type:
                                filters?.product_listing_type,
                              days_for_the_forecast:
                                filters?.days_for_the_forecast,
                              asin: filters?.asin,
                              pageSize: pageSize,
                              page: page,
                              sort: sort,
                            };
                            setLoading(true);
                            hitGetInventoryData(obj);
                          }}
                        >
                          Available Inventory
                          <div
                            className="d-grid ms-1"
                            style={{
                              position: "relative",
                              top: "-4px",
                              transform: "scale(0.8)",
                            }}
                          >
                            <span className="sorter-up">
                              <svg
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="caret-up"
                                width="1em"
                                height="1em"
                                fill={
                                  sortTable === "available_inventory"
                                    ? "#9c9c9c"
                                    : "#000"
                                }
                                aria-hidden="true"
                              >
                                <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
                              </svg>
                            </span>
                            <span
                              style={{ position: "relative", top: "-13px" }}
                              className="sorter-down"
                            >
                              <svg
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="caret-down"
                                width="1em"
                                height="1em"
                                fill={
                                  sortTable === "-available_inventory"
                                    ? "#9c9c9c"
                                    : "#000"
                                }
                                aria-hidden="true"
                              >
                                <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div
                          className="sorter-full d-flex ms-2"
                          style={{
                            fontSize: "1.15rem",
                            height: "23px",
                            borderLeft: "1px solid #b9b9b9",
                            paddingLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const sort =
                              sortTable === "available_inventory" ||
                              sortTable === "-available_inventory" ||
                              sortTable === ""
                                ? "target_days_of_stock"
                                : sortTable === "target_days_of_stock"
                                ? "-target_days_of_stock"
                                : "";
                            setSortTable(sort);
                            const obj = {
                              region: marketplaceSelected?.region,
                              sales_channel: marketplaceSelected?.sales_channel,
                              product_listing_type:
                                filters?.product_listing_type,
                              days_for_the_forecast:
                                filters?.days_for_the_forecast,
                              asin: filters?.asin,
                              pageSize: pageSize,
                              page: page,
                              sort: sort,
                            };
                            setLoading(true);
                            hitGetInventoryData(obj);
                          }}
                        >
                          Target days of stock
                          <div
                            className="d-grid ms-1"
                            style={{
                              position: "relative",
                              top: "-4px",
                              transform: "scale(0.8)",
                            }}
                          >
                            <span className="sorter-up">
                              <svg
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="caret-up"
                                width="1em"
                                height="1em"
                                fill={
                                  sortTable === "target_days_of_stock"
                                    ? "#9c9c9c"
                                    : "#000"
                                }
                                aria-hidden="true"
                              >
                                <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
                              </svg>
                            </span>
                            <span
                              style={{ position: "relative", top: "-13px" }}
                              className="sorter-down"
                            >
                              <svg
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="caret-down"
                                width="1em"
                                height="1em"
                                fill={
                                  sortTable === "-target_days_of_stock"
                                    ? "#9c9c9c"
                                    : "#000"
                                }
                                aria-hidden="true"
                              >
                                <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <Switch
                            unCheckedChildren="FBA"
                            checkedChildren="FBM"
                            className="ms-3"
                            checked={filters?.product_listing_type === "FBM"}
                            onChange={(e) => {
                              const obj = {
                                region: marketplaceSelected?.region,
                                sales_channel:
                                  marketplaceSelected?.sales_channel,
                                days_for_the_forecast:
                                  filters?.days_for_the_forecast,
                                asin: filters?.asin,
                                product_listing_type: e ? "FBM" : "FBA",
                                pageSize: pageSize,
                                page: page,
                                sort: sortTable,
                              };
                              setLoading(true);
                              hitGetInventoryData(obj);
                              setFilters({
                                ...filters,
                                product_listing_type: e ? "FBM" : "FBA",
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <svg
                          width={20}
                          height={20}
                          fill="none"
                          stroke="red"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 17.25v1.5-1.5Zm0-15 10.5 19.5h-21L12 2.25Zm0 6v6-6Z" />
                        </svg>
                        <span
                          style={{ color: "red", fontWeight: "bold" }}
                          className="ms-2"
                        >
                          Email Alert
                        </span>
                        <Switch
                          className="ms-3"
                          checked={emailFlag}
                          onChange={(e) => {
                            setEmailFlag(e);
                            InventoryEmailFlagAction(e);
                          }}
                        />
                      </div>
                    </div>
                    {loading ? (
                      <TableLoading
                        id="test-table"
                        columns={[1, 2, 3, 4, 5]}
                        checkBoxVal={true}
                        actions={[1, 2]}
                      />
                    ) : (
                      <List
                        itemLayout="vertical"
                        size="large"
                        pagination={false}
                        dataSource={inventoryData}
                        renderItem={(item, i) => (
                          <List.Item key={item.id}>
                            <List.Item.Meta
                              avatar={
                                <div className="d-flex  align-items-center justify-content-center">
                                  <div className="me-3">
                                    <Checkbox
                                      checked={selectedRows
                                        ?.map((d) => d?.sku)
                                        .includes(item.sku)}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          addOrUpdateProduct(item);
                                        } else {
                                          removeProduct(item?.sku);
                                        }
                                      }}
                                    />
                                  </div>
                                  <Avatar
                                    size={100}
                                    shape="square"
                                    style={{ objectFit: "contain" }}
                                    src={item.image}
                                  />
                                </div>
                              }
                              title={
                                <div className="row justify-content-between">
                                  <span
                                    style={{ color: "#000", fontWeight: 400 }}
                                    className="col-xxl-10 col-lg-10"
                                  >
                                    {item.title || "-"}
                                  </span>
                                  <div className="d-flex justify-content-end col-xxl-2 col-lg-2">
                                    {/* emailFlag */}
                                    <Tag
                                      style={{ height: "fit-content" }}
                                      color={
                                        emailFlag
                                          ? parseInt(
                                              item?.available_inventory || 0
                                            ) === 0
                                            ? "blue"
                                            : "green"
                                          : "red"
                                      }
                                    >
                                      {emailFlag
                                        ? parseInt(
                                            item?.available_inventory || 0
                                          ) === 0
                                          ? "Email Send"
                                          : "In Stock"
                                        : "Email Not Send"}
                                    </Tag>

                                    {/* {parseInt(
                                      item?.available_inventory || 0
                                    ) === 0 && item?.is_mail_send === 1 ? (
                                      <Tag
                                        style={{ height: "fit-content" }}
                                        color="green-inverse"
                                      >
                                        Inventory out of stock email Sent
                                      </Tag>
                                    ) : parseInt(
                                        item?.available_inventory || 0
                                      ) === 0 && item?.is_mail_send === 0 ? (
                                      <Tag
                                        style={{ height: "fit-content" }}
                                        color="red"
                                      >
                                        Inventory out of stock
                                      </Tag>
                                    ) : (
                                      <Tag
                                        style={{ height: "fit-content" }}
                                        color="green"
                                      >
                                        Inventory in stock
                                      </Tag>
                                    )} */}
                                  </div>
                                </div>
                              }
                              description={
                                <div className="position-relative">
                                  <div className="row">
                                    <div className="col-xxl-10 col-lg-10">
                                      <div className="row mt-3">
                                        <div className="d-flex flex-wrap gap-lg-2 gap-xl-2">
                                          <div>
                                            <Tag
                                              color="blue"
                                              className="fw-bold"
                                            >
                                              SKU : {item?.sku || "-"}
                                            </Tag>
                                          </div>
                                          <div className="">
                                            <Link target="_blank">
                                              <Tag
                                                color="gold"
                                                className="fw-bold"
                                                style={{
                                                  cursor: "pointer",
                                                  // textDecoration: "underline",
                                                }}
                                              >
                                                ASIN : {item?.asin || "-"}
                                              </Tag>
                                            </Link>
                                          </div>

                                          <div className="">
                                            <Tag
                                              color="purple"
                                              className="fw-bold"
                                              style={{
                                                cursor: "pointer",
                                                // textDecoration: "underline",
                                              }}
                                            >
                                              Item price : {sign}
                                              {parseFloat(
                                                item?.item_price || "0"
                                              ).toFixed(2)}
                                            </Tag>
                                          </div>

                                          <div className="">
                                            <Tag
                                              color="volcano"
                                              className="fw-bold"
                                            >
                                              COGS : {sign}
                                              {item?.cogs || "0"}
                                            </Tag>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-2 col-lg-2">
                                      <div className="d-flex flex-wrap align-items-center justify-content-end gap-lg-2 gap-xl-2">
                                        <span
                                          className=""
                                          onClick={() => {
                                            setBulkAction({
                                              show: true,
                                              type: "edit",
                                            });

                                            const resultObject = {
                                              sku: item.sku,
                                            };

                                            if (
                                              item.target_days_of_stock !== null
                                            ) {
                                              resultObject.target_days_of_stock =
                                                item.target_days_of_stock;
                                            }

                                            if (
                                              item.vendor_lead_time !== null
                                            ) {
                                              resultObject.vendor_lead_time =
                                                item.vendor_lead_time;
                                            }
                                            setSelectedValues({
                                              ...resultObject,
                                              target_stock_days:
                                                item.target_days_of_stock,
                                            });

                                            setSelectedRows([resultObject]);
                                          }}
                                        >
                                          <EditOutlined
                                            style={{
                                              fontSize: "20px",
                                              color: "grey",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>

                                        <span
                                          className="ms-3 "
                                          onClick={() => {
                                            setDetailsModal(true);
                                            setSelectedRow(item);
                                            setTypeDrawer("details");
                                          }}
                                        >
                                          <EyeOutlined
                                            style={{
                                              fontSize: "20px",
                                              color: "rgb(24, 144, 255)",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            />
                            <div className="row more-details">
                              <div
                                style={{
                                  borderBottom: "1px solid #80808038",
                                  borderRight: "1px solid #80808038",
                                }}
                                className="col-xxl-4 col-lg-6 col-md-12 d-flex justify-content-between   mb-3 pb-3"
                              >
                                <div>
                                  <label className=" fs-5">
                                    <Tooltip
                                      getPopupContainer={(target) =>
                                        target.parentNode
                                      }
                                      title="Current available inventory as per Amazon"
                                    >
                                      Available inventory :
                                    </Tooltip>
                                  </label>
                                </div>
                                <div>
                                  <span className="fs-5 text-dark">
                                    <span>
                                      {NumberWithCommas(
                                        item?.available_inventory || "0",
                                        ""
                                      )}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  borderBottom: "1px solid #80808038",
                                  borderRight: "1px solid #80808038",
                                }}
                                className="col-xxl-4 col-lg-6 col-md-12 d-flex justify-content-between   mb-3 pb-3"
                              >
                                <div>
                                  <label className=" fs-5">
                                    <Tooltip
                                      getPopupContainer={(target) =>
                                        target.parentNode
                                      }
                                      title="Previous day inventory as per Amazon"
                                    >
                                      Previous Day inventory :
                                    </Tooltip>
                                  </label>
                                </div>
                                <div>
                                  <span className="fs-5 text-dark">
                                    <span>
                                      {NumberWithCommas(
                                        item?.previous_available_inventory ||
                                          "0",
                                        ""
                                      )}
                                    </span>
                                  </span>
                                </div>
                              </div>

                              <div
                                style={{
                                  borderBottom: "1px solid #80808038",
                                  borderRight: "1px solid #80808038",
                                }}
                                className="col-xxl-4 col-lg-6 col-md-12 d-flex justify-content-between   mb-3 pb-3"
                              >
                                <div>
                                  <label className=" fs-5">
                                    <Tooltip
                                      getPopupContainer={(target) =>
                                        target.parentNode
                                      }
                                      title={
                                        "In last 7 days, how many days inventory was out of stock"
                                      }
                                    >
                                      Days of stock available in last{" "}
                                      <b>
                                        {filters?.days_for_the_forecast || 0}{" "}
                                        days
                                      </b>{" "}
                                      :
                                    </Tooltip>
                                  </label>
                                </div>
                                <div>
                                  <span className="fs-5 text-dark">
                                    <span>
                                      {NumberWithCommas(
                                        item?.days_of_stock_available_in_last_days ||
                                          "0",
                                        ""
                                      )}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  borderBottom: "1px solid #80808038",
                                  borderRight: "1px solid #80808038",
                                }}
                                className="col-xxl-4 col-lg-6 col-md-12 d-flex justify-content-between   mb-3 pb-3"
                              >
                                <div>
                                  <label className=" fs-5">
                                    <Tooltip
                                      getPopupContainer={(target) =>
                                        target.parentNode
                                      }
                                      title="Number of units sold in last 7 days"
                                    >
                                      Units sold in last 7 days{" "}
                                      <b>
                                        {filters?.days_for_the_forecast || 0}{" "}
                                        days
                                      </b>{" "}
                                      :
                                    </Tooltip>
                                  </label>
                                </div>
                                <div>
                                  <span className="fs-5 text-dark">
                                    <span>
                                      {NumberWithCommas(
                                        item?.units_sold_in_last_days || "0",
                                        ""
                                      )}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  borderBottom: "1px solid #80808038",
                                  borderRight: "1px solid #80808038",
                                }}
                                className="col-xxl-4 col-lg-6 col-md-12 d-flex justify-content-between   mb-3 pb-3"
                              >
                                <div>
                                  <label className=" fs-5">
                                    <Tooltip
                                      getPopupContainer={(target) =>
                                        target.parentNode
                                      }
                                      title="Units sold in last 7 days / Days of stock available in last 7 days"
                                    >
                                      Sales velocity :
                                    </Tooltip>
                                  </label>
                                </div>
                                <div>
                                  <span className="fs-5 text-dark">
                                    <span>
                                      {NumberWithCommas(
                                        item?.sales_velocity || "0",
                                        ""
                                      )}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  borderBottom: "1px solid #80808038",
                                  borderRight: "1px solid #80808038",
                                }}
                                className="col-xxl-4 col-lg-6 col-md-12 d-flex justify-content-between   mb-3 pb-3"
                              >
                                <div>
                                  <label className=" fs-5">
                                    <Tooltip
                                      getPopupContainer={(target) =>
                                        target.parentNode
                                      }
                                      title="Add Time taken by the Vendor to send Inventory"
                                    >
                                      Vendor Lead Time :
                                    </Tooltip>
                                  </label>
                                </div>
                                <div>
                                  <span className="fs-5 text-dark">
                                    <span>
                                      {NumberWithCommas(
                                        item?.vendor_lead_time || "0",
                                        ""
                                      )}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  borderBottom: "1px solid #80808038",
                                  borderRight: "1px solid #80808038",
                                }}
                                className="col-xxl-4 col-lg-6 col-md-12 d-flex justify-content-between   mb-3 pb-3"
                              >
                                <div>
                                  <label className=" fs-5">
                                    <Tooltip
                                      getPopupContainer={(target) =>
                                        target.parentNode
                                      }
                                      title="Sales Velocity * Vendor Lead Time"
                                    >
                                      Vendor Lead Units :
                                    </Tooltip>
                                  </label>
                                </div>
                                <div>
                                  <span class="fs-5 text-dark">
                                    <span>
                                      {NumberWithCommas(
                                        item?.vendor_lead_units || "0",
                                        ""
                                      )}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  borderBottom: "1px solid #80808038",
                                  borderRight: "1px solid #80808038",
                                }}
                                className="col-xxl-4 col-lg-6 col-md-12 d-flex justify-content-between   mb-3 pb-3"
                              >
                                <div>
                                  <label className=" fs-5">
                                    <Tooltip
                                      getPopupContainer={(target) =>
                                        target.parentNode
                                      }
                                      title={
                                        <>
                                          Manually added to check Future{" "}
                                          <b>7 days</b> Forecast
                                        </>
                                      }
                                    >
                                      Target days of stock :
                                    </Tooltip>
                                  </label>
                                </div>
                                <div>
                                  <span className="fs-5 text-dark">
                                    <span>
                                      {NumberWithCommas(
                                        item?.target_days_of_stock || "0",
                                        ""
                                      )}
                                      /Day
                                    </span>
                                  </span>
                                </div>
                              </div>
                              {/* <div
                                style={{
                                  borderBottom: "1px solid #80808038",
                                  borderRight: "1px solid #80808038",
                                }}
                                className="col-xxl-4 col-lg-6 col-md-12 d-flex justify-content-between   mb-3 pb-3"
                              >
                                <div>
                                  <label className=" fs-5">
                                    <Tooltip
                                      getPopupContainer={(target) =>
                                        target.parentNode
                                      }
                                      title="Select the number of days for which Forecast needs to calculated"
                                    >
                                      Target Days of Stock :
                                    </Tooltip>
                                  </label>
                                </div>
                                <div>
                                  <span class="fs-5 text-dark">
                                    <span>
                                      {NumberWithCommas(
                                        item?.forecast_days || "0",
                                        ""
                                      )}
                                      /Day
                                    </span>
                                  </span>
                                </div>
                              </div> */}
                              <div
                                style={{
                                  borderBottom: "1px solid #80808038",
                                  borderRight: "1px solid #80808038",
                                }}
                                className="col-xxl-4 col-lg-6 col-md-12 d-flex justify-content-between   mb-3 pb-3"
                              >
                                <div>
                                  <label className=" fs-5">
                                    <Tooltip
                                      getPopupContainer={(target) =>
                                        target.parentNode
                                      }
                                      title="Units required un till the next Order"
                                    >
                                      Target Units :
                                    </Tooltip>
                                  </label>
                                </div>
                                <div>
                                  <span className="fs-5 text-dark">
                                    <span>
                                      {NumberWithCommas(
                                        item?.forecast_units || "0",
                                        ""
                                      )}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <>
                                <div
                                  style={{
                                    borderBottom: "1px solid #80808038",
                                    borderRight: "1px solid #80808038",
                                  }}
                                  className="col-6 d-flex justify-content-evenly mb-3 pb-3"
                                >
                                  <div>
                                    <label className=" fs-5">
                                      <Tooltip
                                        getPopupContainer={(target) =>
                                          target.parentNode
                                        }
                                        title="Suggested Forecast Units calculated as Target Units + Vendor Lead Units"
                                      >
                                        Forecasted Suggested :
                                      </Tooltip>
                                    </label>
                                  </div>
                                  <div>
                                    <span className="fs-5 text-dark">
                                      <span>
                                        <Tag
                                          style={{
                                            fontSize: "18px",
                                            // width: "25px",
                                            height: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          color="blue-inverse"
                                        >
                                          {NumberWithCommas(
                                            item?.forecasted_suggested || "0",
                                            ""
                                          )}
                                        </Tag>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    borderBottom: "1px solid #80808038",
                                  }}
                                  className="col-6 d-flex justify-content-evenly mb-3 pb-3"
                                >
                                  <div>
                                    <label className=" fs-5">
                                      <Tooltip
                                        getPopupContainer={(target) =>
                                          target.parentNode
                                        }
                                        title="Units required to be there in order to remain In-Stock calculated as Forecast Suggested - Available Inventory"
                                      >
                                        Required Order :
                                      </Tooltip>
                                    </label>
                                  </div>
                                  <div>
                                    <span className="fs-5 text-dark">
                                      <span>
                                        <Tag
                                          color="cyan-inverse"
                                          style={{
                                            fontSize: "18px",
                                            // width: "25px",
                                            height: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {NumberWithCommas(
                                            item?.required_order || "0",
                                            ""
                                          )}
                                        </Tag>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </>
                            </div>
                          </List.Item>
                        )}
                      />
                    )}
                  </>
                </div>
                <div className="card-footer pt-2">
                  <Pagination
                    loading={loading || inventoryData?.length === 0}
                    pageSize={pageSize}
                    page={page}
                    totalPage={totalPage}
                    onPerPage={onPerPage}
                    onPageNo={onPageNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Drawer
          title={
            typeDrawer === "forcast" ? "Forcast Details" : "Inventory Details"
          }
          placement="right"
          onClose={() => setDetailsModal(false)}
          visible={detailsModal}
        >
          <div className="overlay-layer">
            <div>
              <div className="d-flex  align-items-center">
                <div className="symbol symbol-100px overflow-hidden me-5">
                  <img src={selectedRow?.image} className=" bg-light" />
                </div>
                <div className="d-flex flex-column">
                  <a className="text-gray-800 fs-6 text-hover-info mb-1 fw-bolder text-two min-w-200px">
                    {selectedRow?.title || "-"}
                  </a>
                </div>
              </div>
            </div>
            <div className="d-flex flex-center flex-wrap mt-5 mb-5">
              {/*begin::Stats*/}
              <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                <div className="fs-6 fw-bold text-gray-700">
                  {selectedRow?.sku || "-"}
                </div>
                <div className="fw-semibold text-gray-500">SKU</div>
              </div>
              {/*end::Stats*/}
              {/*begin::Stats*/}
              <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                <div className="fs-6 fw-bold text-gray-700">
                  {selectedRow?.asin || "-"}
                </div>
                <div className="fw-semibold text-gray-500">ASIN</div>
              </div>
              {/*end::Stats*/}
            </div>

            {DrawerView?.[typeDrawer]?.map((d, i) => (
              <>
                {i !== 0 && <div className="separator separator-dashed my-3" />}
                <div className="d-flex flex-stack">
                  <div className="text-gray-900 fw-bolder fs-6 me-2">
                    {d?.status}
                  </div>

                  <div className="d-flex align-items-senter">
                    <span className="text-gray-700  fw-semibold fs-6">
                      {selectedRow?.[d?.key] || 0}
                    </span>
                  </div>
                </div>
              </>
            ))}
          </div>
        </Drawer>
        <Modal
          title={
            bulkAction?.type === "edit" ? "Update Details" : bulkAction?.type
          }
          visible={bulkAction?.show}
          centered
          onOk={() => {
            const { sku, target_days_of_stock, ...rest } = selectedValues;
            const obj = {
              ...rest,

              skus: selectedRows?.map((d) => d?.sku)?.join(),
            };

            message.destroy();
            message.loading("Loading...", 0);

            ReplenishmentBulkUpdateAction({
              ...Object.entries(obj).reduce((acc, [key, value]) => {
                if (value !== null) {
                  acc[key] = value;
                }
                return acc;
              }, {}),
              sales_channel: marketplaceSelected?.sales_channel,
            });
          }}
          onCancel={() => {
            setSelectedValues({
              target_stock_days: null,
              vendor_lead_time: null,
            });
            setSelectedRows([]);
            setBulkAction({
              show: false,
              type: "",
            });
          }}
          width={700}
        >
          {console.log(bulkAction, selectedValues, "bulkAction")}
          {bulkAction?.type === "edit" && (
            <>
              <div className="row mt-10">
                <span className="col-6">Target Days Of Stock</span>
                <Input
                  placeholder="Target Days Of Stock"
                  className="col-6"
                  value={selectedValues?.target_stock_days}
                  maxLength={3}
                  onChange={(e) => {
                    const newValue = e.target.value?.replace(/[^0-9]/g, "");
                    if (/^\d*$/.test(newValue)) {
                      setSelectedValues({
                        ...selectedValues,
                        target_stock_days: newValue,
                      });
                    }
                  }}
                />
              </div>

              <div className="row mt-5">
                <span className="col-6">Vendor Lead Time</span>
                <Input
                  value={selectedValues?.vendor_lead_time}
                  maxLength={3}
                  onChange={(e) => {
                    const newValue = e.target.value?.replace(/[^0-9]/g, "");
                    if (/^\d*$/.test(newValue)) {
                      setSelectedValues({
                        ...selectedValues,
                        vendor_lead_time: newValue,
                      });
                    }
                  }}
                  className="col-6"
                  placeholder="Shipping to FBA (days)"
                />
              </div>
            </>
          )}
          {bulkAction?.type === "Add Lead Time" ? (
            <>
              <div className="row mt-5 mb-10">
                <span className="col-6">Vendor Lead Time</span>
                <Input
                  value={selectedValues?.vendor_lead_time}
                  maxLength={3}
                  onChange={(e) => {
                    const newValue = e.target.value?.replace(/[^0-9]/g, "");
                    if (/^\d*$/.test(newValue)) {
                      setSelectedValues({
                        ...selectedValues,
                        vendor_lead_time: newValue,
                      });
                    }
                  }}
                  className="col-6"
                  placeholder="Vendor Lead Time"
                />
              </div>
            </>
          ) : (
            bulkAction?.type !== "edit" && (
              <>
                <div className="row mt-10">
                  <span className="col-6">Target Days Of Stock</span>
                  <Input
                    placeholder="Target Days Of Stock"
                    className="col-6"
                    maxLength={3}
                    value={selectedValues?.target_stock_days}
                    onChange={(e) => {
                      const newValue = e.target.value?.replace(/[^0-9]/g, "");
                      if (/^\d*$/.test(newValue)) {
                        setSelectedValues({
                          ...selectedValues,
                          target_stock_days: newValue,
                        });
                      }
                    }}
                  />
                </div>
              </>
            )
          )}
        </Modal>
      </>
    </Wrapper>
  );
}
