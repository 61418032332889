import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Select, message } from "antd";
import { useSelector } from "react-redux";

const Create = ({
  isOpen,
  onClose,
  AdminAddAction,
  fakeActionManageUser,
  GetUserListAction,
  selectedRow,
  UserUpdateAction,
}) => {
  const UserListResponse = useSelector(
    (state) => state.User.UserListResponse || {}
  );

  const [adminList, setAdminList] = useState([]);
  const onFinish = (values) => {
    message.destroy();
    message.loading("Loading...", 0);
    if (Object?.keys(selectedRow)?.length === 0) {
      AdminAddAction({
        ...values,
        assign_user_id: values?.assign_user_id?.join(","),
        rds_credential_id: 1,
        server_credential_id: 1,
      });
    } else {
      UserUpdateAction(selectedRow?.u_id, {
        ...values,
        assign_user_id: values?.assign_user_id?.join(","),
        rds_credential_id: 1,
        server_credential_id: 1,
      });
    }

    // onClose();
  };

  useEffect(() => {
    if (UserListResponse?.status === true) {
      setAdminList(
        UserListResponse?.data?.records?.map((d) => ({
          label: d?.u_name,
          value: d?.u_id,
        }))
      );
      fakeActionManageUser("UserListResponse");
    } else if (UserListResponse?.status === false) {
      fakeActionManageUser("UserListResponse");
    }
  }, [UserListResponse]);

  useEffect(() => {
    GetUserListAction();
    return () => {};
  }, []);
  const phoneRegex = /^\d{10}$/;

  return (
    <Modal
      title={
        Object?.keys(selectedRow)?.length === 0 ? "Add Admin" : "Update Admin"
      }
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <Form
        initialValues={{
          rds_credential_id: 1,
          server_credential_id: 1,
          ...selectedRow,
          assign_user_id: selectedRow?.users?.map((d) => parseInt(d?.user_id)),
        }}
        name="basic"
        onFinish={onFinish}
        layout="vertical"
      >
        <div>
          <div className="row">
            <div className="col-6">
              <Form.Item
                label="User Name"
                name="u_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your user name!",
                  },
                ]}
              >
                <Input autoComplete="new-password" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item
                label="User/Client"
                name="assign_user_id"
                rules={[
                  { required: true, message: "Please select User/Client!" },
                ]}
              >
                <Select
                  mode={"multiple"}
                  maxTagCount="responsive"
                  options={adminList}
                  // value={[59]}
                  getPopupContainer={(target) => target.parentNode}
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item
            label="Email"
            name="u_email"
            rules={[
              {
                required: true,

                message: "Please input your email!",
              },
              {
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {Object?.keys(selectedRow)?.length === 0 && (
            <>
              <Form.Item
                label="Password"
                name="u_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters",
                  },
                ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="u_confirm_password"
                dependencies={["u_password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("u_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("The two passwords do not match!");
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
          )}

          <Form.Item
            rules={[
              {
                pattern: phoneRegex,
                message: "Please enter a valid phone number!",
              },
            ]}
            label="Contact Number"
            name="u_contact_no"
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{ display: "flex", justifyContent: "end" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Create;
