import axiosCall from "../../configurations/network-services/axiosCall";

export const GetSellerPerfoemanceAction = () => {
  const path = `seller-performance?marketplace_id=`;
  const responseType = "SELLER_PERFORMANCE_BY";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SellerPerdormanceByMarketplaceAction = (id) => {
  const path = `seller-performance-by-marketplace?marketplace_id=${id}`;
  const responseType = "SELLER_PERFORMANCE_BY_MARKETPLACE";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAccountStatus = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ACCOUNT_STATUS", state: data });
};
