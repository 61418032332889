export const vatSettingSchema = [
  { label: "Not Applicable", value: "Not_Applicable" },
  { label: "Not Registered", value: "Not_Registered" },
  { label: "Standard Rate", value: "Standard_Rate" },
  { label: "Flat Rate", value: "Flat_Rate" },
];
export const ifBuyBoxWinner = [
  { label: "Normal: reprice up and down", value: "normal_reprice_up_and_down" },
  {
    label: "Upward: if a price change is required, only reprice up",
    value: "upward_if_a_price_change_is_required_only_reprice_up",
  },
  { label: "Stop: do not reprice", value: "stop_do_not_reprice" },
  {
    label: "Optimize Profit: proactively reprice upward, to optimize profit",
    value: "optimize_profit_proactively_reprice_upward,_to_optimize_profit",
  },
];
export const ifBuyBoxisSuppressed = [
  { label: "Normal: reprice up and down", value: "normal_reprice_up_and_down" },
  { label: "Do not reprice", value: "do_not_reprice" },
  {
    label: "Change my price to my minimum",
    value: "change_my_price_to_my_minimum",
  },
  {
    label: "Change my price to my maximum",
    value: "change_my_price_to_my_maximum",
  },
];
export const ifNoCompetitors = [
  {
    label: "Change my price to my highest allowed price",
    value: "change_my_price_to_my_highest_allowed_price",
  },
  { label: "Do not reprice", value: "do_not_reprice" },
  // {
  //   label: "Do not reprice unless outside of minimum and maximum price bounds",
  //   value: 'do_not_reprice_unless_outside_of_minimum_and_maximum_price_bounds,
  // },
  {
    label: "Change my price to my minimum allowed price",
    value: "change_my_price_to_my_minimum_allowed_price",
  },
];
export const ifCompetitionisBelowMinPrice = [
  {
    label: "Change my price to my minimum allowed price",
    value: "change_my_price_to_my_minimum_allowed_price",
  },
  { label: "Do not reprice", value: "do_not_reprice" },
  {
    label: "Change my price to my highest allowed price",
    value: "change_my_price_to_my_highest_allowed_price",
  },
  {
    label: "Compete with Next Best Seller",
    value: "compete_with_next_best_seller",
  },
];
export const ifCompetitionisMatchMinPrice = [
  {
    label: "Change my price to my minimum allowed price",
    value: "change_my_price_to_my_minimum_allowed_price",
  },
  {
    label: "Change my price to my highest allowed price",
    value: "change_my_price_to_my_highest_allowed_price",
  },
  {
    label: "Compete with Next Best Seller",
    value: "compete_with_next_best_seller",
  },
];
export const buyBoxWinnerRuleF = [
  { label: "It doesn't matter", value: "it_doesn't_matter" },
  { label: "We are the Buy Box Winner", value: "we_are_the_buy_box_winner" },
  {
    label: "We are not the Buy Box Winner",
    value: "we_are_not_the_buy_box_winner",
  },
];
export const buyBoxWinnerRuleS = [
  { label: "It doesn't matter", value: "it_doesn't_matter" },
  {
    label: "Our competition is the Buy Box Winner",
    value: "our_competition_is_the_buy_box_winner",
  },
  {
    label: "Our competition is not the Buy Box Winner",
    value: "our_competition_is_not_the_buy_box_winner",
  },
];
export const FulfilmentRuleF = [
  { label: "We are using FBM", value: "we_are_using_fbm" },
  { label: "We are using FBA", value: "we_are_using_fba" },
  { label: "It doesn't matter", value: "it_doesn't_matter" },
];
export const FulfilmentRuleS = [
  {
    label: "Our competitor is using FBM",
    value: "our_competitor_is_using_fbm",
  },
  {
    label: "Our competitor is using FBA",
    value: "our_competitor_is_using_fba",
  },
  { label: "It doesn't matter", value: "it_doesn't_matter" },
];
export const PrimeRuleF = [
  { label: "It doesn't matter", value: "it_doesn't_matter" },
  { label: "We are Prime", value: "we_are_prime" },
  { label: "We are not Prime", value: "we_are_not_prime" },
];
export const PrimeRuleS = [
  { label: "It doesn't matter", value: "it_doesn't_matter" },
  { label: "Our competitor is Prime", value: "our_competitor_is_prime" },
  {
    label: "Our competitor is NOT Prime",
    value: "our_competitor_is_not_prime",
  },
];

export const CompetitorStockRuleF = [
  { label: "It doesn't matter", value: "it_doesn't_matter" },
  { label: "Our competitor is in stock", value: "our_competitor_is_in_stock" },
  {
    label: "Our competitor is out of stock",
    value: "our_competitor_is_out_of_stock",
  },
];
export const CompetitorStockRuleS = [
  { label: "It doesn't matter", value: "it_doesn't_matter" },
  { label: "Our competitor is domestic", value: "our_competitor_is_domestic" },
  {
    label: "Our competitor is not domestic",
    value: "our_competitor_is_not_domestic",
  },
];
export const LastSaleF = [
  { label: "More than", value: "more_than" },
  { label: "Less than", value: "less_than" },
  { label: "No Data", value: "no_data" },
];
export const LastSaleS = [
  { label: "It doesn't matter", value: "it_doesn't_matter" },
  { label: "Hours", value: "hours" },
  { label: "Days", value: "days" },
  { label: "Weeks", value: "weeks" },
  { label: "Months", value: "months" },
];
export const FreeShippingRuleF = [
  { label: "It doesn't matter", value: "it_doesn't_matter" },
  { label: "We are free shipping", value: "we_are_free_shipping" },
  { label: "We are not free shipping", value: "we_are_not_free_shipping" },
];
export const FreeShippingRuleS = [
  { label: "It doesn't matter", value: "it_doesn't_matter" },
  {
    label: "Our competitor is free shipping",
    value: "our_competitor_is_free_shipping",
  },
  {
    label: "Our competitor is NOT free shipping",
    value: "our_competitor_is_not_free_shipping",
  },
];

export const Marketplaces = [
  { label: "Amazon.com", value: "ATVPDKIKX0DER" },
  { label: "Amazon.co.uk", value: "A1F83G8C2ARO7P" },
  { label: "Amazon.de", value: "A1PA6795UKMFR9" },
];
export const Merchants = [
  { label: "Include all", value: "include_all" },
  { label: "Include the following", value: "include_the_following" },
  { label: "Ignore the following", value: "ignore_the_following" },
];
export const salesRule = [
  { label: "Default 2022-06-20 02:06", value: "1459" },
  { label: "Default 2022-07-19 05:07", value: "1469" },
  { label: "Default 2022-07-19 09:07", value: "1470" },
  { label: "Default 2022-07-25 12:07", value: "1471" },
  { label: "Default 2022-12-30 07:12", value: "1473" },
];
export const usedListings = [
  {
    label: "Used products compete equally against all other used listings",
    value: "used_products_compete_equally_against_all_other_used_listings",
  },
  {
    label:
      "Used products only compete against equal or better quality used listings",
    value:
      "used_products_only_compete_against_equal_or_better_quality_used_listings",
  },
  {
    label: "Used products only compete against equal quality used listings",
    value: "used_products_only_compete_against_equal_quality_used_listings",
  },
];
export const defaultRuleFallback = [
  {
    label:
      "If none of my competitor rules have been matched, always fallback to the default repricing rule",
    value:
      "if_none_of_my_competitor_rules_have_been_matched_always_fallback_to_the_default_repricing_rule",
  },
  {
    label:
      "If none of my competitor rules have been matched, never fallback to the default repricing rule",
    value:
      "if_none_of_my_competitor_rules_have_been_matched_never_fallback_to_the_default_repricing_rule",
  },
];
export const competitorPricingRule = [
  { label: "Above", value: 1 },
  { label: "Below", value: 2 },
  { label: "Match", value: 3 },
];
export const vatSettingOps = [
  { label: "Not Registered", value: "not_registered" },
  { label: "Standard Rate", value: "standard_rate" },
  { label: "Flat Rate", value: "flat_rate" },
];

export const adminCentralLogEventName = [
  {
    label: "Get Product Details",
    value: "Get Product Details",
  },
  {
    label: "Get Best Seller Rank",
    value: "Get Best Seller Rank",
  },
];

export const adminCentralLogEventStatus = [
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    label: "DONE",
    value: "DONE",
  },
  {
    label: "FAILED",
    value: "FAILED",
  },
  {
    label: "FATAL",
    value: "FATAL",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "NOT_FOUND",
    value: "NOT_FOUND",
  },
];

export const userCentralLogEventType = [
  {
    label: "Financial API",
    value: "Financial API",
  },
  {
    label: "SP API Report",
    value: "SP API Report",
  },
  {
    label: "Brand Wise",
    value: "Brand Wise",
  },
  { label: "Sku Data", value: "Sku Data" },
  {
    label: "SKU Wise",
    value: "SKU Wise",
  },
  {
    label: "Repricing Product",
    value: "Repricing Product",
  },
  {
    label: "Sync Data",
    value: "SYNC_DATA",
  },
];

export const userCentralLogEventStatus = [
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    label: "DONE",
    value: "DONE",
  },
  {
    label: "FAILED",
    value: "FAILED",
  },
  {
    label: "FATAL",
    value: "FATAL",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "NOT_FOUND",
    value: "NOT_FOUND",
  },
];

export const adminCategoryStatus = [
  {
    label: "Active",
    value: "1",
  },
  {
    label: "InActive",
    value: "0",
  },
];

export const viewPaymentsSellerAdmin = [
  {
    label: "Back Pack",
    value: 1,
  },
  {
    label: "12 Pack Bandanas",
    value: 2,
  },
  {
    label: "12 Pack Patterned Masks",
    value: 3,
  },
];

export const viewPaymentsSubscriptionPlan = [
  {
    label: "Back Pack",
    value: 1,
  },
  {
    label: "12 Pack Bandanas",
    value: 2,
  },
  {
    label: "12 Pack Patterned Masks",
    value: 3,
  },
];

export const brandRevenueListSortBy = [
  { label: "High to Low", value: "desc" },
  { label: "Low to High", value: "asc" },
];

export const brandRevenueListColor = [
  { label: "Red", value: 4 },
  { label: "Orange", value: 3 },
  { label: "Green", value: 2 },
  { label: "Gold", value: 1 },
];

export const competitorResearchVariationAsin = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

export const skuDataOtherSelectOption = [
  { value: "ba", label: "Brands assigned" },
  { value: "bna", label: "Brands not assigned" },
  { value: "ca", label: "COGS added" },
  { value: "cna", label: "COGS not added" },
  {
    value: "so-bna",
    label: "SKU in Order but Brand not assigned",
  },
  {
    value: "so-cna",
    label: "SKU in Order but COGS not assigned",
  },
  { value: "sku-active", label: "SKU Active" },
  { value: "sku-inactive", label: "SKU Inactive" },
];

export const productResearchColor = [
  { label: "Red", value: "Red" },
  { label: "Orange", value: "Orange" },
  { label: "Green", value: "Green" },
  { label: "Gold", value: "Gold" },
];

export const createRepricingRule = [
  { label: "Above", value: "above" },
  { label: "Below", value: "below" },
  { label: "Match", value: "match" },
];

export const ifBuyBoxIsSuppressedAction = [
  { label: "Increase", value: "increase" },
  { label: "Decrease", value: "decrease" },
];

export const repricingAmtPer = [
  { label: "Amount", value: "amount" },
  { label: "Percent", value: "percent" },
];

export const productRepricingStatus = [
  { label: "All", value: "all" },
  {
    label: "Product is repricing",
    value: "pr",
  },
  {
    label: "Product is not repricing",
    value: "pnr",
  },
];

export const productBuyBoxStatus = [
  {
    value: "ownbuybox",
    label: (
      //   <Checkbox checked={selectedBuyBoxStatus.includes("1")}>
      <>
        <i className="fas fa-shield-alt me-2 fs-5 text-success" />
        You own the Buy Box
      </>
      //   </Checkbox>
    ),
  },
  {
    value: "ownedbyamazon",
    label: (
      // <Checkbox checked={selectedBuyBoxStatus.includes("2")}>
      <>
        <i className="fab fa-amazon me-2 fs-5 text-dark" />
        Buy Box is owned by Amazon
      </>
      // </Checkbox>
    ),
  },
  {
    value: "nobuybox",
    label: (
      // <Checkbox checked={selectedBuyBoxStatus.includes("3")}>
      <>
        <i className="fas fa-minus-circle me-2 fs-5 text-warning" />
        No Buy Box available
      </>
      // </Checkbox>
    ),
  },
  {
    value: "ownedbycompetitor",
    label: (
      // <Checkbox checked={selectedBuyBoxStatus.includes("5")}>
      <>
        <i className="fas fa-archive ms-1 me-2 fs-5 text-danger" />
        Buy Box is owned by a competitor
      </>
      // </Checkbox>
    ),
  },
  // {
  //   value: "4",
  //   label: (
  //     // <Checkbox checked={selectedBuyBoxStatus.includes("4")}>
  //     <>
  //       {" "}
  //       <i className="fas fa-archive me-2 fs-5 text-danger" />
  //       Lost Buy Box in last 24 hours
  //     </>
  //     // </Checkbox>
  //   ),
  // },
];

export const wholesaleSupplierDirectoryType = [
  { label: "Brand", value: "Brand" },
  { label: "Supplier", value: "Supplier" },
  // { label: "Traders", value: "Traders" },
];

export const inventoryType = [
  { label: "Available", value: "available" },
  { label: "Inbound", value: "inbound" },
  { label: "Unfulfillable", value: "unfulfillable" },
  { label: "Reserching", value: "reserching" },
  { label: "Reserved", value: "reserved" },
  { label: "Warehouse", value: "warehouse" },
  { label: "Total", value: "total" },
];

export const reservedInventoryType = [
  { label: "Transfer", value: "transfer" },
  { label: "Processing", value: "processing" },
  { label: "Customer Order", value: "customer_order" },
  { label: "Reserved", value: "reserved" },
];

export const ClsPriority = [
  {
    label: (
      <div className="d-flex align-items-center">
        <div
          className="priorityColorDot me-2"
          style={{ background: "#ff0000" }}
        ></div>
        High
      </div>
    ),
    value: "1",
  },
  {
    label: (
      <div className="d-flex align-items-center">
        <div
          className="priorityColorDot me-2"
          style={{ background: "#faad14" }}
        ></div>
        Medium
      </div>
    ),
    value: "2",
  },
  {
    label: (
      <div className="d-flex align-items-center">
        <div
          className="priorityColorDot me-2"
          style={{ background: "#52c41a" }}
        ></div>
        Low
      </div>
    ),
    value: "3",
  },
];

export const SpApiReportList = [
  { label: "Orders Report", value: "orders_report" },
  { label: "Return Orders", value: "return_orders" },
  { label: "Archived Inventory", value: "archived_inventory" },
  { label: "Finance Data", value: "finance_data" },
  { label: "Reserved Inventory", value: "reserved_inventory" },
  { label: "Planning Inventory", value: "planning_inventory" },
  { label: "Stranded Inventory", value: "stranded_inventory" },
  { label: "Product Data", value: "product_data" },
  { label: "Reimbursements Data", value: "reimbursements_data" },
];

export const SalesRankDropType = [
  {
    value: "drop_last_30_days",
    label: "For Last 30 Days",
  },
  {
    value: "drop_last_90_days",
    label: "For Last 90 Days",
  },
];

export const reviewAnalysisEmailStatus = [
  {
    value: "shipped",
    label: "Shipped",
  },
  {
    value: "ordered",
    label: "Ordered",
  },
  {
    value: "delivered",
    label: "Delivered",
  },
];

export const reviewAnalysisExcludeOrders = [
  {
    label: "1 filters selected",
    value: "1f",
  },
  {
    label: "2 filters selected",
    value: "2f",
  },
  {
    label: "3 filters selected",
    value: "3f",
  },
];
export const reviewAnalysisSendEmails = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const reviewAnalysisDayRange = [
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
];
