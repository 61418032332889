import { Input, message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import ASINLink from "../../../components/router-link";
import { defaultMenus, testArr } from "../../../config";
import AuthWrapper from "./style";
import { LoginResponse } from "../../../redux/fake";
import { GlobalContext } from "../../../commonContext";

const adminMenus = ["manage-user", "setting", "manage-admin", "manage-agency"];
const userMenus = [
  "analytics/overview",
  "analytics",
  "account-status",
  "vendor-order",
  "reports",
  "inventory",
  "brand-analytics",
  "amazon-catalog-health",
  "catalog",
  "finance",
  "customer-sentiment",
  "campaign-management",
  "brand-protection",
  "vendor",
  "advertising-analytics",
  "agency",
  "client-data",
  "setting",
  "application-logs",
];
function Login(props) {
  const focusFirst = useRef();
  const { loginAction, fakeActionAuth } = props;

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [sending, setSending] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [moduleName, setModuleName] = useState("");

  const [moveMenus, setMoveMenus] = useState("");

  const LoginRes = useSelector((state) => state.Auth.LoginResponse || {});
  const contextValue = useContext(GlobalContext);

  useEffect(() => {
    focusFirst?.current?.focus();
    return () => {};
  }, []);

  const onLogin = (e) => {
    // e.preventDefault();
    if (username && password) {
      setSending(true);
      let body = {
        email: username,
        password,
      };
      loginAction(body);
    }
  };

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      const { token, user, userType, plan, menusList } = e?.storageArea;
      if (token && user && userType && plan && menusList) {
        setModuleName(JSON.parse(menusList)?.[0]);
        setRedirect(true);
      }
    });
    return () => {
      window.removeEventListener("storage", (e) => {});
    };
  }, []);

  const menuRedirect = {
    1: "/manage-user",
    2: "/",
    3: "/manage-user",
  };

  useEffect(() => {
    if (Object.keys(LoginRes).length !== 0 && LoginRes?.status) {
      setSending(false);
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...LoginRes?.data?.user_data,
          credentials: LoginRes?.data?.credentials,
        })
      );
      contextValue?.updateCommonGlobalVal({
        user_: {
          ...LoginRes?.data?.user_data,
          credentials: LoginRes?.data?.credentials,
        },
        admin: LoginRes?.data?.user_data?.u_type === 1 ? false : false,
        account_type: LoginRes?.data?.account_type || 1,
      });
      localStorage.setItem("token", LoginRes?.data?.auth_token);
      localStorage.setItem("userType", LoginRes?.data?.user_data?.u_type);
      localStorage.setItem(
        "role_configuration",
        JSON.stringify(LoginRes?.data?.role_configuration)
      );
      localStorage.setItem(
        "menus",
        LoginRes?.data?.user_data?.u_type === 1
          ? JSON.stringify(adminMenus)
          : JSON.stringify(userMenus)
      );

      // setMoveMenus(menuRedirect?.[LoginRes?.data?.user_data?.u_type]);

      setModuleName(
        LoginRes?.data?.user_data?.u_type === 1
          ? adminMenus?.[0]
          : userMenus?.[0]
      );
      message.destroy();
      message.success(LoginRes?.message);
      setRedirect(true);
      fakeActionAuth("LoginResponse");
      window.location.assign(menuRedirect?.[LoginRes?.data?.user_data?.u_type]);
    } else if (Object.keys(LoginRes).length !== 0) {
      setSending(false);
      message.destroy();
      message.error(LoginRes?.message);
      fakeActionAuth("LoginResponse");
    }
  }, [LoginRes]);

  if (localStorage.getItem("userType") || redirect) {
    return <Redirect to={moveMenus || "/pricing-plans"} />;
  }
  document.title = "Sign In";

  return (
    <AuthWrapper style={{ height: "100%", overflow: "auto" }}>
      <div className="row" style={{ height: "100%", marginRight: "0px" }}>
        <div
          id="div1"
          style={{ background: "linear-gradient(45deg, #775a90, #c36d5d)" }}
          className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6  flex-column d-flex"
        >
          <div className="d-flex flex-row-fluid flex-column text-center p-10">
            <span className="cursor-pointer py-9 mb-5 mt-20 d-flex justify-content-center">
              <img
                alt="Logo"
                src="/media/logowhite.png"
                loading="lazy"
                className="h-70px  shimmer"
                onError={(e) => {
                  e.target.src =
                    "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
                }}
              />
            </span>
            <h1
              className="fs-2qx pb-5 pb-md-4 fw-normal"
              style={{ color: "#FFF" }}
            >
              Welcome to MARKET <b className="fw-boldest">FORCE</b>
            </h1>
            <p className="fw-normal fs-3" style={{ color: "#FFF" }}>
              The first software that constantly verifies the integrity of your
              Amazon business for you.
            </p>
          </div>
          <div className="authFadeInTop d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
            <img
              src="https://www.getmarketforce.it/wp-content/uploads/2022/06/grafica-principale.svg"
              alt="login-image.svg"
              loading="lazy"
              className="mx-auto"
              style={{ width: "60%" }}
              onError={(e) => {
                e.target.src =
                  "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
              }}
            />
          </div>
        </div>
        <div
          id="div2 "
          style={{ background: "#fff", height: "auto", overflow: "auto" }}
          className=" authFadeInBottom d-flex flex-column col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
        >
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div className="w-lg-500px  mx-auto animated">
                <form
                  className="form w-100"
                  noValidate="novalidate"
                  id="kt_sign_in_form"
                >
                  <div className="text-center mb-10">
                    <h1 className="text-dark fw-bold mb-3">
                      Sign In to <b className="fw-boldest">MARKET FORCE</b>
                    </h1>
                    <div className="text-gray-400 fw-bold fs-4">
                      New Here?{" "}
                      <Link
                        to="/create-account"
                        className="link-primary fw-bolder"
                      >
                        Create an Account
                      </Link>
                    </div>
                  </div>
                  <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Email
                    </label>
                    <Input
                      placeholder="Enter Email"
                      type="email"
                      size="large"
                      autoFocus
                      ref={focusFirst}
                      value={username}
                      autoComplete="new-password"
                      onChange={(e) => setUserName(e.target.value)}
                      disabled={sending}
                    />
                  </div>
                  <div className="fv-row mb-10">
                    <div className="d-flex flex-stack mb-2">
                      <label className="form-label fw-bolder text-dark fs-6 mb-0">
                        Password
                      </label>
                      <ASINLink
                        id={Math.random()}
                        to={{ pathname: "/forgot-password" }}
                        className="link-primary fs-6 fw-bolder"
                      >
                        Forgot Password ?
                      </ASINLink>
                    </div>
                    <Input.Password
                      placeholder="Enter Password"
                      size="large"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={sending}
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={username && password && !sending ? false : true}
                      className="btn btn-lg btn-primary w-100 mb-5"
                      onClick={onLogin}
                    >
                      {sending ? (
                        <span>
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      ) : (
                        <span className="indicator-label">Login</span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
              <div className="d-flex flex-center fw-bold fs-6">
                <Link className="text-muted text-hover-primary px-2">
                  About
                </Link>
                <Link className="text-muted text-hover-primary px-2">
                  Support
                </Link>
                <Link className="text-muted text-hover-primary px-2">
                  Purchase
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
}

export default Login;
