import { GetCompetitorListResponse } from "../../fake";

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_COMPETITOR_LIST_SUCCESS":
    case "GET_COMPETITOR_LIST_ERROR":
      return {
        ...state,
        GetCompetitorListResponse: GetCompetitorListResponse,
      };
    case "GET_COMPETITOR_SELLER_LIST_SUCCESS":
    case "GET_COMPETITOR_SELLER_LIST_ERROR":
      return {
        ...state,
        GetSellerListResponse: action.updatePayload,
      };

    case "FAKE_ACTION_COMPETITOR":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
