import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VendorOrder from "../../modules/pages/vendor-order";

import {
  SalesChannelListAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";
import {
  GetVendorOrderStatusAction,
  fakeActionAnalytics,
  GetVendorOrderListAction,
} from "../../redux/module_/analytics/index.action";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,

  GetVendorOrderStatusResponse: state.Analytics.GetVendorOrderStatusResponse,
  GetVendorOrderListResponse: state.Analytics.GetVendorOrderListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      fakeActionCatalog,

      GetVendorOrderStatusAction,
      GetVendorOrderListAction,
      fakeActionAnalytics,
    },
    dispatch
  );

const VendorOrder____ = connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorOrder);

export default VendorOrder____;
