import { Avatar, Divider, List, Rate, Skeleton, Tag } from "antd";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

const CompetitorResearch = () => {
  const columns_ = [
    {
      title: "CategoryId",
      dataIndex: "categoryId",
      align: "left",
      width: 120,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "CategoryName",
      dataIndex: "categoryName",
      align: "left",
      width: 180,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "Color",
      dataIndex: "color",
      align: "left",
      width: 120,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "ItemWeight",
      dataIndex: "itemWeight",
      align: "left",
      width: 120,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "ListPrice",
      dataIndex: "listPrice",
      align: "left",
      width: 120,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "FBM",
      dataIndex: "fbm",
      align: "left",
      width: 80,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "FBA",
      dataIndex: "fba",
      align: "left",
      width: 80,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "ParentAsin",
      dataIndex: "parentAsin",
      align: "left",
      width: 150,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "ProductPartNumber",
      dataIndex: "productPartNumber",
      align: "left",
      width: 180,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "ReviewsCount",
      dataIndex: "reviewsCount",
      align: "left",
      width: 120,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "RootCategory",
      dataIndex: "rootCategory",
      align: "left",
      width: 120,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      align: "left",
      width: 120,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      align: "left",
      width: 180,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "Variation ASINs",
      dataIndex: "variationAsins",
      align: "left",
      width: 180,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "Sales Rank",
      dataIndex: "salesRank",
      align: "left",
      width: 120,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "ReviewsRating",
      dataIndex: "reviewsRating",
      align: "left",
      width: 120,
      render: (text) => {
        const value = 0;
        return <span>{value}</span>;
      },
    },
  ];
  const generateRandomLightColor = () => {
    // Generate random RGB values that are biased towards lighter shades
    const r = Math.floor(Math.random() * 128) + 128; // Red component
    const g = Math.floor(Math.random() * 128) + 128; // Green component
    const b = Math.floor(Math.random() * 128) + 128; // Blue component

    // Convert RGB values to hexadecimal
    const hexColor = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;

    return hexColor;
  };
  const [data, setData] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const loadMoreData = () => {
    if (loadingList) {
      return;
    }
    setLoadingList(true);
    fetch(
      "https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo"
    )
      .then((res) => res.json())
      .then((body) => {
        setData([...data, ...body.results]);
        setLoadingList(false);
      })
      .catch(() => {
        setLoadingList(false);
      });
  };
  useEffect(() => {
    loadMoreData();
  }, []);
  return (
    <div
      className="fadeInBottom content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <div className="card">
          <div className="card-header">
            <div className="card-title">Competitor Research</div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div
                id="scrollableDiv"
                style={{
                  height: "650px",
                  overflow: "auto",
                  padding: "0 10px",
                }}
              >
                <InfiniteScroll
                  dataLength={data.length}
                  next={loadMoreData}
                  hasMore={data.length < 50}
                  loader={
                    <Skeleton
                      avatar
                      paragraph={{
                        rows: 1,
                      }}
                      active
                    />
                  }
                  endMessage={
                    <Divider plain>It is all, nothing more 🤐</Divider>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <List
                    dataSource={data}
                    renderItem={(item) => (
                      <List.Item key={item.email}>
                        <List.Item.Meta
                          avatar={
                            <>
                              <Avatar
                                size={130}
                                shape="square"
                                src={"https://via.placeholder.com/100"}
                              />
                              <div
                                className="my-2 mx-1"
                                style={{ width: "fit-content" }}
                              >
                                <div
                                  style={{
                                    display: "grid",
                                    justifyItems: "center",
                                  }}
                                >
                                  <Rate
                                    allowHalf
                                    disabled
                                    style={{ fontSize: "14px" }}
                                    value={parseFloat(3.5)}
                                  />
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      color: "#7e78ef",
                                    }}
                                  >
                                    {(6, 581 || 0)}&nbsp; reviews
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                          title={
                            <div>
                              Londa Professional Fiber Infusion Treatment, 100
                              ml, 8005610685168
                            </div>
                          }
                          description={
                            <div className="gap-2">
                              <Tag className="my-2 mx-1" color="magenta">
                                SKU&nbsp;:&nbsp;S1_dlm_sch_807
                              </Tag>
                              <Tag className="my-2 mx-1" color="cyan">
                                Brand&nbsp;:&nbsp;LONDA dlm 021
                              </Tag>
                              <Tag className="my-2 mx-1" color="blue">
                                ASIN&nbsp;:&nbsp;B0JDN4I3SH4N
                              </Tag>
                            </div>
                          }
                        />
                        <div className="col-8">
                          <div
                            className="row mx-0 gridScroll"
                            style={{ height: "190px", overflow: "auto" }}
                          >
                            {columns_?.map((d, j) => {
                              return (
                                <div
                                  className="col-4 d-flex align-items-center justify-content-between"
                                  style={{
                                    borderRight:
                                      j === columns_?.length - 1
                                        ? "1px solid #80808000"
                                        : `3px solid ${generateRandomLightColor()}`,
                                  }}
                                >
                                  <label
                                    className="fs-5"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {d?.title}&nbsp;:&nbsp;
                                  </label>
                                  <span>{d?.render({})}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitorResearch;
