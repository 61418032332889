import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-tabs-tab-active {
    color: #ffffff important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 8px !important;
    background: #4169e1 !important;
    .ant-tabs-tab-btn {
      color: #ffffff !important;
    }
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid rgb(5 5 5 / 0%) !important;
  }
  .ant-tabs-tab {
    border-radius: 8px !important;
  }
  .product_description {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    // height: 29px;
    margin: 0 auto;
    font-size: 14px;
    // line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
