import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import ASINLink from "../../../../components/router-link";
import Wrapper from "./style";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 16,
    },
    sm: {
      span: 16,
    },
  },
};

export default function (props) {
  const { fakeActionAuth, signUpAction, checkEmailAction } = props;
  const [form] = Form.useForm();

  const [submit, setSubmit] = useState(false);
  const [existing, setExisting] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const SignUpRes = useSelector((state) => state.Auth.SignUpResponse || {});
  const CreateUserCheckEmailResponse = useSelector(
    (state) => state.Auth.CreateUserCheckEmailResponse || {}
  );

  const checkWidth = () => {
    return window.innerWidth;
  };
  useEffect(() => {
    window.addEventListener("resize", (e) => {
      checkWidth();
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    if (SignUpRes?.status === true) {
      message?.destroy();
      message.success(SignUpRes?.message || "User Added");
      setTimeout(() => {
        setRedirect(true);
      }, 500);
      setSubmit(false);
      fakeActionAuth("SignUpResponse");
    } else if (SignUpRes?.status === false) {
      message?.destroy();
      setSubmit(false);
      if (SignUpRes?.error?.length !== 0 || SignUpRes?.error_data) {
        message.warning(
          Object.values(SignUpRes?.error || SignUpRes?.error_data)?.[0]?.[0] ||
            SignUpRes?.message ||
            "Something Went Wrong."
        );
      } else if (SignUpRes?.data && SignUpRes?.data?.length !== 0) {
        message.warning(SignUpRes?.data?.[Object.keys(SignUpRes?.data)?.[0]]);
      } else {
        message.warning("Something Went Wrong.");
      }
      fakeActionAuth("SignUpResponse");
    }
  }, [SignUpRes]);

  useEffect(() => {
    if (CreateUserCheckEmailResponse?.status === true) {
      form.validateFields(["email"]);
      setExisting(CreateUserCheckEmailResponse?.data?.is_email);
      fakeActionAuth("CreateUserCheckEmailResponse");
    } else if (CreateUserCheckEmailResponse?.status === false) {
      fakeActionAuth("CreateUserCheckEmailResponse");
    }
  }, [CreateUserCheckEmailResponse]);

  const onFinish = (values) => {
    delete values?.confirm;
    message.destroy();
    setSubmit(true);
    signUpAction(values);
  };
  if (localStorage.getItem("userType") || redirect) {
    return (
      <Redirect
        to={
          localStorage.getItem("userType") == 1
            ? `/manage-seller-users`
            : `/pricing-plans`
        }
      />
    );
  }
  document.title = "Sign Up";
  return (
    <Wrapper style={{ height: "100%", overflowY: "auto" }}>
      <div className="row" style={{ height: "100%", marginRight: "0px" }}>
        <div
          id="div1"
          style={{ background: "#eff3fe" }}
          className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6  flex-column d-flex"
        >
          <div className="d-flex flex-row-fluid flex-column text-center p-10">
            <span className="cursor-pointer py-9 mb-5 mt-20 d-flex justify-content-center">
              <img
                alt="Logo"
                loading="lazy"
                src="/media/logo-main.png"
                className="h-70px  shimmer"
                onError={(e) => {
                  e.target.src =
                    "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
                }}
              />
            </span>
            <h1
              className="fs-2qx pb-5 pb-md-4 fw-normal"
              style={{ color: "#494951" }}
            >
              Welcome to <b className="fw-boldest">Market Force</b>
            </h1>
            <p className="fw-normal fs-3" style={{ color: "#494951" }}>
              An Intelligent tool for Amazon Sellers
            </p>
          </div>
          <div className="authFadeInTop d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
            <img
              src="/media/login-image.svg"
              alt="login-image.svg"
              className="mx-auto"
              loading="lazy"
              style={{ width: "90%" }}
              onError={(e) => {
                e.target.src =
                  "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
              }}
            />
          </div>
        </div>
        <div
          id="div2"
          style={{ background: "#fff", height: "auto" }}
          className="authFadeInBottom d-flex flex-column col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
        >
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div
                className="form fv-plugins-bootstrap5 fv-plugins-framework w-lg-500px"
                // noValidate="novalidate"
                id="kt_sign_up_form"
              >
                {/*begin::Heading*/}
                <div className="mb-10 text-center">
                  {/*begin::Title*/}
                  <h1
                    className="text-dark fw-bold mb-3"
                    style={{ color: "#494951" }}
                  >
                    Create an <b className="fw-boldest">Account</b>
                  </h1>
                  {/*end::Title*/}
                  {/*begin::Link*/}
                  <div className="text-gray-400 fw-bold fs-4">
                    Already have an account?
                    <ASINLink
                      id={Math.random()}
                      to="/login"
                      className="link-primary fw-bolder"
                    >
                      Sign in here
                    </ASINLink>
                  </div>
                  {/*end::Link*/}
                </div>
                <div className="d-flex justify-content-center">
                  <Form
                    {...formItemLayout}
                    layout="vertical"
                    form={form}
                    autoComplete="new-password"
                    name="basic"
                    onFinish={onFinish}
                    disabled={submit}
                    style={{ width: checkWidth() <= 576 ? "75%" : "100%" }}
                  >
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        {" "}
                        <Form.Item
                          name="name"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: "First Name is required",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input
                            // disabled={submit}
                            size="large"
                            autoFocus
                            name="name"
                            autoComplete="new-password"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        {" "}
                        <Form.Item
                          name="email"
                          label="E-mail"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "E-mail is required",
                            },
                            () => ({
                              validator(_, value) {
                                if (!existing && value) {
                                  return Promise.reject(
                                    new Error("This email is already exists.")
                                  );
                                } else if (existing) {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                          hasFeedback
                        >
                          <Input
                            size="large"
                            name="email"
                            autoComplete="new-password"
                            // disabled={submit}
                            onBlur={(e) => {
                              if (e.target.value) {
                                checkEmailAction({
                                  email: e.target.value,
                                });
                              }
                            }}
                            onClick={() => setExisting(true)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <Form.Item
                          name="company_name"
                          label="Company Name"
                          rules={[
                            {
                              required: true,
                              message: "Company Name  is required",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input
                            size="large"
                            name="company_name"
                            autoComplete="new-password"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <Form.Item
                          name="contact_no"
                          label="Phone Number"
                          rules={[
                            {
                              required: true,
                              message: "Phone Number is required",
                            },
                            () => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.reject();
                                }
                                const validation = /^[0-9]+$/.test(value);
                                if (!validation) {
                                  return Promise.reject("is not valid.");
                                }
                                if (value.length < 10 || value.length > 10) {
                                  return Promise.reject(
                                    `can't be ${
                                      value.length < 10 ? "less" : "more"
                                    } than 10 digits`
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                          hasFeedback
                        >
                          <Input
                            maxLength={10}
                            size="large"
                            name="phone_number"
                            autoComplete="new-password"
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Password is required",
                        },
                        {
                          min: 6,
                          message: "Password must be at least 6 characters",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        autoComplete="new-password"
                        size="large"
                      />
                    </Form.Item>

                    <Form.Item
                      name="confirm"
                      label="Confirm Password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Confirm password is required",
                        },

                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        size="large"
                        autoComplete="new-password"
                      />
                    </Form.Item>
                    <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <label className="form-check form-check-custom form-check-solid form-check-inline">
                        <input
                          className="form-check-input mx-4"
                          type="checkbox"
                          name="toc"
                          defaultValue={1}
                        />
                        <span
                          style={{ width: "410px" }}
                          className="form-check-label fw-bold text-gray-700 fs-6"
                        >
                          I Agree to the Market Force
                          <span className="ms-1">Terms and conditions</span> to
                          use the services provided by the application.
                        </span>
                      </label>
                      <div className="fv-plugins-message-container invalid-feedback" />
                    </div>
                    <Form.Item
                      className="d-flex justify-content-center"
                      // wrapperCol={{
                      //   offset: 8,
                      //   span: 16,
                      // }}
                    >
                      <Button
                        style={{ height: "45px" }}
                        type="primary"
                        htmlType="submit"
                        disabled={submit}
                        className="btn btn-lg btn-primary"
                      >
                        {submit ? (
                          <span>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        ) : (
                          <span className="indicator-label">Submit</span>
                        )}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div />
              </div>
            </div>
            <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
              <div className="d-flex flex-center fw-bold fs-6">
                <Link className="text-muted text-hover-primary px-2">
                  About
                </Link>
                <Link className="text-muted text-hover-primary px-2">
                  Support
                </Link>
                <Link className="text-muted text-hover-primary px-2">
                  Purchase
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
