import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Criteria from "../../modules/pages/criteria";
import {
  GetCriteriaListAction,
  GetCriteriaViewAction,
  UpdateCriteriaAction,
  DeleteCriteriaAction,
  fakeActionCriteria,
  AddCriteriaViewAction,
  CloneAmazonLQSAction,
  GetMarketplaceListAction,
} from "../../redux/module_/criteria/criteria.action";

const mapStateToProps = (state) => ({
  ItemsCriteriaListResponse: state.Criteria.ItemsCriteriaListResponse,
  ItemsCriteriaViewResponse: state.Criteria.ItemsCriteriaViewResponse,
  UpdateItemsCriteriaResponse: state.Criteria.UpdateItemsCriteriaResponse,
  DeleteItemsCriteriaResponse: state.Criteria.DeleteItemsCriteriaResponse,
  GetMarketplaceListResponse: state.Criteria.GetMarketplaceListResponse,
  AddNewItemsCriterialResponse: state.Criteria.AddNewItemsCriterialResponse,
  CloneAmazonLQSResponse: state.Criteria.CloneAmazonLQSResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetCriteriaListAction,
      GetCriteriaViewAction,
      UpdateCriteriaAction,
      DeleteCriteriaAction,
      CloneAmazonLQSAction,
      AddCriteriaViewAction,
      fakeActionCriteria,
      GetMarketplaceListAction,
    },
    dispatch
  );

const Criteria_ = connect(mapStateToProps, mapDispatchToProps)(Criteria);

export default Criteria_;
