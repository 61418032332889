import { DatePicker, Result, Select, Spin, Table, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Wrapper } from "../new-style";

import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";

import dayjs from "dayjs";
import moment from "moment";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../../commonContext";
import {
  AllMarketPlace,
  SwapCommaAndDot,
  checkPermission,
  updateDate,
} from "../../../../config";

HighchartsMap(Highcharts);

const Overview = (props) => {
  const {
    CardOverViewAction,
    MonthCompareDataAction,
    MarketplaceDataAction,
    fakeActionAnalytics,
    SalesChannelListAction,
    GetGraphDataAction,
    fakeActionCatalog,
  } = props;

  const contextValue = useContext(GlobalContext);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    account_type: null,
  });

  const [dateFilter, setDateFilter] = useState({
    start: dayjs().subtract(30, "days")?.format("YYYY-MM-DD"),
    end: dayjs()?.format("YYYY-MM-DD"),
  });

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Calculate the last four years
  const lastFourYears = [];
  for (let i = 0; i < 3; i++) {
    lastFourYears.push({ label: currentYear - i, value: currentYear - i });
  }

  const [yearFilter, setYearFilter] = useState({
    currant: lastFourYears?.[0]?.value,
    pervious: lastFourYears?.[1]?.value,
  });
  const [graphData, setGraphData] = useState({});
  const [cardData, setCardData] = useState({});
  const [sign, setSign] = useState("");

  const [mapData, setMapData] = useState([]);
  const [mapLoading, setMapLoading] = useState(true);

  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const [cardLoading, setCardLoading] = useState(true);
  const [graphLoading, setGraphLoading] = useState(true);

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const CardOverviewDataResponse = useSelector(
    (state) => state.Analytics.CardOverviewDataResponse || {}
  );
  const MonthCompareDataResponse = useSelector(
    (state) => state.Analytics.MonthCompareDataResponse || {}
  );
  const MarketplaceDataResponse = useSelector(
    (state) => state.Analytics.MarketplaceDataResponse || {}
  );
  const GetGraphDataResponse = useSelector(
    (state) => state.Analytics.GetGraphDataResponse || {}
  );

  // map data

  useEffect(() => {
    if (GetGraphDataResponse?.status === true) {
      // setMapData(
      //   GetGraphDataResponse?.data?.data?.map((d) => [
      //     d?.other_marketplace_name?.toLowerCase(),
      //     d?.order_revenue?.value || 0,
      //     d?.currency,
      //     d?.marketplace_id,
      //   ]) || []
      // );

      setMapLoading(false);
      fakeActionAnalytics("GetGraphDataResponse");
    } else if (GetGraphDataResponse?.status === false) {
      setMapLoading(false);
      fakeActionAnalytics("GetGraphDataResponse");
    }
  }, [GetGraphDataResponse]);

  // table
  useEffect(() => {
    if (MarketplaceDataResponse?.status === true) {
      setTableData(MarketplaceDataResponse?.data?.data || []);
      setTableLoading(false);

      setCardData(MarketplaceDataResponse?.data?.card_data || {});
      setSign(MarketplaceDataResponse?.data?.card_data?.currency || "");
      setCardLoading(false);
      setMapLoading(false);
      setMapData(
        MarketplaceDataResponse?.data?.data?.map((d) => [
          d?.marketplace_short_name?.toLowerCase(),
          d?.order_revenue?.value || 0,
          d?.currency,
          d?.marketplace_id,
        ]) || []
      );
      setMapLoading(false);
      fakeActionAnalytics("MarketplaceDataResponse");
    } else if (MarketplaceDataResponse?.status === false) {
      setTableData([]);
      setTableLoading(false);
      fakeActionAnalytics("MarketplaceDataResponse");
    }
  }, [MarketplaceDataResponse]);

  // graph
  useEffect(() => {
    if (MonthCompareDataResponse?.status === true) {
      setGraphData(MonthCompareDataResponse?.data?.data || {});
      setGraphLoading(false);
      fakeActionAnalytics("MonthCompareDataResponse");
    } else if (MonthCompareDataResponse?.status === false) {
      setGraphData({});
      setGraphLoading(false);
      fakeActionAnalytics("MonthCompareDataResponse");
    }
  }, [MonthCompareDataResponse]);

  // card
  useEffect(() => {
    if (CardOverviewDataResponse?.status === true) {
      // setCardData(CardOverviewDataResponse?.data?.data || {});
      // setSign(CardOverviewDataResponse?.data?.currency || "");
      // setCardLoading(false);
      fakeActionAnalytics("CardOverviewDataResponse");
    } else if (CardOverviewDataResponse?.status === false) {
      setSign("");
      setCardLoading(false);
      setCardData({});
      fakeActionAnalytics("CardOverviewDataResponse");
    }
  }, [CardOverviewDataResponse]);

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );
            if (e?.default_marketplace === 1) {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });

              setGraphLoading(true);
              setTableLoading(true);
              MonthCompareDataAction({
                region: e?.region,
                sales_channel: e?.sales_channel,
                year: yearFilter?.currant,
                previous_year: yearFilter?.pervious,
                account_type: e?.account_type,
              });
              // GetGraphDataAction({
              //   sales_channel: e?.sales_channel,
              //   region: e?.region,
              //   date: dateFilter
              //     ? `${dateFilter.start}+-+${dateFilter.end}`
              //     : "",
              // });
              MarketplaceDataAction({
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
                date: dateFilter
                  ? `${dateFilter.start}+-+${dateFilter.end}`
                  : "",
              });
            } else {
              setGraphLoading(false);
              setCardLoading(false);
              setTableLoading(false);
              setMarketplaceLoading(false);
              setMapLoading(false);
            }
            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                    account_type: e?.account_type,
                  },
                ],
              });
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);

        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);

        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setGraphLoading(false);
      setMarketplaceList([]);
      message.destroy();
      setCardLoading(false);
      message.warning(SalesChannelListResponse?.message);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  useEffect(() => {
    SalesChannelListAction();
    setDateFilter({
      start: contextValue?.data?.date_range?.start_date,
      end: contextValue?.data?.date_range?.end_date,
    });

    return () => {};
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://code.highcharts.com/mapdata/custom/europe.topo.json"
        );
        const topology = await response.json();

        const data = mapData;
        console.log(mapData, "mapData");

        // console.log(data, "data");
        Highcharts.mapChart("map-container", {
          chart: {
            map: topology,
          },
          title: {
            text: "",
          },

          mapNavigation: {
            enabled: true,
            buttonOptions: {
              verticalAlign: "bottom",
            },
          },
          colorAxis: {
            min: 0,
          },
          tooltip: {
            headerFormat: "",
            formatter: function () {
              return `<b>${this.point.name}</b><br>Revenue: ${SwapCommaAndDot(
                parseFloat(this.point.value || 0)?.toLocaleString(),
                mapData?.filter(
                  (d) => d?.[0] === this.point?.["hc-key"]
                )?.[0]?.[2],
                "",
                mapData?.filter(
                  (d) => d?.[0] === this.point?.["hc-key"]
                )?.[0]?.[3]
              )}`;
            },
          },
          series: [
            {
              data: mapData,
              name: "Overall revenue",
              states: {
                color: "#336699",
                hover: {
                  color: "#BADA55",
                },
              },
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching map data", error);
      }
    };

    fetchData();

    return () => {};
  }, [mapLoading, mapData]);

  const columns = [
    {
      title: "Marketplace Name",
      sorter: (a, b) => {
        const nameA = `${a.marketplace?.account_type || "-"}${
          a.marketplace?.marketplace_short_name || "-"
        }`;
        const nameB = `${b.marketplace?.account_type || "-"}${
          b.marketplace?.marketplace_short_name || "-"
        }`;
        return nameA.localeCompare(nameB);
      },
      render: (e) => {
        return (
          <span>
            Amazon.{e?.account_type || "-"}.{e?.marketplace_short_name || "-"}
          </span>
        );
      },
    },
    {
      title:
        marketplaceSelected?.account_type === "Vendor"
          ? "Sell Out"
          : "Overall Revenue",
      dataIndex: "order_revenue",
      key: "order_revenue",
      sorter: (a, b) =>
        parseFloat(a.order_revenue?.value || 0) -
        parseFloat(b.order_revenue?.value || 0),
      render: (e, text) => {
        return (
          <span>
            {SwapCommaAndDot(
              parseFloat(e?.value || 0)?.toLocaleString(),
              text?.currency,
              "",
              text?.marketplace_id
            )}
          </span>
        );
      },
    },
    {
      title:
        marketplaceSelected?.account_type === "Vendor"
          ? "Units"
          : "Units Ordered",

      sorter: (a, b) =>
        parseFloat(a?.current_quantity_value || 0) -
        parseFloat(b?.current_quantity_value || 0),
      render: (text, record) => (
        <span>
          {SwapCommaAndDot(
            parseInt(record.current_quantity_value || 0)?.toLocaleString(),
            "",
            "",
            record?.marketplace_id
          )}
        </span>
      ),
    },
    marketplaceSelected?.account_type !== "Vendor"
      ? {
          title: "Orders",

          sorter: (a, b) =>
            parseFloat(a?.current_order_count_value || 0) -
            parseFloat(b?.current_order_count_value || 0),

          render: (text, record) => (
            <span>
              {SwapCommaAndDot(
                parseInt(
                  record.current_order_count_value || 0
                )?.toLocaleString(),
                "",
                "",
                record?.marketplace_id
              )}
            </span>
          ),
        }
      : {},
    {
      title: "% Variation Previous Period",
      dataIndex: "order_revenue",
      key: "order_revenue",
      sorter: (a, b) =>
        parseFloat(a.order_revenue?.period_percentage || 0) -
        parseFloat(b.order_revenue?.period_percentage || 0),
      render: (text, record) => (
        <span style={{ color: text.period_percentage >= 0 ? "green" : "red" }}>
          {SwapCommaAndDot(
            parseFloat(text.period_percentage || 0)
              .toFixed(2)
              ?.toLocaleString(),
            "",
            "",
            record?.marketplace_id
          )}
          %
        </span>
      ),
    },
    {
      title: "% Variation Previous Year",
      dataIndex: "order_revenue",
      key: "order_revenue",
      sorter: (a, b) =>
        parseFloat(a.order_revenue?.previous_percentage || 0) -
        parseFloat(b.order_revenue?.previous_percentage || 0),
      render: (text, record) => (
        <span
          style={{ color: text.previous_percentage >= 0 ? "green" : "red" }}
        >
          {SwapCommaAndDot(
            parseFloat(text.previous_percentage || 0)
              .toFixed(2)
              ?.toLocaleString(),
            "",
            "",
            record?.marketplace_id
          )}
          %
        </span>
      ),
    },
  ];

  const currentMonth = moment().month(); // Get the current month (0-indexed)
  const quarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3), "months");
  const quarterEnd = quarterStart.clone().add(3, "months").subtract(1, "day");

  const lastQuarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3) - 3, "months");
  const lastQuarterEnd = lastQuarterStart
    .clone()
    .add(3, "months")
    .subtract(1, "day");
  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
      type: "last_30_days_by_day",
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
      type: "last_30_days_by_day",
    },
    {
      label: "This Quarter",
      value: [dayjs(quarterStart), dayjs(quarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Quarter",
      value: [dayjs(lastQuarterStart), dayjs(lastQuarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
      type: "last_12_months_by_month",
    },
    {
      label: "Custom Range",
      value: [0, 0],
      type: "last_12_months_by_month",
    },
  ];

  const chartOptions_ = {
    noData: {
      text: "",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#000000",
        fontSize: "16px",
      },
    },

    chart: {
      id: "multi-line-chart",
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#7C5EFF", "#000", "#CED4DC"], // Customize line colors
    stroke: {
      width: [3, 3, 3], // Customize line thickness
      dashArray: [0, 5, 8], // Add dash array for the lines
    },
    markers: {
      size: 5,
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      marker: {
        show: true,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return SwapCommaAndDot(
            parseFloat(value || 0).toLocaleString() || 0,
            sign,
            "",
            marketplaceSelected?.marketplace_id
          );
        },
      },
    },
    xaxis: {
      categories:
        graphData?.chartLable?.map((d) =>
          dayjs(d, "MMMM YYYY").format("MMMM")
        ) || [],
    },

    yaxis: {
      title: {
        text: "Values",
      },
      labels: {
        formatter: (e) => e?.toLocaleString(),
      },
    },
  };

  const chartSeries_ = [
    {
      name: `${
        marketplaceSelected?.account_type === "Vendor"
          ? "Sell Out"
          : "Overall Revenue"
      } ${yearFilter?.currant}`,
      data: graphData?.chartData?.current_year?.map((d) => d?.current) || [],
    },
    {
      name: `${
        marketplaceSelected?.account_type === "Vendor"
          ? "Sell Out"
          : "Overall Revenue"
      } ${yearFilter?.pervious}`,
      data: graphData?.chartData?.previous_year?.map((d) => d?.previous) || [],
    },
  ];

  const [columnsList, setColumnsList] = useState([]);

  useEffect(() => {
    setColumnsList(columns);
  }, [marketplaceSelected, tableData]);

  if (checkPermission(contextValue, "analytics", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }

  return (
    <Wrapper className="px-5">
      <div className="d-flex justify-content-end flex-wrap  mb-5">
        <div className="d-flex flex-wrap">
          <div className="fix-over">
            <DatePicker.RangePicker
              presets={rangePresets}
              getPopupContainer={(trigger) => trigger.parentNode}
              className="me-3"
              size="large"
              value={
                dateFilter?.start && dateFilter?.end
                  ? [
                      dayjs(dateFilter?.start, "YYYY-MM-DD"),
                      dayjs(dateFilter?.end, "YYYY-MM-DD"),
                    ]
                  : null
              }
              onChange={(e) => {
                setCardLoading(true);
                setDateFilter({
                  start: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                  end: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
                });
                const date_range = {
                  start_date: e?.[0]
                    ? dayjs(e?.[0]).format("YYYY-MM-DD")
                    : null,
                  end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
                };
                contextValue?.updateCommonGlobalVal({
                  date_range,
                });
                updateDate(date_range);

                setTableLoading(true);
                // GetGraphDataAction({
                //   region: marketplaceSelected?.region,
                //   sales_channel: marketplaceSelected?.sales_channel,
                //   date: e
                //     ? `${
                //         e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null
                //       }+-+${e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null}`
                //     : "",
                // });
                MarketplaceDataAction({
                  region: marketplaceSelected?.region,
                  sales_channel: marketplaceSelected?.sales_channel,
                  account_type: marketplaceSelected?.account_type,
                  date: e
                    ? `${
                        e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null
                      }+-+${e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null}`
                    : "",
                });
              }}
            />
          </div>
          <div className="fix-over">
            <Select
              className="w-225px  ms-3"
              size="large"
              getPopupContainer={(trigger) => trigger.parentNode}
              options={marketplaceList}
              onChange={(_, e) => {
                setMarketplaceSelected({
                  marketplace: e?.marketplace,
                  account_type: e?.account_type,
                  marketplace_id: e?.marketplace_id,
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                });

                setGraphLoading(true);
                MonthCompareDataAction({
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                  year: yearFilter?.currant,
                  previous_year: yearFilter?.pervious,
                  account_type: e?.account_type,
                });
                // GetGraphDataAction({
                //   region: e?.region,
                //   sales_channel: e?.sales_channel,
                //   date: dateFilter?.start + "+-+" + dateFilter?.end,
                // });
                MarketplaceDataAction({
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                  date: dateFilter?.start + "+-+" + dateFilter?.end,
                  account_type: e?.account_type,
                });
                setMapLoading(true);

                setTableLoading(true);
              }}
              value={marketplaceSelected?.marketplace_id}
              loading={marketplaceLoading}
              placeholder="Select Marketplace"
            ></Select>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-xxl-4 col-xl-4 col-12 mb-md-5">
          <div className="over-view-card">
            <div
              style={{
                background: "#9c4063",
                width: "2px",
                marginRight: "15px",
              }}
            />

            <div className="details" style={{ width: "100vw" }}>
              {cardLoading ? (
                <div
                  className="d-flex align-items-center justify-content-center "
                  style={{ height: "85px", width: "100%" }}
                >
                  <Spin />
                </div>
              ) : (
                <>
                  <div className="icon1">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M12.5 22C11.9 22 11.5 21.6 11.5 21V3C11.5 2.4 11.9 2 12.5 2C13.1 2 13.5 2.4 13.5 3V21C13.5 21.6 13.1 22 12.5 22Z"
                        fill="currentColor"
                      />
                      <path
                        d="M17.8 14.7C17.8 15.5 17.6 16.3 17.2 16.9C16.8 17.6 16.2 18.1 15.3 18.4C14.5 18.8 13.5 19 12.4 19C11.1 19 10 18.7 9.10001 18.2C8.50001 17.8 8.00001 17.4 7.60001 16.7C7.20001 16.1 7 15.5 7 14.9C7 14.6 7.09999 14.3 7.29999 14C7.49999 13.8 7.80001 13.6 8.20001 13.6C8.50001 13.6 8.69999 13.7 8.89999 13.9C9.09999 14.1 9.29999 14.4 9.39999 14.7C9.59999 15.1 9.8 15.5 10 15.8C10.2 16.1 10.5 16.3 10.8 16.5C11.2 16.7 11.6 16.8 12.2 16.8C13 16.8 13.7 16.6 14.2 16.2C14.7 15.8 15 15.3 15 14.8C15 14.4 14.9 14 14.6 13.7C14.3 13.4 14 13.2 13.5 13.1C13.1 13 12.5 12.8 11.8 12.6C10.8 12.4 9.99999 12.1 9.39999 11.8C8.69999 11.5 8.19999 11.1 7.79999 10.6C7.39999 10.1 7.20001 9.39998 7.20001 8.59998C7.20001 7.89998 7.39999 7.19998 7.79999 6.59998C8.19999 5.99998 8.80001 5.60005 9.60001 5.30005C10.4 5.00005 11.3 4.80005 12.3 4.80005C13.1 4.80005 13.8 4.89998 14.5 5.09998C15.1 5.29998 15.6 5.60002 16 5.90002C16.4 6.20002 16.7 6.6 16.9 7C17.1 7.4 17.2 7.69998 17.2 8.09998C17.2 8.39998 17.1 8.7 16.9 9C16.7 9.3 16.4 9.40002 16 9.40002C15.7 9.40002 15.4 9.29995 15.3 9.19995C15.2 9.09995 15 8.80002 14.8 8.40002C14.6 7.90002 14.3 7.49995 13.9 7.19995C13.5 6.89995 13 6.80005 12.2 6.80005C11.5 6.80005 10.9 7.00005 10.5 7.30005C10.1 7.60005 9.79999 8.00002 9.79999 8.40002C9.79999 8.70002 9.9 8.89998 10 9.09998C10.1 9.29998 10.4 9.49998 10.6 9.59998C10.8 9.69998 11.1 9.90002 11.4 9.90002C11.7 10 12.1 10.1 12.7 10.3C13.5 10.5 14.2 10.7 14.8 10.9C15.4 11.1 15.9 11.4 16.4 11.7C16.8 12 17.2 12.4 17.4 12.9C17.6 13.4 17.8 14 17.8 14.7Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="card-body-overview" style={{ width: "100%" }}>
                    <div className="left-card">
                      <div className="title">
                        {marketplaceSelected?.account_type === "Vendor"
                          ? "Sell Out"
                          : "Overall Revenue"}
                      </div>
                      <div style={{ fontWeight: "700", fontSize: "large" }}>
                        {SwapCommaAndDot(
                          parseFloat(
                            cardData?.overall_revenue?.value || 0
                          )?.toLocaleString(),
                          sign,
                          "",
                          marketplaceSelected?.marketplace_id
                        )}
                      </div>
                      <div
                        style={{
                          background:
                            cardData?.overall_revenue?.period_percentage < 0
                              ? "#f2e5e5"
                              : "#E8F2E5",
                          padding: "6px 10px",
                          borderRadius: "10px",
                        }}
                      >
                        <b
                          style={{
                            color:
                              cardData?.overall_revenue?.period_percentage < 0
                                ? "rgb(130 2 2)"
                                : "#228202",
                          }}
                        >
                          {SwapCommaAndDot(
                            parseFloat(
                              cardData?.overall_revenue?.period_percentage || 0
                            )
                              .toFixed(2)
                              ?.toLocaleString(),
                            "",
                            "",
                            marketplaceSelected?.marketplace_id
                          )}
                          %
                        </b>{" "}
                        <span style={{ color: "#737771" }}>
                          Previous Period
                        </span>
                      </div>
                    </div>
                    <div className="right-card">
                      <span
                        style={{
                          color:
                            cardData?.overall_revenue?.previous_percentage < 0
                              ? "rgb(130 2 2)"
                              : "#228202",
                        }}
                      >
                        {SwapCommaAndDot(
                          parseFloat(
                            cardData?.overall_revenue?.previous_percentage || 0
                          )
                            .toFixed(2)
                            ?.toLocaleString(),
                          "",
                          "",
                          marketplaceSelected?.marketplace_id
                        )}
                        %
                      </span>
                      <span>Previous year</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-12 mb-md-5">
          <div className="over-view-card">
            <div
              style={{
                background: "#4a52a1",
                width: "2px",
                marginRight: "15px",
              }}
            />
            <div className="details" style={{ width: "100vw" }}>
              {cardLoading ? (
                <div
                  className="d-flex align-items-center justify-content-center "
                  style={{ height: "85px", width: "100%" }}
                >
                  <Spin />
                </div>
              ) : (
                <>
                  <div className="icon2">
                    <svg
                      width={22}
                      height={22}
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M19.5997 3.52344H2.39639C2.09618 3.53047 1.8003 3.59658 1.52565 3.718C1.25101 3.83941 1.00298 4.01375 0.79573 4.23106C0.588484 4.44837 0.426087 4.70438 0.317815 4.98447C0.209544 5.26456 0.157521 5.56324 0.164719 5.86344C0.157521 6.16364 0.209544 6.46232 0.317815 6.74241C0.426087 7.0225 0.588484 7.27851 0.79573 7.49581C1.00298 7.71312 1.25101 7.88746 1.52565 8.00888C1.8003 8.1303 2.09618 8.19641 2.39639 8.20344H19.5997C19.8999 8.19641 20.1958 8.1303 20.4704 8.00888C20.7451 7.88746 20.9931 7.71312 21.2004 7.49581C21.4076 7.27851 21.57 7.0225 21.6783 6.74241C21.7866 6.46232 21.8386 6.16364 21.8314 5.86344C21.8386 5.56324 21.7866 5.26456 21.6783 4.98447C21.57 4.70438 21.4076 4.44837 21.2004 4.23106C20.9931 4.01375 20.7451 3.83941 20.4704 3.718C20.1958 3.59658 19.8999 3.53047 19.5997 3.52344Z"
                        fill="currentColor"
                        fillOpacity="0.8"
                      />
                      <path
                        d="M2.39453 8.20361L4.01953 18.3111C4.15644 19.145 4.58173 19.9043 5.22121 20.4567C5.8607 21.009 6.6738 21.3194 7.5187 21.3336H14.5712C15.4215 21.3202 16.2395 21.006 16.8801 20.4468C17.5207 19.8875 17.9424 19.1193 18.0704 18.2786L19.5979 8.20361H2.39453ZM9.28453 16.3178C9.28453 16.5333 9.19893 16.7399 9.04656 16.8923C8.89418 17.0447 8.68752 17.1303 8.47203 17.1303C8.25654 17.1303 8.04988 17.0447 7.89751 16.8923C7.74513 16.7399 7.65953 16.5333 7.65953 16.3178V12.4069C7.65953 12.1915 7.74513 11.9848 7.89751 11.8324C8.04988 11.68 8.25654 11.5944 8.47203 11.5944C8.68752 11.5944 8.89418 11.68 9.04656 11.8324C9.19893 11.9848 9.28453 12.1915 9.28453 12.4069V16.3178ZM14.322 16.3178C14.322 16.5333 14.2364 16.7399 14.0841 16.8923C13.9317 17.0447 13.725 17.1303 13.5095 17.1303C13.294 17.1303 13.0874 17.0447 12.935 16.8923C12.7826 16.7399 12.697 16.5333 12.697 16.3178V12.4069C12.697 12.1915 12.7826 11.9848 12.935 11.8324C13.0874 11.68 13.294 11.5944 13.5095 11.5944C13.725 11.5944 13.9317 11.68 14.0841 11.8324C14.2364 11.9848 14.322 12.1915 14.322 12.4069V16.3178Z"
                        fill="currentColor"
                        fillOpacity="0.8"
                      />
                      <path
                        d="M17.3895 4.87755C17.2529 4.87776 17.1185 4.84303 16.999 4.77667C16.8796 4.71031 16.7791 4.61452 16.707 4.49839L14.5945 1.24839C14.488 1.07063 14.4544 0.858502 14.5009 0.656521C14.5473 0.45454 14.6702 0.2784 14.8437 0.165055C15.0215 0.0626479 15.2311 0.0303209 15.4315 0.0744071C15.6319 0.118493 15.8086 0.235816 15.927 0.403388L18.0395 3.70755C18.1434 3.88599 18.1755 4.09728 18.1292 4.2985C18.0829 4.49972 17.9618 4.67577 17.7904 4.79089C17.6659 4.85225 17.5282 4.88202 17.3895 4.87755Z"
                        fill="currentColor"
                        fillOpacity="0.8"
                      />
                      <path
                        d="M4.49988 4.8885C4.34679 4.8928 4.19591 4.85131 4.06655 4.76933C3.89514 4.65422 3.77399 4.47817 3.72771 4.27694C3.68143 4.07572 3.71349 3.86443 3.81738 3.686L5.98405 0.435999C6.09739 0.262485 6.27353 0.13961 6.47551 0.0931545C6.6775 0.0466989 6.88962 0.0802727 7.06738 0.186832C7.23676 0.303623 7.35627 0.479597 7.40239 0.680101C7.4485 0.880606 7.41788 1.09111 7.31655 1.27017L5.20405 4.52017C5.12881 4.63747 5.0243 4.73313 4.90082 4.79773C4.77733 4.86232 4.63914 4.8936 4.49988 4.8885Z"
                        fill="currentColor"
                        fillOpacity="0.8"
                      />
                    </svg>
                  </div>
                  <div className="card-body-overview" style={{ width: "100%" }}>
                    <div className="left-card">
                      <div className="title">
                        {marketplaceSelected?.account_type === "Vendor"
                          ? "Units"
                          : "Orders"}
                      </div>
                      <div style={{ fontWeight: "700", fontSize: "large" }}>
                        {SwapCommaAndDot(
                          parseFloat(
                            cardData?.[
                              marketplaceSelected?.account_type === "Vendor"
                                ? "units_ordered"
                                : "orders"
                            ]?.value || 0
                          )?.toLocaleString(),
                          "",
                          "",
                          marketplaceSelected?.marketplace_id
                        )}
                      </div>
                      <div
                        style={{
                          background: "#E8F2E5",
                          background:
                            cardData?.[
                              marketplaceSelected?.account_type === "Vendor"
                                ? "units_ordered"
                                : "orders"
                            ]?.period_percentage < 0
                              ? "#f2e5e5"
                              : "#E8F2E5",
                          padding: "6px 10px",
                          borderRadius: "10px",
                        }}
                      >
                        <b
                          style={{
                            color:
                              cardData?.[
                                marketplaceSelected?.account_type === "Vendor"
                                  ? "units_ordered"
                                  : "orders"
                              ]?.period_percentage < 0
                                ? "rgb(130 2 2)"
                                : "#228202",
                          }}
                        >
                          {SwapCommaAndDot(
                            parseFloat(
                              cardData?.[
                                marketplaceSelected?.account_type === "Vendor"
                                  ? "units_ordered"
                                  : "orders"
                              ]?.period_percentage || 0
                            )
                              .toFixed(2)
                              ?.toLocaleString(),
                            "",
                            "",
                            marketplaceSelected?.marketplace_id
                          )}
                          %
                        </b>{" "}
                        <span style={{ color: "#737771" }}>
                          Previous Period
                        </span>
                      </div>
                    </div>
                    <div className="right-card">
                      <span
                        style={{
                          color:
                            cardData?.[
                              marketplaceSelected?.account_type === "Vendor"
                                ? "units_ordered"
                                : "orders"
                            ]?.previous_percentage < 0
                              ? "rgb(130 2 2)"
                              : "#228202",
                        }}
                      >
                        {SwapCommaAndDot(
                          parseFloat(
                            cardData?.[
                              marketplaceSelected?.account_type === "Vendor"
                                ? "units_ordered"
                                : "orders"
                            ]?.previous_percentage || 0
                          )
                            .toFixed(2)
                            ?.toLocaleString(),
                          "",
                          "",
                          marketplaceSelected?.marketplace_id
                        )}
                        %
                      </span>
                      <span>Previous year</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {marketplaceSelected?.account_type !== "Vendor" && (
          <div className="col-xxl-4 col-xl-4 col-12">
            <div className="over-view-card">
              <div
                style={{
                  background: "#ECA61D",
                  width: "2px",
                  marginRight: "15px",
                }}
              />
              <div className="details" style={{ width: "100vw" }}>
                {cardLoading ? (
                  <div
                    className="d-flex align-items-center justify-content-center "
                    style={{ height: "85px", width: "100%" }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <>
                    <div className="icon3">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z"
                          fill="currentColor"
                        />
                        <path
                          d="M7 16H6C5.4 16 5 15.6 5 15V13H8V15C8 15.6 7.6 16 7 16Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z"
                          fill="currentColor"
                        />
                        <path
                          d="M18 16H17C16.4 16 16 15.6 16 15V13H19V15C19 15.6 18.6 16 18 16Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z"
                          fill="currentColor"
                        />
                        <path
                          d="M7 5H6C5.4 5 5 4.6 5 4V2H8V4C8 4.6 7.6 5 7 5Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z"
                          fill="currentColor"
                        />
                        <path
                          d="M18 5H17C16.4 5 16 4.6 16 4V2H19V4C19 4.6 18.6 5 18 5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    <div
                      className="card-body-overview"
                      style={{ width: "100%" }}
                    >
                      <div className="left-card">
                        <div className="title">Units Ordered</div>
                        <div style={{ fontWeight: "700", fontSize: "large" }}>
                          {SwapCommaAndDot(
                            parseFloat(
                              cardData?.units_ordered?.value || 0
                            )?.toLocaleString(),
                            "",
                            "",
                            marketplaceSelected?.marketplace_id
                          )}
                        </div>
                        <div
                          style={{
                            background:
                              cardData?.units_ordered?.period_percentage < 0
                                ? "#f2e5e5"
                                : "#E8F2E5",
                            padding: "6px 10px",
                            borderRadius: "10px",
                          }}
                        >
                          <b
                            style={{
                              color:
                                cardData?.units_ordered?.period_percentage < 0
                                  ? "rgb(130 2 2)"
                                  : "#228202",
                            }}
                          >
                            {SwapCommaAndDot(
                              parseFloat(
                                cardData?.units_ordered?.period_percentage || 0
                              )
                                .toFixed(2)
                                ?.toLocaleString(),
                              "",
                              "",
                              marketplaceSelected?.marketplace_id
                            )}
                            %
                          </b>{" "}
                          <span style={{ color: "#737771" }}>
                            Previous Period
                          </span>
                        </div>
                      </div>
                      <div className="right-card">
                        <span
                          style={{
                            color:
                              cardData?.units_ordered?.previous_percentage < 0
                                ? "rgb(130 2 2)"
                                : "#228202",
                          }}
                        >
                          {SwapCommaAndDot(
                            parseFloat(
                              cardData?.units_ordered?.previous_percentage || 0
                            )
                              .toFixed(2)
                              ?.toLocaleString(),
                            "",
                            "",
                            marketplaceSelected?.marketplace_id
                          )}
                          %
                        </span>
                        <span>Previous year</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row mt-5">
        <div className="col-xl-7 col-md-12 mb-md-5">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Sales Overview</div>
              <div className="card-toolbar fix-over" style={{ zIndex: 9 }}>
                <div className="">
                  <Select
                    options={lastFourYears}
                    size="large"
                    style={{ width: "150px" }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    value={yearFilter?.currant}
                    onChange={(e) => {
                      if (e === yearFilter?.pervious) {
                        message.destroy();
                        return message.warning(
                          "This value is already selected"
                        );
                      }
                      setGraphLoading(true);
                      setYearFilter({
                        ...yearFilter,
                        currant: e,
                      });
                      MonthCompareDataAction({
                        region: marketplaceSelected?.region,
                        sales_channel: marketplaceSelected?.sales_channel,
                        account_type: marketplaceSelected?.account_type,
                        year: e,
                        previous_year: yearFilter?.pervious,
                      });
                    }}
                  />
                </div>

                <div className="">
                  <Select
                    options={lastFourYears}
                    value={yearFilter?.pervious}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(e) => {
                      if (e === yearFilter?.currant) {
                        message.destroy();
                        return message.warning(
                          "This value is already selected"
                        );
                      }
                      setGraphLoading(true);
                      setYearFilter({
                        ...yearFilter,
                        pervious: e,
                      });
                      MonthCompareDataAction({
                        region: marketplaceSelected?.region,
                        sales_channel: marketplaceSelected?.sales_channel,
                        account_type: marketplaceSelected?.account_type,
                        year: yearFilter?.currant,
                        previous_year: e,
                      });
                    }}
                    size="large"
                    style={{ width: "150px", marginLeft: "10px" }}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              {graphLoading ? (
                <div
                  className="d-flex align-items-center justify-content-center "
                  style={{ height: "350px", width: "100%" }}
                >
                  <Spin />
                </div>
              ) : (
                <Chart
                  options={chartOptions_}
                  series={chartSeries_}
                  type="line"
                  height={350}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Country Map</div>
            </div>
            <div className="card-body">
              {mapLoading ? (
                <div
                  className="d-flex align-items-center justify-content-center "
                  style={{ height: "350px", width: "100%" }}
                >
                  <Spin />
                </div>
              ) : (
                <></>
              )}
              <div
                id="map-container"
                className={`${mapLoading ? "d-none" : "d-block"}`}
                style={{ width: "100%", height: "365px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-5">
        <div className="card-header">
          <div className="card-title">
            <h2>Marketplace wise sales data</h2>
          </div>
        </div>
        <div className="card-body">
          <Table
            dataSource={tableData}
            columns={columnsList}
            pagination={false}
            loading={tableLoading}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Overview;
