import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProductDetails from "../../modules/pages/sales-analytics/product-details";

import {
  SalesChannelListAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";
import {
  ParentDataAction,
  TopProductAction,
  fakeActionAnalytics,
} from "../../redux/module_/analytics/index.action";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
  ParentDataResponse: state.Analytics.ParentDataResponse,
  TopProductsResponse: state.Analytics.TopProductsResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      fakeActionCatalog,

      ParentDataAction,
      TopProductAction,
      fakeActionAnalytics,
    },
    dispatch
  );

const ProductDetails__ = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetails);

export default ProductDetails__;
