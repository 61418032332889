import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserSetting from "../../modules/pages/user-setting";

import {
  RenerateRefreshAdTokenAction,
  SaveAdProfileAction,
  AdvertisingCredentialsAction,
  StoreSPOtherMarketplaceAction,
  fakeActionMarketPlace,
  GetMarketplaceParticipationAction,

  // SP API
  CredentialDeleteAction,
  GenerateRefreshSPTokenAction,
  GetSPAPIListAction,
} from "../../redux/module_/marketplace/index.action";
import {
  SalesChannelListAction,
  GetMarketplaceListAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";

const mapStateToProps = (state) => ({
  GenerateRefreshAdTokenResponse:
    state.UserSetting.GenerateRefreshAdTokenResponse,
  SaveAdProfileResponse: state.UserSetting.SaveAdProfileResponse,
  GetMarketplaceParticipationResponse:
    state.UserSetting.GetMarketplaceParticipationResponse,
  StoreSPOtherMarket: state.UserSetting.StoreSPOtherMarket,
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
  GetMarketplaceListResponse: state.Catalog.GetMarketplaceListResponse,
  AdvertisingCredentialsResponse: state.Catalog.AdvertisingCredentialsResponse,

  CredentialDelete: state.UserSetting.CredentialDelete,

  // SP API
  GetSPAPIListResponse: state.UserSetting.GetSPAPIListResponse,
  GenerateRefreshSPTokenResponse:
    state.UserSetting.GenerateRefreshSPTokenResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      RenerateRefreshAdTokenAction,
      SaveAdProfileAction,
      StoreSPOtherMarketplaceAction,
      AdvertisingCredentialsAction,
      fakeActionMarketPlace,

      SalesChannelListAction,
      GetMarketplaceParticipationAction,
      fakeActionCatalog,

      GetMarketplaceListAction,

      // SP API
      CredentialDeleteAction,
      GenerateRefreshSPTokenAction,
      GetSPAPIListAction,
    },
    dispatch
  );

const UserSetting__ = connect(mapStateToProps, mapDispatchToProps)(UserSetting);

export default UserSetting__;
