import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0px 22px 20px 40px;
  max-height: 650px;
  overflow: auto;
  #btnLogout {
    background-color: #5f668b;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    color: rgb(255, 255, 255);
    &:hover {
      background-color: #868fc1 !important;
      box-shadow: 0px 0px 10px #ed76b3 !important;
    }
  }
  .loadingSubmit {
    position: absolute;
    inset: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
  }
  .submitFeedBack {
    img {
      width: 450px;
      padding: 1px;
      position: absolute;
      background: #fff;
      inset: 0;
      margin: auto;
      height: fit-content;
    }
  }
  .headerClose {
    margin: 20px 0px;
    display: flex;
    z-index: 9999;
    position: relative;
    justify-content: space-between;
    .logo_feedback {
      img {
        width: 40px;
        height: fit-content;
      }
    }
    .icon {
      background: #e7e9ee;
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      transition: 0.3s;
      justify-content: center;
      span {
        transition: 0.3s;
        color: #7e78ef;
        font-size: 17px;
      }
      &:hover {
        cursor: pointer;
        background: #e7e9ee00;
        span {
          font-size: 26px;
        }
      }
    }
  }
  .titles {
    display: grid;
    gap: 2px;
    margin-bottom: 25px;
    strong {
      font-size: 18px;
    }
    span {
      font-size: 16px;
    }
  }
  .values {
    margin-bottom: 20px;
    display: grid;
    gap: 10px;
    textarea {
      // resize: none;
      background-color: #7e78ef1c;
      border-radius: 0.65rem;
      border: 1px solid #7e78ef52 !important;
      color: #000;
      &::placeholder {
        color: #5e6278 !important;
        font-size: 14px !important;
        letter-spacing: 0.5px !important;
      }

      line-height: 40px;
      padding: 10px 15px 19px;
      background-image: url(/media/Line.png);
      background-repeat: repeat-y, repeat;
      background-size: 400px 40px;
      background-attachment: local;
      width: 100%;
      // height: 200px;
      resize: none;
    }
  }

  textarea:focus-visible {
    border: 1px solid #7e78ef52 !important;
    outline-offset: 0px;
    outline: none;
  }
  .feedbackChip {
    border: 1px solid #dfdfdf;
    padding: 10px;
    border-radius: 5px;
    background-color: #f5e1ea94;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    button:first-child {
      margin-right: 10px;
    }
  }
  .actionIcon {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
    svg {
      font-size: 24px;
      color: #f8a3a8;
      cursor: pointer;
      &:hover {
        color: #f082b0;
      }
    }
  }
`;

export default Wrapper;
