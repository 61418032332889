import styled from "styled-components";

export const Wrapper = styled.div`
  .apexcharts--tooltip-title {
    background: #eceff1;
    border-bottom: 1px solid #ddd;
    padding: 6px;
    margin-bottom: 4px;
  }
  .apexcharts--tooltip {
    background: #fff !important;
    border: 1px solid #cacaca !important;
    border-radius: 7px !important;
    overflow: auto !important;
  }
  .apexcharts--tooltip-series {
    margin: 6px 0px;
    display: flex;
    min-width: 140px;
    max-height: 110px;
    .apexcharts--tooltip-marker {
    }
  }
  .apexcharts--tooltip-series-group {
    padding-bottom: 4px;
    padding: 0 10px;
    text-align: left;
    justify-content: left;
    align-items: center;
    font-family: inherit;
  }
  .apexcharts--tooltip-marker {
    width: 10px;
    height: 10px;
    position: relative;
    top: 0;
    margin-right: 10px;
    border-radius: 50%;
  }
  .apexcharts--tooltip-text {
  }
  .over-view-card {
    .icon1,
    .icon2,
    .icon3 {
      width: 70px;
      height: 70px;
      background: #f0e2e8;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      svg {
        path,
        rect {
          fill: #9c4063;
        }
      }
    }
    .title {
      font-size: 18px;
      font-weight: 100;
    }
  }
  .icon2 {
    background: #e2e3f0 !important;
    svg {
      path,
      rect {
        fill: #4a52a1 !important;
      }
    }
  }
  .icon3 {
    background: #fbf2dd !important;
    svg {
      path,
      rect {
        fill: #eca61d !important;
      }
    }
  }
  .right-card {
    display: grid;
    justify-items: end;
  }
  .card-body-overview {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 320px;
  }
  .details {
    display: flex;
    align-items: center;
  }
  .over-view-card {
    background: #fff;
    padding: 15px 15px 15px 0px;
    border-radius: 10px;
    display: flex;
    position: relative;
  }
`;
