import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Carousel,
  Empty,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Steps,
  Tag,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";
import Icons from "../../../../components/icons";

const config = {
  title: "Amazon",
  center: true,
  content: (
    <>
      <span>Are you sure to upload the Catalog data to Amazon?</span>
    </>
  ),
};

const ChildUpdate = (props) => {
  const {
    visible,
    onClose,
    selectedRow,
    UpdateCatalogModal,
    StartLoading,
    updateLoading,
    CatalogManualMappingAction,
    setCurrent,
    current,
    marketplaceSelected,
    form,
  } = props;
  const pageType =
    Object?.keys(selectedRow || {})?.length === 0 ? "Add" : "Edit";

  const [modal, contextHolder] = Modal.useModal();
  const imagesOthers = Object.entries(selectedRow)
    .reduce((acc, [key, value]) => {
      if (key.includes("other_image_url")) {
        acc.push(value);
      }
      return acc;
    }, [])
    .join(",");

  const [selectedImagesList, setSelectedImagesList] = useState(imagesOthers);
  const [imageUrl, setImageUrl] = useState("fakeImage");
  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (selectedRow) {
      setImageUrl(selectedRow?.main_image_url);
      const obj = {
        ...selectedRow,
        price: selectedRow?.price === "-" ? 0 : selectedRow?.price || 0,
        website_shipping_weight:
          selectedRow?.website_shipping_weight === "-"
            ? 0
            : selectedRow?.website_shipping_weight || 0,
        item_length:
          selectedRow?.item_length === "-"
            ? 0
            : selectedRow?.website_shipping_weight || 0,
        item_width:
          selectedRow?.item_width === "-" ? 0 : selectedRow?.item_width || 0,
        item_height:
          selectedRow?.item_height === "-" ? 0 : selectedRow?.item_height || 0,

        product_cost:
          selectedRow?.product_cost === "-"
            ? 0
            : selectedRow?.product_cost || 0,

        images_list: imagesOthers,
        main_image_url: selectedRow?.main_image_url,
      };

      form.setFieldsValue(obj);
      setSelectedImagesList(imagesOthers);
    }
  }, [selectedRow]);

  const { token } = theme.useToken();

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "Product Unique Identification",
      content: (
        <>
          <div className="row">
            <div className="col">
              <Form.Item
                label="ASIN"
                name="asin"
                rules={[
                  {
                    required: true,
                    message: "Please input ASIN!",
                  },
                  {
                    pattern: /^[a-zA-Z0-9\-]+$/,
                    message: "ASIN can only contain letters, numbers and -",
                  },
                ]}
              >
                <Input disabled={pageType === "Edit"} />
              </Form.Item>
            </div>

            <div className="col">
              <Form.Item
                label="SKU"
                name="sku"
                rules={[
                  {
                    required: true,
                    message: "Please input SKU!",
                  },
                  {
                    pattern: /^[\w-Aa1]+$/,
                    message: "SKU can only contain letters, numbers and -Aa1",
                  },
                ]}
              >
                <Input disabled={pageType === "Edit"} />
              </Form.Item>
            </div>

            <div className="col">
              <Form.Item
                label="Parent SKU"
                name="parent_sku"
                rules={[
                  {
                    pattern: /^[\w-Aa1]+$/,
                    message: "SKU can only contain letters, numbers and -Aa1",
                  },
                ]}
              >
                <Input disabled={pageType === "Edit"} />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item
                rules={[{ required: true, message: "Please input EAN!" }]}
                label="EAN"
                name="ean"
              >
                <Input disabled={pageType === "Edit"} />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Item label="Category" name="category">
                <Input />
              </Form.Item>
            </div>

            <div className="col">
              <Form.Item label="Sub-Category" name="sub_category">
                <Input />
              </Form.Item>
            </div>

            <div className="col">
              <Form.Item label="Brand" name="brand">
                <Input />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item label="Marketplace ID" name="marketplace_id">
                <Input disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Form.Item label="Relationship Type" name="relationship_type">
                <Select>
                  <Select.Option value="parent">Parent</Select.Option>
                  <Select.Option value="child">Child</Select.Option>
                  <Select.Option value="single_product">
                    Single Product
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item label="Status" name="status">
                <Select>
                  <Select.Option value="active">Active</Select.Option>
                  <Select.Option value="inactive">InActive</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item
                rules={[
                  { required: true, message: "Please select Account Type!" },
                ]}
                label="Account Type"
                name="account_type"
              >
                <Select
                  options={[
                    {
                      label: "Seller",
                      value: "seller",
                    },
                    {
                      label: "Vendor",
                      value: "vendor",
                    },
                  ]}
                />
              </Form.Item>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Product Details",
      content: (
        <div className="row">
          <div className="col-12 px-3">
            <div className="row">
              <div className="col-4">
                <Form.Item label="Product Name" name="product_name">
                  <Input />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Product Title" name="product_title">
                  <Input />
                </Form.Item>
              </div>
              <div className="col-2">
                <Form.Item
                  label="Product Cost"
                  name="product_cost"
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                      message:
                        "Please input number or decimal value with 2 decimal place",
                    },
                  ]}
                >
                  <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-2">
                <Form.Item
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                      message:
                        "Please input number or decimal value with 2 decimal place",
                    },
                  ]}
                  label="Price"
                  name="price"
                >
                  <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <Form.Item
                  label="Product Description"
                  name="product_description"
                >
                  <Input.TextArea
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                  />
                </Form.Item>
              </div>

              <div className="col-4">
                <Form.Item label="Bullet Point 1" name="bullet_point_1">
                  <Input.TextArea
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Bullet Point 2" name="bullet_point_2">
                  <Input.TextArea
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                  />
                </Form.Item>
              </div>

              <div className="col-4">
                <Form.Item label="Bullet Point 3" name="bullet_point_3">
                  <Input.TextArea
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Bullet Point 4" name="bullet_point_4">
                  <Input.TextArea
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                  />
                </Form.Item>
              </div>

              <div className="col-4">
                <Form.Item label="Bullet Point 5" name="bullet_point_5">
                  <Input.TextArea
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Product Image",
      content: (
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-8">
                <Form.Item label="Main Image URL" name="main_image_url">
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        main_image_url: e.target.value,
                      });
                      setImageUrl(e?.target.value);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-4 w-100px h-100px d-flex align-items-center justify-content-center">
                <img width={"100px"} height={"100px"} src={imageUrl} />
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <Form.Item label="Other Images" name="images_list">
                  <Input.TextArea
                    onChange={(e) => {
                      form.setFieldsValue({
                        images_list: e.target.value,
                      });
                      setSelectedImagesList(e.target.value);
                    }}
                    autoSize={{
                      minRows: 3,
                      maxRows: 3,
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-4 " style={{ paddingTop: 6 }}>
                <Carousel autoplay>
                  {(selectedImagesList?.split(",") || [])?.length === 0 ? (
                    <div>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    (selectedImagesList?.split(",") || [])?.map((d, i) => (
                      <div key={i}>
                        <img
                          style={{
                            height: "100px",
                            color: "#fff",
                            lineHeight: "100px",
                            textAlign: "center",
                            border: "1px solid #364d79",
                            marginBottom: 0,
                            borderRadius: "8px",
                          }}
                          src={d}
                        />
                      </div>
                    ))
                  )}
                </Carousel>
              </div>
            </div>

            {/* selectedImagesList */}
          </div>
        </div>
      ),
    },
    {
      title: "Product Specifications",
      content: (
        <div className="row">
          <div className="col-12 px-3">
            <div className="row">
              <div className="col">
                <Form.Item
                  label="Volume/Capacity Name"
                  name="volume_capacity_name"
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="col">
                <Form.Item label="Style Name" name="style_name">
                  <Input />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item label="Color Name" name="color_name">
                  <Input />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item label="Size Name" name="size_name">
                  <Input />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item label="Material Name" name="material_name">
                  <Input />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item label="Flavor Name" name="flavor_name">
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Form.Item
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (!value || !/^\d+(\.\d{1})?$/.test(value)) {
                          return Promise.reject(
                            "Please input a valid decimal number"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  label="Item Length"
                  name="item_length"
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (!value || !/^\d+(\.\d{1})?$/.test(value)) {
                          return Promise.reject(
                            "Please input a valid decimal number"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  label="Item Width"
                  name="item_width"
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (!value || !/^\d+(\.\d{1})?$/.test(value)) {
                          return Promise.reject(
                            "Please input a valid decimal number"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  label="Item Height"
                  name="item_height"
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item
                  label="Website Shipping Weight"
                  name="website_shipping_weight"
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (!value || !/^\d+(\.\d{1})?$/.test(value)) {
                          return Promise.reject(
                            "Please input a valid decimal number"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Form.Item label="Variation Theme" name="variation_theme">
                  <Input />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item label="Manufacturer" name="manufacturer">
                  <Input />
                </Form.Item>
              </div>
              <div className="col">
                <Form.Item label="Ingredients" name="ingredients">
                  <Input />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center mb-3">
        {/*begin::User*/}
        <div className="d-flex align-items-center flex-grow-1">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              form.resetFields();
              onClose();
            }}
          >
            <Icon icon="material-symbols:arrow-back" width={25} height={25} />
          </div>
          {pageType === "Edit" ? (
            <>
              {" "}
              <div className="symbol symbol-50px me-5">
                <img
                  src={selectedRow?.main_image_url}
                  alt
                  onError={(e) =>
                    (e.target.src =
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png")
                  }
                />
              </div>
              <div className="d-flex flex-column">
                <div class="timeline-content fw-bold text-gray-800 mb-2">
                  Parent SKU&nbsp;:&nbsp;
                  <a href="#" class="text-primary">
                    {selectedRow?.parent_sku}
                  </a>
                  &nbsp;&nbsp;&nbsp; ASIN&nbsp;:&nbsp;
                  <a href="#" class="text-primary">
                    {selectedRow?.asin}
                  </a>
                </div>

                <div>
                  <Tag bordered={false} color="geekblue">
                    Category:&nbsp;{selectedRow?.category}
                  </Tag>
                  <Tag bordered={false} color="gold">
                    Sub-Category:&nbsp;{selectedRow?.sub_category}
                  </Tag>
                  <Tag bordered={false} color="green">
                    Status:&nbsp;{selectedRow?.status}
                  </Tag>
                </div>
              </div>
            </>
          ) : (
            <h3 className="mb-0 ms-3">Add</h3>
          )}
        </div>
        {/*end::User*/}
        {/*begin::Menu*/}
        <div className="my-0">{/*end::Menu 2*/}</div>
        {/*end::Menu*/}
      </div>

      <div className="card-body pb-8">
        <Steps current={current} items={items} />
        <Form form={form} layout="vertical">
          <div className="row">
            <div className="col-12 ">
              <div style={contentStyle} className="px-5 pt-3">
                {steps?.map((d, i) => {
                  return (
                    <div style={{ display: i === current ? "block" : "none" }}>
                      {d.content}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div className="row d-none">
            <div className="col-12 px-3">
              <div className="row">
                <div className="col">
                  <Form.Item label="ASIN" name="asin">
                    <Input />
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item
                    label="Child ASIN"
                    name="asin"
                    rules={[
                      { required: true, message: "Please input Child ASIN!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item
                    label="Child SKU"
                    name="sku"
                    rules={[
                      { required: true, message: "Please input Child SKU!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item label="Category" name="category">
                    <Input />
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item label="Sub-Category" name="sub_category">
                    <Input />
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item label="Brand" name="brand">
                    <Input />
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item label="EAN" name="ean">
                    <Input />
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item label="Status" name="status">
                    <Select
                      options={[
                        {
                          label: "Active",
                          value: "active",
                        },
                        {
                          label: "In Active",
                          value: "Inactive",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-12 px-3">
              <div className="row">
                <div className="col-4">
                  <Form.Item label="Product Name" name="product_name">
                    <Input />
                  </Form.Item>
                </div>
                <div className="col-4">
                  <Form.Item label="Product Title" name="product_title">
                    <Input />
                  </Form.Item>
                </div>
                <div className="col-2">
                  <Form.Item label="Product Cost" name="product_cost">
                    <InputNumber min={0} style={{ width: "100%" }} />
                  </Form.Item>
                </div>
                <div className="col-2">
                  <Form.Item label="Price" name="price">
                    <InputNumber min={0} style={{ width: "100%" }} />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <Form.Item
                    label="Product Description"
                    name="product_description"
                  >
                    <Input.TextArea
                      autoSize={{
                        minRows: 2,
                        maxRows: 2,
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="col-4">
                  <Form.Item label="Bullet Point 1" name="bullet_point_1">
                    <Input.TextArea
                      autoSize={{
                        minRows: 2,
                        maxRows: 2,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-4">
                  <Form.Item label="Bullet Point 2" name="bullet_point_2">
                    <Input.TextArea
                      autoSize={{
                        minRows: 2,
                        maxRows: 2,
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="col-4">
                  <Form.Item label="Bullet Point 3" name="bullet_point_3">
                    <Input.TextArea
                      autoSize={{
                        minRows: 2,
                        maxRows: 2,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-4">
                  <Form.Item label="Bullet Point 4" name="bullet_point_4">
                    <Input.TextArea
                      autoSize={{
                        minRows: 2,
                        maxRows: 2,
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="col-4">
                  <Form.Item label="Bullet Point 5" name="bullet_point_5">
                    <Input.TextArea
                      autoSize={{
                        minRows: 2,
                        maxRows: 2,
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-12 px-3">
              <div className="row">
                <div className="col">
                  <Form.Item
                    label="Volume/Capacity Name"
                    name="volume_capacity_name"
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item
                    label="Website Shipping Weight"
                    name="website_shipping_weight"
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item label="Variation Theme" name="variation_theme">
                    <Input />
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item label="Style Name" name="style_name">
                    <Input />
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item label="Color Name" name="color_name">
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-12 px-3">
              <div className="row">
                <div className="col">
                  <Form.Item label="Size Name" name="size_name">
                    <Input />
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item label="Material Name" name="material_name">
                    <Input />
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item label="Flavor Name" name="flavor_name">
                    <Input />
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item label="Manufacturer" name="manufacturer">
                    <Input />
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item label="Item Length" name="item_length">
                    <Input />
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item label="Item Width" name="item_width">
                    <Input />
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item label="Item Height" name="item_height">
                    <Input />
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item label="Ingredients" name="ingredients">
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div> */}
        </Form>
        <div
          style={{
            marginTop: 24,
          }}
          className="d-flex justify-content-between align-items-center"
        >
          {current > 0 ? (
            <Button onClick={() => prev()}>Previous</Button>
          ) : (
            <>&nbsp;</>
          )}
          <div>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                htmlType="submit"
                type="primary"
                loading={updateLoading}
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      StartLoading(true);
                      if (pageType === "Edit") {
                        UpdateCatalogModal(selectedRow?.id, {
                          ...values,
                          main_image_url: values?.main_image_url,
                          ...values?.images_list
                            ?.split(",")
                            ?.reduce((acc, d, i) => {
                              acc[`other_image_url_${i + 1}`] = d;
                              return acc;
                            }, {}),
                        });
                      } else {
                        CatalogManualMappingAction({
                          ...values,
                          marketplace_id: marketplaceSelected?.marketplace_id,
                          main_image_url: values?.main_image_url,
                          ...values?.images_list
                            ?.split(",")
                            ?.reduce((acc, d, i) => {
                              acc[`other_image_url_${i + 1}`] = d;
                              return acc;
                            }, {}),
                        });
                      }
                    })
                    .catch((info) => {
                      console.log(info, "info");
                    });
                }}
              >
                Save
              </Button>
            )}
            {/* {current === steps.length - 1 && (
              <Button
                onClick={async () => {
                  const confirmed = await modal.confirm(config);
                  console.log("Confirmed: ", confirmed);
                }}
                type="dashed"
                className="ms-3"
              >
                <Icons type="amazon" />
                Save and Submit to Amazon
              </Button>
            )} */}
          </div>
        </div>
      </div>
      <div className="d-none card-footer">
        <Button key="back" className="me-3" onClick={handleCancel}>
          Back
        </Button>
        <Button
          type="primary"
          loading={updateLoading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                StartLoading(true);
                UpdateCatalogModal(selectedRow?.id, values);
              })
              .catch((info) => {
                console.log(info, "info");
              });
          }}
        >
          Submit
        </Button>

        <Button
          onClick={async () => {
            const confirmed = await modal.confirm(config);
            console.log("Confirmed: ", confirmed);
          }}
          type="dashed"
          className="ms-3"
        >
          <Icons type="amazon" />
          Submit to Amazon
        </Button>
      </div>
      {contextHolder}
    </div>
  );
};

export default ChildUpdate;
