import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignManagement from "../../modules/pages/campaign-management";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const CampaignManagement_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignManagement);

export default CampaignManagement_;
