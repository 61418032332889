import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdminSetting from "../../modules/pages/setting";
import {
  AllAdminAAction,
  GetUserListAction,
  GetAdminConfigAction,
  AddAdminConfigAction,
  fakeActionManageUser,
} from "../../redux/module_/manage-user/index.action";

const mapStateToProps = (state) => ({
  GetAdminListResponse: state.User.GetAdminListResponse,
  GetAdminConfigDataResponse: state.User.GetAdminConfigDataResponse,
  UserListResponse: state.User.UserListResponse,
  AddAdminConfigDataResponse: state.User.AddAdminConfigDataResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      AllAdminAAction,

      GetUserListAction,
      GetAdminConfigAction,
      AddAdminConfigAction,
      fakeActionManageUser,
    },
    dispatch
  );

const AdminSetting_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSetting);

export default AdminSetting_;
