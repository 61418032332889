import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CatalogueByCountry from "../../modules/pages/catalogue-by-country";
import {
  GetCatalogListAction,
  GetCatalogListAmazonAction,
  fakeActionCataLogCountry,
} from "../../redux/module_/catalogue-by-country/index.action";
import {
  SalesChannelListAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";

const mapStateToProps = (state) => ({
  GetCatalogListResponse: state.CataLogCountry.GetCatalogListResponse,
  GetCatalogListAmazonResponse:
    state.CataLogCountry.GetCatalogListAmazonResponse,

  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetCatalogListAction,
      GetCatalogListAmazonAction,
      fakeActionCataLogCountry,

      SalesChannelListAction,
      fakeActionCatalog,
    },
    dispatch
  );

const CatalogueByCountry_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogueByCountry);

export default CatalogueByCountry_;
