import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SidebarASINWiser from "../../modules/layouts/sidebar";
import {
  SwitchUserBackAction,
  fakeActionManageUser,
} from "../../redux/module_/manage-user/index.action";

const mapStateToProps = (state) => ({
  SwitchUserBackResponse: state.User.SwitchUserBackResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SwitchUserBackAction,
      fakeActionManageUser,
    },
    dispatch
  );

const Sidebar_ = connect(mapStateToProps, mapDispatchToProps)(SidebarASINWiser);

export default Sidebar_;
