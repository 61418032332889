import { Tag } from "antd";
import React from "react";

const View2 = () => {
  return (
    <div className="View2  p-8">
      <label className="fs-1">Pre-fulfillment Cancel Rate</label>
      <div className="mt-2 fs-5">
        Target&nbsp;:&nbsp;
        <Tag className="border-remove" color="purple">
          under 2.5%
        </Tag>
      </div>
      <div className="row pt-5">
        <div className="col-xl-6 col-md-12">
          <div className="fs-1 mb-5">N/A</div>
          <div>
            Seller-cancelled orders&nbsp;:&nbsp;<b>0</b>
          </div>
          <div className="mb-5">
            Total orders&nbsp;:&nbsp;<b>0</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View2;
