import axiosCall from "../../configurations/network-services/axiosCall";

const prefix = "products";

export const GetProductsListAction = (data) => {
  const path = `${prefix}?CategoryId=${data?.CategoryId || ""}&DomainId=${
    data?.DomainId || ""
  }&Color=${data?.Color || ""}&Brand=${data?.Brand || ""}&page=${
    data?.page || 1
  }&perPage=${data?.perPage || 10}&SearchValue=${data.search}${Object.entries(
    data?.list
  )
    ?.map((d) => {
      return `&${d[0]}=${d[1] || ""}`;
    })
    ?.join("")}`;
  const responseType = "GET_PRODUCTS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCategoriesListAction = (domain_id) => {
  const path = `categoires?domain_id=${domain_id || ""}`;
  const responseType = "GET_CATEGORIES_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetConfigurationAction = () => {
  const path = `${prefix}/get-configuration`;
  const responseType = "GET_CONFIGURATION_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateConfigurationAction = (data) => {
  const path = `${prefix}/update-configuration`;
  const responseType = "UPDATE_CONFIGURATION_LIST";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetBrandsListAction = (data) => {
  const path = `brands?CategoryId=${data?.CategoryId || ""}&Name=${
    data?.Name || ""
  }&isAll=${data?.isAll}&page=${data?.page || 1}&perPage=${
    data?.perPage || 10
  }&DomainId=${data?.DomainId}&SearchBrand=${data?.searchBrand || ""}`;
  const responseType = "GET_BRANDS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetBrandsListFilterAction = (data) => {
  const path = `brands?CategoryId=${data?.CategoryId || ""}&Name=${
    data?.Name || ""
  }&isAll=${data?.isAll}&page=${data?.page || 1}&perPage=${
    data?.perPage || 10
  }&DomainId=${data?.DomainId || ""}&SearchBrand=${data?.SearchBrand || ""}`;
  const responseType = "GET_BRANDS_FILTER_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetDomainListAction = (data) => {
  const path = `domain`;
  const responseType = "GET_DOMAIN_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetProductDetailsAction = (data) => {
  const path = `product-details?CategoryId=${data?.categoryId || ""}&Asin=${
    data?.asin || ""
  }&DomainId=${data?.DomainId || ""}`;
  const responseType = "GET_PRODUCT_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const PutStoreMyListAction = (data) => {
  const path = `${prefix}/store-my-list`;
  const responseType = "PUT_STORE_MY_LIST_PRODUCT";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const AllMyListAction = () => {
  const path = `${prefix}/all-my-list`;
  const responseType = "GET_ALL_MY_LIST_IN_PRODUCTS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteMyListSingleAction = (asin) => {
  const path = `${prefix}/delete-my-list`;
  const responseType = "DELETE_MY_LIST_SINGLE_PRODUCT";
  return axiosCall(
    "delete",
    path,
    responseType,
    {
      asin: asin,
    },
    {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  );
};

export const fakeActionProducts = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_PRODUCTS", state: data });
};
