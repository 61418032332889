import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "./style";

import {
  Table,
  Space,
  Button,
  Popconfirm,
  Select,
  Tag,
  message,
  Popover,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import AddCriteria from "./lib/add";
import { useSelector } from "react-redux";
import { DefaultPerPage } from "../../../config";
import Pagination from "../../../components/pagination";

const Criteria = ({
  GetCriteriaListAction,
  GetMarketplaceListAction,
  GetCriteriaViewAction,
  AddCriteriaViewAction,
  CloneAmazonLQSAction,
  UpdateCriteriaAction,
  DeleteCriteriaAction,
  fakeActionCriteria,
}) => {
  const popoverRef = useRef();
  const [addView, setAddView] = useState(false);
  // Marketplace States
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelect, setMarketplaceSelect] = useState({
    sales_channel: null,
    region: null,
    id: null,
  });

  const [moveLogStatus, setMoveLogStatus] = useState(false);
  // Table States
  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [selectedRow, setSelectedRow] = useState({});
  const [selectedMoveData, setSelectedMoveData] = useState({
    id: null,
    marketplace_id: [],
  });

  const [visiblePopovers, setVisiblePopovers] = useState({});

  const handleVisibleChange = (key, visible) => {
    setVisiblePopovers((prev) => ({
      ...prev,
      [key]: visible,
    }));
  };

  const closeAllPopovers = () => {
    setVisiblePopovers({});
  };

  // Redux states
  const GetMarketplaceListResponse = useSelector(
    (state) => state.Criteria.GetMarketplaceListResponse || {}
  );
  const AddNewItemsCriterialResponse = useSelector(
    (state) => state.Criteria.AddNewItemsCriterialResponse || {}
  );
  const ItemsCriteriaListResponse = useSelector(
    (state) => state.Criteria.ItemsCriteriaListResponse || {}
  );
  const ItemsCriteriaViewResponse = useSelector(
    (state) => state.Criteria.ItemsCriteriaViewResponse || {}
  );
  const UpdateItemsCriteriaResponse = useSelector(
    (state) => state.Criteria.UpdateItemsCriteriaResponse || {}
  );
  const DeleteItemsCriteriaResponse = useSelector(
    (state) => state.Criteria.DeleteItemsCriteriaResponse || {}
  );
  const CloneAmazonLQSResponse = useSelector(
    (state) => state.Criteria.CloneAmazonLQSResponse || {}
  );

  useEffect(() => {
    if (CloneAmazonLQSResponse?.status === true) {
      setSelectedMoveData({
        id: null,
        marketplace_id: [],
      });
      closeAllPopovers();
      message.destroy();
      message.success(CloneAmazonLQSResponse?.message || "");
      fakeActionCriteria("CloneAmazonLQSResponse");
    } else if (CloneAmazonLQSResponse?.status === false) {
      message.destroy();
      message.error(CloneAmazonLQSResponse?.message || "");
      fakeActionCriteria("CloneAmazonLQSResponse");
    }
  }, [CloneAmazonLQSResponse]);

  useEffect(() => {
    if (DeleteItemsCriteriaResponse?.status === true) {
      setTableLoading(true);
      GetCriteriaListAction({
        sales_channel: marketplaceSelect?.sales_channel,
        region: marketplaceSelect?.region,
        page: page,
        perPage: pageSize,
      });
      message.destroy();
      message.success(DeleteItemsCriteriaResponse?.message || "");
      fakeActionCriteria("DeleteItemsCriteriaResponse");
    } else if (DeleteItemsCriteriaResponse?.status === false) {
      message.destroy();
      message.error(DeleteItemsCriteriaResponse?.message || "");
      fakeActionCriteria("DeleteItemsCriteriaResponse");
    }
  }, [DeleteItemsCriteriaResponse]);
  useEffect(() => {
    if (AddNewItemsCriterialResponse?.status === true) {
      setAddView(false);
      setSelectedRow({});
      setTableLoading(true);
      GetCriteriaListAction({
        sales_channel: marketplaceSelect?.sales_channel,
        region: marketplaceSelect?.region,
        page: page,
        perPage: pageSize,
      });
      message.success(AddNewItemsCriterialResponse?.message || "");
      fakeActionCriteria("AddNewItemsCriterialResponse");
    } else if (AddNewItemsCriterialResponse?.status === false) {
      message.error(
        AddNewItemsCriterialResponse?.error?.[0] ||
          AddNewItemsCriterialResponse?.message ||
          ""
      );
      fakeActionCriteria("AddNewItemsCriterialResponse");
    }
  }, [AddNewItemsCriterialResponse]);
  useEffect(() => {
    if (UpdateItemsCriteriaResponse?.status === true) {
      setAddView(false);
      setSelectedRow({});
      setTableLoading(true);
      GetCriteriaListAction({
        sales_channel: marketplaceSelect?.sales_channel,
        region: marketplaceSelect?.region,
        page: page,
        perPage: pageSize,
      });
      message.success(UpdateItemsCriteriaResponse?.message || "");
      fakeActionCriteria("UpdateItemsCriteriaResponse");
    } else if (UpdateItemsCriteriaResponse?.status === false) {
      message.error(
        UpdateItemsCriteriaResponse?.error?.[0] ||
          UpdateItemsCriteriaResponse?.message ||
          ""
      );
      fakeActionCriteria("UpdateItemsCriteriaResponse");
    }
  }, [UpdateItemsCriteriaResponse]);

  const columns = [
    {
      title: "Criteria Name",
      dataIndex: "name",
      key: "name",
      render: (e) => {
        return <b>{e}</b>;
      },
    },
    {
      title: "Criteria Type",
      dataIndex: "criteria_type",
      key: "criteria_type",
      render: (e) => {
        return (
          <Tag
            style={{ textTransform: "uppercase" }}
            bordered={false}
            color="green"
          >
            {e}
          </Tag>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (e) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {e?.split("_")?.join(" ")}
          </span>
        );
      },
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (e) => {
        return (
          <Tag bordered={false} color="cyan">
            {e}
          </Tag>
        );
      },
    },
    // {
    //   title: "Clone",
    //   key: "clone",
    //   width: 200,
    //   render: (_, record) => (
    //     <CopyOutlined
    //       onClick={() => {
    //         setAddView(true);
    //         setSelectedRow({ ...record, clone: true });
    //       }}
    //     />
    //   ),
    // },
    {
      title: "Action",
      key: "action",

      render: (text, record) => (
        <Space size="middle">
          <div id="close-popover" className="d-flex flex-shrink-0">
            <button
              onClick={() => onView(record)}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
            >
              <span className="svg-icon svg-icon-3">
                <svg
                  width={26}
                  height={26}
                  fill="#a2a5b8"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 17.367c-4.136 0-7.621-2.114-9.82-5.367C4.38 8.746 7.865 6.633 12 6.633S19.621 8.746 21.82 12c-2.199 3.253-5.684 5.367-9.82 5.367ZM12 5.1C7.105 5.1 3.04 7.716.617 11.594a.767.767 0 0 0 0 .812C3.04 16.283 7.105 18.9 12 18.9c4.895 0 8.96-2.616 11.384-6.494a.767.767 0 0 0 0-.812C20.96 7.716 16.894 5.1 12 5.1Zm0 9.967a3.067 3.067 0 1 0 0-6.134 3.067 3.067 0 0 0 0 6.134Z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </button>
            <button
              onClick={() => onEdit(record)}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
            >
              <span className="svg-icon svg-icon-3">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                    fill="currentColor"
                  />
                  <path
                    d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
            <Popconfirm
              title={`Delete the Criteria`}
              description={`Are you sure to delete this  ${record?.name}?`}
              okText="Yes"
              placement="left"
              onConfirm={() => {
                message.destroy();
                message.loading("Loading...", 0);
                DeleteCriteriaAction(record?.id);
              }}
              cancelText="No"
            >
              <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                <span className="svg-icon svg-icon-3">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.5"
                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.5"
                      d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
            </Popconfirm>
            <Popover
              placement="left"
              open={visiblePopovers?.[record?.id]}
              onOpenChange={(visible) =>
                handleVisibleChange(record?.id, visible)
              }
              content={
                <div
                  ref={popoverRef}
                  className="d-grid"
                  style={{ justifyItems: "start" }}
                >
                  <Select
                    options={[
                      {
                        label:
                          selectedMoveData?.marketplace_id?.length ===
                          marketplaceList?.length
                            ? "Unselect All"
                            : "Select All",
                        value: "all",
                      },
                      ...marketplaceList,
                    ]}
                    className="w-200px mb-3"
                    mode="multiple"
                    maxTagCount="responsive"
                    size="large"
                    onChange={(e, _) => {
                      console.log(
                        e?.find((c) => c === "all"),
                        "e"
                      );
                      if (e?.find((c) => c === "all")) {
                        setSelectedMoveData({
                          ...selectedMoveData,
                          marketplace_id:
                            selectedMoveData?.marketplace_id?.length ===
                            marketplaceList?.length
                              ? []
                              : marketplaceList?.map((d) => d?.value),
                        });
                      } else {
                        setSelectedMoveData({
                          ...selectedMoveData,
                          marketplace_id: e,
                        });
                      }
                    }}
                    value={selectedMoveData?.marketplace_id}
                  />

                  <Button
                    onClick={() => {
                      CloneAmazonLQSAction({
                        ...selectedMoveData,
                        id: record?.id,
                        marketplace_id:
                          selectedMoveData?.marketplace_id?.join(","),
                      });
                      setSelectedMoveData({
                        ...selectedMoveData,
                        id: record?.id,
                      });
                    }}
                    type="primary"
                  >
                    Clone
                  </Button>
                  {/* {clickContent} */}
                  {/* <a onClick={hide}>Close</a> */}
                </div>
              }
              title="Clone to Other Marketplace"
              trigger="click"
              id="clone-popover"
              // open={moveLogStatus}
              // onOpenChange={(e) => setMoveLogStatus(e)}
            >
              <button
                // onClick={() => {
                //   setTimeout(() => {
                //     document.getElementById("test-pop").click();
                //   }, 2000);
                // }}
                // onClick={() => onEdit(record)}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2"
              >
                <span className="svg-icon svg-icon-3">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.4 18H16C15.7 18 15.5 17.9 15.3 17.7L12.5 14.9C12.1 14.5 12.1 13.9 12.5 13.5C12.9 13.1 13.5 13.1 13.9 13.5L16.4 16H18.4V18ZM16 6C15.7 6 15.5 6.09999 15.3 6.29999L11 10.6L6.70001 6.29999C6.50001 6.09999 6.3 6 6 6H3C2.4 6 2 6.4 2 7C2 7.6 2.4 8 3 8H5.60001L9.60001 12L5.60001 16H3C2.4 16 2 16.4 2 17C2 17.6 2.4 18 3 18H6C6.3 18 6.50001 17.9 6.70001 17.7L16.4 8H18.4V6H16Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M21.7 6.29999C22.1 6.69999 22.1 7.30001 21.7 7.70001L18.4 11V3L21.7 6.29999ZM18.4 13V21L21.7 17.7C22.1 17.3 22.1 16.7 21.7 16.3L18.4 13Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
            </Popover>
          </div>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (GetMarketplaceListResponse?.status === true) {
      setMarketplaceList(
        GetMarketplaceListResponse?.data?.map((d) => ({
          label: (
            <div>
              <img
                src={`/domainImage/${d?.id}.png`}
                style={{ height: "20px" }}
                loading="lazy"
                className="me-3"
                onError={(e) => {
                  e.target.src = "/media/domainImage/red-flag.png";
                }}
              />
              <span>{d?.sales_channel}</span>
            </div>
          ),
          value: d?.id,
          sales_channel: d?.sales_channel,
          region: d?.region_name,
        }))
      );
      setMarketplaceLoading(false);
      if (GetMarketplaceListResponse?.data?.length > 0) {
        const { id, region_name, sales_channel } =
          GetMarketplaceListResponse?.data?.filter(
            (d) => d?.id === "APJ6JRA9NG5V4"
          )?.[0];
        setMarketplaceSelect({
          sales_channel: sales_channel,
          region: region_name,
          id: id,
        });
        GetCriteriaListAction({
          sales_channel: sales_channel,
          region: region_name,
          page: page,
          perPage: pageSize,
        });
      } else {
        setTableLoading(false);
      }

      fakeActionCriteria("GetMarketplaceListResponse");
    } else if (GetMarketplaceListResponse?.status === false) {
      fakeActionCriteria("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListResponse]);

  useEffect(() => {
    if (ItemsCriteriaListResponse?.status === true) {
      setTotalPage(
        ItemsCriteriaListResponse?.data?.pagination?.totalCount || 0
      );
      setTableData(
        Array.isArray(ItemsCriteriaListResponse?.data?.records)
          ? ItemsCriteriaListResponse?.data?.records
          : []
      );
      setTableLoading(false);
      fakeActionCriteria("ItemsCriteriaListResponse");
    } else if (ItemsCriteriaListResponse?.status === false) {
      setTableData([]);
      setTableLoading(false);
      fakeActionCriteria("ItemsCriteriaListResponse");
    }
  }, [ItemsCriteriaListResponse]);

  useEffect(() => {
    setTableLoading(true);
    setMarketplaceLoading(true);
    GetMarketplaceListAction();
    return () => {};
  }, []);
  const onView = (record) => {
    setAddView(true);
    setSelectedRow({ ...record, view: true });
  };

  const onEdit = (record) => {
    setAddView(true);
    setSelectedRow(record);
  };

  return (
    <Wrapper className="fadeInBottom">
      <div className="card">
        <div className="card-header">
          <div className="card-title">Criteria</div>
          <div className="card-toolbar">
            <Select
              options={marketplaceList}
              className="w-200px me-3"
              size="large"
              onChange={(e, _) => {
                const { region, sales_channel, value } = _;
                setMarketplaceSelect({
                  sales_channel: sales_channel,
                  region: region,
                  id: value,
                });
                setTableLoading(true);
                GetCriteriaListAction({
                  sales_channel: sales_channel,
                  region: region,
                });
              }}
              value={marketplaceSelect?.id}
            />
            <Button
              size="large"
              onClick={() => setAddView(true)}
              type="primary"
            >
              Add +
            </Button>
          </div>
        </div>
        <div className="card-body">
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            loading={tableLoading}
            scroll={{ x: "max-content" }}
          />
        </div>
        <div className="card-footer pt-2">
          <Pagination
            loading={tableLoading || tableData?.length === 0}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={(e) => {
              setPage(1);
              setPageSize(e);
              setTableLoading(true);
              GetCriteriaListAction({
                sales_channel: marketplaceSelect?.sales_channel,
                region: marketplaceSelect?.region,
                page: 1,
                perPage: e,
              });
            }}
            onPageNo={(e) => {
              setPage(e);

              setTableLoading(true);
              GetCriteriaListAction({
                sales_channel: marketplaceSelect?.sales_channel,
                region: marketplaceSelect?.region,
                page: e,
                perPage: pageSize,
              });
            }}
          />
        </div>
      </div>
      {addView && (
        <AddCriteria
          visible={addView}
          onCancel={() => {
            setSelectedRow({});
            setAddView(false);
          }}
          selectedRow={selectedRow}
          onUpdate={(e) => {
            UpdateCriteriaAction(selectedRow?.id, {
              marketplace_id: marketplaceSelect?.id,
              ...e,
            });
          }}
          onCreate={(e) => {
            AddCriteriaViewAction({
              marketplace_id: marketplaceSelect?.id,
              ...e,
            });
          }}
        />
      )}
    </Wrapper>
  );
};

export default Criteria;
