import React, { useEffect } from "react";
import {
  Table,
  Button,
  Space,
  Select,
  message,
  Upload,
  Tooltip,
  Tag,
  Popconfirm,
} from "antd";
import {
  EyeOutlined,
  DownloadOutlined,
  InboxOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AllMarketPlace,
  DefaultPerPage,
  DownloadUrl,
  ENDPOINT,
  ENDPOINTDownload,
} from "../../../config";
import Pagination from "../../../components/pagination";
import { TableLoading } from "../../../components/table-animation";
import * as XLSX from "xlsx";
import ASINTooltip from "../../../components/tooltip";

const { Dragger } = Upload;

const Wrapper = styled.div`
  .ant-upload-btn {
    padding: 10px !important;
  }
  .ant-upload-drag-container {
    padding: 0px !important;
  }
  .ant-upload-wrapper {
    position: relative;
    .ant-upload-list {
      position: absolute;
      width: -webkit-fill-available;
      z-index: 9;
      bottom: 0px;
      padding: 0px 10px;
      max-width: 350px;
    }
  }
`;

export default function BulkCatalogUpload(props) {
  const {
    SalesChannelListAction,
    ListProductTypeMarketplaceAction,
    DeleteFeedProduct,
    GetListUploadedDataAction,
    fakeActionCatalog,
    FeedProductUploadAction,
    ExportFile,
  } = props;
  const [selectedFilters, setSelectedFilters] = useState({
    marketplace: null,
    product_type: null,
    template_url: null,
  });

  const [selectedRow, setSelectedRow] = useState({});

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    account_type: null,
  });

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [productTypeList, setProductTypeList] = useState([]);
  const [productTypeLoading, setProductTypeLoading] = useState(true);

  const [uploadLoading, setUploadLoading] = useState(false);

  const [fileList, setFileList] = useState([]);
  const [columnsView, setColumnsView] = useState([]);

  console.log(list, "list");

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const GenerateTemplateResponse = useSelector(
    (state) => state.Catalog.GenerateTemplateResponse || {}
  );
  const DeleteFeedProductResponse = useSelector(
    (state) => state.Catalog.DeleteFeedProductResponse || {}
  );
  const ListProductTypeResponse = useSelector(
    (state) => state.Catalog.ListProductTypeResponse || {}
  );

  const GetListUploadedDataResponse = useSelector(
    (state) => state.Catalog.GetListUploadedDataResponse || {}
  );
  const FeedProductUploadResponse = useSelector(
    (state) => state.Catalog.FeedProductUploadResponse || {}
  );

  useEffect(() => {
    if (GenerateTemplateResponse?.status === true) {
      setSelectedFilters({
        ...selectedFilters,
        template_url: GenerateTemplateResponse?.data?.file,
      });
      fakeActionCatalog("GenerateTemplateResponse");
    } else if (GenerateTemplateResponse?.status === false) {
      setSelectedFilters({
        ...selectedFilters,
        template_url: null,
      });
      fakeActionCatalog("GenerateTemplateResponse");
    }
  }, [GenerateTemplateResponse]);

  useEffect(() => {
    if (DeleteFeedProductResponse?.status === true) {
      message.destroy();
      setPage(1);
      setPageSize(DefaultPerPage);
      setLoading(true);
      GetListUploadedDataAction({
        page: 1,
        perPage: DefaultPerPage,
        ...selectedFilters,
        ...marketplaceSelected,
      });
      message.success(DeleteFeedProductResponse?.message);
      fakeActionCatalog("DeleteFeedProductResponse");
    } else if (DeleteFeedProductResponse?.status === false) {
      message.destroy();
      message.error(DeleteFeedProductResponse?.message);
      fakeActionCatalog("DeleteFeedProductResponse");
    }
  }, [DeleteFeedProductResponse]);

  useEffect(() => {
    if (FeedProductUploadResponse?.status === true) {
      setUploadLoading(false);
      setLoading(true);
      setFileList([]);

      setPage(1);
      setPageSize(DefaultPerPage);
      setLoading(true);
      GetListUploadedDataAction({
        page: 1,
        perPage: DefaultPerPage,
        ...selectedFilters,
        ...marketplaceSelected,
      });

      message.success(FeedProductUploadResponse?.message);
      fakeActionCatalog("FeedProductUploadResponse");
    } else if (FeedProductUploadResponse?.status === false) {
      setUploadLoading(false);
      message.error(FeedProductUploadResponse?.message);
      fakeActionCatalog("FeedProductUploadResponse");
    }
  }, [FeedProductUploadResponse]);

  useEffect(() => {
    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();

    return () => {};
  }, []);

  useEffect(() => {
    if (ListProductTypeResponse?.status === true) {
      setProductTypeList(ListProductTypeResponse?.data?.records || []);
      setProductTypeLoading(false);
      fakeActionCatalog("ListProductTypeResponse");
    } else if (ListProductTypeResponse?.status === false) {
      setProductTypeLoading(false);
      setProductTypeList([]);
      fakeActionCatalog("ListProductTypeResponse");
    }
  }, [ListProductTypeResponse]);

  useEffect(() => {
    if (GetListUploadedDataResponse?.status === true) {
      setList(GetListUploadedDataResponse?.data?.records || []);
      setLoading(false);
    } else if (GetListUploadedDataResponse?.status === false) {
      setList([]);
      setLoading(false);
    }
  }, [GetListUploadedDataResponse]);

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );
            console.log(e, "eeeee");

            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                        {/* {`Amazon.${e?.account_type || ''}.${e?.short_name || ''}`} */}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                    account_type: e?.account_type,
                  },
                ],
              });
              if (e?.default_marketplace === 1) {
                setLoading(true);
                GetListUploadedDataAction({
                  marketplace_id: e.marketplace_id,
                  product_type: selectedFilters?.product_type,
                });
                setMarketplaceSelected({
                  marketplace: e?.marketplace,
                  marketplace_id: e.marketplace_id,
                  region: e.region,
                  sales_channel: e.sales_channel,
                  account_type: e?.account_type,
                });
                setProductTypeLoading(true);
                setProductTypeList([]);
                ListProductTypeMarketplaceAction({
                  id: e?.marketplace_flag,
                });
                // setLoading(true);
                // GetListAmazonLQSAction({
                //   page: page,
                //   perPage: pageSize,
                //   sort: listSortFilters?.key,
                //   ...filters,
                //   ...{
                //     region: e?.region,
                //     account_type: e?.account_type,
                //     sales_channel: e?.sales_channel,
                //   },
                // });
              }
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);
        setMarketplaceLoading(false);
        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);

      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  const columns = [
    {
      title: "#",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      render: (e) => {
        return (
          <Link>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
              width={40}
              height={40}
            />{" "}
            {e}
          </Link>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "is_uploaded",
      key: "is_uploaded",

      render: (e) => {
        return (
          <Tag color={e === "1" ? "green" : "red"}>
            {e === "1" ? "Done" : "Failed"}
          </Tag>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "uploaded_date",
      key: "uploaded_date",
    },
    {
      title: "Show Total Products",
      dataIndex: "total_count",
      key: "total_count",
    },
    {
      title: "Product Type",
      dataIndex: "product_type",
      key: "product_type",
      render: (e) => {
        return <Tag color="blue">{e}</Tag>;
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      render: (e) => {
        return <Tag bordered={false}>{e}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleAction("view", record)}
            type="text"
          />
          <Button
            icon={<DownloadOutlined />}
            onClick={() => handleAction("download", record)}
            type="text"
          />
          <Popconfirm
            placement="left"
            title="Are you sure to delete this file?"
            onConfirm={() => handleAction("delete", record)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} type="text" />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const fileType = ".xlsx";
  const props_ = {
    name: "file",
    multiple: false,
    style: {
      border: "1px dashed #d9d9d9",
    },

    onChange(info) {
      console.log(info, "info");

      setFileList(info.fileList.slice(-1));
    },
    accept: fileType || ".csv",
    fileList: fileList,
    beforeUpload: (file) => {
      const documentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isCSV = file.type === documentType;
      if (!isCSV && fileType && documentType) {
        message.warning(`You can only upload ${fileType} files!`);
        return Upload.LIST_IGNORE;
      }
      return false;
    },
  };

  const colors = {
    0: "blue",
    1: "volcano",
    2: "cyan",
  };

  const makeColumns = (data) => {
    // Generate columns dynamically
    if (data.length > 0) {
      const dynamicColumns = Object.keys(data[0]).map((key, index) => ({
        title: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " "),
        dataIndex: key,
        key: key,

        onCell: () => ({
          style: {
            maxWidth: 400, // Set max width to match the width property
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
        sorter: (a, b) => {
          if (typeof a[key] === "string") {
            return a[key].localeCompare(b[key]);
          }
          return a[key] - b[key];
        },
        filters: Array.from(new Set(data.map((item) => item[key]))).map(
          (value) => ({
            text: value,
            value: value,
          })
        ),
        render: (e) => {
          return index === 0 || index === 1 || index === 2 ? (
            <Tag color={colors?.[index]}>{e}</Tag>
          ) : (
            <>{e}</>
          );
        },
        onFilter: (value, record) =>
          record[key].toString().indexOf(value) === 0,
      }));

      setColumnsView(dynamicColumns);
    }
  };

  const handleFileRead = async (fileURL, record) => {
    try {
      // Fetch the file as a Blob
      const response = await fetch(fileURL);
      const blob = await response.blob();

      // Create a FileReader to read the Blob
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target.result;

        // Parse the binary string into a workbook
        const workbook = XLSX.read(binaryStr, { type: "binary" });

        // Get the first worksheet from the workbook
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];

        // Convert the worksheet to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        makeColumns(jsonData);
        setSelectedRow({ ...record, tableData: jsonData });
      };

      // Read the Blob as a binary string
      reader.readAsBinaryString(blob);
    } catch (error) {
      console.error("Error reading file:", error);
    }
  };

  const handleAction = (actionType, record) => {
    if (actionType === "download") {
      const link = document.createElement("a");
      link.href = DownloadUrl + "import_files/feed_file/" + record?.file_name;
      link.download = record?.file_name; // You can customize the file name here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (actionType === "view") {
      handleFileRead(
        DownloadUrl + "import_files/feed_file/" + record?.file_name,
        record
      );
    } else if (actionType === "delete") {
      message.destroy();
      message.loading("Loading...", 0);
      DeleteFeedProduct(record?.id);
    }
    console.log(`${actionType} action triggered for:`, record);
  };

  const downloadFile = () => {
    message.destroy();
    message.loading("Loading...", 0);
    window.open(ENDPOINTDownload + selectedFilters?.template_url);
    message.destroy();
  };

  const onPageNo = (e) => {
    setPage(e);
    setLoading(true);
    GetListUploadedDataAction({
      page: e,
      perPage: pageSize,
      ...selectedFilters,
      ...marketplaceSelected,
    });
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    GetListUploadedDataAction({
      page: page,
      perPage: e,
      ...selectedFilters,
      ...marketplaceSelected,
    });
  };
  return (
    <Wrapper>
      {Object.keys(selectedRow || {})?.length === 0 && (
        <div className="row g-5 align-items-center mb-3">
          <div className="col-xxl-auto col-md-12 col-auto  mt-2 d-flex align-items-center justify-content-start">
            <div
              className="dropzone"
              style={{
                borderColor:
                  marketplaceSelected?.marketplace_id &&
                  selectedFilters.product_type
                    ? "#4dd85f"
                    : "#ff6363",
                display: "flex",
                alignItems: "flex-end",
                backgroundColor:
                  marketplaceSelected?.marketplace_id &&
                  selectedFilters.product_type
                    ? "#4dd85f1a"
                    : "#ff63631a",
              }}
            >
              <Select
                options={marketplaceList}
                onChange={(_, e) => {
                  setMarketplaceSelected({
                    marketplace: e?.marketplace,
                    marketplace_id: e?.marketplace_id,
                    region: e?.region,
                    sales_channel: e?.sales_channel,
                    account_type: e?.account_type,
                  });
                  setProductTypeLoading(true);
                  setProductTypeList([]);
                  ListProductTypeMarketplaceAction({
                    id: e?.value?.split("_")?.[0],
                  });

                  setPage(1);
                  setPageSize(DefaultPerPage);
                  setLoading(true);
                  GetListUploadedDataAction({
                    page: 1,
                    perPage: DefaultPerPage,
                    ...selectedFilters,
                    marketplace: e?.marketplace,
                    marketplace_id: e?.marketplace_id,
                    region: e?.region,
                    sales_channel: e?.sales_channel,
                    account_type: e?.account_type,
                  });
                }}
                value={marketplaceSelected?.marketplace_id}
                loading={marketplaceLoading}
                placeholder="Select Marketplace"
                className="w-175px me-3"
              />

              <div className="d-grid">
                {marketplaceSelected?.marketplace_id &&
                selectedFilters.product_type ? (
                  <></>
                ) : (
                  <small
                    style={{
                      textAlign: "left",
                      marginLeft: "5px",
                      marginBottom: "5px",
                      position: "relative",
                      marginTop: "-10px",
                      color: "red",
                    }}
                  >
                    <sug style={{}}>*</sug>&nbsp; Please Select Product Type
                  </small>
                )}

                <Select
                  value={selectedFilters.product_type}
                  showSearch
                  allowClear
                  onChange={(e, r) => {
                    ExportFile(e);
                    setSelectedFilters({
                      ...selectedFilters,
                      product_type: r?.product_type,
                      template_url: r?.template_url,
                    });

                    setPage(1);
                    setPageSize(DefaultPerPage);
                    setLoading(true);
                    GetListUploadedDataAction({
                      page: 1,
                      perPage: DefaultPerPage,
                      product_type: r?.product_type,
                      ...marketplaceSelected,
                    });
                  }}
                  options={productTypeList?.map((d) => ({
                    label: d?.product_type,
                    value: d?.product_type,
                    ...d,
                  }))}
                  className="w-200px"
                  placeholder="Select Product Type"
                />
              </div>
            </div>
          </div>

          <div className="col-xxl  col-md-12 col  position-relative  mt-2">
            <Dragger {...props_}>
              <div className="d-flex align-items-center justify-content-start">
                <p className="ant-upload-drag-icon me-3">
                  <InboxOutlined />
                </p>
                <div className="d-grid " style={{ justifyItems: "flex-start" }}>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited
                    from uploading company data or other banned files.
                  </p>
                </div>
              </div>
            </Dragger>
            <Button
              loading={uploadLoading}
              disabled={
                !marketplaceSelected?.region ||
                !marketplaceSelected?.sales_channel ||
                !selectedFilters?.product_type ||
                !marketplaceSelected?.account_type ||
                fileList?.length === 0
              }
              onClick={() => {
                const obj = {
                  region: marketplaceSelected?.region,
                  sales_channel: marketplaceSelected?.sales_channel,
                  account_type: marketplaceSelected?.account_type,
                  product_type: selectedFilters?.product_type,
                  "UploadedFeedDataFile[importFile]":
                    fileList?.[0]?.originFileObj,
                };
                setUploadLoading(true);
                FeedProductUploadAction(obj);
              }}
              style={{ position: "absolute", top: 10, right: 20 }}
              type="primary"
            >
              <Icon icon="ic:round-upload" width={20} />
            </Button>
          </div>
        </div>
      )}
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            {console.log(selectedFilters, "selectedFilters")}
            {Object.keys(selectedRow || {})?.length !== 0 ? (
              <div className="d-flex">
                <Icon
                  onClick={() => {
                    setSelectedRow({});
                  }}
                  icon="mdi:arrow-left"
                  className="cursor-pointer"
                  width={20}
                  style={{ color: "#000" }}
                />
                <span className="ms-2">{selectedRow?.file_name || ""}</span>
              </div>
            ) : (
              "History Details"
            )}
            {selectedFilters?.product_type && (
              <Button
                className="ms-3"
                onClick={downloadFile}
                type="link"
                size="small"
              >{`Download ${selectedFilters?.product_type} sample file from here `}</Button>
            )}
          </div>
        </div>
        <div className="card-body pt-0">
          {Object.keys(selectedRow || {})?.length === 0 ? (
            <>
              {loading ? (
                <TableLoading
                  id="test-table"
                  columns={[1, 2, 3, 4, 5]}
                  checkBoxVal={true}
                  actions={[1, 2]}
                />
              ) : (
                <Table
                  columns={columns}
                  dataSource={list}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                />
              )}

              <Pagination
                loading={list?.length === 0}
                pageSize={pageSize}
                pageSizeStatus={false}
                page={page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              />
            </>
          ) : (
            <>
              {" "}
              <Table
                columns={columnsView}
                dataSource={selectedRow?.tableData}
                pagination={false}
                scroll={{ x: "max-content" }}
              />
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
