import styled from "styled-components";

const Wrapper = styled.div`
  border-right: 1px solid #c9c9c9;
  .bi-chat-right-quote-fill {
    color: #ef7fb1 !important;
    font-size: 18px !important;
  }
  #kt_aside_logo {
    #MainLoading {
      svg {
        width: 50px !important;
        margin-bottom: 20px !important;
      }
    }
  }
  #btnLogout {
    background-color: #5f668b;
    transition-duration: 200ms !important;
  }

  #btnLogout:hover {
    background-color: #868fc1 !important;
    box-shadow: 0px 0px 10px #8d9cff !important;
  }
  .ant-menu-item {
    a {
      color: rgb(73 89 140);
      font-weight: 500;
    }
  }
  .ant-menu-title-content,
  .ant-menu-submenu-arrow {
    color: rgb(73 89 140);
    font-weight: 500;
  }
  .ant-menu-item-icon {
    path,
    rect {
      fill: rgb(73 89 140);
    }
  }
  .ant-menu-item-selected {
    background-color: transparent;
    padding-left: 24px;
    border-right: 3px solid #af5847 !important;
    border-radius: 0px;
    a,
    .menu-bullet {
      color: #af5847 !important;
      font-weight: 700;
    }
    path,
    rect {
      fill: #af5847;
    }
  }
  .ant-menu-submenu-selected {
    path,
    rect {
      fill: #d5705d;
    }
    .ant-menu-submenu-arrow {
      color: #d5705d;
      font-weight: 500;
    }
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    padding-left: 24px;
    border-right: 3px solid #e9805f !important;
    border-radius: 0px;
    .ant-menu-title-content {
      color: #d5705d !important;
      font-weight: 700;
    }
  }
`;

export default Wrapper;

export const ArrowWrapper = styled.div`
  .arrow {
    // background: white;
    color: #36b7a8;
    svg,
    span {
      cursor: pointer;
    }
  }
`;
export const BackAdmin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0px;
  .cssbuttons-io-button {
    background: rgb(205 107 91);
    color: white;
    font-family: inherit;
    padding: 0.35em;
    padding-left: 1.2em;
    font-size: 12px;
    font-weight: 500;
    border-radius: 0.9em;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    box-shadow: rgb(99 29 73) 0px 0px 1.6em -0.6em inset;
    overflow: hidden;
    position: relative;
    height: 2.8em;
    padding-right: 3.3em;
    cursor: pointer;
  }

  .cssbuttons-io-button .icon {
    background: white;
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 0.7em;
    box-shadow: rgb(124 48 79) 0.1em 0.1em 0.6em 0.2em;
    right: 0.3em;
    transition: all 0.3s;
  }

  .cssbuttons-io-button:hover .icon {
    width: calc(100% - 0.6em);
  }

  .cssbuttons-io-button .icon svg {
    width: 1.1em;
    transition: transform 0.3s;
    color: rgb(206 108 91);
  }

  .cssbuttons-io-button:hover .icon svg {
    transform: translateX(0.1em);
  }

  .cssbuttons-io-button:active .icon {
    transform: scale(0.95);
  }
`;
