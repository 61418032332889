import axiosCall from "../../../configurations/network-services/axiosCall";

export const GetUserSystemLogAction = (data) => {
  const path = `user-system-log?pageSize=${data?.perPage || ""}&page=${
    data?.page || ""
  }`;
  const responseType = "USER_SYSTEM_LOG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionSystemLog = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SYSTEM_LOG", state: data });
};
