import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmbedCodes from "../../modules/pages/admin/embed-codes";
import {
  GetMenuListAction,
  GetUserListAction,
  AddEmbedCodeAction,
  fakeActionManageUser,
} from "../../redux/module_/manage-user/index.action";

const mapStateToProps = (state) => ({
  EmbeddedListResponse: state.User.EmbeddedListResponse,
  UserListResponse: state.User.UserListResponse,
  AddEmbeddedResponse: state.User.AddEmbeddedResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetMenuListAction,
      GetUserListAction,
      AddEmbedCodeAction,
      fakeActionManageUser,
    },
    dispatch
  );

const EmbedCodes_ = connect(mapStateToProps, mapDispatchToProps)(EmbedCodes);

export default EmbedCodes_;
