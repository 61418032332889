import { GetSalesChannelMarketplaceResponse } from "../../fake";

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_DASHBOARD_PRODUCT_DETAILS_SUCCESS":
    case "GET_DASHBOARD_PRODUCT_DETAILS_ERROR":
      return {
        ...state,
        GetProductDetailsResponse: action.updatePayload,
      };
    case "GET_DASHBOARD_GRAPH_DATA_SUCCESS":
    case "GET_DASHBOARD_GRAPH_DATA_ERROR":
      return {
        ...state,
        GetGraphDataResponse: action.updatePayload,
      };

    case "GET_SALES_CHANNEL_MARKETPLACE_SUCCESS":
    case "GET_SALES_CHANNEL_MARKETPLACE_ERROR":
      return {
        ...state,
        GetSalesChannelMarketplaceResponse: GetSalesChannelMarketplaceResponse,
        // GetSalesChannelMarketplaceResponse: action.updatePayload,
      };

    case "GET_DASHBOARD_TOP_SELLING_FILTER_SUCCESS":
    case "GET_DASHBOARD_TOP_SELLING_FILTER_ERROR":
      return {
        ...state,
        GetDashboardTopSellingFilterResponse: action.updatePayload,
      };

    case "FAKE_ACTION_DASHBOARD":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
