import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ManageAdmin from "../../modules/pages/admin/manage-admin";
import {
  AdminListAction,
  AdminAddAction,
  AllCompanyListAction,
  GetUserListAction,
  UserUpdateAction,
  fakeActionManageUser,
} from "../../redux/module_/manage-user/index.action";

const mapStateToProps = (state) => ({
  AddAdminResponse: state.User.AddAdminResponse,
  GetAdminListResponse: state.User.GetAdminListResponse,
  AllCompanyListResponse: state.User.AllCompanyListResponse,
  UserListResponse: state.User.UserListResponse,
  UserUpdateResponse: state.User.UserUpdateResponse,
  GetAdminConfigDataResponse: state.User.GetAdminConfigDataResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      AdminListAction,
      AdminAddAction,
      AllCompanyListAction,
      GetUserListAction,
      UserUpdateAction,

      fakeActionManageUser,
    },
    dispatch
  );

const ManageAdmin_ = connect(mapStateToProps, mapDispatchToProps)(ManageAdmin);

export default ManageAdmin_;
