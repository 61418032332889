import axiosCall from "../../configurations/network-services/axiosCall";

export const GetSPAPIListAction = () => {
  const path = `amazon-credentials`;
  const responseType = "GET_SP_API_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GenerateRefreshSPTokenAction = (data) => {
  const path = `generate-refresh-sp-token`;
  const responseType = "GENERATE_REFRESH_SP_TOKEN";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AdvertisingCredentialsAction = () => {
  const path = `advertising-credentials`;
  const responseType = "ADVERTISING_CREDENTIALS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const RenerateRefreshAdTokenAction = (data) => {
  const path = `generate-refresh-ad-token`;
  const responseType = "GENERATE_REFRESH_AD_TOKEN";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SaveAdProfileAction = (data) => {
  const path = `save-ad-profile`;
  const responseType = "SAVE_AD_PROFILE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetMarketplaceParticipationAction = (region) => {
  const path = `get-marketplace-participations?region=${region || ""}`;
  const responseType = "GET_MARKETPLACE_PARTICIPATION";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const StoreSPOtherMarketplaceAction = (data) => {
  const path = `store-sp-other-marketplace`;
  const responseType = "STORE_SP_OTHER_MARKET";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CredentialDeleteAction = (id) => {
  const path = `credential-delete/${id}`;
  const responseType = "CREDENTIAL_DELETE";

  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionMarketPlace = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_MARKET_PLACE", state: data });
};
