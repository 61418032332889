import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AmazonLqs from "../../modules/pages/catalogue/amazon-lqs";
import {
  SalesChannelListAction,
  GetListAmazonLQSAction,
  fakeActionCatalog,
  GetListAmazonLQSHistoryAction,
  CreateAmazonLQSAction,
} from "../../redux/module_/catalog/index.action";
import {
  fakeActionAnalytics,
  GetCategoryAction,
  GetSubCategoryAction,
} from "../../redux/module_/analytics/index.action";

const mapStateToProps = (state) => ({
  CatalogResponse: state.Catalog.CatalogResponse,
  AmazonLQSListResponse: state.Catalog.AmazonLQSListResponse,
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
  CreateAmazonLQSResponse: state.Catalog.CreateAmazonLQSResponse,
  ListCatalogHistoryResponse: state.Catalog.ListCatalogHistoryResponse,

  GetCategoryListResponse: state.Analytics.GetCategoryListResponse,
  GetSubCategoryListResponse: state.Analytics.GetSubCategoryListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      GetListAmazonLQSAction,
      fakeActionCatalog,
      GetListAmazonLQSHistoryAction,
      CreateAmazonLQSAction,

      fakeActionAnalytics,
      GetCategoryAction,
      GetSubCategoryAction,
    },
    dispatch
  );

const AmazonLqs_ = connect(mapStateToProps, mapDispatchToProps)(AmazonLqs);

export default AmazonLqs_;
