import styled from "styled-components";

export const Wrapper = styled.div`
  // .comparison-box {
  //   width: fit-content;
  //   background: #eceffc;
  //   padding: 10px;
  //   border-radius: 7px;
  //   display: flex;
  //   border: 1px dashed royalblue;
  //   label {
  //     font-weight: 500;
  //   }
  // }
  // .ant-tag {
  //   max-width: 13%;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }
  .slick-list {
    border-radius: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .product_description {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    // height: 29px;
    margin: 0 auto;
    font-size: 14px;
    // line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .make-card-row {
    padding: 10px;
    background: #f3f5fd;
    margin-bottom: 10px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    div {
      text-align: center;
    }
  }
  .border-right {
    border-right: 1px solid #cfcfcf;
  }
  .not_match {
    .make-card-row {
      border: 1px solid #ffb0b0 !important;
      background: #ffeeee !important;
    }
    .col-4 {
      display: flex;
      align-items: center;
      justify-content: left;
      text-align: center;
      font-weight: 600;
    }
  }
  .center-text {
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 600;
  }
  .match {
    .make-card-row {
      border: 1px solid #4ad54a !important;
      background: #deedde !important;
    }
    .col-4 {
      display: flex;
      text-align: center;
      justify-content: left;
      font-weight: 600;
      align-items: center;
    }
  }
  .header-view {
    padding: 10px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
    background: #f4f7fe;
    border-radius: 10px;
    border: 1px dashed royalblue;
    margin: 10px -10px;
    position: sticky;
    top: 101px;
    z-index: 9;
  }
  .slick-list {
    .ant-image {
      width: 100%;
    }
  }
  .ant-image-img {
    height: 160px;
    margin: 0px;
    color: rgb(255, 255, 255);
    line-height: 160px;
    text-align: center;
  }
`;
