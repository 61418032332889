import styled from "styled-components";

const ProductResearchWrapper = styled.div`
  .brandFilter > div {
    &::-webkit-scrollbar {
      width: 10px;
      height: 0px;
      scroll-direction: horizontal;
      transition: 0.8s;
    }
  }
  .ant-list-item-meta {
    border: 1px dashed #e9805f;
    padding: 10px;
    border-radius: 8px;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-right: 2px;
  }
  @media only screen and (max-width: 1354px) {
    .addSpace {
      margin-top: 10px;
    }
  }
  .variationSwitch {
    .ant-switch {
      min-width: 65px !important;
    }
  }
  .ant-radio-button-checked {
    background: #7e78ef !important;
    border-color: #7e78ef !important;
  }
  .ant-radio-button-wrapper-checked {
    &:hover {
      span {
        color: #ffffff !important;
      }
    }
  }
  .variationActionIcon {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    svg {
      font-size: 24px;
      color: #f8a3a8;
      cursor: pointer;
      &:hover {
        color: #f082b0;
      }
    }
  }
  .searchAll {
    height: 40px !important;
    width: 230px !important;
  }
  .Sync-Asin-Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 18px;
    svg {
      transition: 0.7s;
      cursor: pointer;
      path:first-child {
        fill: #faada545;
      }
      path:last-child {
        fill: #ee7ab1;
      }
      &:hover {
        path:first-child {
          fill: #faada5;
        }
      }
    }
  }

  .ant-tag {
    margin-bottom: 8px !important;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: white !important;
  }

  .ant-table-cell {
    .ant-btn-primary {
      border-color: #7e78ef;
      background: #7e78ef;
      border-radius: 6px;
    }
  }
  .info-product-research {
    padding: 0 !important;
    font-size: 11px !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    .ant-table-selection {
      opacity: 0;
    }
  }
  .backtoList {
    svg {
      color: #7e78ef !important;
      transition: 0.7s;
      cursor: pointer;
      &:hover {
        color: #7e78efa8 !important;
      }
    }
  }
  // .brandFilterButton {
  //   position: relative;
  //   display: inline-block;
  //   text-align: center;
  //   font-size: 15px;
  //   padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
  //   letter-spacing: 1px;
  //   text-decoration: none;
  //   color: #7e8299;
  //   background: #f5f8fa;
  //   cursor: pointer;
  //   transition: box-shadow linear 0.5s;
  //   border: none;
  //   border-radius: 10px;
  //   box-shadow: inset 0 0 0 0 #ef7fb1;
  // }

  // .brandFilterButton:hover {
  //   color: white;
  //   box-shadow: inset 0px 20px 10px 20px #ef7fb1;
  //   // box-shadow: inset 0 -100px 0 0 #ef7fb1;
  // }

  // // .brandFilterButton:active {
  // //   transform: scale(0.9);
  // //   color: white;
  // //   box-shadow: inset 0 -100px 0 0 #ef7fb1;
  // // }

  // .brandFilterButtonActive {
  //   background: #7e78ef;
  //   color: #fff;
  //   border: none;
  //   border-radius: 10px;
  // }
  .ui-change {
    transition: 0.4s;
    ${
      "" /* input {
      padding-left: 72px !important;
      transition: 0.4s;
    }
    i {
      position: absolute;
      transition: 0.4s;
      top: 0;
      left: -50px;
      right: 0;
      bottom: 0;
      margin: auto;
      width: fit-content;
      height: fit-content;
    }
    input:not([value=""]) + i {
      left: -120px !important;
      transition: 0.4s;
    }
    input:not([value=""]) {
      transition: 0.4s;
      padding-left: 37px !important;
    }
    input:focus + i {
      transition: 0.4s;
      left: -130px !important;
    }
    input:focus {
      transition: 0.4s;
      padding-left: 35px !important;
    }
    .search-btn {
      position: absolute;
      inset: 0 -161px 0 0px;
      width: 30px;
      height: 32px;
      margin: auto;
      i {
        color: #7e78ef;
        position: absolute;
        left: -20px;
        transition: 0.4s;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          color: #fd937c;
        }
      }
    } */
    }
  }
  .actionIcon {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
    svg {
      font-size: 24px;
      color: #f8a3a8;
      cursor: pointer;
      &:hover {
        color: #f082b0;
      }
    }
  }
  .actionSingleIcon {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
    svg {
      font-size: 24px;
      color: #f8a3a8;
      cursor: pointer;
      &:hover {
        color: #f082b0;
      }
    }
  }

  // .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  //   // background-color: #f5f8fa;
  //   background-color: #7e78ef1c;
  //   border-radius: 0.65rem;
  //   border: 1px solid #7e78ef52 !important;
  //   color: #212124;
  // }

  #kt_advanced_search_form .filter_input {
    background: #7e78ef1c;
    color: #212124;
    border-left: 1px solid #f1f0fd !important;
    border-right: 1px solid #f1f0fd !important;
  }
  #kt_advanced_search_form .sign {
    background: #7e78ef1c;
    color: #212124;
  }
  .ant-input-group:where(
      .css-dev-only-do-not-override-1i9hnpv
    ).ant-input-group:where(
      .css-dev-only-do-not-override-1i9hnpv
    ).ant-input-group-compact
    > *:not(:last-child) {
    margin-inline-end: 0px !important;
  }
  .ant-rate {
    color: #fa7414 !important;
  }
`;
export const GridWrapper = styled.div`
  .actionBar {
    margin: 0px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .action {
    display: flex;
    align-items: center;
    span {
      margin: 0px 10px;
      color: #ff6464;
      font-weight: 600;
      cursor: pointer;
      transition: 0.8s;
      &:hover {
        color: #ff646476;
      }
    }
  }
`;
export const ModalGridWrapper = styled.div`
  .loading {
    height: 270px;
  }
  .loading > div {
    inset: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    position: absolute;
  }
  .actionBar {
    padding: 10px 10px;
    .ant-input {
      width: 220px;
      border-radius: 6px;
      font-weight: 400 !important;
      border: 1px solid #7e78ef59 !important;
      background: #7e78ef24;
      transition: 0.6s;
      &::placeholder {
        color: black;
      }
    }
    .ant-input:placeholder-shown {
      font-weight: 200 !important;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input:focus,
    .ant-input-focused,
    .ant-input:hover {
      border-color: #36b7a8 !important;
    }
  }
`;
export const ASINDetailsWrapper = styled.div`
  .ant-skeleton-image {
    height: 300px !important;
    width: 300px !important;
  }
  .ant-skeleton-button-lg {
    width: 200px !important;
    min-width: 80px;
    height: 75px !important;
  }
  .ant-skeleton-button-sm {
    width: 190px !important;
    min-width: 48px !important;
    height: 55px !important;
    line-height: 24px !important;
  }
  .ant-skeleton-input-sm {
    height: 32px !important;
  }
  .ant-skeleton-image-svg {
    width: 165px !important;
    height: 165px !important;
  }
  @media (max-width: 430px) {
    .ant-skeleton-input-sm {
      min-width: 80px !important;
      width: 80px !important;
    }
  }
  .ant-statistic .ant-statistic-content {
    font-size: 1.1rem !important;
    font-family: Poppins, sans-serif;
  }
  .fs-1-1 {
    font-size: 1.1rem !important;
  }
  .statisticSuccess .ant-statistic .ant-statistic-content {
    color: #50cd89;
  }

  .statisticWarning .ant-statistic .ant-statistic-content {
    color: #ffc700;
  }

  .statisticPrimary .ant-statistic .ant-statistic-content {
    color: #5f5ac5;
  }

  .statisticDanger .ant-statistic .ant-statistic-content {
    color: #f1416c;
  }

  .img-fluid {
    max-width: 65% !important;
  }

  .loaderImg {
    width: 64px;
    height: 64px;
    position: relative;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
  }

  .loaderImg:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    transform: rotate(45deg) translate(30%, 40%);
    background: #d1d1d1;
    box-shadow: 32px -34px 0 5px #ff3d00;
    animation: slide 2s infinite ease-in-out alternate;
  }

  .loaderImg:after {
    content: "";
    position: absolute;
    left: 10px;
    top: 10px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #6e6e6e;
    transform: rotate(0deg);
    transform-origin: 35px 145px;
    animation: rotate 2s infinite ease-in-out;
  }

  @keyframes slide {
    0%,
    100% {
      bottom: -35px;
    }

    25%,
    75% {
      bottom: -2px;
    }

    20%,
    80% {
      bottom: 2px;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(-15deg);
    }

    25%,
    75% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(25deg);
    }
  }
`;
export default ProductResearchWrapper;
