import {
  GetAllMyListInProductsResponse,
  GetBrandsListFilterResponse,
  GetBrandsListResponse,
  GetConfigurationResponse,
  GetDomainListResponse,
  GetProductsListResponse,
} from "../../fake";

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_PRODUCTS_LIST_SUCCESS":
    case "GET_PRODUCTS_LIST_ERROR":
      return {
        ...state,
        GetProductsListResponse: GetProductsListResponse,
      };
    case "PUT_STORE_MY_LIST_PRODUCT_SUCCESS":
    case "PUT_STORE_MY_LIST_PRODUCT_ERROR":
      return {
        ...state,
        PutStoreMyListProductResponse: action.updatePayload,
      };
    case "GET_ALL_MY_LIST_IN_PRODUCTS_SUCCESS":
    case "GET_ALL_MY_LIST_IN_PRODUCTS_ERROR":
      return {
        ...state,
        GetAllMyListInProductsResponse: GetAllMyListInProductsResponse,
      };
    case "DELETE_MY_LIST_SINGLE_PRODUCT_SUCCESS":
    case "DELETE_MY_LIST_SINGLE_PRODUCT_ERROR":
      return {
        ...state,
        DeleteMyListSingleResponse: action.updatePayload,
      };
    case "GET_CATEGORIES_LIST_SUCCESS":
    case "GET_CATEGORIES_LIST_ERROR":
      return {
        ...state,
        GetCategoriesListResponse: action.updatePayload,
      };
    case "GET_BRANDS_LIST_SUCCESS":
    case "GET_BRANDS_LIST_ERROR":
      return {
        ...state,
        GetBrandsListResponse: GetBrandsListResponse,
      };
    case "GET_BRANDS_FILTER_LIST_SUCCESS":
    case "GET_BRANDS_FILTER_LIST_ERROR":
      return {
        ...state,
        GetBrandsListFilterResponse: GetBrandsListFilterResponse,
      };
    case "GET_CONFIGURATION_LIST_SUCCESS":
    case "GET_CONFIGURATION_LIST_ERROR":
      return {
        ...state,
        GetConfigurationResponse: GetConfigurationResponse,
      };
    case "UPDATE_CONFIGURATION_LIST_SUCCESS":
    case "UPDATE_CONFIGURATION_LIST_ERROR":
      return {
        ...state,
        UpdateConfigurationResponse: action.updatePayload,
      };
    case "GET_DOMAIN_LIST_SUCCESS":
    case "GET_DOMAIN_LIST_ERROR":
      return {
        ...state,
        GetDomainListResponse: {
          ...GetDomainListResponse,
          data:
            GetDomainListResponse?.data?.domain_list ||
            GetDomainListResponse?.data,
          currency_sign_list:
            GetDomainListResponse?.data?.currency_sign_list || {},
        },
      };
    case "GET_PRODUCT_DETAILS_SUCCESS":
    case "GET_PRODUCT_DETAILS_ERROR":
      return {
        ...state,
        GetProductDetailsResponse: action.updatePayload,
      };

    case "FAKE_ACTION_PRODUCTS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
