export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "CHANGE_PASSWORD_PROFILE__SUCCESS":
    case "CHANGE_PASSWORD_PROFILE__ERROR":
      return {
        ...state,
        ChangePasswordResponse: action.updatePayload,
      };
    case "UPDATE_USER_PROFILE__SUCCESS":
    case "UPDATE_USER_PROFILE__ERROR":
      return {
        ...state,
        UpdateUserProfileResponse: action.updatePayload,
      };

    case "FAKE_ACTION_PROFILE":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
