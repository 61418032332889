import { PlusOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { ProfileWrapper } from "../style";
import { Button, Form, Input, Upload, message } from "antd";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../../commonContext";
import { API_URL, ENDPOINT } from "../../../../config";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Profile = (props) => {
  const { ChangePasswordAction, UpdateUserAction, fakeActionProfile } = props;

  const [form] = Form.useForm();

  const [emailChange, setEmailChange] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [emailUpdate, setEmailUpdate] = useState(false);
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [profileDetailsLoading, setProfileDetailsLoading] = useState(false);
  const user_ = JSON.parse(localStorage.getItem("user") || {});

  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [email, setEmail] = useState(user_?.email);
  const [emailLoading, setEmailLoading] = useState(false);

  const [userData, setUserData] = useState({
    u_name: user_?.u_name,
    u_email: user_?.u_email,
    u_amazon_seller_name: user_?.u_amazon_seller_name,
  });

  const UpdateUserProfileResponse = useSelector(
    (state) => state.profileReducer.UpdateUserProfileResponse || {}
  );
  const ChangePasswordResponse = useSelector(
    (state) => state.profileReducer.ChangePasswordResponse || {}
  );
  const contextValue = useContext(GlobalContext);

  function clean(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === "" ||
        obj[propName] === null ||
        obj[propName] === undefined
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  useEffect(() => {
    if (UpdateUserProfileResponse?.status === true) {
      setProfileDetailsLoading(false);
      setPasswordUpdate(false);
      setEmailUpdate(false);
      setEmailLoading(false);
      message.destroy();
      message.success(UpdateUserProfileResponse?.message);
      localStorage.setItem(
        "user",
        JSON.stringify({ ...user_, ...clean(UpdateUserProfileResponse?.data) })
      );
      contextValue?.updateCommonGlobalVal({
        user_: UpdateUserProfileResponse?.data,
      });
      setUserData({
        u_name: UpdateUserProfileResponse?.data?.u_name,
        u_email: UpdateUserProfileResponse?.data?.u_email,
      });
      fakeActionProfile("UpdateUserProfileResponse");
    } else if (UpdateUserProfileResponse?.status === false) {
      setProfileDetailsLoading(false);
      message.destroy();

      message.error(
        Object.values(UpdateUserProfileResponse?.data)?.[0]?.[0] ||
          UpdateUserProfileResponse?.message
      );
      fakeActionProfile("UpdateUserProfileResponse");
    }
  }, [UpdateUserProfileResponse]);

  useEffect(() => {
    if (ChangePasswordResponse?.status === true) {
      setPasswordUpdate(false);
      setChangePasswordLoading(false);
      form.resetFields();
      message.destroy();

      message.success(ChangePasswordResponse?.message);
      fakeActionProfile("ChangePasswordResponse");
    } else if (ChangePasswordResponse?.status === false) {
      setChangePasswordLoading(false);
      message.destroy();
      message.error(ChangePasswordResponse?.message);
      fakeActionProfile("ChangePasswordResponse");
    }
  }, [ChangePasswordResponse]);

  const [fileList, setFileList] = useState(
    JSON.parse(localStorage.getItem("user"))?.u_photo
      ? [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url:
              ENDPOINT +
              "images/user-images/" +
              JSON.parse(localStorage.getItem("user"))?.u_photo,
          },
        ]
      : []
  );

  const submit = () => {
    setProfileDetailsLoading(true);

    UpdateUserAction(user_?.u_id, userData);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    message.destroy();
    message.loading("Loading...", 0);
    if (newFileList?.length === 0) {
      setFileList([]);
    }
  };

  const onPasswordChange = (values) => {
    setChangePasswordLoading(true);
    ChangePasswordAction(JSON.parse(localStorage.getItem("user"))?.id, values);
  };
  const handleUploadSuccess = (response, file) => {
    message.destroy();
    // You can parse and process the response as needed
    try {
      const responseData = response;

      // Check for success or error messages in the response
      if (responseData.status) {
        setFileList([
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: ENDPOINT + "images/user-images/" + response?.data,
          },
        ]);

        message.success("Profile updated");
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...JSON.parse(localStorage.getItem("user")),
            u_photo: response?.data,
          })
        );
        // You can update your component's state or perform other actions
      } else {
        // There was an error in the upload
        message.warning("Profile not updated");
        // You can display an error message to the user or handle the error in your application
      }
    } catch (error) {
      // Handle any parsing errors or unexpected response formats
      // You might want to display a generic error message to the user
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <ProfileWrapper className="d-flex flex-column flex-lg-row">
      {/* begin::Sidebar */}
      <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
        {/*begin::Card*/}
        <div
          className="card mb-5 mb-xl-8"
          style={{
            borderTop: "2px solid #4318ff",
            position: "sticky",
            top: 110,
          }}
        >
          <div className="card-body">
            <div className="d-flex flex-center flex-column pb-5">
              <div
                className="cursor-pointer symbol symbol-circle symbol-45px symbol-md-75px"
                style={{ border: "1px solid #5129ff", padding: 5 }}
              >
                <img src={fileList?.[0]?.url} alt="user" />
              </div>
              <a
                href="#"
                className="fs-3 text-gray-800 text-hover-primary fw-bold mb-3 mt-4"
              >
                {user_?.u_name || "-"}
              </a>
              <div className="mb-2">
                <div className="badge badge-lg badge-light-info d-inline">
                  Administrator
                </div>
              </div>
            </div>
            {/*end::User Info*/}
            <div className="separator separator-dashed mb-4" />
            <div className="pb-0 fs-6">
              {/*begin::Details item*/}
              <div className="fw-bold ">Name</div>
              <div className="text-gray-600">{user_?.u_name || "-"}</div>
              {/*begin::Details item*/}
              <div className="separator separator-dashed my-4" />
              {/*begin::Details item*/}
              <div className="fw-bold ">Email</div>
              <div className="text-gray-600">
                <a href="#" className="text-gray-600 text-hover-primary">
                  {user_?.u_email || "-"}
                </a>
              </div>
              {/*begin::Details item*/}
              <div className="separator separator-dashed my-4" />
              {/*begin::Details item*/}
              <div className="fw-bold ">Address</div>
              <div className="text-gray-600">{user_?.u_address || "-"}</div>
              {/*begin::Details item*/}
              <div className="separator separator-dashed my-4" />
              {/*begin::Details item*/}
              <div className="fw-bold ">Contact Number</div>
              <div className="text-gray-600">{user_?.u_contact_no || "-"}</div>
              {/*begin::Details item*/}
              <div className="separator separator-dashed my-4" />
              {/*begin::Details item*/}
              <div className="fw-bold ">Last Login</div>
              <div className="text-gray-600">-</div>
              {/*begin::Details item*/}
            </div>
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Card*/}
      </div>
      {/*end::Sidebar*/}
      {/*begin::Content*/}
      <div className="flex-lg-row-fluid ms-lg-10 ">
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xl-12">
            {/*begin::Basic info*/}
            <div className="card mb-5 mb-xl-10">
              {/*begin::Card header*/}
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
              >
                {/*begin::Card title*/}
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Profile Details</h3>
                </div>
                {/*end::Card title*/}
              </div>
              {/*begin::Card header*/}
              {/*begin::Content*/}
              <div
                id="kt_account_settings_profile_details"
                className="collapse show"
              >
                {/*begin::Form*/}
                <form className="form">
                  {/*begin::Card body*/}
                  <div className="card-body border-top border-top-dashed p-9">
                    {/*begin::Input group*/}
                    <div className="row mb-6">
                      {/*begin::Label*/}
                      <label className="col-lg-4 col-form-label fw-semibold fs-6">
                        Avatar
                      </label>
                      {/*end::Label*/}
                      {/*begin::Col*/}
                      <div className="col-lg-8">
                        {/*begin::Image input*/}
                        <div
                          className="image-input image-input-outline"
                          data-kt-image-input="true"
                        >
                          <Upload
                            name="u_photo"
                            action={API_URL + `image-update`}
                            headers={{
                              Authorization: `Bearer ${localStorage.getItem(
                                "token"
                              )}`,
                            }}
                            listType="picture-card"
                            fileList={fileList}
                            accept=".png, .jpg, .jpeg"
                            onPreview={handlePreview}
                            onChange={handleChange}
                            onSuccess={handleUploadSuccess} // Add this line
                          >
                            {fileList.length >= 1 ? null : uploadButton}
                          </Upload>
                        </div>
                        <div className="form-text">
                          Allowed file types: png, jpg, jpeg.
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Full Name
                      </label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          name="name"
                          className="form-control form-control-solid"
                          placeholder="Name"
                          value={userData?.u_name}
                          onChange={(e) => {
                            setUserData({
                              ...userData,
                              u_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Email Address
                      </label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          name="email"
                          className="form-control form-control-solid"
                          placeholder="E-mail"
                          value={userData?.u_email}
                          onChange={(e) => {
                            setUserData({
                              ...userData,
                              u_email: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                        Seller Name
                      </label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          name="email"
                          className="form-control form-control-solid"
                          placeholder="Seller Name"
                          value={userData?.u_amazon_seller_name}
                          onChange={(e) => {
                            setUserData({
                              ...userData,
                              u_amazon_seller_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end py-6 px-9 border-top border-top-dashed">
                    <button
                      onClick={() => {
                        setUserData({
                          u_name: user_?.u_name,
                          u_email: user_?.u_email,
                        });
                      }}
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2 fs-7"
                    >
                      Discard
                    </button>
                    <Button
                      size="large"
                      loading={profileDetailsLoading}
                      disabled={
                        Object.values(userData)?.filter((d) => !d)?.length !== 0
                      }
                      onClick={() => {
                        if (
                          Object.values(userData)?.filter((d) => !d)?.length ===
                          0
                        ) {
                          submit();
                        }
                      }}
                      className="btn btn-primary  fs-7"
                    >
                      Save Changes
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            {/*end::Basic info*/}
          </div>
          {/*end::Col*/}
          <div className="col-xl-12">
            {/*begin::Sign-in Method*/}
            <div className="card  mb-5 mb-xl-10">
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_signin_method"
              >
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Sign-in Method</h3>
                </div>
              </div>
              <div
                id="kt_account_settings_signin_method"
                className="collapse show"
              >
                <div className="card-body border-top border-top-dashed p-9">
                  <div className="d-flex flex-wrap align-items-center mb-10">
                    <div
                      id="kt_signin_password"
                      className={!passwordUpdate ? "d-block" : "d-none"}
                    >
                      <div className="fs-6 fw-bold mb-1">Password</div>
                      <div className="fw-semibold text-gray-600">
                        ************
                      </div>
                    </div>
                    <div
                      id="kt_signin_password_edit"
                      className={passwordUpdate ? "flex-row-fluid" : "d-none"}
                    >
                      <Form
                        form={form}
                        name="changePassword"
                        layout="vertical"
                        // onFinish={onFinish}
                      >
                        <div className="row mb-1">
                          <div className="col-lg-4">
                            <Form.Item
                              name="u_current_pass"
                              label="Current Password"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please input your Current Password!",
                                },
                              ]}
                              hasFeedback
                            >
                              <Input.Password placeholder="Current Password" />
                            </Form.Item>
                          </div>
                          <div className="col-lg-4">
                            <Form.Item
                              name="u_password"
                              label="Password"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Password!",
                                },
                              ]}
                              hasFeedback
                            >
                              <Input.Password placeholder="Password" />
                            </Form.Item>
                          </div>
                          <div className="col-lg-4">
                            <Form.Item
                              name="u_confirm_password"
                              label="Confirm Password"
                              dependencies={["u_password"]}
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "Please confirm your password!",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue("u_password") === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        "The two passwords that you entered do not match!"
                                      )
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Input.Password placeholder="Confirm Password" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="form-text mb-5">
                          Password must be at least 8 character and contain
                          symbols
                        </div>
                        <div className="d-flex">
                          <Button
                            loading={changePasswordLoading}
                            id="kt_password_submit"
                            type="button"
                            onClick={() => {
                              form
                                .validateFields()
                                .then((values) => {
                                  onPasswordChange(values);
                                })
                                .catch((info) => {});
                            }}
                            size="large"
                            className="btn btn-primary me-2 px-6 fs-7"
                          >
                            Update Password
                          </Button>
                          <button
                            id="kt_password_cancel"
                            type="button"
                            onClick={() => {
                              setPasswordUpdate(false);
                              form.resetFields();
                            }}
                            className="btn btn-color-gray-400 btn-active-light-primary px-6 fs-7"
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                      {/*end::Form*/}
                    </div>
                    <div
                      id="kt_signin_password_button"
                      className={!passwordUpdate ? "d-block ms-auto" : "d-none"}
                    >
                      <button
                        onClick={() => setPasswordUpdate(true)}
                        className="btn btn-light btn-active-light-primary fs-7"
                      >
                        Reset Password
                      </button>
                    </div>
                    {/*end::Action*/}
                  </div>
                  {/*end::Password*/}
                </div>
                {/*end::Card body*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Sign-in Method*/}
          </div>
        </div>
        {/*end::Row*/}
      </div>
      {/*end::Content*/}
    </ProfileWrapper>
  );
};

export default Profile;
