import { Badge, Card, Menu, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { ModulesList } from "../../../config";
import Wrapper, { BackAdmin } from "./style";
import { GlobalContext } from "../../../commonContext";

const adminMenus = ["manage-user", "manage-admin"];
export default function Sidebar(props) {
  const {
    logout,
    user,
    userList,
    SwitchUserBackAction,
    fakeActionManageUser,
    collapsed,
    setCollapsed,
    setHideMenus,
    userType,
    hideMenus,
    bootStatus,
    UserProfile,
  } = props;
  const history = useHistory();
  const location = useLocation();

  const [current, setCurrent] = useState("dashboard");
  const [subMenuOpen, setSubMenuOpen] = useState("");
  const [moveToAdmin, setMoveToAdmin] = useState(false);

  const SwitchUserBackResponse = useSelector(
    (state) => state.User.SwitchUserBackResponse || {}
  );

  const contextValue = useContext(GlobalContext);
  useEffect(() => {
    if (SwitchUserBackResponse?.status === true) {
      localStorage.setItem(
        "user",
        JSON.stringify(SwitchUserBackResponse?.data?.user_data)
      );
      localStorage.setItem("token", SwitchUserBackResponse?.data?.auth_token);
      localStorage.setItem(
        "userType",
        SwitchUserBackResponse?.data?.user_data?.u_type
      );
      localStorage.setItem("menus", JSON.stringify(adminMenus));

      message.destroy();
      fakeActionManageUser("SwitchUserBackResponse");
      setMoveToAdmin(true);
    } else if (SwitchUserBackResponse?.status === false) {
      message.destroy();
      message.warning(SwitchUserBackResponse?.message);
      fakeActionManageUser("SwitchUserBackResponse");
    }
  }, [SwitchUserBackResponse]);

  // userList

  const Types = [
    ...new Set(
      userList?.find(
        (d) => d?.key === contextValue?.data?.user_?.u_id
      )?.marketplace
    ),
  ];

  const isVendor = Types?.length === 1 && Types?.[0] === "Vendor";
  const isSeller = Types?.length === 1 && Types?.[0] === "Seller";
  const bothType = Types?.length === 2;

  useEffect(() => {
    const splitLoca = location.pathname.split("/");
    if (splitLoca[1]) {
      if (splitLoca.length > 2) {
        setCurrent(splitLoca[2]);
      } else {
        setCurrent(splitLoca[1]);
      }
    } else {
      setCurrent("dashboard");
    }
    if (
      ModulesList({ type: "url" })[location.pathname]?.path?.split("/")?.[1]
    ) {
      setCurrent(
        ModulesList({ type: "url" })[location.pathname]?.path?.split("/")?.[1]
      );
    }
    if (window.innerWidth <= 690) {
      setHideMenus(!hideMenus);
    }
  }, [location]);

  const defaultSubMenuSelected = () => {
    if (collapsed) {
      return [];
    }
    let subMenuPath = window.location.pathname.split("/");
    if (subMenuPath.length == 3) {
      return [subMenuPath?.[1]];
    }
    return [];
  };

  const checkMenu = () => {
    const menuList = ModulesList({
      type: "sidebar",
      mode: userType ? "admin" : "common",
    });
    if (defaultSubMenuSelected()?.length == 0) {
      return [current];
    }
    if (
      menuList?.filter((d) => d?.key === defaultSubMenuSelected()?.[0])
        ?.length == 0
    ) {
      return [""];
    }
    return [current];
  };

  const menuList =
    Object?.keys(contextValue?.data?.role_configuration || {})?.length !== 0
      ? JSON.parse(contextValue?.data?.role_configuration?.config)
      : {};

  const MenusObj = {
    account_status: "Account Status",
    agency: "Agency",
    analytics: "Analytics",
    application_logs: "Application Logs",
    brand_analytics: "Brand Analytics",
    catalog: "Catalog",
    catalog_by_country: "Catalog by country",
    client_data: "Client Data",
    customer_sentiment: "Customer sentiment",
    finance: "Finance",
    inventory: "Inventory",
    manage_user: "Manage User/Client",
    reports: "Reports",
    setting: "Setting",
    vendor: "Vendor",
  };

  const keysToFilter = Object?.entries(menuList || {})
    ?.map(([key, value]) => ({
      label: key,
      value: JSON.parse(value?.["view"]?.["status"]),
    }))
    ?.filter((d) => !d?.value)
    ?.map((d) => d?.label);

  const filteredData = Object.fromEntries(
    Object.entries(MenusObj).filter(([key]) => keysToFilter.includes(key))
  );

  const backToAdmin = () => {
    localStorage.setItem(
      "user",
      JSON.stringify(JSON.parse(localStorage.getItem("admin"))?.user_data)
    );
    localStorage.setItem(
      "token",
      JSON.parse(localStorage.getItem("admin"))?.auth_token
    );
    localStorage.setItem(
      "userType",
      JSON.parse(localStorage.getItem("admin"))?.user_data?.u_type
    );
    localStorage.setItem("menus", JSON.stringify(adminMenus));

    contextValue?.updateCommonGlobalVal({
      user_: JSON.parse(localStorage.getItem("admin"))?.user_data,
      admin: false,
    });
    localStorage.removeItem("admin");
    history.push("/manage-user");
  };

  const vendorColumns = ["Analytics", "Vendor Order"];

  const sidebarMenus =
    contextValue?.data?.account_type === 2
      ? ModulesList({
          type: "sidebar",
          mode: "common",
        })?.filter((d) => vendorColumns?.includes(d?.title))
      : contextValue?.data?.account_type === 3
      ? ModulesList({
          type: "sidebar",
          mode: userType ? "admin" : "common",
        })?.filter((d) =>
          contextValue?.data?.user_?.u_type === 3 ? d?.key === "manage-user" : d
        )
      : ModulesList({
          type: "sidebar",
          mode: userType ? "admin" : "common",
        })
          ?.filter((d) =>
            contextValue?.data?.user_?.u_type === 3
              ? d?.key === "manage-user"
              : d
          )
          ?.filter((d) =>
            bothType
              ? d
              : ![Object?.values(filteredData || {}), "Vendor Order"]?.includes(
                  d?.title
                )
          )
          ?.map((item) => {
            if (item.children) {
              return {
                ...item,
                children: item.children.filter((child) =>
                  bothType ? child.title : child.title !== "Vendor Performance"
                ),
              };
            }
            return item;
          });

  const menusDynamic = {
    "Report Account": contextValue?.data?.user_?.embedded_data?.link_1
      ? true
      : false,
    "Advertising Account": contextValue?.data?.user_?.embedded_data?.link_2
      ? true
      : false,
  };

  const menusView = Object.keys(menusDynamic)
    .filter((key) => menusDynamic[key] !== true)
    .reduce((acc, key) => {
      acc[key] = menusDynamic[key];
      return acc;
    }, {});

  const perentMenus = [
    // "Account Status",
    // "Inventory",
    // "Finance",
    // "Customer sentiment",
  ];
  const childVendorMenus = {
    Analytics: ["Product Details"],
    // Catalog: ["Overview", "Bulk Catalog Upload", "Amazon Catalog Health"],
    // "Brand Protection": [
    //   "Reseller Monitor",
    //   "Brand Monitor",
    //   "Brand Analytics",
    // ],
  };
  // isSeller && r?.title !== "Vendor Order"
  const menuListSidebar = sidebarMenus?.map((d) => {
    if (d?.label === "Analytics") {
      return {
        ...d,
        children: d?.children?.filter((r) =>
          isSeller
            ? !Object.keys(menusView || {})?.includes(r?.title) &&
              r?.title !== "Vendor Order"
            : !Object.keys(menusView || {})?.includes(r?.title)
        ),
      };
    }
    return d;
  });

  console.log(menuListSidebar, isSeller, "menuListSidebar");

  return (
    <>
      <Wrapper
        style={{
          height: "100%",
          background: "#ffffff",
          width: collapsed ? "145px" : "289px",
          minWidth: collapsed ? "145px" : "289px",
          zIndex: window.innerWidth >= 992 ? "999" : "1000",
          position:
            690 > window.innerWidth || hideMenus ? "absolute" : "relative",
          transition: "width 0.4s, 0.4s",

          overflowY: "auto",
          overflowX: "hidden",
          transform: `translateX(${hideMenus ? "-106" : "0"}px)`,
          animation: ".5s linear",

          boxShadow: "rgb(37 44 97 / 6%) 0px 0px 20px 20px",
        }}
        className="sidebar_asinwiser"
      >
        {bootStatus ? (
          <div>
            <h5>Loading</h5>
          </div>
        ) : (
          <>
            {" "}
            <div className="px-5 mb-10" style={{ marginTop: "50px" }}>
              <img
                alt="Logo"
                src={!collapsed ? "/media/logo-main.png" : "/media/logo-01.png"}
                loading="lazy"
                className="h-50px  shimmer"
                onError={(e) => {
                  e.target.src =
                    "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
                }}
              />
            </div>
            <div style={{ background: "#cbd5e0", height: "1px" }}></div>
            {contextValue?.data?.admin && (
              <>
                <BackAdmin>
                  <button
                    onClick={() => {
                      backToAdmin();
                    }}
                    className="cssbuttons-io-button"
                  >
                    Back To Admin
                    <div className="icon">
                      <svg
                        height={24}
                        width={24}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </button>
                </BackAdmin>

                <div style={{ background: "#cbd5e0", height: "1px" }}></div>
              </>
            )}
            <div className="mt-5 mb-2" />
            <div
              style={{
                height: !collapsed
                  ? "calc(100% - 320px)"
                  : "calc(100% - 330px)",
                minHeight: "250px",
                overflow: "auto",
                transition: "width 0.4s, 0.4s",
                animation: ".5s linear",

                display: "flex",
                justifyContent: "center",

                width: "100%",
              }}
              id="kt_aside_menu_wrapper"
            >
              <Menu
                selectedKeys={checkMenu()}
                mode="inline"
                onClick={(e) => {
                  history.replace("/" + e?.keyPath?.reverse().join("/"));

                  setCurrent(e?.key);
                  if (hideMenus) {
                    setHideMenus(false);
                  }
                }}
                inlineCollapsed={collapsed}
                items={
                  isVendor
                    ? menuListSidebar
                        ?.filter((d) => {
                          return !perentMenus?.includes(d?.title);
                        })
                        ?.map((d) => ({
                          ...d,
                          children: d?.children?.filter(
                            (t) =>
                              !childVendorMenus?.[d?.title]?.includes(t?.title)
                          ),
                        }))
                    : menuListSidebar
                }
                onOpenChange={(e) => {
                  setSubMenuOpen(e?.[1]);
                }}
              />
            </div>
          </>
        )}
      </Wrapper>
    </>
  );
}
