export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "ITEMS_CRITERIA_LIST_SUCCESS":
    case "ITEMS_CRITERIA_LIST_ERROR":
      return {
        ...state,
        ItemsCriteriaListResponse: action.updatePayload,
      };
    case "ITEMS_CRITERIA_VIEW_SUCCESS":
    case "ITEMS_CRITERIA_VIEW_ERROR":
      return {
        ...state,
        ItemsCriteriaViewResponse: action.updatePayload,
      };
    case "UPDATE_ITEMS_CRITERIA_SUCCESS":
    case "UPDATE_ITEMS_CRITERIA_ERROR":
      return {
        ...state,
        UpdateItemsCriteriaResponse: action.updatePayload,
      };
    case "DELETE_ITEMS_CRITERIA_SUCCESS":
    case "DELETE_ITEMS_CRITERIA_ERROR":
      return {
        ...state,
        DeleteItemsCriteriaResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_LIST_SUCCESS":
    case "GET_MARKETPLACE_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: action.updatePayload,
      };
    case "ADD_NEW_ITEMS_CRITERIA_SUCCESS":
    case "ADD_NEW_ITEMS_CRITERIA_ERROR":
      return {
        ...state,
        AddNewItemsCriterialResponse: action.updatePayload,
      };
    case "CLONE_AMAZON_LQS_SUCCESS":
    case "CLONE_AMAZON_LQS_ERROR":
      return {
        ...state,
        CloneAmazonLQSResponse: action.updatePayload,
      };

    case "FAKE_ACTION_CRITERIA":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
