import React, { useEffect } from "react";
import { ConfigProvider } from "antd";
import { createHashHistory } from "history";
import { Provider } from "react-redux";
import AppRoute from "./AppRoute";
import AppStyleWrapper from "./AppStyle";
import GlobalCommonContextProvider from "./commonContext";
import { GlobalDebug } from "./core/helper/utils/remove-console";
import { store } from "./redux/configurations/store/configure-store.js";

function App() {
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Poppins, sans-serif",
            colorPrimary: "#7e78ef",
            colorPrimaryHover: "#7e78ef",
          },
          components: {
            InputNumber: {
              colorBorder: "rgb(95, 26, 70)",
              colorBgContainer: "rgb(253, 243, 241)",
            },
            Input: {
              hoverBorderColor: "rgb(202, 105, 90)",
            },
            Segmented: {
              itemHoverColor: "rgb(239, 146, 115)",
              colorText: "rgb(255, 255, 255)",
              itemSelectedBg: "rgb(97, 28, 72)",
            },
          },
        }}
      >
        <GlobalCommonContextProvider>
          <Provider store={store}>
            <AppStyleWrapper />
            <AppRoute history={createHashHistory()} />
          </Provider>
        </GlobalCommonContextProvider>
      </ConfigProvider>
    </>
  );
}
export default App;
