import React, { useContext, useState } from "react";
import { Input } from "antd";
import { GlobalContext } from "../../../../commonContext";

export default function TestPage() {
  const contextValue = useContext(GlobalContext);

  //   const [code, setCode] = useState(
  //     `<iframe
  //       width="100%"
  //       height="600"
  //       src="
  // https://lookerstudio.google.com/embed/reporting/3993fea1-a6d4-4187-a0e9-a84b2ac70230/page/h5PrD"
  //       frameBorder={0}
  //       style={{ border: 0 }}
  //       allowFullScreen
  //       sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
  //     />`
  //   );
  return (
    <div className="row">
      {/* <div className="col-12 mt-5">
        <div className="row justify-content-center px-4">
          <Input.TextArea
            rows={4}
            // onChange={(e) => setCode(e.target.value)}
            value={contextValue?.data?.user_?.embedded_data?.link_1}
            placeholder="Add Code"
            size="large"
            className="col-12"
          />
        </div>
      </div> */}
      <div
        className="col-12 mt-5"
        dangerouslySetInnerHTML={{
          __html: contextValue?.data?.user_?.embedded_data?.link_1,
        }}
      ></div>
    </div>
  );
}
