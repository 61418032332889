import React, { useState } from "react";
import { Table, Checkbox, DatePicker, Input, Select } from "antd";
const { Option } = Select;

const ClientData = () => {
  const columns = [
    {
      title: "Company name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Full address",
      dataIndex: "fullAddress",
      key: "fullAddress",
    },
    {
      title: "VAT",
      dataIndex: "vat",
      key: "vat",
    },
    {
      title: "Village",
      dataIndex: "village",
      key: "village",
    },
    {
      title: "Telephone number",
      dataIndex: "telephoneNumber",
      key: "telephoneNumber",
    },
    {
      title: "Reference contact name",
      dataIndex: "referenceContactName",
      key: "referenceContactName",
    },
    {
      title: "Reference email",
      dataIndex: "referenceEmail",
      key: "referenceEmail",
    },
    {
      title: "Amazon contact name",
      dataIndex: "amazonContactName",
      key: "amazonContactName",
    },
    {
      title: "Amazon contact email",
      dataIndex: "amazonContactEmail",
      key: "amazonContactEmail",
    },
    {
      title: "Track record",
      dataIndex: "trackRecord",
      key: "trackRecord",
      render: (_, record) => (
        <Checkbox.Group
          options={[
            "Option 1",
            "Option 2",
            "Option 3",
            "Option 4",
            "Option 5",
            "Option 6",
          ]}
        />
      ),
    },
    {
      title: "Project start date",
      dataIndex: "projectStartDate",
      key: "projectStartDate",
      render: (_, record) => <DatePicker />,
    },
    {
      title: "Project end date",
      dataIndex: "projectEndDate",
      key: "projectEndDate",
      render: (_, record) => <DatePicker />,
    },
    {
      title: "Field for ADV Budget",
      dataIndex: "advBudget",
      key: "advBudget",
      render: (_, record) => <Input />,
    },
    {
      title: "Choice of category",
      dataIndex: "category",
      key: "category",
      render: (_, record) => (
        <Select style={{ width: 150 }}>
          <Option value="Category 1">Category 1</Option>
          <Option value="Category 2">Category 2</Option>
          <Option value="Category 3">Category 3</Option>
        </Select>
      ),
    },
  ];
  const data = []; // Add your data here
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  return (
    <div className="card fadeInBottom">
      <div className="card-header">
        <div className="card-title"></div>
        <div className="card-toolbar">
          <Input
            size="large"
            placeholder="Enter Client name, Company name"
            style={{ width: "250px" }}
          />
        </div>
      </div>
      <div className="card-body">
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          scroll={{ x: "max-content" }}
          rowKey={(record) => record.key} // Replace 'key' with your unique identifier for each row
        />
      </div>
    </div>
  );
};

export default ClientData;
