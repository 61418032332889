import React, { useContext, useEffect } from "react";
import Wrapper from "./style";
import { Result, Segmented, message } from "antd";
import { useState } from "react";
import Profile_ from "../../../containers/profit";
import SPAPI from "./lib/sp-api";
import Advertising from "./lib/advertising";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { APPID, checkPermission, urlDecode } from "../../../config";
import AdList from "./lib/adList";
import { GlobalContext } from "../../../commonContext";

const Setting = (props) => {
  const { RenerateRefreshAdTokenAction, GenerateRefreshSPTokenAction } = props;
  const history = useHistory();
  const contextValue = useContext(GlobalContext);
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState("");

  const [adListModal, setAdListModal] = useState(false);
  const [adList, setAdList] = useState([]);

  const objs = {
    Profile: <Profile_ />,
    "Amazon SP API Credentials": <SPAPI {...props} />,
    "Amazon Advertising Credentials": <Advertising {...props} />,
  };
  const pathName = {
    "/callbackadsapi": "Amazon Advertising Credentials",
    "/callback_sp": "Amazon SP API Credentials",
    "/setting": "Profile",
  };
  useEffect(() => {
    if (location?.pathname) {
      setSelectedTab(pathName?.[location?.pathname]);
    }

    if (location.pathname.includes("/callbackadsapi")) {
      setSelectedTab("Amazon Advertising Credentials");
      if (location.search) {
        const { code, marketplace_id, seller_name } = urlDecode(location);

        if (!code) {
          // GetAdsListAction();
          return;
        }
        // localStorage.setItem("adCode", code);
        localStorage.setItem("adCode", code);
        history.replace(location?.pathname);
        message.destroy();
        message.loading("Loading...", 0);

        RenerateRefreshAdTokenAction({
          ad_code: localStorage.getItem("adCode"),
          marketplace_id,
          seller_name: decodeURIComponent(seller_name),
          other_marketplace_ids: JSON.parse(
            localStorage.getItem("other_marketplace")
          ),
        });
      } else {
        // GetAdsListAction();
      }
    } else if (location.pathname.includes("/callback_sp")) {
      setSelectedTab("Amazon SP API Credentials");

      if (location.search) {
        const { spapi_oauth_code, selling_partner_id, state } =
          urlDecode(location);

        const obj = {
          spapi_oauth_code: spapi_oauth_code,
          selling_partner_id: selling_partner_id,
          app_id: APPID,
          seller_name: decodeURIComponent(state?.split("!!")?.[1] || ""),
          marketplace_id: state?.split("!!")?.[3] || "",
          other_marketplace: localStorage.getItem("other_marketplace")
            ? JSON.parse(localStorage.getItem("other_marketplace"))?.join(",")
            : "",

          account_type: state?.split("!!")?.[4],
        };

        message.destroy();
        message.loading("Loading...", 0);
        GenerateRefreshSPTokenAction(obj);
      }
    }
  }, [location]);

  const navigation = {
    Profile: "/setting",
    "Amazon SP API Credentials": "/callback_sp",
    "Amazon Advertising Credentials": "/callbackadsapi",
  };

  if (checkPermission(contextValue, "setting", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }

  return (
    <Wrapper className="p-5 pt-0 fadeInBottom">
      <div className="tabs">
        <Segmented
          size="middle"
          onChange={(e) => {
            history.push(navigation?.[e]);

            setSelectedTab(e);
          }}
          value={selectedTab}
          options={[
            "Profile",
            "Amazon SP API Credentials",
            "Amazon Advertising Credentials",
          ]}
        />
      </div>
      {objs?.[selectedTab]}

      {adListModal && (
        <AdList
          show={adListModal}
          data={adList?.map((d, i) => {
            return { ...d, key: i };
          })}
          getList={() => {
            // GetAdsListAction();
          }}
          onHide={() => {
            setAdList([]);
            setAdListModal(false);
          }}
          {...props}
        />
      )}
      <div id="amazon-root"></div>
    </Wrapper>
  );
};

export default Setting;
