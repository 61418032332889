import axiosCall from "../../../configurations/network-services/axiosCall";

export const GetCatalogListAmazonAction = (data) => {
  const path = `system-event-process?event_type=${
    data?.event_type || ""
  }&event_name=${data?.event_name || ""}&pageSize=${data?.perPage || ""}&page=${
    data?.page || ""
  }&event_status=${data?.event_status || ""}`;
  const responseType = "SYSTEM_EVENT_PROCESS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const FeedSystemEventProcessListAction = (data) => {
  const path = `feed-system-event-process?pageSize=${
    data?.perPage || ""
  }&page=${data?.page || ""}`;
  const responseType = "FEED_SYSTEM_EVENT_PROCESS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetEventNameAction = () => {
  const path = `get-event-name`;
  const responseType = "GET_EVENT_NAME";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetEventTypeAction = () => {
  const path = `get-event-types`;
  const responseType = "GET_EVENT_TYPE";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCLS = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CENTRAL_LOG", state: data });
};
