import React from "react";
import Wrapper from "./style";
import { useState } from "react";
import { Segmented, Tabs, Tag } from "antd";
import View1 from "./lib/1";
import View2 from "./lib/2";
import View3 from "./lib/3";
import View4 from "./lib/4";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

const chartData = {
  years: ["2018", "2019", "2020", "2021", "2022"],
  values: [100, 120, 90, 150, 130],
};

const ShippingPerformance = () => {
  const [selectedCard, setSelectedCard] = useState("1");
  const CardView = {
    1: <View1 />,
    2: <View2 />,
    3: <View3 />,
    4: <View4 />,
  };
  const data = [
    { year: "2015", value: 184 },
    { year: "2016", value: 165 },
    { year: "2017", value: 150 },
    { year: "2018", value: 130 },
    { year: "2019", value: 51 },
    { year: "2020", value: 100 },
    { year: "2021", value: 150 },
    { year: "2022", value: 200 },
    { year: "2023", value: 250 },
  ];

  // Configure the ApexCharts options

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },

    xaxis: {
      categories: data.map((item) => item.year),
    },
  };

  const series = [
    {
      name: "Value",
      data: data.map((item) => item.value),
    },
  ];

  return (
    <Wrapper className="p-5">
      <div className="mb-10">
        <Segmented options={["7", "30", "60"]} />
      </div>
      <div className="row g-5 g-xl-8">
        <div onClick={() => setSelectedCard("1")} className="col-xl-3">
          <a
            href="#"
            className="card bg-white hoverable card-xl-stretch mb-xl-8"
            style={{
              border:
                selectedCard == 1 ? "1px solid #db715d" : "1px solid #db715d00",
            }}
          >
            <div className="card-body">
              <span className="svg-icon svg-icon-gray-800 svg-icon-3hx">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z"
                    fill="currentColor"
                  />
                </svg>
              </span>

              <div className="text-undefined fw-bold fs-2 mb-2 mt-5">N/A</div>
              <div className="fw-semibold text-undefined">
                Late Shipment Rate
              </div>
            </div>
            {selectedCard == 1 && (
              <div
                style={{
                  background: "#b05555",
                  width: 15,
                  height: 15,
                  borderRadius: 10,
                  position: "absolute",
                  right: 10,
                  top: 10,
                  border: "1px dashed #FFF",
                }}
              />
            )}
          </a>
        </div>
        <div onClick={() => setSelectedCard("2")} className="col-xl-3">
          <a
            href="#"
            style={{
              border:
                selectedCard == 2 ? "1px solid #db715d" : "1px solid #db715d00",
            }}
            className="card bg-dark hoverable card-xl-stretch mb-xl-8"
          >
            <div className="card-body">
              <span className="svg-icon svg-icon-light svg-icon-3hx">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z"
                    fill="currentColor"
                  />
                  <path
                    d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z"
                    fill="currentColor"
                  />
                </svg>
              </span>

              <div className="text-white fw-bold fs-2 mb-2 mt-5">N/A</div>
              <div className="fw-semibold text-white">
                Pre-fulfillment Cancel Rate
              </div>
            </div>
            {selectedCard == 2 && (
              <div
                style={{
                  background: "#b05555",
                  width: 15,
                  height: 15,
                  borderRadius: 10,
                  position: "absolute",
                  right: 10,
                  top: 10,
                  border: "1px dashed #FFF",
                }}
              />
            )}
          </a>
        </div>
        <div onClick={() => setSelectedCard("3")} className="col-xl-3">
          <a
            href="#"
            style={{
              border:
                selectedCard == 3 ? "1px solid #db715d" : "1px solid #db715d00",
            }}
            className="card bg-warning hoverable card-xl-stretch mb-xl-8"
          >
            <div className="card-body">
              <span className="svg-icon svg-icon-light svg-icon-3hx">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M21 11H18.9C18.5 7.9 16 5.49998 13 5.09998V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V5.09998C7.9 5.49998 5.50001 8 5.10001 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H5.10001C5.50001 16.1 8 18.4999 11 18.8999V21C11 21.6 11.4 22 12 22C12.6 22 13 21.6 13 21V18.8999C16.1 18.4999 18.5 16 18.9 13H21C21.6 13 22 12.6 22 12C22 11.4 21.6 11 21 11ZM12 17C9.2 17 7 14.8 7 12C7 9.2 9.2 7 12 7C14.8 7 17 9.2 17 12C17 14.8 14.8 17 12 17Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                    fill="currentColor"
                  />
                </svg>
              </span>

              <div className="text-white fw-bold fs-2 mb-2 mt-5">N/A</div>
              <div className="fw-semibold text-white">Valid Tracking Rate</div>
            </div>
            {selectedCard == 3 && (
              <div
                style={{
                  background: "#b05555",
                  width: 15,
                  height: 15,
                  borderRadius: 10,
                  position: "absolute",
                  right: 10,
                  top: 10,
                  border: "1px dashed #FFF",
                }}
              />
            )}
          </a>
        </div>
        <div onClick={() => setSelectedCard("4")} className="col-xl-3">
          <a
            style={{
              border:
                selectedCard == 4 ? "1px solid #db715d" : "1px solid #db715d00",
            }}
            href="#"
            className="card bg-info hoverable card-xl-stretch mb-5 mb-xl-8"
          >
            <div className="card-body">
              <span className="svg-icon svg-icon-light svg-icon-3hx">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.925 3.90078V8.00077L12.025 10.8008V5.10078L15.525 3.10078C16.125 2.80078 16.925 3.20078 16.925 3.90078ZM2.525 13.5008L6.025 15.5008L10.925 12.7008L6.025 9.90078L2.525 11.9008C1.825 12.3008 1.825 13.2008 2.525 13.5008ZM18.025 19.7008V15.6008L13.125 12.8008V18.5008L16.625 20.5008C17.225 20.8008 18.025 20.4008 18.025 19.7008Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M8.52499 3.10078L12.025 5.10078V10.8008L7.125 8.00077V3.90078C7.125 3.20078 7.92499 2.80078 8.52499 3.10078ZM7.42499 20.5008L10.925 18.5008V12.8008L6.02499 15.6008V19.7008C6.02499 20.4008 6.82499 20.8008 7.42499 20.5008ZM21.525 11.9008L18.025 9.90078L13.125 12.7008L18.025 15.5008L21.525 13.5008C22.225 13.2008 22.225 12.3008 21.525 11.9008Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <div className="text-white fw-bold fs-2 mb-2 mt-5">N/A</div>
              <div className="fw-semibold text-white">
                On-Time Delivery Rate
              </div>
            </div>
            {selectedCard == 4 && (
              <div
                style={{
                  background: "#b05555",
                  width: 15,
                  height: 15,
                  borderRadius: 10,
                  position: "absolute",
                  right: 10,
                  top: 10,
                  border: "1px dashed #FFF",
                }}
              />
            )}
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-12">
          {CardView?.[selectedCard]}
        </div>
        <div className="col-xl-8 col-lg-6 col-md-12">
          <div className="card p-5">
            <Chart options={options} series={series} type="bar" height={310} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ShippingPerformance;
