import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Reports from "../../modules/pages/reports";
import {
  SalesChannelListAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";
import {
  GetReportsListAction,
  fakeActionReports,
} from "../../redux/module_/reports/index.action";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
  GetAllReportsponse: state.Reports.GetAllReportsponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      fakeActionCatalog,

      GetReportsListAction,
      fakeActionReports,
    },
    dispatch
  );

const ReportsASINWiser = connect(mapStateToProps, mapDispatchToProps)(Reports);

export default ReportsASINWiser;
