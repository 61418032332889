import styled from "styled-components";

const Wrapper = styled.div`
  .ant-progress-inner {
    width: 60px !important;
    height: 60px !important;
    font-size: 14px !important;
  }
`;

export default Wrapper;
