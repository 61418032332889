import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BrandRevenueList from "../../modules/pages/brand-revenue-list";
import {
  SalesChannelListAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      fakeActionCatalog,
    },
    dispatch
  );

const BrandRevenueListASINWiser = connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandRevenueList);

export default BrandRevenueListASINWiser;
