import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-segmented-item-selected {
    background: linear-gradient(45deg, #601c46, #e3775f) !important;
    color: #ffffff;
    font-weight: bold;
  }
  .ant-segmented-item,
  .ant-segmented {
    :where(.css-dev-only-do-not-override-i56vxb).ant-segmented
      .ant-segmented-item {
      transition: color 0s cubic-bezier(0, 0, 0, 0);
    }
  }
  .ant-tabs-nav {
  }
`;
