import { GetInventoryDetailsDataResponse } from "../../fake";

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_INVENTORY_DATA_SUCCESS":
    case "GET_INVENTORY_DATA_ERROR":
      return {
        ...state,
        GetInventoryDataResponse: action.updatePayload,
      };
    case "GET_INVENTORY_DETAILS_DATA_SUCCESS":
    case "GET_INVENTORY_DETAILS_DATA_ERROR":
      return {
        ...state,
        GetInventoryDetailsDataResponse: GetInventoryDetailsDataResponse,
      };

    case "GET_REPLENISHMENT_DATA_SUCCESS":
    case "GET_REPLENISHMENT_DATA_ERROR":
      return {
        ...state,
        GetReplenishmentDataResponse: action.updatePayload,
      };

    case "UPDATE_REPLENISHMENT_DATA_SUCCESS":
    case "UPDATE_REPLENISHMENT_DATA_ERROR":
      return {
        ...state,
        UpdateReplenishmentDataResponse: action.updatePayload,
      };
    case "GET_SUPPLIER_LIST_DATA_SUCCESS":
    case "GET_SUPPLIER_LIST_DATA_ERROR":
      return {
        ...state,
        GetSupplierListResponse: action.updatePayload,
      };
    case "UPDATE_REPLENISH_BULK_DATA_SUCCESS":
    case "UPDATE_REPLENISH_BULK_DATA_ERROR":
      return {
        ...state,
        UpdateReplenishBulkDataResponse: action.updatePayload,
      };
    case "GET_REPLENISHMENT_FORECAST_DATA_SUCCESS":
    case "GET_REPLENISHMENT_FORECAST_DATA_ERROR":
      return {
        ...state,
        GetReplenishmentForecastDataResponse: action.updatePayload,
      };

    case "FAKE_ACTION_INVENTORY":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
