import {
  Card,
  Col,
  Input,
  Row,
  Drawer,
  Checkbox,
  Space,
  Statistic,
  Table,
  Button,
} from "antd";
import { motion } from "framer-motion";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import styled from "styled-components";
import { Wrapper } from "./style";

const AnimatedCard = styled(motion(Card))`
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.9s ease;
  .ant-card-body {
    padding: 0 !important;
  }
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const StatisticWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon-view {
    background: #bd5f59;
    padding: 8px;
    border-radius: 9px;
    color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
`;

const columnsData = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "Advertising Type",
    dataIndex: "advertising_type",
    key: "advertising_type",
    sorter: (a, b) => a.advertising_type.localeCompare(b.advertising_type),
  },
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    key: "campaign_name",
    sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
  },
  {
    title: "Campaign ID",
    dataIndex: "campaign_id",
    key: "campaign_id",
    sorter: (a, b) => a.campaign_id - b.campaign_id,
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
    sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "end_date",
    sorter: (a, b) => new Date(a.end_date) - new Date(b.end_date),
  },
  {
    title: "Targeting Type",
    dataIndex: "targeting_type",
    key: "targeting_type",
    sorter: (a, b) => a.targeting_type.localeCompare(b.targeting_type),
  },
  {
    title: "Budget Type",
    dataIndex: "budget_type",
    key: "budget_type",
    sorter: (a, b) => a.budget_type.localeCompare(b.budget_type),
  },
  {
    title: "Budget Amount",
    dataIndex: "budget_amount",
    key: "budget_amount",
    sorter: (a, b) => a.budget_amount - b.budget_amount,
  },
  {
    title: "Budget Currency",
    dataIndex: "budget_currency",
    key: "budget_currency",
    sorter: (a, b) => a.budget_currency.localeCompare(b.budget_currency),
  },
  {
    title: "Top of Search Placement",
    dataIndex: "top_of_search_placement",
    key: "top_of_search_placement",
    sorter: (a, b) =>
      a.top_of_search_placement.localeCompare(b.top_of_search_placement),
  },
  {
    title: "Product Pages Placement",
    dataIndex: "product_pages_placement",
    key: "product_pages_placement",
    sorter: (a, b) =>
      a.product_pages_placement.localeCompare(b.product_pages_placement),
  },
  {
    title: "Bidding Strategy Type",
    dataIndex: "bidding_strategy_type",
    key: "bidding_strategy_type",
    sorter: (a, b) =>
      a.bidding_strategy_type.localeCompare(b.bidding_strategy_type),
  },
  {
    title: "Campaign State",
    dataIndex: "campaign_state",
    key: "campaign_state",
    sorter: (a, b) => a.campaign_state.localeCompare(b.campaign_state),
  },
  {
    title: "Portfolio ID",
    dataIndex: "portfolio_id",
    key: "portfolio_id",
    sorter: (a, b) => a.portfolio_id - b.portfolio_id,
  },
  {
    title: "Marketplace Name",
    dataIndex: "marketplace_name",
    key: "marketplace_name",
    sorter: (a, b) => a.marketplace_name.localeCompare(b.marketplace_name),
  },
  {
    title: "Marketplace ID",
    dataIndex: "marketplace_id",
    key: "marketplace_id",
    sorter: (a, b) => a.marketplace_id - b.marketplace_id,
  },
  {
    title: "Is Fully Created",
    dataIndex: "is_fully_created",
    key: "is_fully_created",
    sorter: (a, b) => a.is_fully_created - b.is_fully_created,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
];

export default function CampaignManagement() {
  const [visible, setVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    columnsData.map((col) => ({ key: col.key, visible: true }))
  );
  const [tempSelectedColumns, setTempSelectedColumns] = useState(
    columnsData.map((col) => ({ key: col.key, visible: true }))
  );

  const handleShowDrawer = () => setVisible(true);
  const handleCloseDrawer = () => setVisible(false);

  const handleColumnChange = (key) => {
    setTempSelectedColumns((prev) =>
      prev.map((col) =>
        col.key === key ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const handleReset = () => {
    setTempSelectedColumns(
      columnsData.map((col) => ({
        key: col.key,
        visible: selectedColumns.find((colState) => colState.key === col.key)
          ?.visible,
      }))
    );
  };

  const handleApply = () => {
    setSelectedColumns(tempSelectedColumns);
    handleCloseDrawer();
  };

  const visibleColumns = columnsData.filter(
    (col) =>
      selectedColumns.find((colState) => colState.key === col.key)?.visible
  );
  const options = {
    chart: {
      type: "line",
      zoomType: "xy",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "Sep 2018",
        "Jan 2019",
        "May 2019",
        "Sep 2019",
        "Jan 2020",
        "May 2020",
        "Sep 2020",
        "Jan 2021",
        "May 2021",
        "Sep 2021",
        "Jan 2022",
        "May 2022",
        "Sep 2022",
        "Jan 2023",
        "May 2023",
        "Sep 2023",
        "Jan 2024",
        "May 2024",
      ],
      crosshair: true,
    },
    yAxis: [
      {
        title: {
          text: "",
          style: {
            color: "#cd822c",
          },
        },
        labels: {
          format: "${value}K",
          style: {
            color: "#cd822c",
          },
        },
      },
      {
        title: {
          text: "",
          style: {
            color: "#a13c78",
          },
        },
        labels: {
          format: "{value}%",
          style: {
            color: "#a13c78",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      backgroundColor: "rgba(255, 255, 255, 1)",
      borderWidth: 1,
      borderColor: "#ec825d70",
      borderRadius: 10,
      shadow: false,
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
      pointFormat:
        '<tr><td style="color: {series.color}">{series.name}: </td>' +
        '<td style="text-align: right"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      style: {
        border: "1px solid gray",
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        states: {
          hover: {
            enabled: true,
            lineWidth: 4,
          },
        },
      },
    },
    series: [
      {
        name: "Value",
        data: [
          2000, 1000, 2500, 2000, 3500, 2700, 1500, 6000, 9000, 500, 5000, 5000,
          8000, 6000, 3500, 7500, 7000, 4500,
        ],
        color: "#cd822c",
      },
      {
        name: "Percentage",
        yAxis: 1,
        data: [
          50, 30, 40, 70, 40, 30, 30, 30, 30, 200, 50, 50, 50, 50, 70, 60, 200,
          50,
        ],
        color: "#a13c78",
      },
    ],
  };

  // Define the data (example)
  const data = [
    {
      id: "1",
      advertising_type: "Type A",
      campaign_name: "Campaign 1",
      campaign_id: "123",
      start_date: "2024-07-01",
      end_date: "2024-07-31",
      targeting_type: "Type 1",
      budget_type: "Daily",
      budget_amount: 100,
      budget_currency: "USD",
      top_of_search_placement: "Yes",
      product_pages_placement: "No",
      bidding_strategy_type: "Manual",
      campaign_state: "Active",
      portfolio_id: "456",
      marketplace_name: "Amazon US",
      marketplace_id: "789",
      is_fully_created: "Yes",
      status: "Running",
    },
    {
      id: "2",
      advertising_type: "Type B",
      campaign_name: "Campaign 2",
      campaign_id: "124",
      start_date: "2024-06-01",
      end_date: "2024-06-30",
      targeting_type: "Type 2",
      budget_type: "Monthly",
      budget_amount: 200,
      budget_currency: "USD",
      top_of_search_placement: "No",
      product_pages_placement: "Yes",
      bidding_strategy_type: "Automatic",
      campaign_state: "Paused",
      portfolio_id: "457",
      marketplace_name: "Amazon UK",
      marketplace_id: "790",
      is_fully_created: "No",
      status: "Completed",
    },
    {
      id: "3",
      advertising_type: "Type C",
      campaign_name: "Campaign 3",
      campaign_id: "125",
      start_date: "2024-05-01",
      end_date: "2024-05-31",
      targeting_type: "Type 3",
      budget_type: "Weekly",
      budget_amount: 300,
      budget_currency: "EUR",
      top_of_search_placement: "Yes",
      product_pages_placement: "No",
      bidding_strategy_type: "Manual",
      campaign_state: "Draft",
      portfolio_id: "458",
      marketplace_name: "Amazon DE",
      marketplace_id: "791",
      is_fully_created: "Yes",
      status: "Pending",
    },
    {
      id: "4",
      advertising_type: "Type D",
      campaign_name: "Campaign 4",
      campaign_id: "126",
      start_date: "2024-04-01",
      end_date: "2024-04-30",
      targeting_type: "Type 4",
      budget_type: "Daily",
      budget_amount: 150,
      budget_currency: "GBP",
      top_of_search_placement: "No",
      product_pages_placement: "Yes",
      bidding_strategy_type: "Automatic",
      campaign_state: "Archived",
      portfolio_id: "459",
      marketplace_name: "Amazon CA",
      marketplace_id: "792",
      is_fully_created: "No",
      status: "Stopped",
    },
    {
      id: "5",
      advertising_type: "Type E",
      campaign_name: "Campaign 5",
      campaign_id: "127",
      start_date: "2024-03-01",
      end_date: "2024-03-31",
      targeting_type: "Type 5",
      budget_type: "Monthly",
      budget_amount: 250,
      budget_currency: "USD",
      top_of_search_placement: "Yes",
      product_pages_placement: "No",
      bidding_strategy_type: "Manual",
      campaign_state: "Active",
      portfolio_id: "460",
      marketplace_name: "Amazon AU",
      marketplace_id: "793",
      is_fully_created: "Yes",
      status: "Running",
    },
  ];

  const BodyRow = ({ className, style, index, ...restProps }) => {
    return (
      <motion.tr
        className={className}
        style={{
          ...style,
          transition: "all 0.9s ease",
        }}
        {...restProps}
        initial={{ opacity: 0, y: -20, zIndex: -1 }}
        animate={{ opacity: 1, y: 0, zIndex: 9 }}
        exit={{ opacity: 0, y: 20, zIndex: -1 }}
        transition={{ duration: 0.7, delay: index * 1 }}
      />
    );
  };

  return (
    <Wrapper>
      <Row className="mb-4 pb-4 mt-4" gutter={16}>
        <Col span={6}>
          <AnimatedCard
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            hoverable
            style={{ backgroundColor: "#FFF", borderColor: "#ca695a" }}
          >
            <StatisticWrapper>
              <div>
                <Statistic
                  title={<span>Spend </span>}
                  value={123790.21}
                  precision={2}
                  prefix="$"
                  valueStyle={{
                    color: "#3c3c3c",
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                  titleStyle={{ color: "#ca695a" }}
                />
              </div>
              <div className="icon-view">
                <svg
                  width={46}
                  height={46}
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 7h13.79a2 2 0 0 1 1.99 2.199l-.6 6A2 2 0 0 1 18.19 17H8.64a2 2 0 0 1-1.962-1.608L5 7z" />
                  <path d="m5 7-.81-3.243A1 1 0 0 0 3.22 3H2" />
                  <path d="M8 21h2" />
                  <path d="M16 21h2" />
                </svg>
              </div>
            </StatisticWrapper>
          </AnimatedCard>
        </Col>
        <Col span={6}>
          <AnimatedCard
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            hoverable
            style={{ backgroundColor: "#FFF", borderColor: "#ca695a" }}
          >
            <StatisticWrapper>
              <div>
                <Statistic
                  title={<span>Sales </span>}
                  value={285970.35}
                  precision={2}
                  prefix="$"
                  valueStyle={{
                    color: "#3c3c3c",
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                  titleStyle={{ color: "#ca695a" }}
                />
              </div>
              <div className="icon-view">
                <svg
                  width={46}
                  height={46}
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.5 2a9.5 10 0 1 1 0 20 9.5 10 0 1 1 0-20z" />
                  <path d="M13 8.8a3.583 3.583 0 0 0-2.25-.8C8.679 8 7 9.79 7 12s1.679 4 3.75 4c.844 0 1.623-.298 2.25-.8" />
                  <path d="M10 2c4.333 0 13 1 13 10s-8.667 10-13 10" />
                </svg>
              </div>
            </StatisticWrapper>
          </AnimatedCard>
        </Col>
        <Col span={6}>
          <AnimatedCard
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
            hoverable
            style={{ backgroundColor: "#FFF", borderColor: "#ca695a" }}
          >
            <StatisticWrapper>
              <div>
                <Statistic
                  title={<span>ACOS </span>}
                  value={43.29}
                  precision={2}
                  suffix="%"
                  valueStyle={{
                    color: "#3c3c3c",
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                  titleStyle={{ color: "#ca695a" }}
                />
              </div>
              <div className="icon-view">
                <svg
                  width={46}
                  height={46}
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10 17h6V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a1 1 0 0 0 1 1h1" />
                  <path d="M22 16v-4a4 4 0 0 0-4-4h-2v9h5a1 1 0 0 0 1-1z" />
                  <path d="M15 17a3 3 0 1 0 6 0h-6z" />
                  <path d="M22 14h-2" />
                  <path d="M7 14a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
                </svg>
              </div>
            </StatisticWrapper>
          </AnimatedCard>
        </Col>
        <Col span={6}>
          <AnimatedCard
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.2 }}
            hoverable
            style={{ backgroundColor: "#FFF", borderColor: "#ca695a" }}
          >
            <StatisticWrapper>
              <div>
                <Statistic
                  title={<span>Impressions </span>}
                  value={41150170}
                  valueStyle={{
                    color: "#3c3c3c",
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                  titleStyle={{ color: "#ca695a" }}
                />
              </div>
              <div className="icon-view">
                <svg
                  width={46}
                  height={46}
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v5a7 7 0 0 1-7 7v0a7 7 0 0 1-7-7V4Z" />
                  <path d="M9 22h6l-3-5-3 5Z" />
                  <path d="M5 4H4a2 2 0 0 0-2 2v1.239a4 4 0 0 0 2.128 3.535L5.5 11.5" />
                  <path d="M19 4h1a2 2 0 0 1 2 2v.637a5 5 0 0 1-2.66 4.419l-.84.444" />
                </svg>
              </div>
            </StatisticWrapper>
          </AnimatedCard>
        </Col>
      </Row>
      <div className="card">
        <div className="card-body pt-4 pb-2">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
      <div className="card mt-5">
        <div className="card-header">
          <div className="card-title"></div>
          <div className="card-toolbar">
            <Input placeholder="Search by Campaign Name" className="w-250px" />
            <Button className="ms-3" type="primary" onClick={handleShowDrawer}>
              <svg
                width={20}
                height={20}
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M11.342 1.497c-.609 0-1.136.422-1.271 1.015l-.365 1.61c-.581.17-1.136.4-1.655.687l-1.397-.88a1.303 1.303 0 0 0-1.617.18l-.93.93a1.303 1.303 0 0 0-.18 1.616l.88 1.398a8.16 8.16 0 0 0-.685 1.654l-1.61.365a1.303 1.303 0 0 0-1.015 1.272v1.314c0 .609.421 1.136 1.015 1.27l1.61.366c.17.581.4 1.135.686 1.655l-.88 1.397a1.303 1.303 0 0 0 .18 1.617l.93.93c.43.43 1.102.505 1.617.18l1.397-.88a8.21 8.21 0 0 0 1.654.685l.365 1.61a1.303 1.303 0 0 0 1.271 1.016h1.314c.61 0 1.137-.422 1.271-1.016l.365-1.61a8.168 8.168 0 0 0 1.656-.685l1.397.88c.515.325 1.186.25 1.617-.18l.93-.93c.43-.43.505-1.102.18-1.616l-.88-1.397c.286-.52.517-1.075.686-1.656l1.61-.365a1.303 1.303 0 0 0 1.015-1.271v-1.314c0-.61-.421-1.137-1.015-1.272l-1.61-.364a8.156 8.156 0 0 0-.686-1.656l.88-1.397a1.303 1.303 0 0 0-.18-1.617l-.93-.929a1.303 1.303 0 0 0-1.616-.181l-1.397.88a8.161 8.161 0 0 0-1.657-.686l-.364-1.61a1.303 1.303 0 0 0-1.272-1.015h-1.314ZM8.045 6.346a6.863 6.863 0 0 1 2.752-1.141l.545-2.405h1.314l.545 2.405a6.864 6.864 0 0 1 2.754 1.14l2.086-1.314.93.929-1.316 2.086a6.86 6.86 0 0 1 1.14 2.753l2.405.545v1.314l-2.404.545a6.86 6.86 0 0 1-1.141 2.753l1.314 2.086-.929.929-2.086-1.315a6.862 6.862 0 0 1-2.753 1.14l-.545 2.404h-1.314l-.545-2.404a6.862 6.862 0 0 1-2.751-1.14L5.96 18.97l-.93-.93 1.315-2.086a6.863 6.863 0 0 1-1.14-2.752L2.8 12.658v-1.314l2.405-.546a6.863 6.863 0 0 1 1.14-2.751L5.029 5.96l.93-.93 2.086 1.316ZM14.338 12a2.338 2.338 0 1 1-4.676 0 2.338 2.338 0 0 1 4.676 0Zm1.38 0a3.718 3.718 0 1 1-7.436 0 3.718 3.718 0 0 1 7.436 0Z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
          </div>
        </div>
        <div className="card-body">
          <Table
            columns={visibleColumns}
            scroll={{ x: "max-content" }}
            dataSource={data.map((item, index) => ({ ...item, key: index }))}
            pagination={false}
            components={{
              body: {
                row: (props) => (
                  <BodyRow {...props} index={props["data-row-key"]} />
                ),
              },
            }}
            rowKey="key"
          />
        </div>
      </div>
      <Drawer
        title="Config"
        placement="right"
        onClose={handleCloseDrawer}
        visible={visible}
        width={300}
      >
        <Space direction="vertical">
          {columnsData.map((col) => (
            <Checkbox
              key={col.key}
              checked={
                tempSelectedColumns.find((colState) => colState.key === col.key)
                  ?.visible
              }
              onChange={() => handleColumnChange(col.key)}
            >
              {col.title}
            </Checkbox>
          ))}
        </Space>
        <div style={{ marginTop: 16 }}>
          <Button type="primary" onClick={handleApply}>
            Apply
          </Button>
        </div>
      </Drawer>
    </Wrapper>
  );
}
