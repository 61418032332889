import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InventoryDetails from "../../../modules/pages/Inventory/details";
import {
  SalesChannelListAction,
  fakeActionCatalog,
} from "../../../redux/module_/catalog/index.action";
import {
  GetInventoryDataAction,
  GetAllInventoryAction,
  InventoryEmailFlagAction,
  ReplenishmentBulkUpdateAction,
  fakeActionInventory,
} from "../../../redux/module_/inventory/index.action";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
  GetInventoryListResponse: state.Inventory_.GetInventoryListResponse,
  GetAllInventoryListResponse: state.Inventory_.GetAllInventoryListResponse,
  ReplenishmentBulkUpdateResponse:
    state.Inventory_.ReplenishmentBulkUpdateResponse,
  InventoryEmailFlagResponse: state.Inventory_.InventoryEmailFlagResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      fakeActionCatalog,

      GetInventoryDataAction,
      GetAllInventoryAction,
      ReplenishmentBulkUpdateAction,
      InventoryEmailFlagAction,
      fakeActionInventory,
    },
    dispatch
  );

const InventoryDetailsASIN = connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryDetails);

export default InventoryDetailsASIN;
