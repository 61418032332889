import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AccountStatus from "../../modules/pages/account-status";
import {
  SalesChannelListAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";
import {
  GetSellerPerfoemanceAction,
  SellerPerdormanceByMarketplaceAction,
  fakeActionAccountStatus,
} from "../../redux/module_/account-status/index.action";
const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,

  SellerPerformanceByResponse: state.AccountStatus.SellerPerformanceByResponse,
  SellerPerformanceByMarketplaceResponse:
    state.AccountStatus.SellerPerformanceByMarketplaceResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      fakeActionCatalog,

      GetSellerPerfoemanceAction,
      SellerPerdormanceByMarketplaceAction,
      fakeActionAccountStatus,
    },
    dispatch
  );

const AccountStatus_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountStatus);

export default AccountStatus_;
