import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BrandMonitor from "../../modules/pages/brand-protection/brand-monitor";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const BrandMonitor_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandMonitor);

export default BrandMonitor_;
