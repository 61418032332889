import styled from "styled-components";

const BrandRevenueListWrapper = styled.div`
  #kt_advanced_search_form .ant-input {
    background: #7e78ef1c;
    border-top: 1px solid #7e78ef52 !important;
    border-bottom: 1px solid #7e78ef52 !important;
  }
  #kt_advanced_search_form .ant-input:last-child {
    border-right: 1px solid #7e78ef52 !important;
  }
  #kt_advanced_search_form .sign {
    margin-inline-end: 0px !important;
    background: #7e78ef1c;
    border-top: 1px solid #7e78ef52 !important;
    border-bottom: 1px solid #7e78ef52 !important;
    border-left: 1px solid #7e78ef52 !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #7e78ef1c;
    border-radius: 0.65rem;
    border: 1px solid #5f1a46 !important;
    color: #000;
  }
  .ant-select-clear {
    background: #d8d5fa !important;
  }

  .ant-segmented-item-selected {
    background: linear-gradient(45deg, #601c46, #e3775f) !important;
    color: #ffffff;
    font-weight: bold;
  }
  .ant-segmented-item,
  .ant-segmented {
    :where(.css-dev-only-do-not-override-i56vxb).ant-segmented
      .ant-segmented-item {
      transition: color 0s cubic-bezier(0, 0, 0, 0);
    }
  }
`;

export default BrandRevenueListWrapper;
