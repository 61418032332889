import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ManageUser from "../../modules/pages/manage-user";
import {
  GetUserListAction,
  AllAdminAAction,
  SwitchUserAction,
  fakeActionManageUser,
  UserUpdateAction,
  UserDeleteAction,
  AddUserAction,
} from "../../redux/module_/manage-user/index.action";

const mapStateToProps = (state) => ({
  UserListResponse: state.User.UserListResponse,
  SwitchUserResponse: state.User.SwitchUserResponse,
  AllAdminAResponse: state.User.AllAdminAResponse,
  AddUserLResponse: state.User.AddUserLResponse,
  UserUpdateResponse: state.User.UserUpdateResponse,
  UserDeleteResponse: state.User.UserDeleteResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetUserListAction,
      AllAdminAAction,
      SwitchUserAction,
      fakeActionManageUser,
      UserUpdateAction,
      UserDeleteAction,
      AddUserAction,
    },
    dispatch
  );

const ManageUser_ = connect(mapStateToProps, mapDispatchToProps)(ManageUser);

export default ManageUser_;
