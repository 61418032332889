import React, { useState } from "react";
import { Modal, Button, Form, Input, Select, Checkbox, message } from "antd";
import { API_URL, APPID } from "../../../../config";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const SpModal = ({ view, onClose, list, Alllist }) => {
  const [region, setRegion] = useState([]);
  const [regionLoading, setRegionLoading] = useState(true);
  const [regionSelected, setRegionSelected] = useState({
    url: null,
    value: null,
  });
  const [selectedMarketplaceList, setSelectedMarketplaceList] = useState([]);

  const [marketplaceSelected, setMarketplaceSelected] = useState(null);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(false);
  const [urls, setUrls] = useState({});
  const [sellerName, setSellerName] = useState(
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))?.u_amazon_seller_name || ""
      : ""
  );

  const [primarySelectedMarketplace, setPrimarySelectedMarketplace] =
    useState(false);
  const [accountType, setAccountType] = useState(null);
  const getRegion = () => {
    setRegionLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}get-region`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setRegionLoading(false);
        if (result?.status === true) {
          setRegion(
            Object.keys(result?.data?.records)?.map((d, i) => {
              if (i === 0) {
                getMarketplace(d);
                setMarketplaceLoading(true);
                setMarketplaceSelected(null);

                setRegionSelected({
                  url: result?.data?.records?.[d]?.url,
                  value: d,
                });
              }
              return {
                label: result?.data?.records?.[d]?.name,
                url: result?.data?.records?.[d]?.url,
                value: d,
              };
            })
          );
        }
      })
      .catch((error) => {});
  };

  const getMarketplace = (data) => {
    setMarketplaceLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}get-marketplace-region/${data || ""}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setMarketplaceLoading(false);
        if (result?.status === true) {
          setUrls(result?.data?.urls);
          const index = Object.entries(result?.data?.records)?.findIndex(
            (d) => d?.[0] === list?.[0]?.marketplace_id
          );
          if (index !== -1) {
            // setSelectedMarketplaceList([
            //   ...selectedMarketplaceList,
            //   list?.[0]?.marketplace_id,
            // ]);

            setMarketplaceSelected(list?.[0]?.marketplace_id);
            setPrimarySelectedMarketplace(true);
          } else {
            // setSelectedMarketplaceList([]);

            setMarketplaceSelected(null);
            setPrimarySelectedMarketplace(true);
            setPrimarySelectedMarketplace(false);
          }
          setMarketplaceList(
            Object.entries(result?.data?.records)?.map((d) => {
              return {
                label: d?.[1],
                value: d?.[0],
              };
            })
          );
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getRegion();
    return () => {};
  }, []);
  const getEndPoint = () =>
    accountType === "seller"
      ? urls?.[marketplaceSelected]
      : `https://vendorcentral${urls?.[marketplaceSelected]
          ?.split("https://sellercentral")
          .join("")}`;

  console.log(
    // Alllist,
    Alllist?.filter(
      (d) => d?.account_type?.toLowerCase() === accountType
    )?.filter((d) => d?.region === regionSelected?.value),
    regionSelected?.value,
    accountType,
    marketplaceSelected,
    "list"
  );
  return (
    <Modal
      title="Add New"
      open={view}
      width={750}
      centered
      footer={[
        <Link
          type="submit"
          id="kt_modal_add_customer_submit"
          className={`btn btn-primary mt-10 ${
            Object.values(regionSelected)?.filter((d) => !d)?.length !== 0 ||
            !marketplaceSelected ||
            !accountType
              ? "btn-disable-primary"
              : ""
          }`}
          target="_blank"
          style={{
            pointerEvents:
              Object.values(regionSelected)?.filter((d) => !d)?.length !== 0 ||
              !marketplaceSelected ||
              !accountType
                ? "none"
                : "auto",
          }}
          to={{
            pathname: `${getEndPoint()}/apps/authorize/consent?application_id=${APPID}&state=${
              localStorage.getItem("user")
                ? JSON.parse(localStorage.getItem("user"))
                    ?.u_amazon_seller_email || ""
                : ""
            }!!${sellerName}!!${
              regionSelected?.value
            }!!${marketplaceSelected}!!${accountType}&version=beta`,
          }}
          onClick={() => {
            if (
              Object.values(regionSelected)?.filter((d) => !d)?.length === 0
            ) {
              onClose();
              message.destroy();
            } else {
              message.destroy();
              return message.error(
                "please make sure that all required fields are not-empty"
              );
            }
          }}
        >
          <span className="indicator-label">Login With Amazon</span>
        </Link>,
      ]}
      onCancel={onClose}
    >
      <div className="gap-5 mt-10">
        <div className="row mb-5">
          <div className="col-4">Seller Account Name</div>
          <div className="col-8">
            <Input
              onChange={(e) => setSellerName(e.target.value)}
              value={sellerName}
              placeholder="Seller Account Name"
            />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-4">Account Type</div>
          <div className="col-8">
            <Select
              style={{ width: "100%" }}
              placeholder="Account Type"
              onChange={(e) => setAccountType(e)}
              value={accountType}
              options={[
                { label: "Vendor", value: "vendor" },
                { label: "Seller", value: "seller" },
              ]}
            />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-4">Region</div>
          <div className="col-8">
            <Select
              options={region}
              loading={regionLoading}
              value={regionSelected?.value}
              onChange={(e, r) => {
                // setSelectedMarketplaceList([]);
                getMarketplace(r?.value);
                setMarketplaceLoading(true);
                setMarketplaceSelected(null);
                setRegionSelected({
                  url: r?.url,
                  value: r?.value,
                });
              }}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-4">Primary Marketplace</div>
          <div className="col-8">
            <Select
              style={{ width: "100%" }}
              onChange={(e) => {
                setMarketplaceSelected(e);
              }}
              placeholder="Select Primary Marketplace"
              options={marketplaceList}
              // disabled={primarySelectedMarketplace}
              value={marketplaceSelected}
              loading={marketplaceLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SpModal;
