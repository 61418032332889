import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Catalog from "../../modules/pages/catalogue";
import {
  GetListCatalogAction,
  GetListCatalogConfigAction,
  UpdateCatalogConfigAction,
  CatalogManualMappingAction,
  SalesChannelListAction,
  UpdateCatalogModal,
  DeleteCatalogManualAction,
  CreateFeedListingLogs,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";
import {
  fakeActionAnalytics,
  GetCategoryAction,
  GetSubCategoryAction,
} from "../../redux/module_/analytics/index.action";

const mapStateToProps = (state) => ({
  CatalogResponse: state.Catalog.CatalogResponse,
  GetCatalogConfigResponse: state.Catalog.GetCatalogConfigResponse,
  UpdateCatalogConfigResponse: state.Catalog.UpdateCatalogConfigResponse,
  CatalogManualMappingResponse: state.Catalog.CatalogManualMappingResponse,
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
  UpdateCatalogManualResponse: state.Catalog.UpdateCatalogManualResponse,
  CreateFeedListingLogsResponse: state.Catalog.CreateFeedListingLogsResponse,
  DeleteCatalogManualResponse: state.Catalog.DeleteCatalogManualResponse,

  GetCategoryListResponse: state.Analytics.GetCategoryListResponse,
  GetSubCategoryListResponse: state.Analytics.GetSubCategoryListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetListCatalogAction,
      GetListCatalogConfigAction,
      UpdateCatalogConfigAction,
      CatalogManualMappingAction,
      SalesChannelListAction,
      UpdateCatalogModal,
      DeleteCatalogManualAction,
      CreateFeedListingLogs,
      fakeActionCatalog,

      fakeActionAnalytics,
      GetCategoryAction,
      GetSubCategoryAction,
    },
    dispatch
  );

const Catalog_ = connect(mapStateToProps, mapDispatchToProps)(Catalog);

export default Catalog_;
