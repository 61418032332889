import styled from "styled-components";

const Wrapper = styled.div`
  .gridScroll {
    // display: flex;
    // justify-content: flex-end;
  }
  .more-details {
    background: #fff;
    border-radius: 0px 0px 7px 7px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    margin: 0px 1px;
    padding: 10px 0px 0px 0px;
  }
  .forcast-btn {
    background-color: rgb(199 102 91) !important;
  }
  .ant-list-item-meta {
    border: 1px solid #f7c7ba;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    align-items: center;
    margin-right: 2px;
    background: #fef6f4;
    margin-block-end: 0px !important;
  }
  .ant-select-tree-switcher {
    width: 100% !important;
    position: absolute !important;
    z-index: 9 !important;
    .ant-select-tree-switcher-icon {
      position: relative !important;
      left: -95px !important;
    }
  }
  .ant-select-tree-checkbox {
    margin-left: 25px !important;
    z-index: 9 !important;
  }
  .ant-input-affix-wrapper {
    border-color: #601947 !important;
    background-color: #fef3f2 !important;
  }
  .ant-btn-primary {
    color: rgb(255 255 255) !important;
    background-color: rgb(183 91 88) !important;
  }
  .w-60 {
    width: 60% !important;
  }
  .w-120px {
    width: 120px !important;
  }
  .ant-segmented-item-selected {
    background: linear-gradient(45deg, #601c46, #e3775f) !important;
    color: #ffffff;
    font-weight: bold;
  }
  .ant-segmented-item,
  .ant-segmented {
    :where(.css-dev-only-do-not-override-i56vxb).ant-segmented
      .ant-segmented-item {
      transition: color 0s cubic-bezier(0, 0, 0, 0);
    }
  }
`;

export default Wrapper;

export const EditReplenishment = styled.div`
  .ant-segmented-item-selected {
    background: linear-gradient(45deg, #601c46, #e3775f) !important;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .ant-segmented-item,
  .ant-segmented {
    :where(.css-dev-only-do-not-override-i56vxb).ant-segmented
      .ant-segmented-item {
      transition: color 0s cubic-bezier(0, 0, 0, 0);
    }
  }
  .ant-segmented-item-label {
    line-height: 37px !important;
    min-height: 35px !important;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  .ant-checkbox .ant-checkbox-inner {
    width: 21px !important;
    height: 23px !important;
  }
`;

export const ReplenishmentTableWrapper = styled.div`
  .ant-tag-custom-border {
    border-color: transparent !important;
  }
`;
