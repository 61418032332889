import React, { useContext, useEffect, useState } from "react";
import { Wrapper } from "./style";
import { DefaultPerPage, checkPermission } from "../../../../config";
import { useSelector } from "react-redux";
import Pagination from "../../../../components/pagination";
import { Table, Tooltip, Popover, Result } from "antd";
import moment from "moment";
import { GlobalContext } from "../../../../commonContext";

const SystemLog = ({ GetUserSystemLogAction, fakeActionSystemLog }) => {
  const [list, setList] = useState([]);
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const SystemEventLogListResponse = useSelector(
    (state) => state?.System?.SystemEventLogListResponse || {}
  );

  useEffect(() => {
    if (SystemEventLogListResponse?.status === true) {
      setTotalPage(
        SystemEventLogListResponse?.data?.pagination?.totalCount || 0
      );
      setList(SystemEventLogListResponse?.data?.records || []);
      setLoading(false);
      fakeActionSystemLog("SystemEventLogListResponse");
    } else if (SystemEventLogListResponse?.status === false) {
      setLoading(false);
      setList([]);
      fakeActionSystemLog("SystemEventLogListResponse");
    }
  }, [SystemEventLogListResponse]);

  useEffect(() => {
    setLoading(true);
    setList([]);
    GetUserSystemLogAction({
      page: page,
      perPage: pageSize,
    });

    return () => {};
  }, []);

  const onPageNo = (e) => {
    setLoading(true);
    setList([]);
    GetUserSystemLogAction({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    GetUserSystemLogAction({
      page: 1,
      perPage: e,
    });
  };

  const columns = [
    {
      title: "Sr. No.",
      width: 100,
      align: "left",
      ellipsis: true,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Date & Time Log Created",
      width: 250,
      align: "left",
      render: (d) => {
        return (
          <span>
            {moment(new Date(d.created_at * 1000)).format(
              "MMM DD, YYYY hh:mm A"
            )}
          </span>
        );
      },
    },
    {
      title: "Event Name",
      width: 220,
      align: "left",
      render: (d) => {
        return <span>{d.event_name || "-"}</span>;
      },
    },
    {
      title: "Event Type",
      width: 150,
      align: "left",
      render: (d) => {
        return <span>{d.event_type || "-"}</span>;
      },
    },
    {
      title: "Event Message",
      width: 180,
      align: "left",
      ellipsis: false,
      render: (d) => {
        return (
          <Popover
            placement="left"
            title=""
            getPopupContainer={(target) => target.parentNode}
            content={
              <div
                style={{
                  width: "400px",
                  wordBreak: "break-word",
                  minHeight: "20px",
                  maxHeight: "100px",
                  overflow: "auto",
                }}
              >
                {d?.error_message}
              </div>
            }
            trigger="hover"
          >
            <div className="actionIcon">{d.error_message}</div>
          </Popover>
        );
      },
    },
    {
      title: "Event Data",
      width: 150,
      align: "left",
      ellipsis: false,
      render: (d) => {
        if (!d?.error_data) {
          return "-";
        }

        return (
          <Popover
            placement="left"
            title=""
            getPopupContainer={() =>
              document.getElementById("System_Event_Log_Table_User").parentNode
            }
            content={
              <div
                style={{
                  width: "400px",
                  wordBreak: "break-word",
                  minHeight: "20px",
                  maxHeight: "100px",
                  overflow: "auto",
                }}
              >
                {d?.error_data}
              </div>
            }
            trigger="hover"
          >
            <div className="actionIcon">{d.error_data}</div>
          </Popover>
        );
      },
    },
  ];

  if (checkPermission(contextValue, "application_logs", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }

  return (
    <Wrapper className="p-5 fadeInBottom">
      <div className="card">
        <div className="card-body">
          <Table
            columns={columns}
            dataSource={list}
            loading={loading}
            pagination={false}
            scroll={{ x: "max-content" }}
          />
          <Pagination
            loading={loading || list?.length === 0}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={onPerPage}
            onPageNo={onPageNo}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default SystemLog;
