import React, { useState } from "react";
import { Modal, Form, Input, Select, Button, message } from "antd";
import { useEffect } from "react";

const { Option } = Select;

const AddCriteria = ({
  visible,
  onCancel,
  onUpdate,
  onCreate,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState("");
  const [conditionsList, setConditionsList] = useState([
    {
      operator: null,
      value: null,
      score: null,
    },
  ]);

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const findTableData = {
    lqs: {
      "Title length": "product_title_score",
      "Bullet point 1": "bullet_point_1_score",
      "Bullet point 2": "bullet_point_2_score",
      "Bullet point 3": "bullet_point_3_score",
      "Bullet point 4": "bullet_point_4_score",
      "Bullet point 5": "bullet_point_5_score",
      Description: "description_score",
      "Images count": "image_count_score",
    },
    retail_readiness: {
      "Reviews Number": "review_number_score",
      "Review Score": "review_score",
      "Buy box": "buy_box",
      Quantity: "quantity",
    },
    bqs: {
      "A+": "a_plus_score",
      "Brand Content": "brand_content_score",
      "Store Link+": "store_link_score",
      Video: "video_score",
    },
  };

  useEffect(() => {
    if (Object?.keys(selectedRow)?.length > 0) {
      try {
        handleTypeChange(selectedRow?.type);
        setConditionsList(JSON.parse(selectedRow?.criteria));
      } catch (e) {
        console.error("Error", e);
      }
    }
  }, [selectedRow]);

  const modalFooter = [
    <Button key="cancel" onClick={onCancel}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      loading={loading}
      onClick={() => {
        form.validateFields().then((values) => {
          if (Object?.keys(selectedRow)?.length > 0 && !selectedRow?.clone) {
            setLoading(true);
            onUpdate({
              ...values,
              criteria: conditionsList?.filter(
                (d) =>
                  d?.operator &&
                  (d?.value || d?.value == 0) &&
                  (d?.score || d?.score == 0)
              ),
            });
          } else {
            setLoading(true);
            onCreate({
              ...values,
              criteria: conditionsList?.filter(
                (d) =>
                  d?.operator &&
                  (d?.value || d?.value == 0) &&
                  (d?.score || d?.score == 0)
              ),
            });
          }
        });
      }}
    >
      {selectedRow?.clone
        ? "Clone"
        : Object?.keys(selectedRow)?.length > 0
        ? "Save"
        : "Create"}
    </Button>,
  ];
  const handleRemoveCondition = (indexToRemove) => {
    setConditionsList(
      conditionsList.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <Modal
      visible={visible}
      title={
        selectedRow?.clone
          ? "Clone Criteria"
          : selectedRow?.view
          ? "View Criteria"
          : Object?.keys(selectedRow)?.length > 0
          ? "Edit Criteria"
          : "Add Criteria"
      }
      onCancel={onCancel}
      footer={selectedRow?.view ? [] : modalFooter}
      width={750}
    >
      <Form
        form={form}
        initialValues={{
          ...selectedRow,
          name: selectedRow?.clone
            ? selectedRow?.name + "_clone"
            : selectedRow?.name,
        }}
        disabled={selectedRow?.view ? true : false}
        layout="vertical"
        name="form_in_modal"
      >
        <div className="row">
          <div className="col-4">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Criteria Name is required",
                },
              ]}
              hasFeedback
              name="name"
              label="Criteria Name"
            >
              <Input size="large" placeholder="Enter Criteria Name" />
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Criteria Type is required",
                },
              ]}
              hasFeedback
              name="criteria_type"
              label="Criteria Type"
            >
              <Select
                size="large"
                placeholder="Criteria Type"
                className="Select Criteria Type"
                allowClear
                options={[
                  {
                    label: "LQS",
                    value: "lqs",
                  },
                  {
                    label: "BQS",
                    value: "bqs",
                  },
                  {
                    label: "Retail Readiness",
                    value: "retail_readiness",
                  },
                ]}
                onDropdownVisibleChange={(e) => {
                  if (!e) {
                    setOptions(form.getFieldValue("criteria_type"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Metrics is required",
                },
              ]}
              hasFeedback
              name="type"
              label="Metrics"
            >
              <Select
                size="large"
                className="Select Metrics"
                allowClear
                placeholder="Metrics"
                onChange={handleTypeChange}
                onClick={() => {
                  setOptions(form.getFieldValue("criteria_type"));
                }}
                options={Object.entries(findTableData?.[options] || {})?.map(
                  ([key, value]) => ({
                    label: key,
                    value: value,
                  })
                )}
              />
            </Form.Item>
          </div>
          <div className="col-2">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Weight is required",
                },
                () => ({
                  validator(_, value) {
                    const reg = /^[0-9]*(\.[0-9]+)?$/;
                    if (!reg.test(value)) {
                      return Promise.reject(
                        new Error("Weight must be a number")
                      );
                    }
                    const numberValue = parseFloat(value);
                    if (numberValue < 0) {
                      return Promise.reject(
                        new Error("Weight cannot be a negative number")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              hasFeedback
              name="weight"
              label="Weight"
            >
              <Input size="large" placeholder="Weight" />
            </Form.Item>
          </div>
        </div>
        <div
          className="d-grid"
          style={{
            background: "#fef4f3",
            padding: "20px 10px 0px 10px",
            borderRadius: "8px",
            border: "1px dashed #601947",
            height: "300px",
            maxHeight: "300px",
            overflow: "auto",
            alignItems: "start",
          }}
        >
          {conditionsList?.map((d, i) => (
            <div className="row align-items-center mb-5" key={i}>
              <div className="col-3">If</div>
              <div className="col">
                <Select
                  value={d?.operator}
                  placeholder={"Operator"}
                  onChange={(v) => {
                    setConditionsList((prev) => {
                      return prev?.map((e, f) => {
                        if (f === i) {
                          return {
                            ...e,
                            operator: v,
                          };
                        }
                        return e;
                      });
                    });
                  }}
                  disabled={!selectedType || selectedRow?.view}
                  size="large"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className="w-100"
                >
                  <Option value=">=">{">="}</Option>
                  <Option value="<=">{"<="}</Option>
                  <Option value=">">{">"}</Option>
                  <Option value="<">{"<"}</Option>
                  <Option value="=">{"="}</Option>
                  <Option value="Contains">{"Contains"}</Option>
                  <Option value="Doesn't Contain">{"Doesn't Contain"}</Option>
                </Select>
              </div>
              <div className="col">
                <Input
                  type="number"
                  placeholder="Value"
                  pattern="[0-9]*"
                  title="Please enter a number"
                  value={d?.value}
                  onChange={(v) => {
                    const reg = /^\+?\d+(\.\d+)?$/;
                    if (reg.test(v.target.value)) {
                      setConditionsList((prev) => {
                        return prev?.map((e, f) => {
                          if (f === i) {
                            return {
                              ...e,
                              value: v.target.value,
                            };
                          }
                          return e;
                        });
                      });
                    } else {
                      message.destroy();
                      message.error("Please enter a number");
                    }
                  }}
                  disabled={!selectedType || selectedRow?.view}
                  size="large"
                  validateStatus={[
                    "validateFailed",
                    (v) => {
                      const reg = /^\+?\d+(\.\d+)?$/;
                      if (reg.test(v)) {
                        return "validating";
                      }
                      return "error";
                    },
                  ]}
                />
              </div>
              <div className="col">
                <Input
                  placeholder="Score"
                  type="number"
                  pattern="[0-9]*"
                  disabled={!selectedType || selectedRow?.view}
                  value={d?.score}
                  validateStatus={[
                    "validateFailed",
                    (v) => {
                      const reg = /^\d+$/;
                      if (reg.test(v)) {
                        return "validating";
                      }
                      return "error";
                    },
                  ]}
                  onChange={(v) => {
                    const reg = /^\+?\d+(\.\d+)?$/;
                    if (reg.test(v.target.value)) {
                      setConditionsList((prev) => {
                        return prev?.map((e, f) => {
                          if (f === i) {
                            return {
                              ...e,
                              score: v.target.value,
                            };
                          }
                          return e;
                        });
                      });
                    } else {
                      message.destroy();
                      message.error("Please enter a number");
                    }
                  }}
                  size="large"
                />
              </div>
              <div className="col d-flex justify-content-end">
                <Button
                  disabled={!selectedType || selectedRow?.view}
                  type="primary"
                  size="large"
                  className="me-3"
                  onClick={() =>
                    setConditionsList([
                      ...conditionsList,
                      {
                        operator: null,
                        value: null,
                        score: null,
                      },
                    ])
                  }
                >
                  +
                </Button>
                {conditionsList?.length > 1 && (
                  <Button
                    type="dashed"
                    disabled={!selectedType || selectedRow?.view}
                    size="large"
                    onClick={() => handleRemoveCondition(i)}
                  >
                    -
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      </Form>
    </Modal>
  );
};

export default AddCriteria;
