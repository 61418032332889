import { Button, Checkbox, Segmented, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoData from "../../../../components/no-data";
export const Config = [
  {
    title: "Manage User",
    index: "manage-user",
    children: [
      {
        title: "View",
        flag: true,
      },
      {
        title: "Add",
        flag: true,
      },
      {
        title: "Edit",
        flag: true,
      },
      {
        title: "Switch",
        flag: true,
      },
      {
        title: "Delete",
        flag: true,
      },
    ],
  },
  {
    title: "Analytics",
    index: "analytics",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Account Status",
    index: "account-status",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Reports",
    index: "reports",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Inventory",
    index: "inventory",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Brand analytics",
    index: "brand-analytics",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Amazon Catalog Health",
    index: "amazon-catalog-health",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Catalog",
    index: "catalog",
    children: [
      {
        title: "View",
        flag: true,
      },
      {
        title: "Add",
        flag: true,
      },
      {
        title: "Import",
        flag: true,
      },
      {
        title: "Config",
        flag: true,
      },
    ],
  },
  {
    title: "Finance",
    index: "finance",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Customer sentiment",
    index: "customer-sentiment",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Vendor",
    index: "vendor",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Agency",
    index: "agency",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Client data",
    index: "client-data",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Setting",
    index: "setting",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
  {
    title: "Application logs",
    index: "application-logs",
    children: [
      {
        title: "View",
        flag: true,
      },
    ],
  },
];
const RuleConfig = (props) => {
  const {
    AllAdminAAction,
    GetAdminConfigAction,
    AddAdminConfigAction,
    fakeActionManageUser,
  } = props;

  const AllAdminAResponse = useSelector(
    (state) => state.User.AllAdminAResponse || {}
  );
  const GetAdminConfigDataResponse = useSelector(
    (state) => state.User.GetAdminConfigDataResponse || {}
  );
  const AddAdminConfigDataResponse = useSelector(
    (state) => state.User.AddAdminConfigDataResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [configData, setConfigData] = useState({});
  const [configDetails, setConfigDetails] = useState({});

  const [loadingBtn, setLoadingBtn] = useState(false);

  const staticData = {
    config: {
      manage_user: {
        view: {
          status: true,
        },
        add: {
          status: true,
        },
        edit: {
          status: true,
        },
        switch: {
          status: true,
        },
        delete: {
          status: true,
        },
      },
      analytics: {
        view: {
          status: true,
        },
      },
      account_status: {
        view: {
          status: false,
        },
      },
      reports: {
        view: {
          status: true,
        },
        add: {
          status: true,
        },
      },
      inventory: {
        view: {
          status: true,
        },
        add: {
          status: true,
        },
      },
      brand_analytics: {
        view: {
          status: true,
        },
        add: {
          status: true,
        },
      },
      catalog_by_country: {
        view: {
          status: true,
        },
        add: {
          status: true,
        },
      },
      catalog: {
        view: {
          status: true,
        },
        add: {
          status: true,
        },
        import: {
          status: true,
        },
        config: {
          status: true,
        },
      },
      finance: {
        view: {
          status: true,
        },
      },
      customer_sentiment: {
        view: {
          status: true,
        },
      },
      vendor: {
        view: {
          status: "true",
        },
      },
      agency: {
        view: {
          status: "true",
        },
      },
      client_data: {
        view: {
          status: true,
        },
      },
      setting: {
        view: {
          status: true,
        },
      },
      application_logs: {
        view: {
          status: true,
        },
      },
    },
  };

  useEffect(() => {
    if (GetAdminConfigDataResponse?.status === true) {
      setConfigData(
        GetAdminConfigDataResponse?.data
          ? GetAdminConfigDataResponse?.data?.config
            ? JSON.parse(GetAdminConfigDataResponse?.data?.config)
            : {}
          : staticData?.config
      );
      setConfigDetails({
        user_id: GetAdminConfigDataResponse?.data?.user_id || selectedAdmin,
      });

      message.destroy();
      message.success(GetAdminConfigDataResponse?.message);
      fakeActionManageUser("GetAdminConfigDataResponse");
    } else if (GetAdminConfigDataResponse?.status === false) {
      message.destroy();
      message.error(GetAdminConfigDataResponse?.message);
      fakeActionManageUser("GetAdminConfigDataResponse");
    }
  }, [GetAdminConfigDataResponse]);

  useEffect(() => {
    if (AllAdminAResponse?.status === true) {
      setList(
        Object?.entries(AllAdminAResponse?.data)?.map((d) => ({
          label: d?.[1],
          value: d?.[0],
        }))
      );
      setLoading(false);
      fakeActionManageUser("AllAdminAResponse");
    } else if (AllAdminAResponse?.status === false) {
      setList([]);
      setLoading(false);
      fakeActionManageUser("AllAdminAResponse");
    }
  }, [AllAdminAResponse]);

  useEffect(() => {
    if (AddAdminConfigDataResponse?.status === true) {
      message.destroy();
      message.success(AddAdminConfigDataResponse?.message);
      setLoadingBtn(false);
      fakeActionManageUser("AddAdminConfigDataResponse");
    } else if (AddAdminConfigDataResponse?.status === false) {
      message.destroy();
      setLoadingBtn(false);
      message.error(AddAdminConfigDataResponse?.message);
      fakeActionManageUser("AddAdminConfigDataResponse");
    }
  }, [AddAdminConfigDataResponse]);

  const [selectedAdmin, setSelectedAdmin] = useState(null);

  useEffect(() => {
    setLoading(true);
    AllAdminAAction();
    return () => {};
  }, []);

  const onChangeAdmin = (e) => {
    if (e) {
      message.destroy();
      message.loading("Loading...", 0);
      GetAdminConfigAction(e);
    }

    setSelectedAdmin(e);
  };

  return (
    <div className="card mt-6 fadeInBottom">
      <div className="card-header">
        <div className="card-title">Role Config</div>
        <div className="card-toolbar fix-over">
          <Select
            placeholder={`Select Admin`}
            className="w-200px ms-3"
            getPopupContainer={(trigger) => trigger.parentNode}
            loading={loading}
            options={list}
            onChange={(e) => {
              if (!e) {
                setConfigData({});
                setConfigDetails({});
              }
              onChangeAdmin(e);
            }}
            value={selectedAdmin}
            allowClear
          />
          {Object.entries(configData || {})?.length !== 0 && (
            <div className="ms-2">
              <Button
                onClick={() => {
                  const obj = {
                    admin_id: configDetails?.user_id,
                    config: configData,
                  };
                  setLoadingBtn(true);
                  AddAdminConfigAction(obj);
                }}
                loading={loadingBtn}
                type="primary"
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  message.destroy();
                  message.loading("Loading...", 0);
                  GetAdminConfigAction(selectedAdmin);
                }}
                className="ms-2"
                type="dashed"
              >
                Reset
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="card-body">
        {Object.entries(configData || {})?.length === 0 ? (
          <NoData />
        ) : (
          Object.entries(configData || {})?.map(([key, value], i) => {
            return (
              <div
                className="row"
                style={{
                  borderBottom: "1px solid #e3e3e3",
                  padding: "10px 0px",
                }}
                key={i}
              >
                <div
                  className="col-2"
                  style={{ borderRight: "1px solid #e3e3e3" }}
                >
                  <h4 style={{ textTransform: "capitalize" }}>
                    {key?.split("_")?.join(" ")}
                  </h4>
                </div>
                {Object?.entries(value || {})?.map(([key_, value_], t) => (
                  <div className="col-2 d-flex align-items-center" key={t}>
                    <div>{key_}</div>
                    <div className="ms-3">
                      <Checkbox
                        onChange={(e) => {
                          setConfigData({
                            ...configData,
                            [key]: {
                              ...configData?.[key],
                              [key_]: {
                                status: e.target.checked,
                              },
                            },
                          });
                        }}
                        checked={JSON.parse(value_?.status)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default RuleConfig;
